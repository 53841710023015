import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box } from 'theme-ui'
import useScrollAtTop from '../../hooks/useScrollAtTop'
import useScrollDirection from '../../hooks/useScrollDirection'
import useTypedSelector from '../../hooks/useTypedSelector'
import { navigationActions } from '../../redux/modules/navigation'
import useBreakpointIndex from '../../hooks/useBreakpointIndex'
import BoxMotion from '../BoxMotion'
import HeaderLarge from '../HeaderLarge'
import HeaderSmall from '../HeaderSmall'
import MenuLarge from '../MenuLarge'
import MenuSmall from '../MenuSmall'
import { menuActions } from '../../redux/modules/menu'
import { HEADER_HEIGHT_LARGE } from '../../styled/theme'

const Navigation = () => {
  // Redux
  const menuSmallVisible = useTypedSelector(state => state.menu.small.visible)
  const menuLargeActiveSection = useTypedSelector(
    state => state.menu.large.activeSection
  )
  const navigationHeaderAtTop = useTypedSelector(
    state => state.navigation.header?.atTop
  )
  const navigationHeaderHover = useTypedSelector(
    state => state.navigation.header.hover
  )
  const navigationSubheaderVisible = useTypedSelector(
    state => state.navigation?.subheader?.visible
  )
  const dispatch = useDispatch()

  // Ignore scroll direction when:
  // - either menu (large or small) is visible
  // - the header is currently being hovered over
  const scrollDirectionActive =
    !!!menuLargeActiveSection && !menuSmallVisible && !navigationHeaderHover
  const breakpointIndex = useBreakpointIndex()
  const lastDirection = useScrollDirection({
    active: scrollDirectionActive,
    threshold: 10,
    yOffset: HEADER_HEIGHT_LARGE,
  })
  const atTop = useScrollAtTop()
  const router = useRouter()

  // Effects
  useEffect(() => {
    dispatch(navigationActions.setHeaderAtTop({ atTop }))
  }, [atTop])

  // Hide small menu on large breakpoint
  useEffect(() => {
    if (breakpointIndex && breakpointIndex >= 3) {
      dispatch(menuActions.setSmallVisible({ visible: false }))
    }
  }, [breakpointIndex])

  useEffect(() => {
    if (lastDirection === 'down') {
      dispatch(navigationActions.setHeaderVisible({ visible: false }))
      if (navigationHeaderAtTop) {
        dispatch(navigationActions.setHeaderTransparent({ transparent: true }))
      }
    }
    if (lastDirection === 'up') {
      dispatch(navigationActions.setHeaderVisible({ visible: true }))
      dispatch(navigationActions.setHeaderTransparent({ transparent: false }))
    }
  }, [lastDirection])

  return (
    <>
      {/* Small */}
      <Box sx={{ display: ['block', null, null, 'none'] }}>
        <MenuSmall />
        <HeaderSmall />
      </Box>

      {/* Large */}
      <Box sx={{ display: ['none', null, null, 'block'] }}>
        <MenuLarge />
        <HeaderLarge />
      </Box>

      {/* Subheader portal destination */}
      <BoxMotion
        // framer-motion
        animate={{
          y: navigationSubheaderVisible ? '0%' : '-100%',
        }}
        initial={{
          y: '-100%',
        }}
        transition={{
          damping: 30,
          stiffness: 200,
          type: 'spring',
        }}
        // theme-ui
        id="portal-subheader"
        key={router.asPath}
        sx={{
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 'subheader',
        }}
      />
    </>
  )
}

export default Navigation
