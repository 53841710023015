import { debounce } from 'lodash'
import { useRouter } from 'next/router'
import React, { SyntheticEvent, useState } from 'react'
import { useMemo } from 'react'
import { connectHits, connectSearchBox } from 'react-instantsearch-dom'
import { useDispatch } from 'react-redux'
import { Box, Flex, Input } from 'theme-ui'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'
import useTypedSelector from '../../hooks/useTypedSelector'
import { searchActions } from '../../redux/modules/search'
import Button from '../Button'
import Icon from '../Icon'

type Props = {
  // (connectHits)
  hits: any
  // (connectSearchBox)
  currentRefinement: any
  initialValue?: string
  isSearchStalled?: boolean
  refine: any
  showViewAllButton?: boolean
  // (custom)
  syncWithReducer?: boolean
}

const CustomSearchBox = (props: Props) => {
  const {
    // (connectHits)
    hits,
    // (connectSearchBox)
    currentRefinement,
    initialValue = '',
    isSearchStalled,
    refine,
    showViewAllButton,
    // (custom)
    syncWithReducer,
  } = props

  // Redux
  const dispatch = useDispatch()
  const globalSearchState = useTypedSelector(state => state.search.searchState)

  // State
  const [value, setValue] = useState(initialValue)

  const router = useRouter()

  const refineDebounced = useMemo(() => debounce(refine, 250), [])
  const hasHits = hits && hits.length > 0

  // Callbacks
  const handleClearQuery = () => {
    if (value !== '') {
      setValue('')
      refine('')
    }
  }

  const handleInputChange = (value: string) => {
    setValue(value)
    refineDebounced(value)
  }

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault()
    // Only enable submission if hits are found
    if (hasHits) {
      // Update reducer if present
      if (globalSearchState) {
        dispatch(
          searchActions.setSearchState({
            searchState: {
              page: 1,
              query: value,
            },
          })
        )
      } else {
        router.push(`/search?q=${value}`)
      }
    }
  }

  // Effects
  useDeepCompareEffectNoCheck(() => {
    if (syncWithReducer && globalSearchState?.query) {
      if (value !== globalSearchState.query) {
        setValue(globalSearchState.query)
      }
    }
  }, [globalSearchState])

  return (
    <Box>
      <Flex sx={{ alignItems: 'center' }}>
        <form
          action=""
          noValidate
          onSubmit={handleSubmit}
          style={{ width: '100%' }}
        >
          {/* HACK: allow keyboard enter to trigger form submission */}
          <button style={{ display: 'none' }} type="submit" />
          <Flex
            sx={{
              alignItems: 'center',
              flexDirection: ['column', null, null, 'row'],
              width: '100%',
            }}
          >
            <Flex
              sx={{
                alignItems: 'center',
                flex: 1,
                position: 'relative',
                width: '100%',
              }}
            >
              {/* Icon */}
              <Box
                sx={{
                  flexShrink: 0,
                  left: 0,
                  pointerEvents: 'none',
                  position: 'absolute',
                  width: ['20px', null, null, '34px'],
                }}
              >
                <Icon type="search" />
              </Box>
              <Input
                autoFocus
                color="midnight"
                pl={[8, null, null, 12]}
                pr={[12, null, null, hasHits ? 52 : 16]}
                onChange={event => {
                  handleInputChange(event.currentTarget.value)
                  event.preventDefault()
                }}
                placeholder="DO search..."
                sx={{
                  appearance: 'none',
                  border: 'none',
                  borderBottom: '1px solid',
                  borderColor: 'stone',
                  borderRadius: 0,
                  fontSize: ['l', null, null, 'xl'],
                  fontWeight: 'semibold',
                  lineHeight: '1.75em',
                  '&:focus': {
                    borderColor: 'slate',
                    outline: 'none',
                  },
                }}
                type="text"
                value={value}
              />

              <Flex
                sx={{
                  flexShrink: 0,
                  position: 'absolute',
                  right: 0,
                }}
              >
                {value !== '' && (
                  <Button
                    mt="1px"
                    ml={6}
                    onClick={handleClearQuery}
                    sx={{
                      color: 'slate',
                      flexShrink: 0,
                      fontSize: 'xs',
                    }}
                    variant="ghost"
                  >
                    Clear
                  </Button>
                )}

                {showViewAllButton && hasHits && (
                  <Box
                    ml={6}
                    sx={{
                      display: ['none', null, null, 'block'],
                    }}
                  >
                    <Button
                      background="midnight"
                      color="white"
                      type="submit"
                      variant="outline"
                    >
                      View all results
                    </Button>
                  </Box>
                )}
              </Flex>
            </Flex>
          </Flex>

          {showViewAllButton && hasHits && (
            <Box
              mt={6}
              sx={{
                display: ['block', null, null, 'none'],
              }}
            >
              <a href={`/search?q=${value}`}>
                <Button
                  background="midnight"
                  color="white"
                  sx={{ width: '100%' }}
                  variant="outline"
                >
                  View all results
                </Button>
              </a>
            </Box>
          )}
        </form>
      </Flex>
      {/* {isSearchStalled ? 'Search is stalled' : ''} */}
    </Box>
  )
}

const ConnectedCustomSearchBox: any = connectSearchBox(CustomSearchBox)
const AlgoliaSearchBox: any = connectHits(ConnectedCustomSearchBox)

export default AlgoliaSearchBox
