import React from 'react'

const Path01 = () => {
  return (
    <>
      <path d="M1.1908 118.193C0.719459 113.497 0.0940303 110.081 0.075902 106.665C-0.0238041 82.6294 0.00338683 58.6025 0.00338683 34.5665C0.00338683 33.0858 0.0396433 31.5869 0.29344 30.1426C0.909805 26.5272 2.8586 24.6832 6.49334 24.1836C7.78952 24.011 9.11289 23.9928 10.4272 24.0019C53.1467 24.4833 95.8571 24.9466 138.577 25.4735C148.701 25.6006 150.206 27.2358 149.979 37.437C149.399 63.4351 148.837 89.4333 148.112 115.431C148.003 119.156 147.006 122.853 146.39 126.75C143.707 126.35 142.837 124.406 141.586 123.062C130.691 111.416 120.213 99.3529 108.91 88.1252C97.7334 77.0156 85.7415 66.7326 73.804 55.8047C49.7476 76.4706 25.9904 96.8912 1.1908 118.193Z" />
    </>
  )
}

export default Path01
