import React from 'react'

const Path05 = () => {
  return (
    <>
      <path d="M144.721 92.6369C144.721 100.757 144.644 108.877 144.77 116.997C144.808 119.359 144.189 120.53 141.605 121.314C110.614 130.663 79.6516 140.119 48.7187 149.652C46.3474 150.387 45.2053 150.01 43.8987 148.016C31.481 129.085 18.9279 110.222 6.4908 91.3013C5.69715 90.0915 5.13576 88.4946 5.12608 87.0815C5.01961 73.89 5.12607 60.6888 5.00025 47.4973C4.98089 45.1261 6.08423 44.2357 8.11674 43.3646C24.8898 36.1736 41.5758 28.8278 58.3489 21.6465C74.4929 14.7362 90.5014 7.4581 107.003 1.39947C112.762 -0.720075 119.808 0.267114 126.274 0.025156C126.816 0.00579938 127.735 1.36076 127.958 2.20277C133.475 23.0886 139.591 43.8582 144.073 64.9473C145.96 73.8416 144.382 83.3941 144.382 92.6466C144.499 92.6369 144.615 92.6369 144.721 92.6369Z" />
    </>
  )
}

export default Path05
