// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useRouter } from 'next/router'
import React, { useEffect, useRef } from 'react'
import { Box } from 'theme-ui'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'
import useTypedSelector from '../../hooks/useTypedSelector'
import { menuActions } from '../../redux/modules/menu'
import { navigationActions } from '../../redux/modules/navigation'
import { useAppDispatch } from '../../redux/store'
import theme, { customScrollbarStyles } from '../../styled/theme'
import MenuSection from '../MenuSection'

const MenuLarge = () => {
  // Refs
  const refContainer = useRef<HTMLDivElement>(null)
  const refMenuContainer = useRef<HTMLDivElement>(null)

  // Redux
  const dispatch = useAppDispatch()
  const navigationHeaderAtTop = useTypedSelector(
    state => state.navigation?.header?.atTop
  )
  const menuLargeActiveSection = useTypedSelector(
    state => state.menu.large.activeSection
  )
  const navigationSubheaderVisible = useTypedSelector(
    state => state.navigation.subheader.visible
  )
  const everythingSearchState = useTypedSelector(
    state => state.everything.searchState
  )

  const router = useRouter()

  // Callbacks
  const handleCloseMenu = () => {
    if (navigationHeaderAtTop) {
      dispatch(navigationActions.setHeaderTransparent({ transparent: true }))
    }

    // Hide header if the submenu is currently active
    // (this may occur if you scroll down the page with your mouse over the header)
    if (navigationSubheaderVisible) {
      dispatch(navigationActions.setHeaderVisible({ visible: false }))
    }

    dispatch(menuActions.clearLargeActiveSection())
  }

  // Effects
  useEffect(() => {
    /*
    // Lock / unlock body scroll when submenu is active
    if (refContainer?.current) {
      if (menuLargeActiveSection) {
        disableBodyScroll(refContainer.current)
      } else {
        enableBodyScroll(refContainer.current)
      }
    }
    */

    // Reset menu container scroll position on section change
    if (refMenuContainer?.current) {
      refMenuContainer.current.scrollTo(0, 0)
    }
  }, [menuLargeActiveSection])

  // Clear active section on route change
  useEffect(() => {
    // Reset form on route change
    router.events.on('routeChangeComplete', handleCloseMenu)

    return () => {
      router.events.off('routeChangeComplete', handleCloseMenu)
    }
  }, [])

  // Close menu when 'everything' search state has changed (i.e. if a refinement link has been clicked) and the following conditions are met
  // - A menu section is currently active
  useDeepCompareEffectNoCheck(() => {
    if (menuLargeActiveSection) {
      handleCloseMenu()
    }
  }, [everythingSearchState])

  return (
    <Box
      key="menu"
      sx={{
        display: menuLargeActiveSection ? 'block' : 'none',
        height: '100vh',
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 'menu',
      }}
    >
      {/* Overlay */}
      <Box
        onMouseEnter={() => {
          if (menuLargeActiveSection) {
            handleCloseMenu()
          }
        }}
        sx={{
          background: 'rgba(0, 0, 0, 0.5)',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          width: '100%',
        }}
      />

      {/* TODO: move refContainer to parent */}
      <Box
        bg="white"
        ref={refContainer}
        sx={{
          height: '100vh',
          left: 0,
          maxWidth: '530px',
          position: 'absolute',
          width: '100%',
          zIndex: 2,
        }}
      >
        <Box
          bg="white"
          px={5}
          pb={20} // Account for gradient overlay
          pt={8}
          ref={refMenuContainer}
          sx={{
            height: 'menuHeight',
            marginTop: theme.sizes.headerHeight,
            maskImage: `linear-gradient(
              to bottom,
            rgba(255, 255, 255, 1) calc(100% - 80px),
              rgba(255, 255, 255, 0) 100%
            )`,
            overflowY: 'auto',
            ...customScrollbarStyles(),
          }}
        >
          <MenuSection type={menuLargeActiveSection} />
        </Box>
      </Box>
    </Box>
  )
}

export default MenuLarge
