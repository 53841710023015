import React from 'react'

const Path01 = () => {
  return (
    <>
      <path
        d="M1015.1 7.89752C1002.87 7.86679 990.614 7.8213 978.372 7.80966C950.074 7.77866 921.823 7.74329 893.545 7.74136C873.787 7.73938 854.003 7.8238 834.223 7.79276C805.628 7.74927 777.029 7.59988 748.429 7.56117C727.244 7.53107 706.078 7.64071 684.893 7.61061C655.331 7.56747 625.758 7.428 596.207 7.37054C557.187 7.29436 518.236 7.29583 479.238 7.19101C439.23 7.08611 399.237 6.90916 359.243 6.73221C328.133 6.59242 297.023 6.45262 265.986 6.22209C244.948 6.06346 223.986 5.69868 202.919 5.52053C191.185 5.42234 179.319 5.54423 167.481 5.52203C127.977 5.44603 88.4619 5.38435 48.9603 5.25063C34.9032 5.20233 20.7901 5.00431 6.86666 4.78886C4.43777 4.75111 0.365342 4.1875 1.09376 4.04531C3.16894 3.64657 -0.825885 2.76612 12.0702 3.05832C28.5014 3.43258 45.6427 3.57785 62.553 3.68241C80.8616 3.79558 99.1959 3.71172 117.546 3.77236C161.563 3.91574 205.635 4.04519 249.627 4.27495C288.199 4.47194 326.737 4.93327 365.255 5.0335C428.667 5.1946 492.032 5.13872 555.386 5.20784C595.53 5.25149 635.765 5.40187 675.885 5.42122C696.297 5.42947 716.62 5.22032 737.074 5.17604C752.911 5.14037 768.8 5.25918 784.629 5.23306C816.948 5.17755 849.225 5.01092 881.594 5.004C935.443 4.98671 989.358 5.16254 1043.15 5.05804C1079.34 4.99157 1115.28 4.58104 1151.4 4.33579C1159.43 4.28298 1167.55 4.28873 1175.62 4.24111C1197.94 4.11846 1220.29 4.00577 1242.56 3.8393C1260.98 3.70331 1279.29 3.47969 1297.71 3.34847C1301.73 3.31968 1306.42 3.43691 1310.2 3.5983C1319.58 4.00592 1320.85 4.663 1312.1 5.0698C1301.97 5.54524 1290.67 5.89392 1279.3 6.10245C1262.72 6.41008 1245.68 6.58332 1228.73 6.74308C1200.55 7.00673 1172.33 7.26043 1144.05 7.42212C1102.67 7.66015 1061.19 7.81533 1019.76 8C1018.27 7.99533 1016.7 7.92733 1015.1 7.89752Z"
        fill="currentColor"
      />
    </>
  )
}

export default Path01
