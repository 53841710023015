import React from 'react'
import { Box, Flex } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import CartLineItem from '../CartLineItem'
import IllustrationThick from '../IllustrationThick'

const CartLineItems = () => {
  // Store: redux
  const checkout = useTypedSelector(state => state.store?.checkout)
  const lineItems = checkout?.lineItems?.edges?.map(lineItem => lineItem.node)

  return (
    <>
      {lineItems && lineItems?.length > 0 ? (
        <Box>
          {lineItems?.map(lineItem => (
            <CartLineItem lineItem={lineItem} key={lineItem.id} />
          ))}
        </Box>
      ) : (
        <Flex
          sx={{
            alignItems: 'center',
            flex: 1,
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <Flex
            color="slate"
            px={6}
            py={4}
            sx={{
              alignItems: 'center',
              flexDirection: 'column',
              fontSize: 'm',
              fontWeight: 'semibold',
              justifyContent: 'center',
            }}
          >
            <Box
              mb={4}
              sx={{
                heigth: '38px',
                width: '38px',
              }}
            >
              <IllustrationThick type="bag" />
            </Box>
            There's nothing here...yet!
          </Flex>
        </Flex>
      )}
    </>
  )
}

export default CartLineItems
