import { MenuSection, MenuSectionType } from '@types'
import debounce from 'lodash.debounce'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { rgba } from 'polished'
import React, { useEffect, useMemo, useRef } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { COLORS } from '../../constants'
import useTypedSelector from '../../hooks/useTypedSelector'
import { menuActions } from '../../redux/modules/menu'
import { navigationActions } from '../../redux/modules/navigation'
import { useAppDispatch } from '../../redux/store'
import { HEADER_HEIGHT_LARGE } from '../../styled/theme'
import getSingletonHref from '../../utils/getSingletonHref'
import Announcements from '../Announcements'
import BoxMotion from '../BoxMotion'
import ButtonCart from '../ButtonCart'
import ButtonIcon from '../ButtonIcon'
import ButtonSearch from '../ButtonSearch'
import ButtonSubscribe from '../ButtonSubscribe'
import Logo from '../Logo'

const HeaderLarge = () => {
  // Redux
  const menuLargeActiveSection = useTypedSelector(
    state => state.menu.large.activeSection
  )
  const navigationHeader = useTypedSelector(state => state.navigation?.header)
  const navigationHeaderAtTop = useTypedSelector(
    state => state.navigation?.header?.atTop
  )
  const navigationHeaderCustomColor = useTypedSelector(
    state => state.navigation?.header?.customColor
  )
  const navigationHeaderSections = useTypedSelector(
    state => state.navigation?.header?.sections
  )

  const navigationButtonSubscribeLabel = useTypedSelector(
    state => state.navigation.header.subscribeLabel
  )
  const navigationHeaderTransparent = useTypedSelector(
    state => state.navigation?.header?.transparent
  )
  const navigationSubheaderVisible = useTypedSelector(
    state => state.navigation.subheader.visible
  )
  const searchVisible = useTypedSelector(state => state.search?.visible)
  const courseLoginUrl = useTypedSelector(state => state.system.courseLoginUrl)
  const dispatch = useAppDispatch()
  const router = useRouter()

  // Refs
  const refContainer = useRef<HTMLDivElement>(null)

  const cancelDebounce = () => {
    handleShowSectionDebounced.cancel()
  }

  // Callbacks
  const handleMouseEnter = () => {
    dispatch(navigationActions.setHeaderTransparent({ transparent: false }))
    dispatch(navigationActions.setHeaderHover({ hover: true }))
  }

  const handleMouseLeave = () => {
    cancelDebounce()
    dispatch(navigationActions.setHeaderHover({ hover: false }))
    if (navigationHeaderAtTop && !menuLargeActiveSection) {
      dispatch(navigationActions.setHeaderTransparent({ transparent: true }))
    }

    // Hide if subheader (and an menu section) is active
    // (this may occur if you scroll down the page with your mouse over the header)
    if (navigationSubheaderVisible && !menuLargeActiveSection) {
      dispatch(navigationActions.setHeaderVisible({ visible: false }))
    }
  }

  const handleHideSection = () => {
    cancelDebounce()
    if (menuLargeActiveSection) {
      dispatch(menuActions.clearLargeActiveSection())
    }
  }

  const handleShowSection = (sectionType: MenuSectionType) => {
    cancelDebounce()
    dispatch(menuActions.setLargeActiveSection({ activeSection: sectionType }))
    dispatch(navigationActions.setHeaderTransparent({ transparent: false }))
  }

  const handleShowSectionDebounced = useMemo(
    () => debounce(handleShowSection, 250),
    []
  )

  // EFFECTS
  useEffect(() => {
    router.events.on('routeChangeStart', cancelDebounce)
    return () => {
      router.events.off('routeChangeStart', cancelDebounce)
    }
  }, [])

  useEffect(() => {
    return () => {
      cancelDebounce()
    }
  }, [])

  const useTransparency =
    navigationHeaderTransparent && !searchVisible && !menuLargeActiveSection

  const backgroundColor =
    navigationHeaderCustomColor && useTransparency
      ? rgba(COLORS[navigationHeaderCustomColor || 'white'], 0.02)
      : rgba(COLORS.white, 1.0)
  const borderColor =
    navigationHeaderCustomColor && useTransparency
      ? rgba(COLORS[navigationHeaderCustomColor || 'midnight'], 0.2)
      : rgba(COLORS.stone, 1.0)
  const color =
    navigationHeaderCustomColor && useTransparency
      ? COLORS[navigationHeaderCustomColor || 'white']
      : COLORS.midnight

  return (
    <>
      <BoxMotion
        // framer-motion
        animate={{
          // NOTE: we use `top` over `translate` due to visual bugs with `backdrop-filter: blur`
          // when the element has negative Y values.
          top: navigationHeader.visible ? 0 : -HEADER_HEIGHT_LARGE,
          // y: navigationHeader.visible ? '0%' : '-100%',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        transition={{
          damping: 30,
          stiffness: 200,
          type: 'spring',
        }}
        // theme-ui
        ref={refContainer}
        sx={{
          backgroundColor,
          backdropFilter: 'blur(100px)',
          flexDirection: 'column',
          height: HEADER_HEIGHT_LARGE,
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 'header',
        }}
      >
        {/* Row 1 */}
        <Flex
          px={6}
          sx={{
            alignItems: 'center',
            borderBottom: '1px solid',
            borderColor,
            height: '72px',
            justifyContent: 'space-between',
            position: 'relative',
          }}
        >
          {/* Row 1: menu proxy */}
          <Box
            onMouseEnter={handleHideSection}
            sx={{
              height: '100%',
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
            }}
          />

          {/* Logo */}
          <Box ml="-5px" sx={{ color, position: 'relative', width: '77px' }}>
            <NextLink href="/">
              <a>
                <Logo />
              </a>
            </NextLink>
          </Box>

          {/* Announcements */}
          <Box
            sx={{
              color,
              left: '50%',
              position: 'absolute',
              transform: 'translateX(-50%)',
            }}
          >
            <Announcements />
          </Box>

          {/* Subscribe button */}
          <Box sx={{ position: 'relative' }}>
            <ButtonSubscribe
              background={
                navigationHeaderCustomColor && useTransparency
                  ? navigationHeaderCustomColor || 'midnight'
                  : 'racing'
              }
              color={
                navigationHeaderCustomColor && useTransparency
                  ? navigationHeaderCustomColor || 'white'
                  : 'white'
              }
              transparent={navigationHeaderCustomColor && useTransparency}
              label={navigationButtonSubscribeLabel}
            />
          </Box>
        </Flex>

        {/* Row 2 */}
        <Flex
          px={2}
          sx={{
            alignItems: 'center',
            borderBottom: '1px solid',
            borderColor,
            color,
            height: '40px',
            justifyContent: 'space-between',
            position: 'relative',
          }}
        >
          {/* Row 2: menu proxy */}
          <Box
            onMouseEnter={handleHideSection}
            sx={{
              height: '100%',
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
            }}
          />

          {/* Top level navigation */}
          <Flex
            px={1}
            sx={{
              alignItems: 'stretch',
              height: '100%',
              position: 'relative',
            }}
          >
            {navigationHeaderSections?.map(section => {
              if (section._type !== 'linkExternal') {
                return (
                  <NextLink
                    href={getSingletonHref(section.documentType)}
                    key={section._type}
                  >
                    <a>
                      <HeaderLink
                        onMouseEnter={() => {
                          handleShowSectionDebounced(
                            section._type as MenuSectionType
                          )
                        }}
                        onMouseLeave={cancelDebounce}
                        title={section.title}
                        type={section._type}
                      />
                    </a>
                  </NextLink>
                )
              } else {
                /**
                 * This is for externalLinks only
                 */
                return (
                  <a
                    href={section.url}
                    rel="noopener noreferrer"
                    target={section.newWindow ? '_blank' : '_self'}
                  >
                    <HeaderLink
                      onMouseEnter={handleHideSection}
                      title={section.title}
                      type={section._type}
                    />
                  </a>
                )
              }
            })}
          </Flex>

          <Flex p={1} sx={{ alignItems: 'center', position: 'relative' }}>
            {/* Course login URL */}
            {courseLoginUrl && (
              <Box>
                <a href={courseLoginUrl}>
                  <ButtonIcon iconSize="18px" type="learn" />
                </a>
              </Box>
            )}
            {/* Search button */}
            <ButtonSearch />
            {/* Cart button */}
            <ButtonCart />
          </Flex>
        </Flex>
      </BoxMotion>
    </>
  )
}

interface HeaderLinkProps {
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  type: MenuSectionType
  title: string
}

const HeaderLink = ({
  onMouseEnter,
  onMouseLeave,
  type,
  title,
}: HeaderLinkProps) => {
  const menuLargeActiveSection = useTypedSelector(
    state => state.menu.large.activeSection
  )

  const handleMouseEnter = () => {
    if (onMouseEnter) {
      onMouseEnter()
    }
  }

  const handleMouseLeave = () => {
    if (onMouseLeave) {
      onMouseLeave()
    }
  }

  return (
    <Flex
      px="10px" // TODO: consider moving exception into theme
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        fontSize: 'xs',
        height: '100%',
        '&:hover': {
          span: {
            borderColor: 'currentColor',
          },
        },
      }}
    >
      <Text
        sx={{
          borderBottom: '1px solid',
          borderColor:
            menuLargeActiveSection === type ? 'currentColor' : 'transparent',
          '&:hover': {
            borderColor: 'currentColor',
          },
        }}
      >
        {title}
      </Text>
    </Flex>
  )
}

export default HeaderLarge
