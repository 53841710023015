import React, { useEffect, useState } from 'react'
import { Box, Flex, Heading } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import { trackNewsletterFooterSubmit } from '../../utils/analytics/trackNewsletterFooterSubmit'
import BlockSubscribe from '../BlockSubscribe'
import IllustrationThin from '../IllustrationThin'

const Subscribe = () => {
  const newsletter = useTypedSelector(state => state.system.newsletter)

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  // Rendered on the client only
  if (!newsletter?.mailingList || newsletter?.hidden || !mounted) {
    return null
  }

  const title = newsletter?.customTitle || newsletter?.title

  const handleSubmitSuccess = () => {
    // Analytics
    trackNewsletterFooterSubmit()
  }

  return (
    <Flex
      bg="midnight"
      color="white"
      pb={[2, null, 16]}
      pt={[8, null, 14]}
      px={4}
      sx={{
        alignItems: 'center',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      {/* Illustration: lightbulb */}
      <Box
        mb={10}
        sx={{
          height: '44px',
          width: '44px',
        }}
      >
        <IllustrationThin type="lightbulb" />
      </Box>

      {/* Title */}
      {title && (
        <Box sx={{ position: 'relative' }}>
          <Heading
            sx={{
              fontSize: ['l', null, 'xl'],
              fontWeight: 'semibold',
              maxWidth: '595px',
              textAlign: 'center',
            }}
          >
            {title}
          </Heading>
        </Box>
      )}

      {newsletter?.mailingList && (
        <Box
          mt={8}
          sx={{ position: 'relative', maxWidth: '420px', width: '100%' }}
        >
          <BlockSubscribe
            onSuccess={handleSubmitSuccess}
            {...newsletter.mailingList}
          />
        </Box>
      )}
    </Flex>
  )
}

export default Subscribe
