import { createAsyncThunk } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { Checkout } from '@types'
import cookies from 'js-cookie'
import { CHECKOUT_COOKIE_ID } from '../../../../constants'
import createCheckout from '../../../../services/shopify/createCheckout'
import getCheckout from '../../../../services/shopify/getCheckout'
import { CurrencyCode } from '../../../../types/codegen/shopify'
import { RootState } from '../../../store'

const checkoutGet = createAsyncThunk<
  // Return type of the payload creator
  { checkout: Checkout },
  // First argument to the payload creator
  any,
  { state: RootState }
>('store/checkoutGet', async (_, { getState, rejectWithValue }) => {
  // Get checkout ID cookie
  const existingCheckoutId = cookies.get(CHECKOUT_COOKIE_ID)

  let existingCheckout: Checkout
  if (existingCheckoutId) {
    const result = await getCheckout(existingCheckoutId)
    existingCheckout = result?.data?.node as Checkout
    // console.log(result)
    // Existing (non-completed) checkout found
    if (existingCheckout && !existingCheckout?.completedAt) {
      return { checkout: existingCheckout }
    }
  }

  // Get detected user currency from store, use default (store primary) currency if none found
  const detectedCurrency =
    getState()?.store?.detectedCurrency || getState()?.store?.defaultCurrency

  // Create new checkout
  const { data, errors } = await createCheckout({
    currencyCode: detectedCurrency as CurrencyCode,
  })

  const newCheckout = data?.checkoutCreate?.checkout

  if (newCheckout) {
    // Store checkout id cookie
    cookies.set(CHECKOUT_COOKIE_ID, newCheckout.id, {
      expires: 1000 * 60 * 60 * 24 * 7, // 7 days
    })

    return {
      checkout: newCheckout,
    }
  } else {
    Sentry.captureMessage(
      'A user was unable to create a new checkout (and no existing checkout was found).'
    )

    return rejectWithValue('Unable to create new checkout')
  }
})

export default checkoutGet
