import {
  ArticlePreview,
  ManifestoPreview,
  OnlineCoursePreview,
  PlaylistPreview,
  ProductPreview,
  ReadingListPreview,
  TalkPreview,
  WorkshopPreview,
} from '@types'
import { Hit } from 'react-instantsearch-core'
import humanizeString from 'humanize-string'
import NextLink from 'next/link'
import React, { useState } from 'react'
import { Box, Flex } from 'theme-ui'
import Eyebrow from '../Eyebrow'
import Link from '../Link'

type CardBasic = Hit &
  (
    | ArticlePreview
    | ManifestoPreview
    | OnlineCoursePreview
    | PlaylistPreview
    | ProductPreview
    | ReadingListPreview
    | TalkPreview
    | WorkshopPreview
  )

export type Props = {
  item: CardBasic
}

const DocumentHit = (props: Props) => {
  const { item } = props

  // State
  const [hover, setHover] = useState(false)

  const href = item.slug

  return (
    <Flex mb={1} sx={{ justifyContent: 'space-between' }}>
      {/* Info */}
      <Box sx={{ flex: 1 }}>
        <Eyebrow color="slate">{humanizeString(item.type)}</Eyebrow>

        {/* Title */}
        {item?.title && (
          <Box
            mt={2}
            sx={{
              fontSize: 'm',
              fontWeight: 'semibold',
            }}
          >
            <NextLink href={href} passHref>
              <Link
                active={hover}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                variant="simple"
              >
                {item.title}
              </Link>
            </NextLink>
          </Box>
        )}
      </Box>
    </Flex>
  )
}

export default DocumentHit
