import { CurrencyCode } from '../types/codegen/shopify'

const formatIntlCurrency = ({
  amount,
  currencyCode,
  locale = 'en',
  significantFigures = 2,
}: {
  amount: number
  currencyCode: CurrencyCode
  locale?: string
  significantFigures?: number
}) => {
  return new Intl.NumberFormat(locale, {
    currency: currencyCode,
    minimumFractionDigits: significantFigures,
    maximumFractionDigits: significantFigures,
    style: 'currency',
  }).format(amount)
}

export default formatIntlCurrency
