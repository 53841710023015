import { IllustrationThickType } from '@types'
import NextLink from 'next/link'
import React from 'react'
import { Box, Flex, Heading } from 'theme-ui'
import IllustrationThick from '../IllustrationThick'
import Link from '../Link'

export type Props = {
  description?: string
  href?: string
  illustrationType?: IllustrationThickType
  label?: string
  large?: boolean
  title?: string
}

const IndexSubheader = (props: Props) => {
  const { description, href, illustrationType, label, large, title } = props

  if (!title) {
    return null
  }

  return (
    <Box mb={[4, null, null, 7]}>
      <Flex
        sx={{
          alignItems: description ? 'flex-end' : 'center',
          justifyContent: 'space-between',
        }}
      >
        {title && (
          <Flex
            sx={{
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            {illustrationType && (
              <Box mr={2} sx={{ height: '24px', width: '24px' }}>
                <IllustrationThick type={illustrationType} />
              </Box>
            )}
            <Box>
              <Heading
                sx={{
                  fontSize: large ? ['m', null, null, 'l'] : 'm',
                  fontWeight: 'semibold',
                  lineHeight: 'single',
                }}
              >
                {href ? (
                  <NextLink href={href} passHref>
                    <Link variant="simple">{title}</Link>
                  </NextLink>
                ) : (
                  title
                )}
              </Heading>

              {/* Description (large) */}
              {description && (
                <Box
                  mt={4}
                  sx={{
                    display: ['none', null, null, 'block'],
                    fontSize: 'xs',
                    lineHeight: 'body',
                    maxWidth: '380px',
                  }}
                >
                  {description}
                </Box>
              )}
            </Box>
          </Flex>
        )}

        {href && (
          <NextLink href={href} passHref>
            <Link variant="simple" sx={{ flexShrink: 0, fontSize: 'xs' }}>
              {label || 'View more'}
            </Link>
          </NextLink>
        )}
      </Flex>

      {/* Description (small) */}
      {description && (
        <Box
          mt={4}
          sx={{
            display: ['block', null, null, 'none'],
            fontSize: 's',
            lineHeight: 'body',
            maxWidth: '380px',
          }}
        >
          {description}
        </Box>
      )}
    </Box>
  )
}

export default IndexSubheader
