import React from 'react'
import { Image, ThemeUIStyleObject } from 'theme-ui'
import useImageLoaded from '../../hooks/useImageLoaded'

type Props = {
  src: string
  sx?: ThemeUIStyleObject
}

const ImageBasic = (props: Props) => {
  const { src, sx } = props

  const imageLoaded = useImageLoaded(src, true)

  return (
    <Image
      src={src}
      sx={{
        opacity: Number(imageLoaded),
        pointerEvents: 'none',
        userSelect: 'none',
        width: '100%',
        ...sx,
      }}
    />
  )
}

export default ImageBasic
