import { ReactNode } from 'react'
import { createPortal } from 'react-dom'
import usePortal from '../../hooks/usePortal'

const Portal = ({ id, children }: { children: ReactNode; id: string }) => {
  const target = usePortal(id)
  return createPortal(children, target)
}

export default Portal
