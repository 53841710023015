import NextLink from 'next/link'
import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import { MoneyV2 } from '../../types/codegen/shopify'
import getSingletonHref from '../../utils/getSingletonHref'
import Button from '../Button'
import ButtonCheckout from '../ButtonCheckout'
import FormattedMoney from '../FormattedMoney/FormattedMoney'
import PortableText from '../PortableText'

const CartFooter = () => {
  // Redux
  const cartText = useTypedSelector(state => state.system.cartText)
  const checkout = useTypedSelector(state => state.store?.checkout)
  const lineItems = checkout?.lineItems?.edges?.map(lineItem => lineItem.node)

  return (
    <Box>
      {lineItems && lineItems?.length > 0 ? (
        <Box>
          {/* Custom cart text */}
          {cartText && (
            <Box
              py={6}
              px={[4, null, null, 6]}
              sx={{
                borderTop: '1px solid',
                borderColor: 'stone',
                li: { fontSize: 'xxs' },
                p: { fontSize: 'xxs' },
              }}
            >
              <PortableText blocks={cartText} />
            </Box>
          )}

          {/* Subtotal + Shipping */}
          <Box
            pb={8}
            pt={6}
            px={[4, null, null, 6]}
            sx={{
              borderTop: '1px solid',
              borderColor: 'stone',
              fontSize: 'xs',
              fontWeight: 'semibold',
              justifyContent: 'space-between',
            }}
          >
            <Flex mb={4} sx={{ justifyContent: 'space-between' }}>
              <Text>Subtotal</Text>
              <FormattedMoney price={checkout?.subtotalPriceV2 as MoneyV2} />
            </Flex>

            <Flex sx={{ justifyContent: 'space-between' }}>
              <Text>Delivery</Text>
              <Text>Calculated at checkout</Text>
            </Flex>
          </Box>

          {/* Checkout */}
          <Flex
            pb={8}
            px={[4, null, null, 6]}
            sx={{
              flexDirection: 'column',
              flexShrink: 0,
            }}
          >
            <ButtonCheckout />
          </Flex>
        </Box>
      ) : (
        <Flex
          pb={8}
          px={[4, null, null, 6]}
          sx={{
            width: '100%',
          }}
        >
          <Box mr={1} sx={{ flex: 1 }}>
            <NextLink href={getSingletonHref('courses')}>
              <a>
                <Button
                  background="midnight"
                  color="white"
                  sx={{ width: '100%' }}
                >
                  Shop Courses
                </Button>
              </a>
            </NextLink>
          </Box>
          <Box ml={1} sx={{ flex: 1 }}>
            <NextLink href={getSingletonHref('productIndex')}>
              <a>
                <Button
                  background="midnight"
                  color="white"
                  sx={{ width: '100%' }}
                >
                  Shop Products
                </Button>
              </a>
            </NextLink>
          </Box>
        </Flex>
      )}
    </Box>
  )
}

export default CartFooter
