import React, { FunctionComponent, ReactNode } from 'react'
import { Box, BoxOwnProps, ThemeUIStyleObject } from 'theme-ui'
import theme from '../../styled/theme'

type Props = {
  children?: ReactNode
  sx?: ThemeUIStyleObject
}

const Eyebrow: FunctionComponent<BoxOwnProps> = (props: Props) => {
  const { children, sx, ...rest } = props
  return (
    <Box
      sx={{
        fontSize: 'xxs',
        fontWeight: 'semibold',
        lineHeight: 'single',
        textTransform: 'uppercase',
        // Force computed values for letter-spacing
        '--letter-spacing': theme.letterSpacings.loose,
        letterSpacing: 'var(--letter-spacing)',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default Eyebrow
