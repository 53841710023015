import React from 'react'

import theme from '../../../styled/theme'

const Path04 = () => {
  return (
    <g fill={theme.colors.bubblegum}>
      {/* <path d="M68.1 27.0999C53.2 12.7999 35.6 4.8999 16.7 -0.100098H0V89.8999C1.9 91.2999 3.7 92.5999 5.5 93.9999H89.9C90.4 89.5999 90.9 85.1999 90.8 80.8999C90.6 59.9999 83.3 41.6999 68.1 27.0999Z" /> */}
      <path d="M-10.7618 -5.51196C18.4446 -1.01991 46.1489 6.15869 68.0116 27.1663C83.2225 41.7838 90.542 60.0035 90.8209 80.9738C90.8939 86.6238 89.954 92.2793 89.5239 97.9366C89.3997 99.5458 88.6564 100.339 87.0587 100.792C69.4989 105.784 51.9674 110.863 34.4044 115.821C33.4683 116.085 32.1635 115.619 31.2156 115.133C30.1598 114.598 29.2938 113.672 28.3845 112.871C10.8857 97.428 -11.5928 89.1725 -31.7963 77.4211C-33.72 76.3047 -34.0985 75.2538 -33.5 73C-27.7959 51.3002 -18.3954 23.8499 -12.8178 2.11482C-12.1849 -0.314671 -11.5141 -2.72209 -10.7618 -5.51196Z" />
    </g>
  )
}

export default Path04
