import NextLink from 'next/link'
import React from 'react'
import { Box } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import { selectSectionInfo } from '../../redux/modules/menu'
import Pill from '../Pill'
import MenuSectionWrapper from '../MenuSectionWrapper'

const MenuSectionInfo = () => {
  const sectionInfo = useTypedSelector(selectSectionInfo)

  return (
    <MenuSectionWrapper
      documentTypeIndex="info"
      documentTypeLabel="View info overview"
    >
      {sectionInfo?.links.map((link, index) => {
        return (
          <Box key={index} mb={2}>
            <NextLink href={link.slug}>
              <a>
                <Pill linkedLayout={link.linkedLayout} title={link.title} />
              </a>
            </NextLink>
          </Box>
        )
      })}
    </MenuSectionWrapper>
  )
}

export default MenuSectionInfo
