import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type PlaylistsReducerState = Record<
  string,
  {
    items?: string[]
    shuffle: boolean
  }
>

export const initialState = {} as PlaylistsReducerState

const playlistsSlice = createSlice({
  name: 'playlists',
  initialState,
  reducers: {
    setPlaylistShuffleOff(
      state,
      action: PayloadAction<{ playlistId: string }>
    ) {
      state[action.payload?.playlistId] = {
        shuffle: false,
      }
    },
    setPlaylistShuffleOn(
      state,
      action: PayloadAction<{ items: string[]; playlistId: string }>
    ) {
      state[action.payload?.playlistId] = {
        items: action.payload?.items,
        shuffle: true,
      }
    },
  },
})

export const playlistsActions = playlistsSlice.actions

export default playlistsSlice.reducer
