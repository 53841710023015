import React from 'react'

const Path06 = () => {
  return (
    <>
      <path d="M138 100.75C137.543 105.02 137.335 109.331 136.574 113.545C134.819 123.218 129.371 130.627 121.672 136.43C112.225 143.566 101.417 147.379 89.8158 149.129C78.8634 150.775 68.0871 150.205 57.5914 146.416C45.5413 142.065 36.4717 134.456 31.2239 122.503C25.6155 109.74 21.9459 96.3592 18.2925 82.97C15.6565 73.3295 13.6054 63.5445 13.0766 53.5187C12.2033 37.1275 18.8293 24.1075 31.2159 13.8008C39.5323 6.88146 49.3951 3.51009 59.8428 1.54346C71.2199 -0.599771 82.5489 -0.696095 93.7817 2.43446C105.495 5.70148 114.373 12.5486 120.189 23.353C126.719 35.4739 130.661 48.4617 133.145 61.915C135.34 73.8272 137.135 85.8036 137.639 97.9325C137.68 98.8556 137.648 99.7868 137.648 100.71C137.76 100.726 137.88 100.742 138 100.75Z" />
    </>
  )
}

export default Path06
