import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import { rootReducer } from './modules'

const store = configureStore({
  reducer: rootReducer,
})

export const initStore = (preloadedState = {}) => {
  const store = configureStore({
    preloadedState,
    reducer: rootReducer,
  })
  return store
}

export type AppDispatch = typeof store.dispatch

export type RootState = ReturnType<typeof store.getState>

// Export a hook that can be reused to resolve types
export const useAppDispatch = () => useDispatch<AppDispatch>()
