import React from 'react'

const Path19 = () => {
  return (
    <>
      <path d="M76.9005 64.4002C77.0005 65.6002 77.4005 66.7002 77.4005 67.9002C77.4005 70.2002 78.6005 72.2002 78.1005 74.5002C77.8005 75.8002 77.2005 77.1002 76.2005 77.1002C74.6005 77.2002 73.2005 75.8002 73.5005 74.0002C73.6005 73.3002 73.2005 72.7002 73.1005 72.2002C72.6005 70.4002 72.8005 68.8002 72.6005 67.1002C72.4005 65.4002 72.2005 63.5002 72.1005 61.7002C72.0005 60.6002 72.0005 59.7002 72.4005 58.6002C72.8005 57.3002 74.0005 57.5002 75.0005 57.4002C75.7005 57.3002 76.1005 58.2002 76.2005 58.9002C76.5005 60.8002 76.4005 62.7002 76.9005 64.4002Z" />
      <path d="M91.0996 70.3999C91.0996 70.7999 90.9996 71.0999 91.0996 71.1999C92.5996 74.1999 92.1996 77.3999 92.8996 80.4999C93.1996 81.5999 92.7996 82.0999 92.1996 82.7999C91.4996 83.5999 90.2996 83.4999 89.5996 83.2999C88.0996 82.8999 87.2996 81.4999 86.7996 79.8999C86.4996 78.7999 86.3996 77.6999 86.3996 76.4999C86.3996 75.3999 86.0996 74.1999 85.8996 73.0999C85.4996 71.0999 85.5996 68.8999 85.8996 66.8999C85.9996 65.5999 86.7996 65.5999 88.0996 65.7999C89.6996 66.0999 90.2996 67.0999 90.8996 68.3999C91.0996 69.1999 90.7996 69.9999 91.0996 70.3999Z" />
      <path d="M84.1998 53.3998C83.7998 50.6998 83.8998 47.9998 83.8998 45.1998C83.8998 43.9998 83.5998 42.5998 83.4998 41.3998C83.3998 40.4998 83.7998 39.7998 84.2998 39.1998C85.3998 38.2998 87.6998 39.0998 87.8998 40.4998C88.2998 42.7998 89.0998 44.9998 89.1998 47.1998C89.2998 49.3998 89.6998 51.4998 89.4998 53.4998C89.1998 55.2998 88.2998 56.5998 86.3998 57.2998C85.5998 57.5998 84.8998 57.3998 84.5998 56.3998C84.2998 55.3998 83.8998 54.3998 84.1998 53.3998Z" />
      <path d="M87.3 11.3C87.2 12 87 13.2 87.3 13.7C88.1 15.3 87.3 16.9 88.1 18.4C88.4 18.9 88.1 19.5 88.2 20.2C88.5 21.3 88.5 22.5 88.6 23.6C88.7 24.9 87.9 26 87.3 27.2C87.2 27.3 87 27.6 86.9 27.5C86.2 26.8 85.1 27.4 84.6 26.7C84.1 26 83.8 25.2 83.8 24.3C83.8 21.3 83.5 18.2 83.5 15.3C83.5 13.7 83.5 11.9 83.9 10.3C84.3 8.99998 85.1 7.89998 85.9 7.99998C86.7 8.09998 87.4 9.49998 87.4 10.8C87.3 10.6 87.3 10.7 87.3 11.3Z" />
      <path d="M74.6001 25.1998C74.3001 24.8998 74.1001 25.0998 74.1001 25.2998C74.2001 26.0998 73.7001 26.0998 73.2001 26.1998C72.5001 26.2998 71.9001 26.0998 71.3001 26.2998C71.2001 26.2998 70.6001 25.9998 70.4001 25.4998C69.3001 23.4998 70.1001 21.4998 70.0001 19.3998C70.0001 18.5998 69.7001 17.8998 69.6001 17.1998C68.8001 14.9998 69.1001 12.6998 69.2001 10.5998C69.2001 9.89979 70.8001 8.59979 71.5001 8.69979C72.4001 8.99979 72.8001 9.59979 72.8001 10.5998C73.1001 12.9998 73.5001 15.2998 74.6001 17.7998C75.4001 19.6998 75.4001 21.9998 75.0001 24.0998C74.7001 24.3998 74.2001 24.5998 74.6001 25.1998Z" />
      <path d="M74.3 37.0001C74 37.5001 74.3 37.9001 74.3 38.5001C74.8 40.7001 75.5 42.8001 75.8 45.0001C75.9 46.3001 76.2 47.8001 76.1 49.3001C76 50.5001 75.4 51.3001 74.6 52.1001C74.5 52.2001 74.2 52.4001 74.1 52.4001C73.3 51.5001 71.7 51.7001 71 50.4001C70.9 50.3001 70.5 50.1001 70.7 49.9001C71.4 49.0001 70.7 47.9001 70.7 46.9001C70.7 45.8001 70.6 44.5001 70.7 43.4001C71 41.5001 69.6 40.0001 70.2 38.1001C70.5 36.9001 70.3 35.7001 71.5 34.9001C72.3 34.5001 73 34.9001 73.5 35.3001C74.1 35.6001 74.1 36.5001 74.3 37.0001Z" />
      <path d="M79.8996 103.2C77.4996 102.1 77.0996 99.7998 76.8996 97.4998C76.7996 94.3998 76.1996 91.3998 76.0996 88.2998C76.0996 87.3998 76.4996 86.6998 76.7996 85.9998C76.8996 85.4998 77.4996 85.5998 77.6996 85.6998C78.0996 85.7998 78.5996 86.1998 78.5996 86.7998C78.9996 89.0998 79.3996 91.2998 79.7996 93.4998C80.1996 95.6998 80.1996 97.6998 80.8996 99.7998C81.1996 100.6 81.2996 101.8 80.1996 102.2C79.9996 102.2 79.9996 102.7 79.8996 103.2Z" />
      <path d="M59.7999 64.2C59.7999 63.3 59.6999 62 59.4999 60.8C59.3999 58.8 60.2999 57.3 60.7999 55.5C60.7999 55.4 60.8999 55.2 61.0999 55.4C61.3999 55.5 61.4999 55.8 61.4999 56.1C61.5999 57 61.5999 57.9 61.7999 58.8C62.0999 61.8 62.8999 64.5 63.2999 67.4C63.5999 69.8 63.9999 72.3 63.3999 74.7C63.2999 75.5 62.9999 76 62.2999 76.2C61.5999 76.3 61.4999 75.5 61.1999 75C60.6999 73.4 60.4999 71.6 60.3999 70C60.2999 68.8 60.0999 67.4 59.9999 66.2C59.8999 65.5 59.5999 65 59.7999 64.2Z" />
      <path d="M95.4997 103.7C95.1997 105.2 95.8997 106.4 95.8997 107.6C95.8997 108.5 96.1997 109.6 94.7997 110.3C94.0997 110.6 92.5997 110.4 92.1997 109.8C91.6997 108.6 90.9997 107.5 90.9997 106C90.8997 103.2 90.2997 100.3 89.7997 97.4999C89.6997 96.6999 89.0997 95.6999 90.2997 95.0999C91.3997 94.5999 92.2997 94.7999 92.9997 95.7999C94.8997 98.2999 94.8997 101.1 95.4997 103.7Z" />
      <path d="M65.6995 94.1998C65.6995 94.4998 65.7995 94.8998 65.7995 95.2998C65.7995 96.1998 65.4995 97.1998 64.8995 97.4998C64.3995 97.7998 63.5995 98.2998 62.8995 97.3998C62.7995 97.0998 62.5995 96.9998 62.5995 96.6998C61.7995 94.3998 61.7995 91.9998 61.3995 89.6998C61.0995 88.0998 61.3995 86.4998 60.8995 84.7998C60.3995 83.1998 61.1995 81.9998 62.4995 81.3998C63.1995 81.0998 63.3995 81.6998 63.5995 82.1998C64.8995 85.7998 65.4995 89.7998 65.8995 93.4998C65.6995 93.6998 65.6995 93.9998 65.6995 94.1998Z" />
      <path d="M63.6996 113.9C63.3996 112.4 63.1996 110.5 62.8996 108.6C62.7996 107.3 62.5996 106 62.5996 104.7C62.5996 103.9 63.0996 103.2 63.8996 103.1C64.6996 102.8 65.0996 103.6 65.3996 104.2C66.0996 106.1 66.6996 108.1 66.9996 110.3C67.0996 111.2 67.2996 112.2 67.4996 113.1C67.8996 114.4 67.5996 115.8 66.7996 116.9C66.2996 117.7 64.7996 117.8 64.1996 117C64.0996 116.4 63.6996 115.2 63.6996 113.9Z" />
      <path d="M38.4996 27.3002C37.9996 25.4002 38.0995 23.0002 37.7995 20.7002C37.6995 19.2002 37.6995 17.5002 38.8995 16.4002C39.5995 15.9002 40.0996 15.9002 40.4996 16.7002C40.7996 17.1002 40.8996 17.6002 40.8996 18.2002C41.1996 20.8002 41.2996 23.3002 41.6996 25.9002C41.9996 27.9002 41.2996 29.8002 41.1996 31.8002C41.1996 32.7002 40.2996 33.0002 39.8996 32.2002C39.2996 30.8002 38.2996 29.5002 38.4996 27.3002Z" />
      <path d="M61.7001 23.6C61.6001 25.4 60.6001 26.4 58.7001 26.4C58.6001 26.4 58.2001 26.3 58.2001 26.1C58.2001 25 57.3001 24.2 57.1001 23C56.8001 20.8 57.1001 18.7 56.4001 16.5C56.0001 15.3 57.1001 12.9 58.3001 12C59.1001 11.5 59.6001 11.6 59.9001 12.5C60.2001 14 60.3001 15.5 60.6001 17C60.9001 19.2 61.7001 21.4 61.7001 23.6Z" />
      <path d="M77.2002 117.7C77.2002 116.9 77.3002 116.1 77.3002 115.1C77.3002 114.1 78.1002 113.8 78.8002 113.5C79.3002 113.2 79.7002 114 80.1002 114.4C81.0002 115.2 80.9002 116.2 80.9002 117C80.9002 118.2 81.6002 119.2 81.7002 120.2C81.8002 121.8 82.2002 123.4 82.4002 124.9C82.4002 125.6 82.0002 126.2 81.3002 126.5C80.2002 126.9 79.4002 126.6 78.7002 125.7C78.2002 124.9 78.2002 123.9 78.0002 123C77.9002 121.5 77.4002 119.7 77.2002 117.7Z" />
      <path d="M55.1001 127.3C55.0001 128.4 55.2001 129.5 55.4001 130.7C55.5001 132.2 55.7001 133.5 55.4001 135.2C55.3001 136.1 54.7001 136.3 54.2001 136.4C53.7001 136.5 53.1001 136.4 52.7001 135.6C52.3001 134.3 52.0001 133 52.0001 131.6C51.9001 129.4 52.3001 127.1 51.5001 125C51.2001 124.2 51.1001 122.8 52.0001 122.2C52.5001 121.8 53.6001 122.1 53.9001 122.6C54.8001 123.9 55.1001 125.7 55.1001 127.3Z" />
      <path d="M44.1 130.4C44.1 130.7 44.1 130.9 44.2 131.2C44.5 132.5 44.7 133.9 44.5 135.4C44.4 136.3 45 137.3 45 138.4C45 138.9 45 139.5 44.9 140C44.6 140.8 44.2 141.6 43.4 141.5C42.9 141.4 42.6 140.6 42.3 139.9C41.4 136.1 41.5 132.1 40.8 128.3C40.5 127 41.2 125.6 42.1 125.2C42.6 124.9 43 124.9 43.3 125.7C44 127.2 43.9 128.8 44.1 130.4Z" />
      <path d="M47.2001 65.9999C48.0001 63.1999 46.7001 60.2999 47.2001 57.3999C47.3001 56.5999 46.9001 55.3999 48.4001 55.3999C49.7001 55.3999 49.7001 56.5999 49.6001 57.3999C49.5001 59.1999 50.1001 60.7999 50.3001 62.3999C50.4001 63.2999 50.6001 64.1999 50.3001 65.0999C50.0001 66.8999 51.0001 68.4999 51.1001 70.1999C51.1001 70.6999 51.1001 71.2999 51.0001 71.7999C50.9001 72.2999 50.2001 73.0999 50.1001 72.8999C49.4001 72.3999 48.2001 72.9999 47.9001 71.7999C47.5001 69.9999 47.5001 68.0999 47.2001 65.9999Z" />
      <path d="M63.3004 129.7C63.8004 128.2 63.4004 126.6 62.8004 125.1C62.5004 124.4 62.8004 123.8 63.3004 123.2C63.6004 122.8 64.0004 122.8 64.4004 122.8C64.8004 122.8 64.9004 123.3 65.1004 123.6C66.3004 126.7 66.9004 129.9 67.8004 133C68.1004 133.9 67.7004 134.8 67.4004 135.7C67.3004 136.1 66.6004 136.4 66.3004 136.2C65.8004 136.1 65.0004 135.7 64.8004 135.3C64.5004 133.5 63.3004 132.1 63.3004 130.2C63.3004 130 63.3004 129.9 63.3004 129.7Z" />
      <path d="M42.4996 63.4001C42.5996 66.2001 42.5996 69.1001 42.9996 71.9001C43.0996 72.8001 42.5995 73.8001 42.5995 74.7001C42.5995 74.8001 42.4996 75.1001 42.2996 75.1001C42.1996 75.1001 41.9996 75.0001 41.9996 74.8001C41.2996 73.2001 40.7996 71.4001 40.4996 69.5001C40.1996 67.1001 40.1996 64.8001 40.3996 62.3001C40.3996 61.4001 39.8996 60.5001 39.9996 59.5001C39.9996 59.1001 39.9996 58.4001 40.3996 58.4001C40.7996 58.3001 41.2995 58.4001 41.5995 58.9001C42.0995 60.5001 42.3996 61.9001 42.4996 63.4001Z" />
      <path d="M47.5004 47.5999C47.5004 45.1999 47.9004 43.0999 47.4004 41.0999C47.3004 40.6999 47.4004 40.2999 47.4004 39.8999C47.4004 39.1999 47.5004 38.5999 47.3004 37.6999C47.0004 36.9999 47.2004 36.1999 48.0004 35.7999C48.7004 35.4999 48.9004 35.7999 49.1004 36.2999C49.4004 37.4999 49.9004 38.6999 49.6004 40.0999C49.5004 40.3999 49.9004 40.7999 49.9004 41.1999C50.0004 43.9999 50.2004 46.8999 50.3004 49.6999C50.3004 50.1999 50.3004 51.1999 49.6004 51.1999C48.9004 51.1999 48.4004 50.6999 48.0004 49.9999C47.5004 49.1999 47.6004 48.0999 47.5004 47.5999Z" />
      <path d="M46.7996 30.3998C46.2996 30.0998 46.2996 29.4998 46.3996 29.0998C46.6996 25.1998 46.2996 21.3998 46.0996 17.4998C46.0996 16.6998 46.1996 15.9998 46.0996 15.1998C46.0996 14.7998 46.4996 14.0998 46.9996 13.9998C47.6996 13.8998 47.7996 14.4998 47.8996 14.8998C48.1996 15.7998 47.8996 16.8998 48.1996 17.9998C48.2996 18.4998 48.2996 19.1998 48.4996 19.7998C49.2996 21.7998 48.5996 23.9998 48.9996 26.0998C49.2996 27.3998 48.8996 28.6998 48.8996 29.8998C48.5996 29.9998 48.0996 29.5998 47.9996 30.1998C47.8996 30.7998 47.1996 30.3998 46.7996 30.3998Z" />
      <path d="M50.2003 93.3002C50.6003 89.5002 49.8003 86.3002 49.3003 82.9002C49.3003 82.5002 49.2003 82.1002 49.3003 81.7002C49.3003 81.0002 49.4003 80.2002 50.2003 80.2002C51.0003 80.2002 51.8003 80.7002 52.0003 81.7002C52.3003 83.3002 52.7003 84.9002 52.9003 86.6002C53.2003 88.8002 53.8003 91.1002 53.0003 93.3002C52.7003 94.0002 53.1003 94.6002 53.3003 95.2002C53.4003 96.0002 53.2003 96.7002 52.4003 96.8002C51.9003 96.9002 51.6003 96.3002 51.2003 95.7002C50.6003 94.9002 49.9003 94.1002 50.2003 93.3002Z" />
      <path d="M93.5003 128.8C93.8003 128 93.5003 127.5 93.4003 126.8C92.9003 124.9 92.7003 122.9 93.0003 120.9C93.1003 120.1 93.7003 119.7 94.3003 119.7C95.1003 119.7 94.8003 120.5 95.1003 120.9C95.8003 122.8 95.6003 124.8 96.3003 126.7C97.0003 128.6 96.8003 130.9 97.1003 132.9C97.1003 133.6 96.8003 134.2 96.0003 134.2C95.5003 134.2 94.9003 133.8 94.7003 133.4C93.4003 131.9 93.9003 130.3 93.5003 128.8Z" />
      <path d="M40.2002 49.6C39.9002 48.7 40.3002 47.6 39.9002 46.6C39.0002 44.5 39.0002 42.3 39.1002 40C39.1002 39.2 38.6002 38.2 39.1002 37.4C39.2002 37.1 39.1002 36.5 39.4002 36.6C39.9002 37 40.5002 36.5 41.0002 36.7C41.8002 37.2 42.2002 37.9 42.2002 38.9C42.2002 40.7 42.1002 42.5 42.3002 44.3C42.4002 45.2 43.1002 46.2 43.0002 47.3C42.7002 48.6 43.1002 50 42.2002 51.2C41.8002 51.1 41.4002 50.7 41.4002 50.3C41.4002 49.5 40.7002 49.6 40.2002 49.6Z" />
      <path d="M42.9002 119.7C42.2002 118.9 41.7002 118.2 41.8002 117.5C41.9002 116 41.5002 114.7 41.8002 113.3C42.1002 111.4 41.8002 109.5 41.5002 107.8C41.5002 107.4 41.4002 107 41.4002 106.5C41.4002 105.8 41.7002 105.2 41.0002 104.5C40.7002 104.2 40.9002 103.7 41.0002 103.3C41.1002 102.8 41.5002 102.4 42.1002 102.2C42.6002 102.2 42.6002 102.9 42.8002 103.3C44.0002 108 44.3002 112.7 43.7002 117.5C43.7002 118 42.9002 118.5 42.9002 119.7Z" />
      <path d="M50.6004 110.2C50.3004 107.6 50.1004 104.9 50.5004 102.4C50.6004 101.9 50.6004 101.2 51.3004 101.2C51.8004 101.2 52.0004 102 52.0004 102.3C52.3004 104.5 52.5004 106.8 53.2004 108.9C53.7004 110.7 53.2004 112.4 53.5004 114C53.6004 114.8 53.1004 115.5 52.3004 115.6C51.5004 115.7 51.4004 114.9 51.2004 114.4C50.6004 112.9 50.6004 111.5 50.6004 110.2Z" />
      <path d="M42.7998 81.3999C42.3998 83.1999 43.2998 84.5999 43.4998 86.2999C43.4998 86.5999 43.7998 86.7999 43.5998 86.9999C42.8998 88.9999 43.9998 90.8999 43.8998 92.8999C43.8998 92.9999 43.8998 93.2999 43.7998 93.3999C43.4998 93.7999 43.4998 94.6999 42.9998 94.5999C42.4998 94.5999 42.6998 93.6999 42.4998 93.2999C41.7998 91.9999 41.5998 90.2999 41.5998 88.7999C41.5998 86.5999 41.0998 84.4999 40.7998 82.2999C40.7998 81.7999 40.7998 81.1999 41.0998 80.6999C41.3998 80.2999 41.7998 79.8999 42.1998 79.8999C42.8998 80.2999 42.7998 80.9999 42.7998 81.3999Z" />
      <path d="M59.2002 44.6001C58.9002 42.2001 59.2002 39.5001 58.7002 36.9001C58.6002 36.0001 58.3002 35.0001 58.7002 34.1001C58.8002 34.0001 58.7002 33.6001 59.0002 33.6001C59.3002 33.6001 59.5002 33.9001 59.5002 34.3001C59.9002 36.2001 60.0002 38.2001 60.7002 40.1001C60.8002 40.4001 60.8002 40.6001 60.7002 40.9001C60.3002 42.8001 60.8002 44.7001 61.2002 46.4001C61.5002 47.2001 61.3002 47.9001 60.8002 48.2001C60.4002 48.3001 59.9002 48.1001 59.6002 47.4001C59.6002 46.4001 59.1002 45.7001 59.2002 44.6001Z" />
      <path d="M97.1 22.1999C96.3 20.2999 96.4 17.8999 96 15.5999C95.7 14.0999 95.6 12.3999 97.1 11.0999C97.8 10.5999 98.4 10.5999 99 11.3999C99.3 11.7999 99.5 12.2999 99.5 12.8999C99.9 15.4999 100.3 17.9999 100.8 20.4999C101.2 22.4999 100.5 24.3999 100.7 26.3999C100.7 27.2999 99.8 27.5999 99.2 26.8999C98.2 25.6999 97.1 24.3999 97.1 22.1999Z" />
      <path d="M119 121.6C118.9 122.7 119.3 123.6 119.4 124.7C119.7 126 119.8 127.4 119.7 128.7C119.6 129.6 119 129.8 118.5 129.9C117.8 130 117.3 129.9 116.9 129.2C116.4 128 116.1 126.8 115.8 125.6C115.5 123.6 115.9 121.6 114.9 119.5C114.5 118.8 114.4 117.5 115.4 116.8C115.9 116.4 117.2 116.5 117.6 117.1C118.5 118.5 119 120.1 119 121.6Z" />
      <path d="M109 124.9C109 125.2 109.1 125.4 109.1 125.7C109.4 127 109.9 128.3 109.6 129.6C109.5 130.5 110.3 131.4 110.3 132.3C110.3 132.8 110.3 133.4 110.2 133.8C110.1 134.6 109.5 135.4 108.7 135.3C108.2 135.3 107.8 134.5 107.5 133.8C106.3 130.3 106.2 126.5 105.2 122.9C104.9 121.7 105.5 120.3 106.5 119.8C107 119.5 107.4 119.5 107.8 120.2C108.6 121.8 108.5 123.4 109 124.9Z" />
      <path d="M108.7 60.3998C109.2 57.6998 108 54.9998 108.3 52.2998C108.4 51.5998 108 50.3998 109.2 50.2998C110.3 50.2998 110.4 51.3998 110.4 52.1998C110.4 53.7998 110.9 55.2998 111.3 56.8998C111.4 57.6998 111.6 58.4998 111.4 59.4998C111.3 61.0998 112.1 62.5998 112.3 64.1998C112.3 64.6998 112.3 65.2998 112.3 65.7998C112.2 66.2998 111.8 66.9998 111.5 66.8998C111 66.4998 109.9 66.9998 109.6 65.9998C109.3 63.9998 109.2 62.0998 108.7 60.3998Z" />
      <path d="M103.1 58.1002C103.4 60.9002 103.5 63.8002 104.2 66.6002C104.5 67.5002 103.9 68.5002 103.8 69.6002C103.8 69.7002 103.7 70.0002 103.5 70.0002C103.3 70.0002 103.2 69.9002 103.2 69.7002C102.4 68.1002 101.7 66.3002 101.3 64.6002C100.9 62.2002 100.8 59.9002 100.8 57.4002C100.8 56.5002 100.1 55.6002 100.1 54.6002C100.1 54.2002 100 53.5002 100.4 53.4002C100.9 53.3002 101.5 53.3002 101.9 53.9002C102.5 55.1002 102.9 56.6002 103.1 58.1002Z" />
      <path d="M107.3 42.4002C107.2 40.0002 107.4 37.8002 106.8 35.9002C106.7 35.5002 106.8 35.1002 106.8 34.7002C106.8 34.0002 106.9 33.4002 106.5 32.5002C106.2 31.8002 106.2 31.0002 107.2 30.6002C107.9 30.3002 108.1 30.5002 108.4 31.1002C108.8 32.3002 109.5 33.5002 109.2 34.9002C109.2 35.2002 109.5 35.6002 109.6 36.0002C109.9 38.8002 110.3 41.7002 110.5 44.5002C110.5 45.0002 110.5 46.0002 109.8 46.0002C109.1 46.0002 108.5 45.5002 108 44.9002C107.3 44.1002 107.4 43.0002 107.3 42.4002Z" />
      <path d="M105.9 25.3C105.2 25 105.4 24.5 105.4 24C105.4 20.1 104.9 16.3 104.3 12.4C104.2 11.6 104.3 10.8 104.2 10.1C104.2 9.59998 104.6 8.89998 105.3 8.79998C106.1 8.69998 106.2 9.19998 106.4 9.69998C106.8 10.6 106.5 11.7 106.8 12.8C106.9 13.3 106.9 13.9 107.2 14.4C108.1 16.4 107.6 18.6 108.1 20.6C108.5 21.8 108.1 23.2 108.2 24.4C107.8 24.5 107.3 24.1 107.1 24.8C107.1 25.6 106.5 25.2 105.9 25.3Z" />
      <path d="M112.1 87.4998C112.2 83.8998 111.3 80.8998 110.5 77.7998C110.4 77.3998 110.4 76.9998 110.4 76.5998C110.4 76.0998 110.4 75.2998 111.3 75.2998C112.2 75.2998 113.1 75.6998 113.3 76.5998C113.7 78.0998 114.2 79.5998 114.6 81.0998C115.1 83.0998 115.9 85.2998 115.1 87.3998C114.8 88.0998 115.4 88.5998 115.5 89.1998C115.6 89.9998 115.5 90.6998 114.6 90.7998C113.9 90.8998 113.7 90.3998 113.3 89.8998C112.8 88.7998 112 88.1998 112.1 87.4998Z" />
      <path d="M99.7004 44.4999C99.4004 43.5999 99.7004 42.4999 99.3004 41.3999C98.4004 39.1999 98.2004 37.0999 98.2004 34.7999C98.2004 33.9999 97.7004 32.9999 98.1004 32.1999C98.2004 31.8999 98.1004 31.2999 98.2004 31.3999C98.7004 31.7999 99.1004 31.0999 99.7004 31.4999C100.5 31.9999 100.8 32.6999 100.9 33.4999C101 35.3999 101 37.0999 101.4 38.8999C101.5 39.7999 102.2 40.7999 102.1 41.8999C102 43.1999 102.4 44.5999 101.6 45.8999C101.2 45.7999 100.9 45.3999 100.9 45.0999C100.8 44.2999 100.4 44.2999 99.7004 44.4999Z" />
      <path d="M106.6 114.5C105.9 113.7 105.5 113 105.5 112.3C105.5 110.8 105.1 109.5 105.2 108.1C105.3 106.2 105.1 104.3 104.7 102.6C104.6 102.2 104.6 101.8 104.6 101.3C104.6 100.6 104.7 99.9999 104.2 99.2999C104 98.9999 104 98.3999 104.2 97.9999C104.2 97.4999 104.6 96.8999 105 96.8999C105.4 96.8999 105.5 97.3999 105.7 97.7999C106.9 102.4 107.5 107.1 107.3 112C107.3 112.9 106.6 113.4 106.6 114.5Z" />
      <path d="M114 104C113.7 101.4 113.3 98.6999 113.5 96.1999C113.5 95.6999 113.6 94.9999 114.2 94.8999C114.7 94.8999 114.7 95.5999 114.9 95.9999C115.2 98.1999 115.6 100.5 116.2 102.5C116.7 104.3 116.5 106 116.6 107.6C116.7 108.4 116.3 109.1 115.7 109.4C115 109.5 114.8 108.9 114.6 108.2C114.3 106.8 114.1 105.3 114 104Z" />
      <path d="M104.2 76.3C103.9 78.1 105 79.4999 105.3 81.1999C105.3 81.4999 105.6 81.7 105.6 81.9C104.9 83.9 106.3 85.8 106.3 87.8C106.3 87.9 106.3 88.2 106.2 88.3C105.9 88.8 106.1 89.6 105.4 89.6C104.9 89.6 105 88.7 104.7 88.4C103.8 87.1 103.5 85.6 103.5 84.1C103.4 81.9 102.6 79.8 102.3 77.6C102.2 77.1 102.3 76.5 102.6 76C102.9 75.6 103.3 75.0999 103.8 75.1999C104.2 75.1999 104.2 75.8 104.2 76.3Z" />
    </>
  )
}

export default Path19
