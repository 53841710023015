import React from 'react'
import { Box, Flex } from 'theme-ui'

import useTypedSelector from '../../hooks/useTypedSelector'
import { menuActions } from '../../redux/modules/menu'
import { useAppDispatch } from '../../redux/store'
import ButtonIcon from '../ButtonIcon'

type Props = {
  iconSize?: number
}

const ButotnMenuToggle = (props: Props) => {
  const { iconSize = 16 } = props

  // Redux
  const dispatch = useAppDispatch()
  const smallVisible = useTypedSelector(state => state.menu.small.visible)

  // Callbacks
  const handleToggleSmallMenu = () => {
    dispatch(menuActions.setSmallVisible({ visible: !smallVisible }))
  }

  return (
    <Flex>
      <Box
        onClick={handleToggleSmallMenu}
        sx={{
          userSelect: 'none',
        }}
      >
        {smallVisible ? (
          <ButtonIcon type="close" />
        ) : (
          <ButtonIcon iconSize={iconSize} type="menu" />
        )}
      </Box>
    </Flex>
  )
}

export default ButotnMenuToggle
