import React from 'react'

const Path14 = () => {
  return (
    <>
      <path d="M113.425 76.5007C114.801 76.9666 116.184 77.402 117.544 77.9061C122.458 79.747 127.585 81.1601 132.224 83.5433C148.004 91.6552 152.651 107.031 148.631 122.568C146.659 130.183 141.692 135.141 133.722 137.149C120.425 140.51 107.098 143.726 93.4112 144.818C80.0227 145.888 66.596 146.56 53.1617 145.368C49.9903 145.086 46.8266 144.734 43.6553 144.543C30.4731 143.741 19.2778 137.669 8.31181 131.13C3.01603 127.976 1.04445 122.553 0.295546 116.763C-1.17168 105.473 2.89376 96.1313 10.9406 88.3707C13.6764 85.7355 17.0617 84.2001 20.6228 82.9169C25.781 81.0531 30.8476 78.9373 36.9381 76.5389C34.3017 75.248 32.6128 74.3925 30.9087 73.5828C25.6435 71.0774 20.5999 68.213 16.2364 64.2869C9.67206 58.3824 6.97449 51.1947 8.21247 42.3341C10.0924 28.8524 16.8859 19.4266 29.5714 14.0415C46.0777 7.02949 63.2336 4.22622 81.0544 5.18102C95.3369 5.94486 109.321 8.38151 123.092 12.3382C136.878 16.2949 144.352 25.545 147.164 39.1337C148.096 43.648 148.081 48.1851 147.278 52.7453C146.132 59.2761 142.854 64.3327 137.061 67.6019C130.986 71.0392 124.384 73.0634 117.575 74.4765C116.237 74.7515 114.915 75.0876 113.586 75.3931C113.54 75.7597 113.479 76.134 113.425 76.5007Z" />
    </>
  )
}

export default Path14
