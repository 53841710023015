import { Refinement } from '@types'

const getRefinementHref = (refinements: Refinement[]) => {
  const searchParams = new URLSearchParams()
  refinements.forEach(ref => {
    searchParams.append(ref.slug, encodeURIComponent(ref.value))
  })

  return `/everything?${searchParams.toString()}`
}

export default getRefinementHref
