import { PayloadAction, createSlice, createSelector } from '@reduxjs/toolkit'
import { IubendaPreferences } from '@types'
import cloneDeep from 'clone-deep'
import { RootState } from '../../store'

type IubendaReducerState = IubendaPreferences

export const initialState = {
  consent: undefined,
  id: undefined,
  purposes: undefined,
  timestamp: undefined,
  version: undefined,
} as IubendaReducerState

const iubendaSlice = createSlice({
  name: 'iubenda',
  initialState,
  reducers: {
    setPreferences(state, action: PayloadAction<IubendaPreferences>) {
      const preferences = action.payload

      // Send iubenda events to GTM
      window?.dataLayer.push({
        iubenda_ccpa_opted_out: window?._iub.cs.api.isCcpaOptedOut(),
      })

      if (!preferences) {
        window?.dataLayer.push({
          event: 'iubenda_preference_not_needed',
        })
      } else {
        if (preferences.consent === true) {
          window?.dataLayer.push({
            event: 'iubenda_consent_given',
          })
        } else if (preferences.consent === false) {
          window?.dataLayer.push({
            event: 'iubenda_consent_rejected',
          })
        } else if (preferences.purposes) {
          for (var purposeId in preferences.purposes) {
            if (preferences.purposes[purposeId]) {
              window?.dataLayer.push({
                event: 'iubenda_consent_given_purpose_' + purposeId,
              })
            }
          }
        }
      }

      Object.assign(state, cloneDeep(action.payload))
    },
  },
})

// Selectors
const selectSelf = (state: RootState) => state

export const selectMeasurementConsent = createSelector(
  selectSelf,
  state => state.iubenda.purposes?.[4]
)

export const iubendaActions = iubendaSlice.actions

export default iubendaSlice.reducer
