import React from 'react'

const Sun = () => {
  return (
    <>
      <path d="M24.5368 13.5204C23.103 11.1062 20.6833 10.176 18.0081 10.7731C17.0221 10.9931 17.4397 12.5047 18.4249 12.2847C20.9879 11.7126 22.9913 13.1995 23.7978 15.6309C24.411 17.4793 23.4872 19.3675 22.2303 20.6874C19.7702 23.2715 15.6279 23.492 13.0117 21.0717C11.1844 19.3807 10.5707 16.61 11.1816 14.2496C11.886 11.5271 14.3477 10.4816 16.9626 10.8233C17.9625 10.954 17.95 9.38469 16.9626 9.25547C8.65535 8.16984 7.14066 19.1966 13.1647 23.1661C17.0669 25.7376 22.2928 23.9108 24.6252 20.1355C25.9048 18.064 25.7501 15.5651 24.5368 13.5204Z" />
      <path d="M8.36397 19.4029C8.30548 18.9901 7.98273 18.5723 7.47911 18.5723C7.38843 18.5723 7.29466 18.5861 7.20058 18.6137C5.94794 18.9756 4.84008 19.3621 3.8145 19.795C3.28642 20.0176 3.1365 20.5421 3.2215 20.9631C3.30785 21.3909 3.62048 21.6789 3.99956 21.6789C3.99956 21.6789 3.99956 21.6789 3.99987 21.6789C4.11483 21.6789 4.23466 21.6525 4.35573 21.6018C5.36748 21.1745 6.46347 20.7924 7.70655 20.433C8.23963 20.2786 8.42111 19.8043 8.36397 19.4029Z" />
      <path d="M4.6198 6.85946C6.09767 7.83859 7.69407 8.83038 9.79693 10.0754C9.95111 10.1667 10.1114 10.2127 10.2729 10.2131C10.6831 10.2131 11.0529 9.90957 11.1716 9.47532C11.285 9.06128 11.1241 8.66687 10.7508 8.44616C8.65127 7.20297 7.0587 6.21364 5.58794 5.23872C5.42832 5.13303 5.26123 5.0791 5.09204 5.0791C4.7111 5.0791 4.35839 5.36751 4.23386 5.78094C4.10661 6.20388 4.25437 6.61732 4.6198 6.85946Z" />
      <path d="M17.9766 25.5796C17.709 25.5796 17.4618 25.6797 17.2987 25.854C17.1424 26.0216 17.0682 26.2467 17.0855 26.5049C17.162 27.6542 17.239 28.8035 17.3206 29.9518C17.363 30.5453 17.8549 30.8552 18.3194 30.8552C18.5873 30.8552 18.8342 30.7549 18.9966 30.5804C19.1525 30.413 19.2256 30.1877 19.2071 29.9296C19.1256 28.7809 19.049 27.6325 18.9725 26.4833C18.933 25.8898 18.4415 25.5796 17.9766 25.5796Z" />
      <path d="M11.1008 23.5347C10.7789 23.5347 10.5031 23.6915 10.3232 23.9763C9.01816 26.0447 7.68929 28.0828 6.32971 30.1625C6.1415 30.4505 6.12099 30.7622 6.27153 31.0411C6.4401 31.3532 6.80084 31.5624 7.16955 31.5624C7.16986 31.5624 7.16986 31.5624 7.16986 31.5624C7.49076 31.5624 7.76805 31.4084 7.95101 31.1286C9.3078 29.054 10.6343 27.0196 11.947 24.9383C12.1309 24.6475 12.1472 24.3321 11.993 24.0522C11.8225 23.743 11.4634 23.5347 11.1008 23.5347Z" />
      <path d="M23.7745 9.86077C24.0951 9.86077 24.3728 9.70851 24.5571 9.43202C25.0399 8.70776 25.5056 7.99369 25.8984 7.22249C26.0562 6.9127 26.0462 6.58452 25.8708 6.29846C25.6858 5.99671 25.3476 5.80176 25.0092 5.80176C24.6895 5.80176 24.4163 5.97818 24.2598 6.28592C23.8756 7.03971 23.4151 7.74605 22.9374 8.46297C22.747 8.74884 22.7241 9.05905 22.8731 9.33733C23.0402 9.65001 23.4025 9.86047 23.7745 9.86077Z" />
      <path d="M29.9618 26.0499C28.577 24.9473 27.3772 24.0841 26.1855 23.3325C26.0281 23.2328 25.864 23.1826 25.6975 23.1826C25.3038 23.1826 24.9435 23.4778 24.8214 23.9003C24.7003 24.3193 24.8543 24.7237 25.2232 24.9567C26.3574 25.6725 27.4798 26.4743 28.6538 27.409C28.8345 27.5527 29.046 27.6286 29.2651 27.6286C29.2655 27.6286 29.2651 27.6286 29.2655 27.6286C29.6958 27.6286 30.1089 27.3407 30.2473 26.9448C30.3636 26.6129 30.2593 26.2873 29.9618 26.0499Z" />
      <path d="M17.0115 8.13488C17.2804 8.13488 17.5255 8.03438 17.6836 7.85933C17.8354 7.69137 17.9016 7.46615 17.8753 7.20832C17.7266 5.75103 17.6391 4.28428 17.5623 2.9071C17.5289 2.31163 17.0404 2 16.5751 2C16.3078 2 16.0599 2.09988 15.895 2.27426C15.7371 2.4416 15.6609 2.66657 15.675 2.92465C15.7522 4.30837 15.8402 5.78142 15.9891 7.24063C16.0489 7.82751 16.5488 8.13488 17.0115 8.13488Z" />
      <path d="M29.8369 14.6628C29.2889 14.7355 27.67 14.7394 27.351 14.7397C26.7481 14.7397 26.4326 15.2148 26.4326 15.6841C26.4329 16.1527 26.7481 16.6278 27.3507 16.6278H27.351C27.6738 16.6278 29.3121 16.6232 29.8792 16.5481C30.4394 16.4736 30.8018 16.1036 30.8018 15.6053C30.8017 15.1391 30.3626 14.5901 29.8369 14.6628Z" />
    </>
  )
}

export default Sun
