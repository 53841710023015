import React, { ReactNode } from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import { systemActions } from '../../redux/modules/system'
import { useAppDispatch } from '../../redux/store'
import { LinkVariant } from '../../types'
import { trackSubscribeClick } from '../../utils/analytics/trackSubscribeClick'
import Link from '../Link'

type Props = {
  children: ReactNode
  sx?: ThemeUIStyleObject
  variant?: LinkVariant
}

const LinkModalNewsletter = (props: Props) => {
  const { children, sx, variant } = props

  const dispatch = useAppDispatch()

  const handleShowNewsletter = () => {
    dispatch(
      systemActions.setNewsletterModalVisible({ newsletterModalVisible: true })
    )

    // Analytics
    trackSubscribeClick()
  }

  return (
    <Link onClick={handleShowNewsletter} sx={sx} variant={variant}>
      {children}
    </Link>
  )
}

export default LinkModalNewsletter
