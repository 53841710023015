import React from 'react'

const Question = () => {
  return (
    <>
      <path d="M14.8105 25.1282C13.8723 25.4028 13.2642 26.1611 12.8436 27.005C12.4054 27.8839 12.0887 28.8933 12.287 29.8798C12.3996 30.4378 12.644 31.0722 12.9557 31.5505C14.3065 33.6222 16.9174 33.8923 18.987 32.8304C21.121 31.7351 22.1983 29.174 20.5458 27.2017C19.3006 25.7153 16.7609 24.5579 14.8105 25.1282ZM17.9575 31.0694C16.7903 31.7675 15.3336 31.4679 14.5924 30.3315C14.1196 29.6061 14.1976 28.5089 14.6502 27.806C15.1708 26.9978 16.1295 27.0673 16.9472 27.2811C17.5595 27.4414 18.1411 27.7374 18.6274 28.1425C19.9041 29.2062 19.1774 30.3403 17.9575 31.0694Z" />
      <path d="M11.3377 10.0751C11.8387 10.8218 12.8308 10.5728 13.1629 9.98818C14.5288 8.86959 17.36 7.43445 18.2804 9.7486C19.2923 12.2929 16.0085 13.5157 14.0885 13.7769C13.5148 13.8552 13.3041 14.2822 13.3572 14.7196C13.3432 14.7956 13.3364 14.8767 13.3395 14.964C13.4377 17.4099 13.4414 19.8574 13.4417 22.3049C13.4417 22.412 13.4596 22.5075 13.4844 22.5974C13.4305 23.1426 13.7538 23.7329 14.4615 23.7324C15.9956 23.7321 17.5249 23.7167 19.0499 23.5285C19.2147 23.5082 19.3574 23.4564 19.4809 23.383C19.9456 23.3336 20.3756 23.0103 20.3756 22.4071V17.8983C24.5138 15.4132 28.6069 10.2346 26.364 5.23165C23.3773 -1.42926 13.551 1.1222 8.7466 3.7861C8.46492 3.94231 8.31553 4.1659 8.26496 4.40486C7.97122 4.7735 7.86903 5.32804 8.23416 5.79535C9.32057 7.18412 10.3552 8.61113 11.3377 10.0751ZM24.4759 5.97885C26.6246 10.115 22.0609 14.6675 18.8409 16.4295C18.4294 16.6545 18.2992 17.0231 18.3496 17.3756C18.3443 17.4211 18.3359 17.4644 18.3359 17.5134V21.5593C17.3856 21.6453 16.4341 21.6797 15.4799 21.6891C15.4782 19.6625 15.4673 17.6367 15.399 15.6115C17.1029 15.257 18.6729 14.4871 19.7246 13.0353C20.5962 11.8326 20.691 10.1751 20.0955 8.84189C19.4966 7.50163 18.189 6.63295 16.7422 6.49356C15.1323 6.33894 13.6447 7.05113 12.3828 8.00387C11.7285 7.06684 11.0471 6.15 10.3534 5.2415C14.3334 3.21751 21.9203 1.05935 24.4759 5.97885Z" />
    </>
  )
}

export default Question
