import React, { useEffect, useState } from 'react'
import { Box } from 'theme-ui'
import { v4 as uuidv4 } from 'uuid'
import { SanityImage } from '../../types'
import NextImageZoomable from '../NextImageZoomable'
import MaskCircleRandom from '../MaskCircleRandom'

type Props = {
  circleIndex?: number
  image?: SanityImage
  sizes?: string | (string | null)[]
  zoomed?: boolean
}

const ImageAvatar = (props: Props) => {
  const { image, circleIndex, sizes = '100vw', zoomed } = props

  const [maskId, setMaskId] = useState<string>()

  useEffect(() => {
    // Generate random mask ID
    setMaskId(uuidv4())
  }, [])

  if (!image) {
    return null
  }

  return (
    <Box
      sx={{
        clipPath: `url(#${maskId})`,
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
      }}
    >
      {maskId && (
        <MaskCircleRandom maskId={maskId} patternIndex={circleIndex} />
      )}

      <NextImageZoomable
        image={image}
        layout="fill"
        sizes={sizes}
        zoomed={zoomed}
      />
    </Box>
  )
}

export default ImageAvatar
