import React from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import BoxMotion from '../BoxMotion'
import NextImage, { Props as NextImageProps } from '../NextImage'

type Props = NextImageProps & {
  sx?: ThemeUIStyleObject
  zoomed?: boolean
}

const NextImageZoomable = (props: Props) => {
  const { sx, zoomed, ...rest } = props
  return (
    <BoxMotion
      // framer-motion
      animate={{
        scale: zoomed ? 1.06 : 1.0,
      }}
      transition={{
        damping: 25,
        stiffness: 200,
        type: 'spring',
      }}
      // theme-ui
      sx={{
        display: 'inline-block',
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
        ...sx,
      }}
    >
      <NextImage {...rest} />
    </BoxMotion>
  )
}

export default NextImageZoomable
