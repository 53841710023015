import React from 'react'
import { Box, Flex, Text } from 'theme-ui'

import ButtonAddSingleQuantity from '../ButtonAddSingleQuantity'
import ButtonRemoveSingleQuantity from '../ButtonRemoveSingleQuantity'

type Props = {
  productVariantId: string
  quantity: number
}

const CartQuantityStepper = (props: Props) => {
  const { productVariantId, quantity } = props

  return (
    <Flex sx={{ alignItems: 'center' }}>
      {/* Remove */}
      <Box>
        <ButtonRemoveSingleQuantity
          productVariantId={productVariantId}
          quantity={quantity}
        />
      </Box>

      <Box
        mx={3}
        sx={{
          border: '1px solid',
          borderColor: 'stone',
          fontSize: 'xxs',
          fontWeight: 'semibold',
          lineHeight: 'single',
          userSelect: 'none',
        }}
      >
        <Box px={2} py={1}>
          {quantity}
        </Box>
      </Box>

      {/* Add */}
      <Box>
        <ButtonAddSingleQuantity
          productVariantId={productVariantId}
          quantity={quantity}
        />
      </Box>
    </Flex>
  )
}

export default CartQuantityStepper
