import React from 'react'

const Keyboard = () => {
  return (
    <>
      <path d="M1.80823 26.2671C1.8115 26.2974 1.81984 26.3238 1.82581 26.3521C1.7927 26.6555 1.9372 26.967 2.3227 27.0598C11.7969 29.3386 21.5919 28.6182 31.2449 28.8372C31.3585 28.8397 31.4576 28.8199 31.5426 28.7831C31.8144 28.7107 32.0484 28.4963 32.0626 28.1363C32.2409 23.7371 32.452 18.2865 32.2379 13.8846C32.2343 13.813 32.2197 13.7489 32.2001 13.6882C32.1506 13.3822 31.929 13.097 31.5367 13.067C26.9823 12.7187 22.4276 11.9502 17.8696 11.3947C18.1609 10.5997 18.6998 9.97669 19.4889 9.58471C19.5271 9.57938 19.5649 9.57136 19.602 9.55622C20.8258 9.0626 21.6766 8.16526 21.9033 6.8382C22.047 5.99662 20.7606 5.6364 20.6163 6.48337C20.473 7.32348 20.0559 7.94312 19.2472 8.2694C19.1927 8.29153 19.1419 8.31957 19.0934 8.35018C19.0103 8.35685 18.9249 8.37758 18.8377 8.42025C17.6842 8.9831 16.8739 10.019 16.5189 11.2362C11.5212 10.68 6.51849 10.4555 1.50512 11.3981C0.995071 11.4941 0.904342 11.9796 1.08612 12.345C1.11576 12.5519 1.14509 12.7583 1.17473 12.9652C1.16671 13.0149 1.16286 13.068 1.16575 13.1254C1.38141 17.1568 1.3705 22.2497 1.80823 26.2671ZM2.62588 14.2935C2.62934 13.7316 2.66438 13.1722 2.70474 12.6122C12.0871 11.0914 21.5069 13.6102 30.8565 14.4122C31.0201 18.401 30.8536 23.4393 30.6903 27.4254C21.5056 27.2514 12.186 27.8832 3.16647 25.8116C2.81683 22.3428 2.77679 17.8057 2.62261 14.3248C2.62331 14.3138 2.62588 14.3045 2.62588 14.2935Z" />
      <path d="M4.19213 16.7217C5.15903 17.0273 5.47164 17.1464 6.48088 17.0387C6.64835 17.0206 6.78438 16.9562 6.88968 16.8642C7.15949 16.7894 7.39343 16.5762 7.41538 16.2209C7.4739 15.2871 7.52773 15.0543 7.53216 14.1184C7.5328 13.9612 7.48917 13.8331 7.41974 13.7306C7.34595 13.4641 7.13171 13.2422 6.77277 13.2422H4.37814C4.06797 13.2422 3.86675 13.4087 3.76967 13.6255C3.71885 13.715 3.68465 13.8197 3.67747 13.9432C3.62517 14.8382 3.61914 15.0327 3.61914 15.929C3.61914 16.1135 3.67926 16.2584 3.7723 16.3674C3.84603 16.5236 3.9818 16.6554 4.19213 16.7217ZM5.05213 14.6441H6.11758C6.1036 15.2183 6.07851 15.0909 6.04476 15.6643C5.4606 15.6857 5.59137 15.6277 5.02377 15.4908C5.02537 14.9748 5.03519 15.1597 5.05213 14.6441Z" />
      <path d="M8.74779 17.0721C9.71469 17.3779 10.0273 17.4968 11.0365 17.3889C11.204 17.371 11.3402 17.3066 11.4453 17.2144C11.7152 17.1398 11.949 16.9265 11.9712 16.5713C12.0295 15.6373 12.0836 15.4044 12.0878 14.4685C12.0887 14.3114 12.0448 14.1832 11.9754 14.0809C11.9018 13.8142 11.6876 13.5923 11.3285 13.5923H8.934C8.6237 13.5923 8.42248 13.7588 8.3254 13.9757C8.27471 14.0651 8.24038 14.1698 8.23332 14.2933C8.1809 15.1885 8.1748 15.3831 8.1748 16.2792C8.1748 16.4637 8.23493 16.6085 8.32809 16.7176C8.40176 16.8741 8.53766 17.0056 8.74779 17.0721ZM9.60799 14.9944H10.6733C10.6593 15.5687 10.6342 15.4411 10.6005 16.0148C10.0163 16.0361 10.1471 15.9779 9.57969 15.8413C9.58161 15.325 9.59085 15.5102 9.60799 14.9944Z" />
      <path d="M13.3033 17.4225C14.2702 17.7282 14.5828 17.8471 15.5922 17.7396C15.7597 17.7213 15.8957 17.6571 16.0008 17.5649C16.2708 17.4903 16.5047 17.2769 16.5266 16.9216C16.585 15.9877 16.6391 15.7548 16.6435 14.819C16.6441 14.6618 16.6005 14.5338 16.5311 14.4312C16.4573 14.1648 16.243 13.9429 15.8841 13.9429H13.4895C13.1791 13.9429 12.9779 14.1094 12.881 14.3262C12.8302 14.4157 12.7958 14.5204 12.7888 14.6437C12.7365 15.539 12.7305 15.7335 12.7305 16.6297C12.7305 16.8142 12.7906 16.9591 12.8836 17.0681C12.9577 17.2246 13.0934 17.356 13.3033 17.4225ZM14.1638 15.3447H15.2291C15.2151 15.919 15.1902 15.7916 15.1565 16.3651C14.5723 16.3865 14.7031 16.3284 14.1355 16.1916C14.137 15.6754 14.1468 15.8607 14.1638 15.3447Z" />
      <path d="M17.8593 17.7732C18.826 18.0788 19.1388 18.198 20.1479 18.0901C20.3153 18.0722 20.4516 18.0078 20.5568 17.9155C20.8266 17.8409 21.0606 17.6278 21.0823 17.2723C21.141 16.3383 21.1951 16.1055 21.1993 15.1696C21.2 15.0126 21.1563 14.8843 21.0869 14.7818C21.0129 14.5153 20.7989 14.2935 20.4398 14.2935H18.0453C17.7351 14.2935 17.5337 14.46 17.4367 14.6768C17.3862 14.7662 17.3516 14.8713 17.3448 14.9945C17.2924 15.8897 17.2861 16.0842 17.2861 16.9804C17.2861 17.1648 17.3464 17.3096 17.4393 17.4189C17.5132 17.5751 17.6487 17.7067 17.8593 17.7732ZM18.7191 15.6956H19.7846C19.7705 16.2697 19.7455 16.1423 19.7119 16.7157C19.1277 16.7372 19.2584 16.6791 18.6908 16.5424C18.6928 16.0261 18.7022 16.211 18.7191 15.6956Z" />
      <path d="M22.4147 18.1233C23.3817 18.4289 23.6941 18.5482 24.7036 18.4403C24.871 18.4224 25.0072 18.3579 25.1121 18.266C25.3823 18.191 25.6162 17.9779 25.638 17.6225C25.6964 16.6887 25.7505 16.4557 25.755 15.5197C25.7556 15.3627 25.7116 15.2346 25.6422 15.1322C25.5686 14.8655 25.3546 14.6436 24.9955 14.6436H22.601C22.2905 14.6436 22.0894 14.8101 21.9924 15.0271C21.9415 15.1163 21.9073 15.2212 21.9001 15.3446C21.848 16.2398 21.8418 16.4343 21.8418 17.3304C21.8418 17.5152 21.9018 17.6599 21.995 17.769C22.0689 17.9255 22.2045 18.0572 22.4147 18.1233ZM23.2752 16.0457H24.3406C24.3265 16.62 24.3015 16.4924 24.2679 17.066C23.6837 17.0874 23.8143 17.0294 23.2467 16.8925C23.2484 16.3764 23.2578 16.5615 23.2752 16.0457Z" />
      <path d="M4.19213 20.9273C5.15903 21.233 5.47164 21.3522 6.48088 21.2443C6.64835 21.226 6.78438 21.1619 6.88968 21.0696C7.15949 20.995 7.39343 20.7819 7.41538 20.4265C7.4739 19.4926 7.52773 19.2596 7.53216 18.3239C7.5328 18.1665 7.48917 18.0385 7.41974 17.9362C7.34595 17.6696 7.13171 17.4478 6.77277 17.4478H4.37814C4.06797 17.4478 3.86675 17.6143 3.76967 17.8309C3.71885 17.9205 3.68465 18.0251 3.67747 18.1486C3.62517 19.0437 3.61914 19.2381 3.61914 20.1345C3.61914 20.3189 3.67926 20.4639 3.7723 20.5728C3.84603 20.7292 3.9818 20.8608 4.19213 20.9273ZM5.05213 18.8498H6.11758C6.1036 19.4239 6.07851 19.2965 6.04476 19.87C5.4606 19.8911 5.59137 19.8331 5.02377 19.6963C5.02537 19.1801 5.03519 19.3653 5.05213 18.8498Z" />
      <path d="M4.821 22.3869C4.77031 22.4762 4.73599 22.5815 4.72893 22.7046C4.67651 23.5996 4.67041 23.7942 4.67041 24.6905C4.67041 24.8749 4.73053 25.0196 4.82357 25.1288C4.89768 25.2849 5.03358 25.4168 5.2434 25.4833C6.2103 25.7889 6.52291 25.9078 7.53215 25.8002C7.69962 25.782 7.83584 25.7178 7.94095 25.6256C8.21089 25.551 8.44464 25.3375 8.46665 24.9824C8.52517 24.0482 8.57919 23.8156 8.58349 22.8796C8.58413 22.7225 8.5405 22.5945 8.47107 22.4922C8.39741 22.2253 8.18323 22.0034 7.8241 22.0034H5.42967C5.11931 22.0034 4.91809 22.1696 4.821 22.3869ZM6.10366 23.4052H7.16892C7.15493 23.9796 7.12984 23.8519 7.09609 24.4256C6.51187 24.4468 6.6427 24.3888 6.0751 24.2519C6.0769 23.7359 6.08652 23.9209 6.10366 23.4052Z" />
      <path d="M8.74779 21.2774C9.71469 21.583 10.0273 21.7022 11.0365 21.5943C11.204 21.5764 11.3402 21.512 11.4453 21.4197C11.7152 21.3451 11.949 21.132 11.9712 20.7766C12.0295 19.8427 12.0836 19.6097 12.0878 18.674C12.0887 18.5169 12.0448 18.3886 11.9754 18.2862C11.9018 18.0197 11.6876 17.7979 11.3285 17.7979H8.934C8.6237 17.7979 8.42248 17.9644 8.3254 18.181C8.27471 18.2706 8.24038 18.3755 8.23332 18.4987C8.1809 19.3941 8.1748 19.5886 8.1748 20.4846C8.1748 20.669 8.23493 20.814 8.32809 20.9232C8.40176 21.0796 8.53766 21.2109 8.74779 21.2774ZM9.60799 19.1999H10.6733C10.6593 19.774 10.6342 19.6466 10.6005 20.22C10.0163 20.2416 10.1471 20.1832 9.57969 20.0467C9.58161 19.5306 9.59085 19.7157 9.60799 19.1999Z" />
      <path d="M13.3033 21.6275C14.2702 21.9331 14.5828 22.0523 15.5922 21.9444C15.7597 21.9265 15.8957 21.8621 16.0008 21.7701C16.2708 21.6952 16.5047 21.4821 16.5266 21.1267C16.585 20.1928 16.6391 19.9598 16.6435 19.0241C16.6441 18.867 16.6005 18.739 16.5311 18.6363C16.4573 18.3698 16.243 18.1479 15.8841 18.1479H13.4895C13.1791 18.1479 12.9779 18.3145 12.881 18.5315C12.8302 18.6208 12.7958 18.7259 12.7888 18.8491C12.7365 19.7442 12.7305 19.9387 12.7305 20.8347C12.7305 21.0194 12.7906 21.1641 12.8836 21.2733C12.9577 21.4297 13.0934 21.5613 13.3033 21.6275ZM14.1638 19.55H15.2291C15.2151 20.1241 15.1902 19.9967 15.1565 20.5701C14.5723 20.5916 14.7031 20.5337 14.1355 20.3968C14.137 19.8807 14.1468 20.0658 14.1638 19.55Z" />
      <path d="M17.8593 21.9787C18.826 22.2844 19.1388 22.4032 20.1479 22.2955C20.3153 22.2776 20.4516 22.2131 20.5568 22.1211C20.8266 22.0462 21.0606 21.8331 21.0823 21.478C21.141 20.5438 21.1951 20.3112 21.1993 19.3751C21.2 19.2181 21.1563 19.09 21.0869 18.9874C21.0129 18.7209 20.7989 18.499 20.4398 18.499H18.0453C17.7351 18.499 17.5337 18.6655 17.4367 18.8825C17.3862 18.9719 17.3516 19.0767 17.3448 19.2002C17.2924 20.0952 17.2861 20.2897 17.2861 21.1861C17.2861 21.3705 17.3464 21.5152 17.4393 21.6243C17.5132 21.7803 17.6487 21.9122 17.8593 21.9787ZM18.7191 19.9009H19.7846C19.7705 20.475 19.7455 20.3477 19.7119 20.9214C19.1277 20.9425 19.2584 20.8846 18.6908 20.7477C18.6928 20.2316 18.7022 20.4164 18.7191 19.9009Z" />
      <path d="M22.4147 22.329C23.3817 22.6346 23.6941 22.7535 24.7036 22.6459C24.871 22.6277 25.0072 22.5635 25.1121 22.4713C25.3823 22.3967 25.6162 22.1833 25.638 21.8282C25.6964 20.8943 25.7505 20.6613 25.755 19.7255C25.7556 19.5682 25.7116 19.4401 25.6422 19.3378C25.5686 19.0709 25.3546 18.8491 24.9955 18.8491H22.601C22.2905 18.8491 22.0894 19.0156 21.9924 19.2326C21.9415 19.3222 21.9073 19.4268 21.9001 19.5503C21.848 20.4453 21.8418 20.6398 21.8418 21.5362C21.8418 21.7206 21.9018 21.8652 21.995 21.9744C22.0689 22.1312 22.2045 22.2625 22.4147 22.329ZM23.2752 20.2512H24.3406C24.3265 20.8256 24.3015 20.6979 24.2679 21.2717C23.6837 21.2928 23.8143 21.2349 23.2467 21.098C23.2484 20.5819 23.2578 20.767 23.2752 20.2512Z" />
      <path d="M27.7994 23.0547H25.4046C25.0944 23.0547 24.8934 23.2212 24.7963 23.4382C24.7455 23.5275 24.7109 23.6327 24.7041 23.7556C24.6517 24.6509 24.6458 24.8455 24.6458 25.7415C24.6458 25.9262 24.7057 26.0709 24.7989 26.18C24.8728 26.3361 25.0088 26.4681 25.2185 26.5342C26.1856 26.8399 26.4981 26.9591 27.5075 26.8512C27.675 26.8333 27.8109 26.7688 27.9161 26.6769C28.1858 26.602 28.4199 26.3889 28.442 26.0334C28.5003 25.0996 28.5544 24.8666 28.5586 23.9309C28.5593 23.7738 28.5156 23.6458 28.4462 23.5431C28.3725 23.2762 28.1581 23.0547 27.7994 23.0547ZM27.0712 25.4766C26.487 25.4981 26.618 25.4401 26.0504 25.3032C26.052 24.7871 26.0618 24.9722 26.0787 24.4564H27.1438C27.1298 25.0305 27.105 24.9031 27.0712 25.4766Z" />
      <path d="M26.9705 22.3289C27.9375 22.6345 28.25 22.7534 29.2594 22.6458C29.4269 22.6276 29.5628 22.5635 29.668 22.4712C29.9378 22.3966 30.1718 22.1832 30.1939 21.8281C30.2522 20.8942 30.3063 16.8065 30.3105 15.8703C30.3112 15.7131 30.2675 15.585 30.1981 15.4826C30.1245 15.216 29.9101 14.9941 29.5513 14.9941H27.1565C26.8463 14.9941 26.6453 15.1606 26.5482 15.3775C26.4974 15.467 26.4629 15.5717 26.456 15.6952C26.4035 16.5902 26.3977 20.6395 26.3977 21.5362C26.3977 21.7206 26.4576 21.8652 26.5509 21.9744C26.6248 22.1311 26.7606 22.2624 26.9705 22.3289ZM27.831 16.3961H28.8964C28.8824 16.9703 28.8573 20.6978 28.8234 21.2716C28.2392 21.2927 28.3703 21.2348 27.8026 21.0979C27.8043 20.5818 27.814 16.912 27.831 16.3961Z" />
      <path d="M23.2436 23.0547H10.6862C10.3759 23.0547 10.1747 23.2212 10.0776 23.4382C10.027 23.5275 9.99257 23.6327 9.98557 23.7556C9.93308 24.6509 9.92725 24.4948 9.92725 25.3912C9.92725 25.5756 9.98724 25.7206 10.0804 25.8297C10.1543 25.9859 10.2902 26.1175 10.5 26.1839C11.4669 26.4896 21.9423 26.6088 22.9517 26.501C23.1192 26.4831 23.2553 26.4185 23.3603 26.3263C23.6304 26.2517 23.8643 26.0386 23.8861 25.6831C23.9445 24.7493 23.9986 24.867 24.0031 23.9309C24.0037 23.7738 23.9597 23.6458 23.8904 23.5431C23.8163 23.2762 23.6026 23.0547 23.2436 23.0547ZM22.5157 25.1263C21.9315 25.1478 11.8993 25.0895 11.3319 24.9529C11.3335 24.4365 11.3431 24.9722 11.3602 24.4564H22.5883C22.5744 25.0305 22.5492 24.5529 22.5157 25.1263Z" />
    </>
  )
}

export default Keyboard
