import React from 'react'

const Path02 = () => {
  return (
    <>
      <path
        d="M434.742 3.95472C411.998 3.91596 389.901 3.8997 367.777 3.83227C333.067 3.72519 298.346 3.62369 263.696 3.44356C221.091 3.22092 178.431 2.96101 135.941 2.6375C98.0893 2.35079 60.448 1.95839 22.7345 1.60214C18.9578 1.56704 15.3254 1.45961 11.6688 1.38593C3.53359 1.21739 -2.08622 0.719547 0.739712 0.364602C4.08684 -0.0566169 11.2985 -0.0317767 18.5803 0.0445983C39.6607 0.267186 60.7447 0.509609 81.8695 0.709944C100.365 0.884979 118.984 1.04128 137.596 1.1579C159.54 1.2945 181.583 1.38104 203.534 1.49224C222.1 1.58867 240.599 1.71846 259.184 1.78392C289.226 1.89072 319.238 1.96897 349.273 2.0361C374.918 2.094 400.559 2.13207 426.191 2.15308C453.508 2.17435 480.804 2.18413 508.034 2.16222C520.719 2.1518 533.238 2.02963 545.908 2.00494C560.705 1.97579 575.68 2.03023 590.499 1.98995C608.184 1.94305 625.67 1.80108 643.369 1.76845C658.384 1.73834 673.58 1.83425 688.609 1.81841C703.166 1.80136 717.669 1.72443 732.113 1.65549C743.068 1.602 753.859 1.47922 764.827 1.46262C793.829 1.41907 822.891 1.43262 851.882 1.39464C876.625 1.36279 901.298 1.2794 926.021 1.23607C957.118 1.18214 988.288 1.15712 1019.4 1.09763C1052.5 1.03537 1085.51 0.825213 1118.6 0.898655C1157.54 0.988109 1194.3 0.413932 1232.77 0.408926C1245.1 0.406729 1257.05 0.0929667 1269.33 0.10746C1281.84 0.123769 1295.17 -0.101796 1307.36 0.457909C1318.93 0.986769 1321.04 1.46595 1308.37 1.81598C1297.4 2.11547 1284.16 2.23148 1271.57 2.34736C1246.12 2.57973 1220.52 2.77968 1194.82 2.94479C1176.3 3.06377 1157.56 3.14404 1138.84 3.16795C1107.98 3.20979 1076.9 3.14508 1046.09 3.20989C1004.68 3.29458 963.553 3.50322 922.135 3.57363C886.662 3.6326 850.937 3.53666 815.455 3.57858C784.22 3.61436 753.152 3.7619 721.964 3.81788C697.975 3.86183 673.827 3.83368 649.765 3.84873C613.631 3.87093 577.48 3.90147 541.383 3.92681C507.146 3.95108 472.946 3.97851 438.715 4C437.12 3.99769 435.586 3.96477 434.742 3.95472Z"
        fill="currentColor"
      />
    </>
  )
}

export default Path02
