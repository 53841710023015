import React from 'react'
import type { Hit } from 'react-instantsearch-core'
import { connectInfiniteHits } from 'react-instantsearch-dom'
import { Box, Flex, Grid } from 'theme-ui'
import Button from '../Button'
import Card from '../Card'

type Props = {
  hasMore: boolean
  hasPrevious: boolean
  hits: (Hit & Card)[]
  refineNext: any
  refinePrevious: any
  // custom props
  allowPagination?: boolean
  columnGap?: (number | null) | (number | null)[]
  gridTemplateColumns?: (string | null)[]
  hitComponent: any
  rowGap?: (number | null) | (number | null)[]
}

const InfiniteHits = (props: Props) => {
  const {
    allowPagination,
    columnGap,
    gridTemplateColumns,
    hasPrevious,
    hasMore,
    hits,
    hitComponent: HitComponent,
    refinePrevious,
    refineNext,
    rowGap,
  } = props

  return (
    <Box>
      <Grid
        sx={{
          columnGap,
          gridTemplateColumns,
          rowGap,
        }}
      >
        {hits.map(hit => (
          <HitComponent item={hit} key={hit.objectID} />
        ))}
      </Grid>

      {allowPagination && hasMore && (
        <Flex
          // my={[8, null, null, 12]}
          mb={[4, null, null, 6]}
          mt={[8, null, null, 12]}
          sx={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <Button
            background="midnight"
            color="white"
            onClick={refineNext}
            variant="outline"
          >
            View more
          </Button>
        </Flex>
      )}
    </Box>
  )
}

const AlgoliaCustomInfiniteHits = connectInfiniteHits(InfiniteHits)

export default AlgoliaCustomInfiniteHits
