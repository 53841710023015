import { css } from '@emotion/react'
import theme from './theme'

// Color used in inline SVG
const backgroundColorEncoded = encodeURIComponent(theme.colors.racing)

// Iubenda style overrides
// Based on Sample CSS 2.3.0
// Source: https://codepen.io/iubenda/pen/8e08b840e5290d9415b7f7238b80f596?editors=0100
const iubenda = css`
  #iubenda-cs-banner {
    font-size: 15px !important;
    background: 0 0 !important;
    line-height: 1.4 !important;
    position: fixed !important;
    z-index: 99999998 !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    will-change: opacity, visibility;
    opacity: 0 !important;
    visibility: hidden !important;
    pointer-events: none !important;
    -webkit-transition: opacity 0.4s ease, visibility 0.4s ease !important;
    -o-transition: opacity 0.4s ease, visibility 0.4s ease !important;
    transition: opacity 0.4s ease, visibility 0.4s ease !important;
  }
  #iubenda-cs-banner .iubenda-banner-content:not(.iubenda-custom-content) *,
  #iubenda-cs-banner [class*=' iub'],
  #iubenda-cs-banner [class^='iub'] {
    font-size: 100% !important;
    width: auto !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    background: 0 0 !important;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
    -webkit-tap-highlight-color: transparent !important;
    -webkit-backface-visibility: hidden !important;
    backface-visibility: hidden !important;
    font-family: -apple-system, sans-serif !important;
    text-decoration: none !important;
    color: currentColor !important;
    background-attachment: scroll !important;
    background-color: transparent !important;
    background-image: none !important;
    background-position: 0 0 !important;
    background-repeat: repeat !important;
    border: 0 !important;
    border-color: #000 !important;
    border-color: currentColor !important;
    border-radius: 0 !important;
    border-style: none !important;
    border-width: medium !important;
    bottom: auto !important;
    clear: none !important;
    clip: auto !important;
    counter-increment: none !important;
    counter-reset: none !important;
    direction: inherit !important;
    float: none !important;
    font-style: inherit !important;
    font-variant: normal !important;
    font-weight: inherit !important;
    height: auto !important;
    left: auto !important;
    letter-spacing: normal !important;
    line-height: inherit !important;
    list-style-type: inherit !important;
    list-style-position: outside !important;
    list-style-image: none !important;
    margin: 0 !important;
    max-height: none !important;
    max-width: none !important;
    min-height: 0 !important;
    min-width: 0 !important;
    opacity: 1;
    outline: 0 !important;
    overflow: visible !important;
    padding: 0 !important;
    position: static !important;
    quotes: '' '' !important;
    right: auto !important;
    table-layout: auto !important;
    text-align: left !important;
    text-indent: 0 !important;
    text-transform: none !important;
    top: auto !important;
    unicode-bidi: normal !important;
    vertical-align: baseline !important;
    visibility: inherit !important;
    white-space: normal !important;
    width: auto !important;
    word-spacing: normal !important;
    z-index: auto !important;
    background-origin: padding-box !important;
    background-origin: padding-box !important;
    background-clip: border-box !important;
    background-size: auto !important;
    -o-border-image: none !important;
    border-image: none !important;
    border-radius: 0 !important;
    border-radius: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-column-count: auto !important;
    -moz-column-count: auto !important;
    column-count: auto !important;
    -webkit-column-gap: normal !important;
    -moz-column-gap: normal !important;
    column-gap: normal !important;
    -webkit-column-rule: medium none #000 !important;
    -moz-column-rule: medium none #000 !important;
    column-rule: medium none #000 !important;
    -webkit-column-span: none !important;
    -moz-column-span: none !important;
    column-span: none !important;
    -webkit-column-width: auto !important;
    -moz-column-width: auto !important;
    column-width: auto !important;
    -webkit-font-feature-settings: normal !important;
    font-feature-settings: normal !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
    -webkit-hyphens: manual !important;
    -ms-hyphens: manual !important;
    hyphens: manual !important;
    -webkit-perspective: none !important;
    perspective: none !important;
    -webkit-perspective-origin: 50% 50% !important;
    perspective-origin: 50% 50% !important;
    text-shadow: none !important;
    -webkit-transition: all 0s ease 0s !important;
    -o-transition: all 0s ease 0s !important;
    transition: all 0s ease 0s !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    -webkit-transform-origin: 50% 50% !important;
    -ms-transform-origin: 50% 50% !important;
    transform-origin: 50% 50% !important;
    -webkit-transform-style: flat !important;
    transform-style: flat !important;
    word-break: normal !important;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  #iubenda-cs-banner.iubenda-cs-overlay:before {
    content: '' !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    z-index: 1 !important;
    pointer-events: auto !important;
  }
  #iubenda-cs-banner.iubenda-cs-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  #iubenda-cs-banner.iubenda-cs-top {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  #iubenda-cs-banner.iubenda-cs-bottom {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  #iubenda-cs-banner.iubenda-cs-left {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  #iubenda-cs-banner.iubenda-cs-right {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  #iubenda-cs-banner.iubenda-cs-visible {
    opacity: 1 !important;
    visibility: visible !important;
  }
  #iubenda-cs-banner.iubenda-cs-visible > * {
    pointer-events: auto !important;
  }
  #iubenda-cs-banner.iubenda-cs-slidein .iubenda-cs-container {
    -webkit-transition: -webkit-transform 0.4s ease !important;
    transition: -webkit-transform 0.4s ease !important;
    -o-transition: transform 0.4s ease !important;
    transition: transform 0.4s ease !important;
    transition: transform 0.4s ease, -webkit-transform 0.4s ease !important;
  }
  #iubenda-cs-banner.iubenda-cs-slidein.iubenda-cs-top .iubenda-cs-container {
    -webkit-transform: translateY(-48px) !important;
    -ms-transform: translateY(-48px) !important;
    transform: translateY(-48px) !important;
  }
  #iubenda-cs-banner.iubenda-cs-slidein.iubenda-cs-bottom
    .iubenda-cs-container {
    -webkit-transform: translateY(48px) !important;
    -ms-transform: translateY(48px) !important;
    transform: translateY(48px) !important;
  }
  #iubenda-cs-banner.iubenda-cs-slidein.iubenda-cs-visible
    .iubenda-cs-container {
    -webkit-transform: translateY(0) !important;
    -ms-transform: translateY(0) !important;
    transform: translateY(0) !important;
  }
  #iubenda-cs-banner .iubenda-cs-container {
    position: relative !important;
    z-index: 2 !important;
  }
  #iubenda-cs-banner .iubenda-cs-brand {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    padding: 16px !important;
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  #iubenda-cs-banner .iubenda-cs-brand > div {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  #iubenda-cs-banner .iubenda-cs-brand img {
    max-width: 192px !important;
    max-height: 56px !important;
  }
  #iubenda-cs-banner .iubenda-cs-content {
    position: relative !important;
    z-index: 1 !important;
    overflow: hidden !important;
    -webkit-transition: -webkit-transform 0.4s ease !important;
    transition: -webkit-transform 0.4s ease !important;
    -o-transition: transform 0.4s ease !important;
    transition: transform 0.4s ease !important;
    transition: transform 0.4s ease, -webkit-transform 0.4s ease !important;
  }
  #iubenda-cs-banner .iubenda-cs-rationale {
    position: relative !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  #iubenda-cs-banner .iubenda-cs-close-btn {
    position: absolute !important;
    top: -2px !important;
    padding: 16px !important;
    right: 0 !important;
    min-width: 48px !important;
    height: 48px !important;
    font-size: 24px !important;
    line-height: 0 !important;
    font-weight: lighter !important;
    cursor: pointer !important;
    text-align: center !important;
  }
  #iubenda-cs-banner .iubenda-cs-close-btn:hover {
    opacity: 0.5 !important;
  }
  #iubenda-cs-banner .iubenda-banner-content {
    font-weight: 300 !important;
    margin: 16px !important;
    margin-bottom: 0 !important;
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
    overflow-y: auto !important;
    mask-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 0,
      #000 16px
    ) !important;
    -webkit-mask-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 0,
      #000 16px
    ) !important;
    padding-bottom: 32px !important;
  }
  #iubenda-cs-banner .iubenda-banner-content-padded {
    padding-right: 32px !important;
  }
  #iubenda-cs-banner .iubenda-banner-content a {
    cursor: pointer !important;
    color: currentColor !important;
    opacity: 0.7 !important;
    text-decoration: underline !important;
  }
  #iubenda-cs-banner .iubenda-banner-content a:hover {
    opacity: 1 !important;
  }
  #iubenda-cs-banner #iubenda-cs-title {
    font-weight: 700 !important;
    margin-bottom: 16px !important;
  }
  #iubenda-cs-banner .iubenda-cs-opt-group {
    margin: 16px !important;
    z-index: 1 !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    margin-top: 0 !important;
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  #iubenda-cs-banner .iubenda-cs-opt-group > div {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  @media (min-width: 640px) {
    #iubenda-cs-banner .iubenda-cs-opt-group {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    #iubenda-cs-banner .iubenda-cs-opt-group-custom {
      margin-right: auto !important;
      -ms-flex-item-align: start !important;
      align-self: start !important;
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    #iubenda-cs-banner .iubenda-cs-opt-group-consent {
      margin-left: auto !important;
      -ms-flex-item-align: end !important;
      align-self: end !important;
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
  }
  @media (max-width: 639px) {
    #iubenda-cs-banner .iubenda-cs-opt-group {
      margin: 12px !important;
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    #iubenda-cs-banner .iubenda-cs-opt-group-custom {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
    }
    #iubenda-cs-banner .iubenda-cs-opt-group-consent {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
    }
  }
  #iubenda-cs-banner .iubenda-cs-opt-group button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    padding: 8px 32px !important;
    border-radius: 64px !important;
    cursor: pointer !important;
    font-weight: 700 !important;
    font-size: 100% !important;
    margin-top: 4px !important;
    margin-bottom: 4px !important;
    text-align: center !important;
  }
  #iubenda-cs-banner .iubenda-cs-opt-group button:focus {
    opacity: 0.8 !important;
  }
  #iubenda-cs-banner .iubenda-cs-opt-group button:hover {
    opacity: 0.5 !important;
  }
  @media (min-width: 640px) {
    #iubenda-cs-banner .iubenda-cs-opt-group button:not(:last-of-type) {
      margin-right: 8px !important;
    }
  }
  @media (max-width: 639px) {
    #iubenda-cs-banner .iubenda-cs-opt-group button {
      padding: 8px 24px !important;
      width: 100% !important;
      display: block;
      text-align: center !important;
      margin: 6px 3px !important;
    }
  }
  #iubenda-cs-banner.iubenda-cs-default .iubenda-cs-brand {
    margin: 0 -8px 0 !important;
  }
  @media (max-width: 991px) {
    #iubenda-cs-banner.iubenda-cs-default .iubenda-cs-brand {
      margin: -8px -8px 0 !important;
    }
  }
  @media (min-width: 992px) {
    #iubenda-cs-banner.iubenda-cs-default .iubenda-cs-brand div {
      margin: 0 auto !important;
      width: calc(992px - 32px) !important;
    }
  }
  @media (max-width: 991px) {
    #iubenda-cs-banner.iubenda-cs-default .iubenda-cs-brand div {
      margin: 0 8px !important;
    }
  }
  #iubenda-cs-banner.iubenda-cs-default .iubenda-cs-container {
    width: 100% !important;
  }
  @media (min-width: 992px) {
    #iubenda-cs-banner.iubenda-cs-default .iubenda-cs-rationale {
      width: 992px !important;
      margin: 16px auto !important;
    }
  }
  @media (max-width: 991px) {
    #iubenda-cs-banner.iubenda-cs-default .iubenda-cs-content {
      padding: 8px !important;
    }
  }
  #iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-brand {
    margin: -8px -8px 0 !important;
  }
  #iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-brand div {
    margin: 8px !important;
  }
  @media (min-width: 992px) {
    #iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-container {
      width: 992px !important;
    }
  }
  @media (max-width: 991px) {
    #iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center.iubenda-cs-bottom
      .iubenda-cs-container,
    #iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center.iubenda-cs-top
      .iubenda-cs-container {
      width: 100% !important;
    }
  }
  @media (min-width: 640px) {
    #iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center:not(.iubenda-cs-top):not(.iubenda-cs-bottom)
      .iubenda-cs-container,
    #iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-bottom):not(.iubenda-cs-center)
      .iubenda-cs-container,
    #iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-top):not(.iubenda-cs-center)
      .iubenda-cs-container {
      width: 480px !important;
    }
  }
  #iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center:not(.iubenda-cs-top):not(.iubenda-cs-bottom)
    .iubenda-cs-opt-group,
  #iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-bottom):not(.iubenda-cs-center)
    .iubenda-cs-opt-group,
  #iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-top):not(.iubenda-cs-center)
    .iubenda-cs-opt-group {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  #iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center:not(.iubenda-cs-top):not(.iubenda-cs-bottom)
    .iubenda-cs-opt-group
    > div,
  #iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-bottom):not(.iubenda-cs-center)
    .iubenda-cs-opt-group
    > div,
  #iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-top):not(.iubenda-cs-center)
    .iubenda-cs-opt-group
    > div {
    width: 100% !important;
  }
  #iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center:not(.iubenda-cs-top):not(.iubenda-cs-bottom)
    .iubenda-cs-opt-group
    button,
  #iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-bottom):not(.iubenda-cs-center)
    .iubenda-cs-opt-group
    button,
  #iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-top):not(.iubenda-cs-center)
    .iubenda-cs-opt-group
    button {
    display: block !important;
    width: 100% !important;
    text-align: center !important;
  }
  #iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center:not(.iubenda-cs-top):not(.iubenda-cs-bottom)
    .iubenda-cs-opt-group-custom,
  #iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-bottom):not(.iubenda-cs-center)
    .iubenda-cs-opt-group-custom,
  #iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-top):not(.iubenda-cs-center)
    .iubenda-cs-opt-group-custom {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  #iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center:not(.iubenda-cs-top):not(.iubenda-cs-bottom)
    .iubenda-cs-opt-group-consent,
  #iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-bottom):not(.iubenda-cs-center)
    .iubenda-cs-opt-group-consent,
  #iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-top):not(.iubenda-cs-center)
    .iubenda-cs-opt-group-consent {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  #iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-content {
    -webkit-box-shadow: 0 8px 48px rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0 8px 48px rgba(0, 0, 0, 0.15) !important;
    padding: 8px !important;
  }
  @media (min-width: 992px) {
    #iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-content {
      border-radius: 4px !important;
      margin: 16px !important;
    }
  }
  #iubenda-cs-banner.iubenda-cs-fix-height .iubenda-cs-container,
  #iubenda-cs-banner.iubenda-cs-fix-height .iubenda-cs-content,
  #iubenda-cs-banner.iubenda-cs-fix-height .iubenda-cs-rationale {
    height: 100% !important;
  }
  #iubenda-cs-banner.iubenda-cs-fix-height.iubenda-cs-default-floating
    .iubenda-cs-content {
    height: calc(100% - 32px) !important;
  }
  #iubenda-cs-banner.iubenda-cs-fix-height .iubenda-cs-brand img {
    max-width: 75% !important;
  }
  #iubenda-cs-banner .iubenda-cs-content {
    background-color: #000 !important;
    color: #fff !important;
  }
  #iubenda-cs-banner .iubenda-cs-opt-group {
    color: #000 !important;
  }
  #iubenda-cs-banner .iubenda-cs-opt-group button {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: #fff !important;
  }
  #iubenda-cs-banner .iubenda-cs-opt-group button.iubenda-cs-btn-primary {
    background-color: #0073ce !important;
    color: #fff !important;
  }
  .iubenda-tp-btn:not([data-tp-nostyle]) {
    font-size: 100% !important;
    width: auto !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    background: 0 0 !important;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
    -webkit-tap-highlight-color: transparent !important;
    -webkit-backface-visibility: hidden !important;
    backface-visibility: hidden !important;
    font-family: -apple-system, sans-serif !important;
    text-decoration: none !important;
    color: currentColor !important;
    background-attachment: scroll !important;
    background-color: transparent !important;
    background-image: none !important;
    background-position: 0 0 !important;
    background-repeat: repeat !important;
    border: 0 !important;
    border-color: #000 !important;
    border-color: currentColor !important;
    border-radius: 0 !important;
    border-style: none !important;
    border-width: medium !important;
    bottom: auto !important;
    clear: none !important;
    clip: auto !important;
    counter-increment: none !important;
    counter-reset: none !important;
    cursor: auto !important;
    direction: inherit !important;
    float: none !important;
    font-style: inherit !important;
    font-variant: normal !important;
    font-weight: inherit !important;
    height: auto !important;
    left: auto !important;
    letter-spacing: normal !important;
    line-height: inherit !important;
    list-style-type: inherit !important;
    list-style-position: outside !important;
    list-style-image: none !important;
    margin: 0 !important;
    max-height: none !important;
    max-width: none !important;
    min-height: 0 !important;
    min-width: 0 !important;
    opacity: 1;
    outline: 0 !important;
    overflow: visible !important;
    padding: 0 !important;
    position: static !important;
    quotes: '' '' !important;
    right: auto !important;
    table-layout: auto !important;
    text-align: left !important;
    text-indent: 0 !important;
    text-transform: none !important;
    top: auto !important;
    unicode-bidi: normal !important;
    vertical-align: baseline !important;
    visibility: inherit !important;
    white-space: normal !important;
    width: auto !important;
    word-spacing: normal !important;
    z-index: auto !important;
    background-origin: padding-box !important;
    background-origin: padding-box !important;
    background-clip: border-box !important;
    background-size: auto !important;
    -o-border-image: none !important;
    border-image: none !important;
    border-radius: 0 !important;
    border-radius: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-column-count: auto !important;
    -moz-column-count: auto !important;
    column-count: auto !important;
    -webkit-column-gap: normal !important;
    -moz-column-gap: normal !important;
    column-gap: normal !important;
    -webkit-column-rule: medium none #000 !important;
    -moz-column-rule: medium none #000 !important;
    column-rule: medium none #000 !important;
    -webkit-column-span: none !important;
    -moz-column-span: none !important;
    column-span: none !important;
    -webkit-column-width: auto !important;
    -moz-column-width: auto !important;
    column-width: auto !important;
    -webkit-font-feature-settings: normal !important;
    font-feature-settings: normal !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
    -webkit-hyphens: manual !important;
    -ms-hyphens: manual !important;
    hyphens: manual !important;
    -webkit-perspective: none !important;
    perspective: none !important;
    -webkit-perspective-origin: 50% 50% !important;
    perspective-origin: 50% 50% !important;
    text-shadow: none !important;
    -webkit-transition: all 0s ease 0s !important;
    -o-transition: all 0s ease 0s !important;
    transition: all 0s ease 0s !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    -webkit-transform-origin: 50% 50% !important;
    -ms-transform-origin: 50% 50% !important;
    transform-origin: 50% 50% !important;
    -webkit-transform-style: flat !important;
    transform-style: flat !important;
    word-break: normal !important;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    appearance: none !important;
    line-height: 34px !important;
    height: 34px !important;
    min-width: 34px !important;
    border-radius: 4px !important;
    cursor: pointer !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) !important;
    color: rgba(0, 0, 0, 0.65) !important;
    background-color: #fff !important;
    display: inline-block !important;
    vertical-align: middle !important;
  }
  .iubenda-tp-btn[data-tp-icon] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath fill='%231CC691' fill-rule='evenodd' d='M16 7a4 4 0 0 1 2.627 7.016L19.5 25h-7l.873-10.984A4 4 0 0 1 16 7z'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;
    background-size: 32px 32px !important;
    background-position: top 0.5px left 1px !important;
  }
  .iubenda-tp-btn[data-tp-circle] {
    border-radius: 32px !important;
  }
  .iubenda-tp-btn[data-tp-label]:after {
    content: attr(data-tp-label) !important;
    padding: 0 16px !important;
    white-space: nowrap !important;
  }
  .iubenda-tp-btn[data-tp-label][data-tp-icon]:after {
    padding-left: calc(16px + 8px + 8px) !important;
  }
  .iubenda-tp-btn[data-tp-float] {
    position: fixed !important;
    z-index: 2147483647 !important;
  }
  .iubenda-tp-btn[data-tp-float]:not([data-tp-anchored]) {
    margin: 16px !important;
  }
  .iubenda-tp-btn[data-tp-float][data-tp-anchored] {
    margin: 0 16px !important;
    border-radius: 6px !important;
  }
  .iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover]):not([data-tp-label])[data-tp-float='center-left'],
  .iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover]):not([data-tp-label])[data-tp-float='center-right'],
  .iubenda-tp-btn[data-tp-float][data-tp-anchored][data-tp-hover][data-tp-float='center-left'],
  .iubenda-tp-btn[data-tp-float][data-tp-anchored][data-tp-hover][data-tp-float='center-right'] {
    margin: 0 !important;
    top: 75% !important;
    -webkit-transform: translateY(-50%) !important;
    -ms-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
  }
  .iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover]):not([data-tp-label])[data-tp-float='center-left'],
  .iubenda-tp-btn[data-tp-float][data-tp-anchored][data-tp-hover][data-tp-float='center-left'] {
    left: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0 !important;
  }
  .iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover]):not([data-tp-label])[data-tp-float='center-right'],
  .iubenda-tp-btn[data-tp-float][data-tp-anchored][data-tp-hover][data-tp-float='center-right'] {
    right: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0 !important;
  }
  .iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover])[data-tp-label][data-tp-float='center-left'],
  .iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover])[data-tp-label][data-tp-float='center-right'] {
    margin: 0 !important;
    top: 50% !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom: 0 !important;
    -webkit-transform-origin: bottom !important;
    -ms-transform-origin: bottom !important;
    transform-origin: bottom !important;
  }
  .iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover])[data-tp-label][data-tp-float='center-left'] {
    left: 0 !important;
    -webkit-transform: translateY(-50%) rotate(90deg) !important;
    -ms-transform: translateY(-50%) rotate(90deg) !important;
    transform: translateY(-50%) rotate(90deg) !important;
    -webkit-transform-origin: left bottom !important;
    -ms-transform-origin: left bottom !important;
    transform-origin: left bottom !important;
  }
  .iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover])[data-tp-label][data-tp-float='center-right'] {
    right: 0 !important;
    -webkit-transform: translateY(-50%) rotate(-90deg) !important;
    -ms-transform: translateY(-50%) rotate(-90deg) !important;
    transform: translateY(-50%) rotate(-90deg) !important;
    -webkit-transform-origin: right bottom !important;
    -ms-transform-origin: right bottom !important;
    transform-origin: right bottom !important;
  }
  .iubenda-tp-btn[data-tp-float][data-tp-float='bottom-left'],
  .iubenda-tp-btn[data-tp-float][data-tp-float='bottom-right'] {
    bottom: 0 !important;
  }
  .iubenda-tp-btn[data-tp-float][data-tp-float='bottom-left'][data-tp-anchored],
  .iubenda-tp-btn[data-tp-float][data-tp-float='bottom-right'][data-tp-anchored] {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom: 0 !important;
  }
  .iubenda-tp-btn[data-tp-float][data-tp-float='top-left'],
  .iubenda-tp-btn[data-tp-float][data-tp-float='top-right'] {
    top: 0 !important;
  }
  .iubenda-tp-btn[data-tp-float][data-tp-float='top-left'][data-tp-anchored],
  .iubenda-tp-btn[data-tp-float][data-tp-float='top-right'][data-tp-anchored] {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top: 0 !important;
  }
  .iubenda-tp-btn[data-tp-float][data-tp-float='bottom-left'],
  .iubenda-tp-btn[data-tp-float][data-tp-float='top-left'] {
    left: 0 !important;
  }
  .iubenda-tp-btn[data-tp-float][data-tp-float='bottom-right'],
  .iubenda-tp-btn[data-tp-float][data-tp-float='top-right'] {
    right: 0 !important;
  }
  .iubenda-tp-btn[data-tp-float][data-tp-hover][data-tp-label]:after {
    max-width: 0 !important;
    overflow: hidden !important;
    display: block !important;
    padding: 0 !important;
    opacity: 0 !important;
    -webkit-transition: max-width 0.6s ease, padding 0.6s ease,
      opacity 0.6s ease !important;
    -o-transition: max-width 0.6s ease, padding 0.6s ease, opacity 0.6s ease !important;
    transition: max-width 0.6s ease, padding 0.6s ease, opacity 0.6s ease !important;
  }
  .iubenda-tp-btn[data-tp-float][data-tp-hover][data-tp-label]:hover:after {
    max-width: 192px !important;
    padding-left: calc(16px + 8px + 8px) !important;
    padding-right: 10px !important;
    opacity: 1 !important;
  }

  /* OVERRIDES */

  #iubenda-cs-banner .iubenda-banner-content:not(.iubenda-custom-content) *,
  #iubenda-cs-banner [class*=' iub'],
  #iubenda-cs-banner [class^='iub'] {
    font-family: ${theme.fonts.sans} !important;
    font-size: ${theme.fontSizes.s} !important;
    font-weight: ${theme.fontWeights.medium} !important;
    letter-spacing: ${theme.letterSpacings.default} !important;
  }

  #iubenda-cs-banner {
    // 'Notice' title
    #iubenda-cs-title {
      font-size: ${theme.fontSizes.m} !important;
      font-weight: ${theme.fontWeights.semibold} !important;
      margin-bottom: 4px !important;
    }
    .iubenda-banner-content {
      -webkit-mask-image: none !important;
      mask-image: none !important;
      margin: 0 !important;
      padding-bottom: 0 !important;
      padding-right: ${theme.space[2]} !important;
    }
    .iubenda-cs-content {
      background: ${theme.colors.racing} !important;
    }
    .iubenda-cs-rationale {
      flex-direction: column !important;

      @media (min-width: ${theme.breakpoints[2]}) {
        flex-direction: row !important;
      }
    }

    .iubenda-cs-opt-group {
      align-self: flex-start;
      padding-top: ${theme.space[3]} !important;

      @media (min-width: ${theme.breakpoints[2]}) {
        align-self: flex-end;
        padding-top: 0 !important;
      }

      margin: 0 !important;
      button {
        margin: 0 !important;

        &.iubenda-cs-customize-btn {
          background: transparent !important;
          border-radius: 100px !important;
          border: 1px solid !important;
          border-color: ${theme.colors.white} !important;
          color: ${theme.colors.white} !important;
          font-size: ${theme.fontSizes.xs} !important;
          font-weight: ${theme.fontWeights.medium} !important;
          height: 36px !important;
          justify-content: center !important;
          letter-spacing: ${theme.letterSpacings.default} !important;
          line-height: ${theme.lineHeights.default} !important;
          margin-right: ${theme.space[3]} !important;
          margin-top: ${theme.space[2]} !important;
          padding-left: 15px !important;
          padding-right: 15px !important;
        }

        &.iubenda-cs-btn-primary {
          background: ${theme.colors.white} !important;
          border-radius: 100px !important;
          color: ${theme.colors.midnight} !important;
          font-size: ${theme.fontSizes.xs} !important;
          font-weight: ${theme.fontWeights.medium} !important;
          height: 36px !important;
          justify-content: center !important;
          letter-spacing: ${theme.letterSpacings.default} !important;
          line-height: ${theme.lineHeights.default} !important;
          margin-right: ${theme.space[3]} !important;
          margin-top: ${theme.space[2]} !important;
          padding-left: 15px !important;
          padding-right: 15px !important;

          &:last-of-type {
            margin-right: 0 !important;
          }
        }
      }
    }
  }

  #iubenda-cs-banner.iubenda-cs-default-floating {
    .iubenda-cs-container {
      width: 100% !important;
    }
    .iubenda-cs-container:before {
      display: block;
      content: ' ';
      background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' width='1366' height='38' viewBox='0 0 1366 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 35.2691C51.0571 26.0403 102.155 18.2525 153.279 14.0623C240.983 6.8222 328.867 7.3234 416.752 7.82461C446.595 7.9948 476.439 8.165 506.275 8.03208C632.956 7.24561 759.528 6.4599 886.208 8.81888C916.617 9.39335 946.993 12.6432 977.39 15.8953C1002.47 18.5784 1027.56 21.263 1052.7 22.4479C1064.01 23.0339 1075.35 19.7719 1086.7 16.5057C1095.66 13.9279 1104.63 11.3474 1113.61 10.6537C1126.5 9.58866 1139.45 7.8504 1152.41 6.11103C1187.28 1.42919 1222.22 -3.26069 1256.3 5.14847C1293.41 14.3211 1329.81 9.71168 1366 1.96495V38H0L0 35.2691Z' fill='${backgroundColorEncoded}'/%3E%3C/svg%3E%0A");
      background-repeat: repeat-x;
      background-position: center bottom;
      background-size: 100% 100%;
      height: 38px;
      width: 100%;
    }
    .iubenda-cs-content {
      border-radius: 0 !important;
      box-shadow: none !important;
      margin: 0 !important;
      padding-bottom: ${theme.space[4]} !important;
      padding-left: ${theme.space[6]} !important;
      padding-right: ${theme.space[6]} !important;
      padding-top: ${theme.space[4]} !important;
    }
  }

  // Iframe

  #iubenda-iframe .iubenda-cs-close-btn--label,
  #iubenda-iframe .iubenda-cs-reject-btn--label,
  #iubenda-iframe .purposes-header-title,
  #iubenda-iframe .purposes-header-text,
  #iubenda-iframe .purposes-desktop span,
  #iubenda-iframe .purposes-mobile span,
  #iubenda-iframe .purposes-item div,
  #iubenda-iframe .purposes-item div div div label,
  #iubenda-iframe .purposes-item div div div span,
  #iubenda-iframe .purposes-item div div button,
  #iubenda-iframe .purposes-btn {
    font-family: ${theme.fonts.sans} !important;
    font-size: ${theme.fontSizes.s} !important;
    font-weight: ${theme.fontWeights.medium} !important;
    letter-spacing: ${theme.letterSpacings.default} !important;
  }

  #iubenda-iframe-content {
    .purposes-buttons {
      div button.purposes-btn-accept {
        background-color: transparent !important;
      }
      div button.purposes-btn-reject {
        background-color: transparent !important;
      }
    }

    #iubFooterBtnContainer #iubFooterBtn {
      background-color: transparent !important;
    }
  }

  // "See full Cookie Policy" button text
  #purposes-container > div > div.purposes-top-right > div {
    background-color: transparent !important;
    span {
      font-size: ${theme.fontSizes.xs} !important;
      letter-spacing: ${theme.letterSpacings.default} !important;
    }
  }

  // Accept all button: Use black background and white text + icon
  #iubenda-iframe-content .purposes-buttons div button.purposes-btn-accept {
    background-color: ${theme.colors.midnight} !important;
    color: white !important;
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='12' viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.76691 9.1786L1.21092 5.75802L0 6.91462L4.76691 11.5L15 1.6566L13.7976 0.5L4.76691 9.1786Z' fill='white'/%3E%3C/svg%3E%0A") !important;
  }
  // Save and continue button: Use black background and white text
  #iubenda-iframe-content
    .iubenda-iframe-footer-absolute
    #iubFooterBtnContainer
    button#iubFooterBtn {
    background-color: ${theme.colors.midnight} !important;
    color: white !important;
  }

  #purposes-container .purposes-buttons button.purposes-btn-accept,
  #iubenda-iframe .purposes-buttons button.purposes-btn-reject,
  #iubenda-iframe-content
    .iubenda-iframe-footer-absolute
    #iubFooterBtnContainer
    #iubFooterBtn,
  #iubenda-iframe .purposes-desktop,
  #iubenda-iframe .purposes-mobile,
  #purposes-container .purposes-btn-stroked,
  #iubenda-iframe-content #purposes-container .purposes-top .purposes-desktop,
  #iubenda-iframe-content #purposes-container .purposes-top .purposes-mobile {
    background-color: none !important;
    border-radius: 100px !important;
    border: 1px solid !important;
    border-color: ${theme.colors.midnight} !important;
    box-shadow: none !important;
    color: ${theme.colors.midnight} !important;
    font-family: ${theme.fonts.sans} !important;
    font-size: ${theme.fontSizes.xs} !important;
    font-weight: ${theme.fontWeights.medium} !important;
    height: 36px !important;
    justify-content: center !important;
    letter-spacing: ${theme.letterSpacings.default} !important;
    line-height: ${theme.lineHeights.default} !important;
    margin-right: ${theme.space[3]} !important;
    margin-top: ${theme.space[2]} !important;
  }

  #purposes-container .purposes-header > p {
    font-size: ${theme.fontSizes.s} !important;
    font-weight: inherit !important;
    > button {
      font-family: inherit !important;
      font-weight: inherit !important;
      letter-spacing: inherit !important;
    }
  }
`

export default iubenda
