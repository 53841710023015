import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  Color,
  MenuSection,
  SanityBlock,
  SanityLinkExternal,
  SanityLinkInternal,
  SanityLinkStub,
} from '@types'

type NavigationState = {
  footer?: {
    links: (SanityLinkExternal | SanityLinkInternal | SanityLinkStub)[]
    text?: SanityBlock[]
  }
  header: {
    announcements?: (SanityLinkExternal | SanityLinkInternal)[]
    atTop: boolean
    customColor?: Color
    hover?: boolean
    sections?: MenuSection[]
    transparent: boolean
    visible: boolean
    subscribeLabel?: string
  }
  subheader: {
    visible: boolean
  }
}

export const initialState = {
  footer: undefined,
  header: {
    announcements: undefined,
    atTop: true,
    customColor: undefined,
    hover: undefined,
    sections: undefined,
    transparent: true,
    visible: true,
  },
  subheader: {
    visible: false,
  },
} as NavigationState

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    clearHeaderCustomColor(state) {
      delete state.header.customColor
    },
    setHeaderAtTop(state, action: PayloadAction<{ atTop: boolean }>) {
      state.header.atTop = action.payload.atTop
      state.header.transparent = action.payload.atTop
      if (action.payload.atTop) {
        state.header.visible = true
      }
    },
    setHeaderCustomColor(
      state,
      action: PayloadAction<{ customColor?: Color }>
    ) {
      state.header.customColor = action.payload?.customColor
    },
    setHeaderHover(state, action: PayloadAction<{ hover: boolean }>) {
      state.header.hover = action.payload.hover
    },
    setHeaderTransparent(
      state,
      action: PayloadAction<{ transparent: boolean }>
    ) {
      state.header.transparent = action.payload.transparent
    },
    setHeaderVisible(state, action: PayloadAction<{ visible: boolean }>) {
      state.header.visible = action.payload.visible
    },
    setSubheaderVisible(state, action: PayloadAction<{ visible: boolean }>) {
      state.subheader.visible = action.payload.visible
    },
  },
})

export const navigationActions = navigationSlice.actions

export default navigationSlice.reducer
