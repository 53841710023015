import React from 'react'
import useTypedSelector from '../../hooks/useTypedSelector'
import lineItemRemove from '../../redux/modules/store/thunks/lineItemRemove'
import { useAppDispatch } from '../../redux/store'
import Link from '../Link'

type Props = {
  disabled?: boolean
  productVariantId: string
}

const ButtonRemoveItem = (props: Props) => {
  const { disabled, productVariantId } = props

  // Store: redux
  const dispatch = useAppDispatch()
  const lineItemRemoving = useTypedSelector(
    state => state.store.lineItemRemoving
  )
  const lineItemUpdating = useTypedSelector(
    state => state.store.lineItemUpdating
  )
  const checkoutUpdating = useTypedSelector(
    state => state.store.checkoutUpdating
  )

  // Callbacks
  const handleRemove = () => {
    dispatch(lineItemRemove({ productVariantId }))
  }

  return (
    <Link
      disabled={
        checkoutUpdating || disabled || lineItemRemoving || lineItemUpdating
      }
      onClick={handleRemove}
      sx={{
        color: 'slate',
        fontSize: 'xxs',
      }}
      variant="simple"
    >
      Remove
    </Link>
  )
}

export default ButtonRemoveItem
