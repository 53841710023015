import React from 'react'

const Path07 = () => {
  return (
    <>
      <path d="M145 9.79727C144.821 46.0031 141.119 80.8727 119.623 111.156C104.666 132.225 83.9263 144.38 58.5893 148.498C51.7624 149.605 44.7465 149.485 37.8201 149.984C35.8496 150.123 34.755 149.366 33.919 147.511C24.7137 127.13 15.4088 106.798 6.24331 86.4068C5.75567 85.3199 6.08408 83.6547 6.50205 82.4183C6.95983 81.0422 7.92515 79.8257 8.73124 78.5793C24.2758 54.5883 37.8201 29.5404 48.4087 2.93701C49.4138 0.404298 50.618 -0.243831 53.4542 0.0752503C80.7517 3.07661 108.079 5.83864 135.397 8.68046C138.452 9.00951 141.487 9.38845 145 9.79727Z" />
    </>
  )
}

export default Path07
