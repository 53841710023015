import { alpha } from '@theme-ui/color'
import { ColorTheme } from '@types'
import React from 'react'
import { Box, Flex } from 'theme-ui'
import Icon from '../Icon'

type Props = {
  colorTheme?: ColorTheme
  size?: string | string[]
}

const PlaylistPlayButton = (props: Props) => {
  const {
    colorTheme = {
      background: 'white',
      text: 'midnight',
    },
    size,
  } = props

  return (
    <Flex
      sx={{
        alignItems: 'center',
        bg: alpha(colorTheme?.text, 0.09),
        border: '1px solid',
        borderColor: colorTheme?.text,
        borderRadius: '100px',
        color: colorTheme?.text,
        flexShrink: 0,
        height: size || '40px',
        justifyContent: 'center',
        transition: '150ms all',
        width: size || '40px',
        '@media (hover: hover) and (pointer: fine)': {
          'a:hover &': {
            bg: colorTheme?.text,
            color: colorTheme?.background || 'white',
          },
        },
      }}
    >
      <Box
        ml="5%"
        sx={{
          height: '30%',
          width: '30%',
        }}
      >
        <Icon type="play" />
      </Box>
    </Flex>
  )
}

export default PlaylistPlayButton
