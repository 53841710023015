import React from 'react'
import { ProductPriceRange } from '../../types/codegen/shopify'
import FormattedMoney from '../FormattedMoney/FormattedMoney'

type Props = {
  compareAtPriceRange?: ProductPriceRange
  displaySingleVariantComparePrice?: boolean
  priceRange: ProductPriceRange
}

const ProductPresentmentPriceRange = (props: Props) => {
  const {
    compareAtPriceRange,
    displaySingleVariantComparePrice,
    priceRange,
  } = props

  const hasPriceRange =
    priceRange?.minVariantPrice?.amount !== priceRange?.maxVariantPrice?.amount

  if (hasPriceRange) {
    return (
      <>
        From <FormattedMoney price={priceRange?.minVariantPrice} />
      </>
    )
  }

  return (
    <FormattedMoney
      compareAtPrice={
        displaySingleVariantComparePrice
          ? compareAtPriceRange?.minVariantPrice
          : undefined
      }
      price={priceRange?.minVariantPrice}
    />
  )
}

export default ProductPresentmentPriceRange
