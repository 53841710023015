import React from 'react'

const Path14 = () => {
  return (
    <>
      <path d="M89.7003 51.5998C89.5003 48.9998 89.2003 46.4998 89.0003 43.9998C88.7003 40.0998 88.3003 36.0998 88.1003 32.1998C87.9003 27.2998 87.7003 22.4998 87.6003 17.5998C87.5003 13.2998 87.7003 8.89983 87.7003 4.59983C87.7003 4.19983 87.8003 3.69983 87.9003 3.29983C88.1003 2.29983 88.8003 1.59983 89.5003 1.79983C90.3003 2.09983 90.3003 2.89983 90.3003 3.69983C90.2003 6.09983 90.1003 8.59983 90.1003 10.9998C90.1003 13.0998 90.1003 15.2998 90.1003 17.3998C90.2003 19.8998 90.4003 22.3998 90.5003 24.8998C90.6003 26.9998 90.7003 29.0998 90.8003 31.1998C91.1003 34.5998 91.4003 37.9998 91.7003 41.3998C92.0003 44.2998 92.3003 47.1998 92.6003 50.0998C93.0003 53.1998 93.4003 56.2998 93.8003 59.2998C94.0003 60.6998 94.4003 62.0998 94.6003 63.4998C94.9003 65.1998 95.0003 66.8998 95.3003 68.4998C95.6003 70.4998 96.1003 72.3998 96.5003 74.3998C96.8003 76.0998 96.8003 77.7998 97.1003 79.4998C97.3003 81.0998 97.7003 82.7998 98.0003 84.3998C98.3003 85.5998 98.6003 86.7998 98.8003 87.9998C99.3003 91.2998 99.7003 94.4998 100.2 97.7998C100.6 100.6 101.1 103.4 101.6 106.1C102.2 109.6 102.7 113.1 103.2 116.6C103.8 120.3 104.6 124 105 127.7C105.4 132.1 107 136.1 107.5 140.5C107.7 141.9 108.4 143.2 108.6 144.6C108.8 146 109.3 147.5 108.6 149C107.9 150.4 107.1 150.8 106.3 149.4C105.6 148.2 105.2 146.8 104.8 145.4C104 142.6 103.3 139.7 102.6 136.9C102.1 134.8 101.7 132.7 101.4 130.6C100.9 127.1 100.5 123.6 100 120.1C99.2003 115.4 98.3003 110.9 97.5003 106.2C96.9003 102.2 96.5003 98.1998 95.9003 94.1998C95.4003 90.6998 94.7003 87.1998 94.1003 83.6998C93.7003 80.9998 93.4003 78.2998 93.0003 75.5998C92.4003 71.4998 91.8003 67.4998 91.3003 63.3998C90.8003 59.4998 90.2003 55.6998 89.7003 51.7998C89.7003 51.8998 89.7003 51.6998 89.7003 51.5998Z" />
      <path d="M59.8006 -0.100098C61.4006 0.299902 61.2006 1.5999 61.1006 3.0999C61.0006 5.2999 61.1006 7.4999 61.1006 9.7999C61.1006 11.9999 61.1006 14.2999 61.1006 16.4999C61.1006 18.0999 61.2006 19.6999 61.3006 21.2999C61.4006 24.8999 61.6006 28.4999 61.7006 32.0999C61.8006 34.3999 61.9006 36.6999 62.0006 38.9999C62.1006 41.7999 62.2006 44.4999 62.4006 47.2999C62.6006 50.8999 62.9006 54.4999 63.2006 58.0999C63.3006 59.7999 63.5006 61.5999 63.7006 63.2999C63.9006 65.1999 64.2006 67.0999 64.5006 68.9999C64.8006 71.5999 65.1006 74.1999 65.5006 76.7999C65.7006 78.3999 65.9006 79.9999 66.0006 81.6999C66.2006 84.0999 66.5006 86.4999 66.7006 88.9999C66.9006 91.3999 67.1006 93.7999 67.2006 96.0999C67.4006 99.2999 67.7006 102.5 67.9006 105.6C68.1006 108.6 68.3006 111.6 68.7006 114.5C69.1006 117.6 69.5006 120.7 70.0006 123.7C70.8006 129.1 71.7006 134.5 72.6006 139.9C72.9006 142 73.5006 144.1 73.7006 146.2C73.8006 147.1 74.3006 148.1 73.7006 149.2C73.3006 150.1 72.7006 150.7 72.0006 150.5C71.5006 150.4 70.9006 149.6 70.8006 149C70.0006 145.5 69.2006 142 68.6006 138.4C67.9006 134.6 67.4006 130.8 66.8006 127C66.1006 122.1 65.3006 117.3 64.8006 112.4C64.3006 108.4 64.1006 104.4 63.7006 100.4C63.5006 98.6999 63.3006 96.9999 63.3006 95.1999C63.1006 90.9999 63.0006 86.9999 62.7006 82.8999C62.5006 79.7999 62.0006 76.6999 61.7006 73.4999C61.1006 68.0999 60.5006 62.5999 60.0006 57.1999C59.6006 52.3999 59.3006 47.4999 59.1006 42.5999C58.9006 38.2999 59.0006 34.0999 58.9006 29.7999C58.8006 26.0999 58.7006 22.4999 58.6006 18.7999C58.6006 15.7999 58.6006 12.6999 58.7006 9.6999C58.8006 7.2999 58.9006 4.7999 59.1006 2.3999C59.2006 1.4999 59.6006 0.699902 59.8006 -0.100098Z" />
      <path d="M71.4997 7.59995C71.5997 11.4 71.7997 15.2 71.8997 19C71.9997 22.1 72.0997 25.1999 72.1997 28.2999C72.2997 31.4 72.4997 34.6 72.6997 37.7C72.7997 40.5 72.8997 43.2999 73.0997 46C73.2997 49.0999 73.5997 52.3 73.9997 55.4C74.3997 58.7 74.7997 62.0999 75.2997 65.3999C75.7997 69.2999 76.3997 73.0999 76.8997 76.9999C77.0997 78.4999 76.9997 80.0999 77.1997 81.5999C77.4997 85.0999 77.8997 88.6999 78.2997 92.1999C78.6997 95.7999 79.2997 99.4999 79.6997 103.1C80.0997 106.5 80.2997 109.9 80.5997 113.2C80.9997 116.7 81.4997 120.2 81.9997 123.7C82.3997 126.5 82.7997 129.4 83.3997 132.2C83.9997 135.2 84.7997 138.2 85.4997 141.2C85.5997 141.8 85.6997 142.5 85.7997 143.1C85.9997 144.2 85.5997 145.2 84.8997 145.3C84.3997 145.4 83.4997 144.6 83.1997 143.9C82.3997 141.9 81.7997 139.7 81.2997 137.6C80.4997 133.8 79.7997 130.1 79.0997 126.3C78.5997 123.4 78.3997 120.4 77.9997 117.5C77.3997 112.1 76.7997 106.7 76.1997 101.3C75.6997 97.2999 75.2997 93.1999 74.7997 89.1999C74.3997 85.5999 73.8997 82.0999 73.3997 78.5999C72.8997 74.8999 72.3997 71.1999 71.7997 67.4999C71.3997 64.5999 70.8997 61.7 70.5997 58.7999C70.2997 56.0999 69.9997 53.2999 69.7997 50.5999C69.4997 46.5999 69.2997 42.5999 69.1997 38.5C68.9997 34.0999 68.8997 29.6 68.7997 25.2C68.6997 19.6 68.6997 14 68.6997 8.39995C68.6997 6.59995 68.5997 4.79995 68.6997 2.99995C68.6997 2.29995 69.0997 1.49995 69.4997 0.89995C69.6997 0.69995 70.4997 0.79995 70.6997 1.09995C70.9997 1.49995 71.1997 2.29995 71.2997 2.89995C71.4997 4.49995 71.4997 5.99995 71.4997 7.59995C71.5997 7.59995 71.4997 7.59995 71.4997 7.59995Z" />
      <path d="M79.1998 1.19971C79.1998 3.29971 79.0998 5.29971 79.1998 7.39971C79.3998 11.3997 79.6998 15.3997 79.8998 19.2997C80.1998 24.4997 80.3998 29.5997 80.6998 34.7997C80.7998 37.2997 80.9998 39.7997 81.1998 42.2997C81.3998 44.7997 81.4998 47.2997 81.6998 49.7997C82.0998 53.0997 82.5998 56.2997 82.9998 59.5997C83.3998 62.2997 83.5998 64.9997 83.9998 67.5997C84.3998 70.5997 84.9998 73.4997 85.3998 76.4997C85.7998 79.0997 85.9998 81.6997 86.2998 84.2997C86.6998 87.2997 87.1998 90.2997 87.6998 93.3997C87.8998 94.9997 88.1998 96.4997 88.4998 98.0997C88.9998 101.4 89.5998 104.7 89.9998 108C90.3998 110.7 90.5998 113.4 90.9998 116.1C91.3998 118.7 91.7998 121.2 92.1998 123.8C92.4998 125.9 92.7998 128 93.0998 130.1C93.2998 131.3 93.5998 132.5 93.7998 133.7C94.1998 135.8 94.5998 138 95.0998 140.1C95.4998 142.2 96.0998 144.2 96.4998 146.3C96.5998 146.9 96.6998 147.6 96.5998 148.2C96.3998 149.2 95.7998 149.8 94.9998 149.8C93.8998 149.7 93.4998 148.5 93.2998 147.5C92.1998 142.8 91.1998 138.2 90.2998 133.5C89.4998 129.5 88.7998 125.4 88.1998 121.4C87.5998 117.6 87.1998 113.8 86.6998 110C86.1998 106.1 85.5998 102.2 84.9998 98.2997C84.5998 95.4997 84.2998 92.5997 83.8998 89.7997C83.4998 86.7997 82.8998 83.6997 82.4998 80.6997C81.9998 76.3997 81.5998 71.9997 81.0998 67.5997C80.5998 63.7997 79.9998 60.0997 79.4998 56.2997C79.1998 53.7997 78.9998 51.1997 78.7998 48.6997C78.4998 44.6997 78.1998 40.5997 77.9998 36.5997C77.7998 33.2997 77.7998 29.9997 77.6998 26.5997C77.5998 21.7997 77.3998 17.0997 77.2998 12.2997C77.2998 9.19971 77.3998 6.19971 77.5998 3.09971C77.5998 1.69971 78.0998 1.19971 79.1998 1.19971Z" />
      <path d="M121.9 52.0998C121.6 49.4998 121.3 46.9998 121.1 44.4998C120.8 40.5998 120.4 36.5998 120.2 32.6998C120 27.7998 119.8 22.9998 119.7 18.0998C119.6 13.7998 119.8 9.39983 119.8 5.09983C119.8 4.69983 119.9 4.19983 120 3.79983C120.2 2.79983 120.9 2.09983 121.6 2.29983C122.4 2.59983 122.4 3.39983 122.4 4.19983C122.3 6.59983 122.2 9.09983 122.2 11.4998C122.2 13.5998 122.2 15.7998 122.2 17.8998C122.3 20.3998 122.5 22.8998 122.6 25.3998C122.7 27.4998 122.8 29.5998 122.9 31.6998C123.2 35.0998 123.5 38.4998 123.8 41.8998C124.1 44.7998 124.4 47.6998 124.7 50.5998C125.1 53.6998 125.5 56.7998 125.9 59.7998C126.1 61.1998 126.5 62.5998 126.7 63.9998C127 65.6998 127.1 67.3998 127.4 68.9998C127.7 70.9998 128.2 72.8998 128.6 74.8998C128.9 76.5998 128.9 78.2998 129.2 79.9998C129.4 81.5998 129.8 83.2998 130.1 84.8998C130.4 86.0998 130.7 87.2998 130.9 88.4998C131.4 91.7998 131.8 94.9998 132.3 98.2998C132.7 101.1 133.2 103.8 133.7 106.6C134.3 110.1 134.8 113.6 135.3 117.1C135.9 120.8 136.7 124.5 137.1 128.2C137.5 132.6 139.1 136.6 139.6 141C139.8 142.4 140.5 143.7 140.7 145.1C140.9 146.5 141.4 148 140.7 149.5C140 150.9 139.2 151.3 138.4 149.9C137.7 148.7 137.3 147.3 136.9 145.9C136.1 143.1 135.4 140.2 134.7 137.4C134.2 135.3 133.8 133.2 133.5 131.1C133 127.6 132.6 124.1 132.1 120.6C131.3 115.9 130.4 111.4 129.6 106.7C129 102.7 128.6 98.6998 128 94.6998C127.5 91.1998 126.8 87.6998 126.2 84.1998C125.8 81.4998 125.5 78.7998 125.1 76.0998C124.5 71.9998 123.9 67.9998 123.4 63.8998C122.9 59.9998 122.3 56.1998 121.8 52.2998C121.9 52.3998 121.9 52.1998 121.9 52.0998Z" />
      <path d="M103.7 8.09995C103.8 11.9 104 15.7 104.1 19.5C104.2 22.6 104.301 25.6999 104.401 28.7999C104.501 31.9 104.701 35.1 104.901 38.2C105.001 41 105.101 43.7999 105.301 46.5C105.501 49.5999 105.8 52.8 106.2 55.9C106.6 59.2 107 62.5999 107.5 65.8999C108 69.7999 108.6 73.5999 109.1 77.4999C109.3 78.9999 109.201 80.5999 109.401 82.0999C109.701 85.5999 110.1 89.1999 110.5 92.6999C110.9 96.2999 111.501 99.9999 111.901 103.6C112.3 107 112.501 110.3 112.801 113.7C113.201 117.2 113.7 120.7 114.2 124.2C114.6 127 115 129.9 115.6 132.7C116.2 135.7 117 138.7 117.7 141.7C117.8 142.3 117.9 143 118 143.6C118.201 144.7 117.8 145.7 117.1 145.8C116.6 145.9 115.701 145.1 115.401 144.4C114.601 142.4 114 140.2 113.5 138.1C112.7 134.3 112.001 130.6 111.301 126.8C110.801 123.9 110.6 120.9 110.2 118C109.6 112.6 109.001 107.2 108.401 101.8C107.901 97.7999 107.5 93.6999 107 89.6999C106.6 86.0999 106.1 82.5999 105.6 79.0999C105.1 75.3999 104.6 71.6999 104 67.9999C103.6 65.0999 103.101 62.2 102.801 59.2999C102.501 56.5999 102.2 53.7999 102 51.0999C101.7 47.0999 101.501 43.0999 101.401 39C101.201 34.5999 101.1 30.1 101 25.7C101 20.1 101 14.5 101 8.89995C101 7.09995 100.9 5.29995 101 3.49995C101 2.79995 101.401 1.99995 101.801 1.39995C102.001 1.19995 102.8 1.29995 103 1.59995C103.3 1.99995 103.5 2.79995 103.6 3.39995C103.7 4.99995 103.7 6.49995 103.7 8.09995C103.8 8.09995 103.7 8.09995 103.7 8.09995Z" />
      <path d="M111.4 1.79988C111.4 3.89988 111.3 5.89988 111.4 7.99988C111.6 11.9999 111.9 15.9999 112.1 19.8999C112.4 25.0999 112.6 30.1999 112.9 35.3999C113 37.8999 113.2 40.3999 113.4 42.8999C113.6 45.3999 113.7 47.8999 113.9 50.3999C114.3 53.6999 114.8 56.8999 115.2 60.1999C115.6 62.8999 115.8 65.5999 116.2 68.1999C116.6 71.1999 117.2 74.0999 117.6 77.0999C118 79.6999 118.2 82.2999 118.5 84.8999C118.9 87.8999 119.4 90.8999 119.9 93.9999C120.1 95.5999 120.4 97.0999 120.7 98.6999C121.2 102 121.8 105.3 122.2 108.6C122.6 111.3 122.8 114 123.2 116.7C123.6 119.3 124 121.8 124.4 124.4C124.7 126.5 125 128.6 125.3 130.7C125.5 131.9 125.8 133.1 126 134.3C126.4 136.4 126.8 138.6 127.3 140.7C127.7 142.8 128.3 144.8 128.7 146.9C128.8 147.5 128.9 148.2 128.8 148.8C128.6 149.8 128 150.4 127.2 150.4C126.1 150.3 125.7 149.1 125.5 148.1C124.4 143.4 123.4 138.8 122.5 134.1C121.7 130.1 121 126 120.4 122C119.8 118.2 119.4 114.4 118.9 110.6C118.4 106.7 117.8 102.8 117.2 98.8999C116.8 96.0999 116.5 93.1999 116.1 90.3999C115.7 87.3999 115.1 84.2999 114.7 81.2999C114.2 76.9999 113.8 72.5999 113.3 68.1999C112.8 64.3999 112.2 60.6999 111.7 56.8999C111.4 54.3999 111.2 51.7999 111 49.2999C110.7 45.2999 110.4 41.1999 110.2 37.1999C110 33.8999 110 30.5999 109.9 27.1999C109.8 22.3999 109.6 17.6999 109.5 12.8999C109.5 9.79988 109.6 6.79988 109.8 3.69988C109.8 2.19988 110.3 1.69988 111.4 1.79988Z" />
      <path d="M49.9997 2.0001C50.0997 2.6001 50.2997 3.2001 50.2997 3.8001C50.4997 7.3001 50.6997 10.7001 50.8997 14.2001C51.0997 19.6001 51.2997 25.1001 51.4997 30.5001C51.7997 36.9001 51.9997 43.3001 52.3997 49.6001C52.5997 52.7001 52.7997 55.9001 53.0997 59.0001C53.5997 63.9001 54.1997 68.7001 54.5997 73.6001C54.8997 76.6001 54.9997 79.6001 55.0997 82.6001C55.3997 89.0001 55.6997 95.5001 56.0997 101.9C56.2997 104.9 56.5997 108 56.7997 111C56.9997 113.2 56.9997 115.3 57.1997 117.5C57.4997 120.4 57.8997 123.3 58.1997 126.2C58.5997 130 58.8997 133.7 59.2997 137.5C59.4997 139 59.7997 140.5 59.9997 142C60.2997 144.3 60.6997 146.7 60.8997 149C60.8997 149.3 59.8997 150.1 59.3997 150C58.7997 149.9 58.0997 149.2 57.8997 148.6C57.1997 146.5 56.6997 144.3 56.1997 142.1C55.7997 140.5 55.5997 138.8 55.3997 137.1C54.8997 133.5 54.4997 130 54.0997 126.4C53.5997 121.6 53.1997 116.9 52.7997 112.1C52.5997 110.3 52.5997 108.5 52.5997 106.7C52.4997 103 52.2997 99.4001 52.1997 95.7001C52.0997 93.7001 52.2997 91.7001 52.1997 89.7001C51.9997 84.4001 51.7997 79.2001 51.5997 73.9001C51.4997 71.5001 51.4997 69.1001 51.3997 66.8001C51.2997 64.4001 50.8997 62.1001 50.7997 59.7001C50.4997 55.4001 50.0997 51.1001 49.8997 46.8001C49.5997 42.0001 49.3997 37.0001 49.2997 31.9001C49.1997 28.5001 49.2997 25.2001 49.1997 21.8001C49.0997 17.8001 48.8997 13.8001 48.6997 9.8001C48.5997 7.6001 48.4997 5.4001 48.4997 3.3001C48.3997 1.9001 49.1997 2.0001 49.9997 2.0001Z" />
      <path d="M38.1003 147.9C36.9003 147.4 36.6003 146.3 36.4003 145C35.4003 139.8 34.2003 134.7 33.2003 129.5C32.7003 127 32.6003 124.4 32.4003 121.9C32.1003 118.7 31.8003 115.6 31.6003 112.4C31.5003 110.1 31.5003 107.8 31.4003 105.5C31.2003 101.7 31.0003 97.9 30.9003 94.2C30.8003 91 31.0003 87.9 31.0003 84.7C31.0003 79 31.0003 73.2 30.8003 67.5C30.7003 62.8 30.6003 58.1 30.0003 53.5C29.1003 46.6 29.3003 39.7 29.0003 32.7C28.8003 27.4 28.5003 22.1 28.3003 16.8C28.2003 13.4 28.3003 10 28.3003 6.60005C28.3003 5.50005 29.0003 5.40005 29.6003 5.30005C30.2003 5.20005 30.1003 5.80005 30.1003 6.40005C30.2003 8.90005 30.2003 11.3 30.4003 13.8C30.6003 17.7 30.9003 21.6 31.2003 25.5C31.3003 28.2 31.4003 30.9 31.5003 33.5C31.6003 35.8 31.8003 38.2 31.9003 40.5C32.1003 44.1 32.1003 47.7 32.3003 51.2C32.5003 54.7 33.0003 58.1 33.3003 61.6C33.4003 63.3 33.4003 64.9 33.4003 66.6C33.5003 69.8 33.6003 73 33.7003 76.2C33.8003 79.8 33.7003 83.4 33.9003 87.1C34.1003 92.2 34.3003 97.2001 34.5003 102.3C34.7003 105.8 34.8003 109.3 35.1003 112.8C35.5003 117.5 35.9003 122.2 36.5003 126.9C37.0003 130.8 37.8003 134.6 38.5003 138.4C38.9003 140.8 39.2003 143.2 39.4003 145.6C39.4003 146.2 38.9003 146.8 38.6003 147.4C38.5003 147.6 38.3003 147.7 38.1003 147.9Z" />
      <path d="M22.1004 117.9C21.9004 115.6 21.7004 113.3 21.6004 110.9C21.4004 108.4 21.4004 105.9 21.2004 103.3C21.0004 100.6 20.8004 97.8999 20.5004 95.1999C20.2004 91.4999 19.8004 87.8999 19.5004 84.1999C19.1004 79.5999 18.7004 74.9999 18.5004 70.3999C18.3004 65.0999 18.4004 59.7999 18.4004 54.4999C18.4004 48.7999 18.4004 43.0999 18.4004 37.3999C18.4004 34.8999 18.6004 32.3999 18.7004 29.8999C18.7004 28.9999 18.5004 28.0999 18.5004 27.1999C18.4004 25.0999 18.2004 22.8999 18.4004 20.7999C18.8004 17.1999 17.9004 13.6999 17.9004 10.0999C17.9004 9.19989 18.0004 8.29989 18.2004 7.39989C18.4004 6.29989 19.5004 5.49989 20.4004 5.79989C21.4004 6.09989 21.6004 6.99989 21.5004 8.09989C21.5004 9.89989 21.6004 11.7999 21.7004 13.6999C21.8004 16.9999 22.0004 20.2999 22.0004 23.5999C22.0004 25.4999 21.7004 27.3999 21.8004 29.1999C22.2004 33.3999 21.8004 37.5999 21.7004 41.6999C21.6004 45.0999 21.2004 48.4999 21.2004 51.8999C21.1004 56.4999 21.0004 61.1999 21.1004 65.7999C21.2004 70.1999 21.5004 74.4999 21.7004 78.8999C21.9004 82.9999 22.2004 86.9999 22.6004 91.0999C22.8004 93.9999 23.2004 96.7999 23.5004 99.6999C23.7004 102.4 23.9004 105.1 24.1004 107.8C24.3004 110.9 24.4004 114.1 24.6004 117.2C24.9004 121 25.1004 124.8 25.5004 128.6C25.9004 131.7 26.5004 134.6 27.1004 137.7C27.4004 139.5 27.8004 141.4 28.0004 143.2C28.0004 143.6 27.4004 144.2 27.0004 144.5C26.8004 144.6 26.2004 144.1 26.1004 143.8C24.6004 138.7 23.5004 133.5 23.0004 128.2C22.6004 124.8 22.4004 121.4 22.0004 118C22.0004 117.9 22.0004 117.9 22.1004 117.9Z" />
      <path d="M13.6001 5.40004C13.6001 9.90004 13.7001 14.3 13.6001 18.7C13.5001 23.5 13.4001 28.2 13.2001 33C13.0001 37.8 12.8001 42.5 12.6001 47.3C12.5001 50.5 12.2001 53.6 12.1001 56.8C12.0001 59.7 12.1001 62.6 12.1001 65.5C12.1001 68.4 12.0001 71.2 12.0001 74.1C12.0001 75.1 12.1001 76.1 12.1001 77.1C12.2001 79.1 12.3001 81.1 12.4001 83C12.6001 86.5 12.8001 90 13.0001 93.6C13.2001 96.4 13.2001 99.2 13.5001 102C13.8001 105.8 14.2001 109.7 14.6001 113.5C15.0001 117.3 15.3001 121.2 15.7001 125C15.9001 126.5 16.1001 128 16.3001 129.5C16.5001 131.8 16.8001 134 16.9001 136.3C17.1001 139.6 17.3001 142.9 17.4001 146.3C17.4001 146.7 17.0001 147.2 16.8001 147.6C16.4001 147.3 15.8001 147.1 15.8001 146.8C15.4001 145 15.1001 143.2 14.8001 141.4C14.3001 137.3 13.9001 133.2 13.5001 129.2C13.2001 126.8 13.0001 124.5 12.7001 122.1C12.2001 118 11.5001 113.9 11.1001 109.8C10.6001 104.5 10.3001 99.2 9.90011 93.9C9.70011 91.1 9.50011 88.3 9.30011 85.5C9.20011 83.6 9.10011 81.7 9.10011 79.8C9.10011 76.7 9.00011 73.6 9.10011 70.5C9.20011 66.5 9.20011 62.5 9.40011 58.6C9.70011 52.8 10.2001 47.1 10.5001 41.3C10.8001 36.5 10.8001 31.7 11.0001 26.8C11.2001 23.3 11.4001 19.9 11.6001 16.4C11.7001 13.8 11.7001 11.2 11.8001 8.50004C11.8001 7.70004 11.7001 6.90004 11.8001 6.10004C11.8001 5.90004 12.2001 5.50004 12.4001 5.50004C12.7001 5.10004 13.1001 5.30004 13.6001 5.40004Z" />
      <path d="M44.9 116.1C44.8 114.7 44.7 113.4 44.6 112C44.4 108.9 44.1 105.7 43.8 102.6C43.6 100.4 43.3 98.1999 43.2 95.9999C43 92.7999 42.8 89.5999 42.6 86.4999C42.4 84.0999 42.1 81.7999 41.9 79.3999C41.7 76.0999 41.5 72.7999 41.3 69.4999C41 65.1999 40.6 60.7999 40.3 56.4999C40 51.9999 39.8 47.5999 39.6 43.0999C39.4 39.5999 39.3 36.1999 39.2 32.6999C39.2 30.2999 39.3 27.9999 39.3 25.5999C39.3 24.2999 39 22.9999 39 21.6999C38.7 17.2999 38.4 12.8999 38.1 8.49992C38 6.89992 38.1 5.29992 38.2 3.79992C38.2 3.49992 38.7 2.99992 38.9 3.09992C39.3 3.29992 40.2 2.79992 40 4.19992C39.8 6.09992 39.8 7.99992 39.9 9.89992C40 11.8999 40.2 13.9999 40.3 15.9999C40.6 20.8999 40.9 25.7999 41.1 30.6999C41.3 34.9999 41.2 39.2999 41.5 43.5999C42 50.6999 42.6 57.6999 43.2 64.6999C43.5 69.1999 43.8 73.5999 44.2 78.0999C44.4 80.3999 44.8 82.5999 45 84.8999C45.2 86.6999 45.2 88.3999 45.4 90.1999C45.8 93.7999 46.3 97.3999 46.6 100.9C47.1 106.9 47.5 112.9 48.1 118.8C48.5 122.8 49.3 126.8 49.9 130.8C50 131.7 50.1 132.6 50.2 133.5C50.5 136 50.9 138.4 51.3 140.9C51.6 142.9 52 144.9 52.3 147C52.4 147.4 52.3 148 52.2 148.4C51.9 149.5 51.2 149.7 50.7 148.7C50.1 147.6 49.7 146.4 49.3 145.1C48.8 143.3 48.5 141.4 48.2 139.5C47.7 136.4 47.1 133.3 46.7 130.2C46.1 125.6 45.5 121 44.9 116.4C44.8 116.5 44.8 116.3 44.9 116.1Z" />
    </>
  )
}

export default Path14
