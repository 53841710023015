import { IconType, SocialType } from '@types'
import React from 'react'
import { Box, Flex } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import { trackSocialChannelClick } from '../../utils/analytics/trackSocialChannelClick'
import ButtonIcon from '../ButtonIcon'

const SocialLinks = () => {
  const socialLinks = useTypedSelector(state => state.system?.social)

  const SOCIAL_LINKS: {
    icon: IconType
    key: SocialType
  }[] = [
    {
      icon: 'logoFacebook',
      key: 'facebook',
    },
    {
      icon: 'logoTwitter',
      key: 'twitter',
    },
    {
      icon: 'logoInstagram',
      key: 'instagram',
    },
    {
      icon: 'logoLinkedin',
      key: 'linkedin',
    },
    {
      icon: 'logoYoutube',
      key: 'youtube',
    },
    {
      icon: 'logoMedium',
      key: 'medium',
    },
    {
      icon: 'logoAnchor',
      key: 'anchor',
    },
    {
      icon: 'logoVimeo',
      key: 'vimeo',
    },
  ]

  const handleTrackClick = (url: string) => {
    // Analytics
    trackSocialChannelClick(url)
  }

  return (
    <Flex>
      {SOCIAL_LINKS.map((link, index) => {
        const socialLinkUrl = socialLinks?.[link.key]

        if (!socialLinkUrl) {
          return null
        }

        return (
          <Box
            key={index}
            mx="10px" // TODO: use theme value
          >
            <a href={socialLinkUrl}>
              <ButtonIcon
                color="white"
                iconSize="24px"
                onClick={handleTrackClick.bind(undefined, socialLinkUrl)}
                size="24px"
                type={link.icon}
              />
            </a>
          </Box>
        )
      })}
    </Flex>
  )
}

export default SocialLinks
