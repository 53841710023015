import React, { FunctionComponent, ReactNode } from 'react'
import { Box, BoxOwnProps, ThemeUIStyleObject } from 'theme-ui'

type Props = {
  children?: ReactNode
  sx?: ThemeUIStyleObject
}

const H1: FunctionComponent<BoxOwnProps> = (props: Props) => {
  const { children, sx, ...rest } = props
  return (
    <Box
      as="h1"
      sx={{
        fontSize: ['xxl', null, null, 'xxxl'],
        fontWeight: 'semibold',
        lineHeight: 'single',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default H1
