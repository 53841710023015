import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { Box, Flex, Heading } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import { systemActions } from '../../redux/modules/system'
import { useAppDispatch } from '../../redux/store'
import { customScrollbarStyles } from '../../styled/theme'
import CartFooter from '../CartFooter'
import CartLineItems from '../CartLineItems'
import Panel from '../Panel'
import PanelHeader from '../PanelHeader'

const PanelCart = () => {
  const router = useRouter()

  // Redux
  const dispatch = useAppDispatch()
  const checkout = useTypedSelector(state => state.store?.checkout)
  const visible = useTypedSelector(state => state.system.cartVisible)

  // Callbacks
  const handleClose = () => {
    dispatch(systemActions.setCartVisible({ cartVisible: false }))
  }

  // Close cart on routes change
  useEffect(() => {
    router.events.on('routeChangeComplete', handleClose)

    return () => {
      router.events.off('routeChangeComplete', handleClose)
    }
  }, [])

  // Iterate through all line items and accumulate count
  const totalItems =
    checkout?.lineItems?.edges?.reduce((acc, val) => {
      acc += val?.node?.quantity
      return acc
    }, 0) || 0

  return (
    <Panel onClickOutside={handleClose} visible={visible}>
      <Flex
        sx={{
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <PanelHeader onClose={handleClose} title={`Bag (${totalItems})`} />

        {/* Line items */}
        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            ...customScrollbarStyles(),
          }}
        >
          <CartLineItems />
        </Box>

        {/* Footer */}
        <CartFooter />
      </Flex>
    </Panel>
  )
}

export default PanelCart
