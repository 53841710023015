import { ShopifyProductVariant } from '@types'
import React from 'react'
import { Box, Flex, ThemeUIStyleObject } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import { CurrencyCode } from '../../types/codegen/shopify'
import Eyebrow from '../Eyebrow'
import ProductVariantPresentmentPrice from '../ProductVariantPresentmentPrice'

type Props = {
  label: string
  onClick?: () => void
  productVariant: ShopifyProductVariant
  selected?: boolean
  singlePurchase?: boolean
  sx?: ThemeUIStyleObject
}

const PillProductVariant = (props: Props) => {
  const {
    label = 'Purchase',
    onClick,
    productVariant,
    selected,
    singlePurchase,
    sx,
  } = props

  // Store: redux
  const checkoutUpdating = useTypedSelector(
    state => state.store.checkoutUpdating
  )
  const lineItems = useTypedSelector(state => state.store.checkout.lineItems)
  const lineItemAdding = useTypedSelector(state => state.store.lineItemAdding)

  let disabled = checkoutUpdating || lineItemAdding || !productVariant.inStock

  return (
    <Flex
      bg="lightGray"
      color="midnight"
      onClick={disabled || selected ? undefined : onClick}
      px={5}
      py={2}
      sx={{
        border: '1px solid',
        borderColor: 'transparent',
        borderRadius: 1,
        cursor: disabled || selected ? 'default' : 'pointer',
        flexDirection: 'column',
        height: '80px',
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'relative',
        transition: 'border-color 300ms',
        width: '100%',
        ...(selected
          ? {
              borderColor: 'midnight',
            }
          : {}),
        ...(disabled
          ? {
              opacity: 0.5,
            }
          : {}),
        ...(!disabled && !selected
          ? {
              '@media (hover: hover) and (pointer: fine)': {
                '&:hover:not(:disabled)': {
                  borderColor: 'midnight',
                },
              },
            }
          : {}),
        ...sx,
      }}
    >
      <Box sx={{ fontSize: ['xs', null, null, 'm'], fontWeight: 'semibold' }}>
        {label}
      </Box>

      <Box
        mt={[2, null, null, 1]}
        sx={{ fontSize: ['xxs', null, null, 'xs'], fontWeight: 'semibold' }}
      >
        {/* Price / sold out */}
        {productVariant.inStock ? (
          <ProductVariantPresentmentPrice
            pricePairs={[
              {
                compareAtPrice: {
                  amount: productVariant.compareAtPrice,
                  currencyCode: 'GBP' as CurrencyCode,
                },
                price: {
                  amount: productVariant.price,
                  currencyCode: 'GBP' as CurrencyCode,
                },
              },
            ]}
          />
        ) : (
          <Eyebrow color="slate" mt={1}>
            Sold out
          </Eyebrow>
        )}
      </Box>
    </Flex>
  )
}

export default PillProductVariant
