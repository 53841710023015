import { ColorTheme, TeamMemberPreview } from '@types'
import { rgba } from 'polished'
import React from 'react'
import { Box, Flex } from 'theme-ui'
import { COLORS } from '../../constants'
import ImageAvatar from '../ImageAvatar'
import Link from '../Link'

export type Props = {
  colorTheme?: ColorTheme
  teamMember: TeamMemberPreview
}

const TeamMemberProfile = (props: Props) => {
  const { colorTheme, teamMember } = props

  // State
  const color = COLORS[colorTheme?.text || 'midnight']

  return (
    <Box color={colorTheme?.text}>
      <Flex
        sx={{
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          position: 'relative',
          width: '100%',
        }}
      >
        {/* Image */}
        <Box
          sx={{
            height: ['50px', null, null, '136px'],
            width: ['50px', null, null, '136px'],
          }}
        >
          <ImageAvatar image={teamMember.image} sizes="136px" />
        </Box>

        <Box ml={[3, null, null, 8]} sx={{ flex: 1 }}>
          {/* Name */}
          {teamMember?.name && (
            <Box>
              <Link
                sx={{
                  fontSize: 's',
                  fontWeight: 'semibold',
                }}
                variant="simple"
              >
                {teamMember.name}
              </Link>
            </Box>
          )}

          {/* Role */}
          {teamMember?.role && (
            <Box
              color={rgba(color, 0.5)}
              mt={[1, null, null, 1]}
              sx={{
                fontSize: 'xs',
                lineHeight: 'body',
              }}
            >
              {teamMember.role}
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  )
}

export default TeamMemberProfile
