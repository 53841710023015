import { EventPreview } from '@types'
import NextLink from 'next/link'
import React, { useState } from 'react'
import { Box, Flex } from 'theme-ui'
import Artboard from '../Artboard'
import BoxResponsive from '../BoxResponsive'
import Button from '../Button'
import NextImageZoomable from '../NextImageZoomable'
import Link from '../Link'

type Props = {
  event: EventPreview
}

const EventCard = (props: Props) => {
  const { event } = props

  // State
  const [hover, setHover] = useState(false)

  const eventHref = event?.slug

  return (
    <Box>
      <NextLink href={eventHref}>
        <a>
          <Box
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <BoxResponsive
              aspect={[480 / 275]}
              sx={{ borderRadius: 1, overflow: 'hidden' }}
            >
              {/* Artboard */}
              {event?.linkedLayout?.artboard && (
                <Artboard artboard={event.linkedLayout.artboard} />
              )}

              {/* Image */}
              {event?.linkedLayout?.image && (
                <Flex
                  sx={{
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'center',
                    transform: 'translate3d(0,0,0)', // Force depth on Safari
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: 1,
                      flexShrink: 0,
                      overflow: 'hidden',
                      position: 'relative',
                      width: '45%',
                    }}
                  >
                    <BoxResponsive aspect={[1]}>
                      <NextImageZoomable
                        image={event.linkedLayout.image}
                        layout="fill"
                        sizes={['45vw']}
                        zoomed={hover}
                      />
                    </BoxResponsive>
                  </Box>
                </Flex>
              )}
            </BoxResponsive>
          </Box>
        </a>
      </NextLink>

      <Box sx={{ maxWidth: '420px' }}>
        {/* Title */}
        <Box
          mt={4}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          sx={{
            fontSize: ['m', null, null, 'l'],
            fontWeight: 'semibold',
          }}
        >
          <NextLink href={eventHref} passHref>
            <Link active={hover} variant="simple">
              {event.title}
            </Link>
          </NextLink>
        </Box>

        {/* Excerpt */}
        {event?.excerpt && (
          <Box
            mt={3}
            sx={{
              fontSize: 'xs',
              lineHeight: 'body',
            }}
          >
            {event.excerpt}
          </Box>
        )}
      </Box>

      {/* Button */}
      {event?.slug && (
        <Box mt={4}>
          <NextLink href={eventHref}>
            <a>
              <Button
                sx={{ width: ['100%', null, null, 'auto'] }}
                variant="outline"
              >
                View event
              </Button>
            </a>
          </NextLink>
        </Box>
      )}
    </Box>
  )
}

export default EventCard
