import React from 'react'

const Path21 = () => {
  return (
    <>
      <path d="M74.5004 42.1001C74.2004 42.8001 74.0004 43.2001 73.8004 43.7001C72.5004 47.5001 71.5004 51.4001 69.3004 54.8001C68.9004 55.4001 68.4004 56.0001 67.8004 56.7001C67.3004 55.8001 67.8004 55.2001 68.0004 54.7001C68.5004 53.4001 69.1004 52.2001 69.6004 50.9001C70.2004 49.3001 70.7004 47.6001 71.2004 45.9001C71.6004 44.6001 71.9004 43.2001 72.3004 41.9001C72.5004 41.0001 72.8004 40.1001 73.8004 39.7001C75.2004 39.1001 76.3004 39.5001 77.2004 40.6001C78.9004 42.6001 80.6004 44.6001 82.3004 46.6001C84.3004 49.0001 86.3004 51.3001 88.4004 53.6001C89.0004 54.3001 89.7004 54.8001 90.4004 55.5001C90.8004 55.9001 91.4004 56.4001 90.8004 56.9001C90.4004 57.2001 89.6004 57.5001 89.2004 57.3001C87.9004 56.8001 86.6004 56.2001 85.7004 55.0001C84.1004 52.9001 82.2004 51.1001 80.4004 49.1001C79.5004 48.1001 78.7004 46.9001 77.8004 45.9001C77.1004 45.1001 76.3004 44.3001 75.6004 43.4001C75.2004 43.1001 74.9004 42.7001 74.5004 42.1001Z" />
      <path d="M131.5 14.9003C131.3 15.7003 131.1 16.4003 130.9 17.2003C130.4 19.2003 129.9 21.2003 129.4 23.2003C128.8 25.3003 128.1 27.3003 127.4 29.4003C127.2 29.8003 127.2 30.7003 126.4 30.3003C126.5 29.7003 126.5 29.1003 126.7 28.5003C127.3 25.8003 128.1 23.0003 128.6 20.3003C129 18.4003 129.2 16.5003 129.5 14.6003C129.6 13.9003 129.7 13.2003 129.9 12.6003C130.2 11.5003 132 11.1003 132.8 11.9003C133.2 12.3003 133.6 12.8003 133.9 13.3003C135.2 15.8003 136.4 18.3003 137.6 20.8003C139.2 23.9003 140.8 27.0003 142.4 30.1003C142.6 30.6003 142.9 31.0003 143.1 31.5003C143.2 31.8003 143.2 32.2003 143 32.4003C142.7 32.7003 142.2 32.9003 141.9 32.9003C141 32.7003 140.2 32.3003 139.7 31.4003C139.1 30.2003 138.4 29.0003 137.8 27.8003C137 26.0003 136.2 24.2003 135.3 22.5003C134.4 20.8003 133.5 19.1003 132.6 17.4003C132.2 16.7003 132 15.9003 131.7 15.2003C131.7 14.9003 131.5 14.9003 131.5 14.9003Z" />
      <path d="M105.1 39.6001C103.2 44.7001 103.1 50.2001 100.3 54.9001C100.1 54.9001 99.9998 54.8001 99.7998 54.8001C99.8998 54.2001 99.8998 53.6001 100.2 53.1001C101 51.5001 101 49.7001 101.4 48.0001C102 45.5001 102.4 43.0001 102.9 40.5001C103.1 39.4001 103.4 38.3001 103.8 37.2001C104.1 36.5001 105.2 36.2001 105.8 36.6001C106.4 37.0001 107.1 37.5001 107.5 38.2001C109.4 41.3001 112 43.7001 114.5 46.3001C116.6 48.5001 118.7 50.7001 120.7 52.9001C121.6 53.9001 121.7 54.6001 121.1 55.2001C120.6 55.7001 119.6 55.6001 118.8 54.8001C117.5 53.5001 116.2 52.2001 115 50.9001C114 49.8001 113 48.6001 112 47.4001C111 46.3001 109.9 45.4001 108.9 44.3001C107.7 42.9001 106.6 41.5001 105.5 40.1001C105.4 40.0001 105.3 39.9001 105.1 39.6001Z" />
      <path d="M14.9998 68.3002C14.1998 70.5002 13.4998 72.5002 12.7998 74.5002C12.2998 75.8002 11.6998 77.1002 11.1998 78.4002C11.0998 78.6002 10.7998 78.7002 10.5998 78.9002C10.4998 78.6002 10.2998 78.3002 10.2998 78.1002C10.6998 76.8002 11.2998 75.4002 11.6998 74.1002C12.4998 71.4002 13.2998 68.6002 13.8998 65.9002C14.0998 65.0002 14.3998 64.6002 15.2998 64.5002C16.1998 64.4002 16.5998 65.0002 16.8998 65.6002C17.7998 67.3002 18.6998 68.9002 19.4998 70.6002C20.3998 72.3002 21.1998 73.9002 22.0998 75.5002C22.9998 77.2002 23.9998 78.8002 24.9998 80.4002C25.4998 81.4002 25.9998 82.4002 26.4998 83.4002C26.7998 84.0002 26.7998 84.7002 27.6998 84.8002C27.7998 84.8002 27.9998 85.4002 27.9998 85.6002C27.7998 86.2002 26.8998 86.6002 26.1998 86.4002C25.6998 86.2002 25.2998 86.0002 24.7998 85.9002C23.9998 85.6002 23.7998 84.9002 23.3998 84.3002C22.0998 81.7002 20.5998 79.2002 19.1998 76.7002C18.1998 74.9002 17.2998 73.1002 16.3998 71.3002C15.9998 70.3002 15.4998 69.4002 14.9998 68.3002Z" />
      <path d="M110.9 62.6002C109.6 68.4002 107.5 73.7002 103.9 78.4002C103.8 78.4002 103.7 78.3002 103.7 78.3002C103.8 77.9002 103.9 77.5002 104.1 77.1002C104.5 76.2002 105.1 75.4002 105.5 74.5002C106.1 73.3002 106.5 72.0002 107 70.7002C108.2 67.5002 109 64.1002 109.6 60.7002C109.8 59.7002 111.2 59.3002 112.1 60.0002C112.6 60.4002 113.2 60.9002 113.5 61.5002C115 63.9002 116.3 66.4002 117.9 68.7002C119.5 71.1002 121.3 73.4002 123 75.7002C123.8 76.8002 124.5 77.9002 125.1 79.1002C125.3 79.4002 125.3 80.0002 125.1 80.3002C124.9 80.5002 124.4 80.5002 124 80.6002C122.8 80.7002 122.4 79.8002 121.8 79.0002C120.9 77.5002 119.8 76.2002 118.8 74.8002C117.1 72.1002 115.4 69.3002 113.7 66.5002C113.1 65.4002 112.5 64.3002 111.9 63.3002C111.8 63.1002 111.6 62.8002 111.5 62.6002C111.2 62.5002 111 62.6002 110.9 62.6002Z" />
      <path d="M75 79.3003C75.3 78.0003 75.7 76.7003 76 75.4003C76.6 72.9003 77.1 70.4003 77.6 67.8003C77.9 66.3003 77.8 64.9003 78 63.4003C78 63.1003 78.3 62.6003 78.6 62.5003C79.3 62.3003 80 61.9003 80.6 62.7003C81.6 64.0003 82.5 65.3003 83.4 66.6003C85.1 69.2003 86.7 71.9003 88.5 74.4003C89.6 75.9003 90.9 77.3003 92.1 78.7003C92.5 79.2003 92.8 79.8003 93.2 80.3003C93.4 80.6003 93.7 80.8003 93.9 81.1003C94.1 81.3003 94.4 81.8003 94.4 81.9003C94.1 82.2003 93.7 82.7003 93.3 82.7003C91.5 82.8003 90.7 81.4003 89.9 80.1003C89.5 79.4003 89 78.8003 88.5 78.1003C87 76.0003 85.4 73.9003 83.9 71.7003C82.8 70.1003 81.7 68.4003 80.6 66.7003C80.4 66.5003 80.2 66.2003 79.9 65.8003C79.6 67.0003 79.5 68.1003 79.2 69.1003C78.3 72.2003 77.5 75.2003 76.5 78.2003C76.4 78.7003 75.8 79.0003 75.5 79.4003C75.1 79.4003 75.1 79.4003 75 79.3003Z" />
      <path d="M45.9998 67.6001C44.5998 71.8001 44.3998 76.1001 42.3998 80.2001C42.1998 79.7001 41.9998 79.4001 42.0998 79.2001C42.9998 76.3001 43.0998 73.2001 43.8998 70.3001C44.2998 68.9001 44.2998 67.5001 44.5998 66.1001C44.6998 65.8001 44.9998 65.3001 45.2998 65.2001C45.7998 65.1001 46.5998 65.0001 46.9998 65.3001C47.5998 65.6001 47.9998 66.3001 48.3998 66.8001C50.5998 69.5001 52.8998 72.2001 55.0998 74.9001C56.5998 76.7001 57.9998 78.5001 59.4998 80.3001C60.0998 81.0001 60.6998 81.8001 61.2998 82.4001C61.4998 82.6001 61.8998 82.7001 62.1998 82.8001C62.8998 82.9001 63.1998 83.4001 62.8998 84.0001C62.4998 84.7001 61.9998 85.0001 61.1998 84.8001C59.7998 84.5001 58.7998 83.9001 58.0998 82.6001C57.3998 81.5001 56.4998 80.6001 55.6998 79.6001C55.0998 78.8001 54.4998 78.0001 53.7998 77.2001C53.3998 76.7001 52.9998 76.4001 52.5998 75.9001C50.9998 74.0001 49.4998 72.2001 47.9998 70.4001C47.4998 69.8001 47.0998 69.2001 46.6998 68.5001C46.4998 68.3001 46.2998 68.1001 45.9998 67.6001Z" />
      <path d="M4.89963 106.2C4.69963 105 5.39963 104 5.69963 103C6.49963 100.5 6.99963 97.9003 7.69963 95.3003C7.89963 94.3003 8.09963 93.3003 8.39963 92.4003C8.79963 91.1003 10.5996 90.6003 11.4996 91.6003C12.4996 92.7003 13.2996 93.9003 14.0996 95.2003C15.5996 97.5003 16.9996 99.9003 18.5996 102.3C19.4996 103.6 20.5996 104.8 21.4996 106.1C21.7996 106.5 22.0996 106.8 22.2996 107.2C22.3996 107.5 22.4996 108.1 22.2996 108.3C21.9996 108.6 21.4996 108.8 21.0996 108.7C19.6996 108.5 18.7996 107.5 17.9996 106.4C17.1996 105.2 16.2996 104.2 15.4996 103C14.3996 101.4 13.4996 99.6003 12.4996 98.0003C11.7996 96.8003 10.9996 95.5003 10.2996 94.3003C10.1996 94.2003 9.89963 94.0003 9.89963 94.1003C9.69963 94.3003 9.59963 94.5003 9.49963 94.7003C8.99963 96.5003 8.49963 98.3003 7.89963 100C7.09963 102 6.19963 104 5.29963 106C5.19963 105.9 5.09963 106 4.89963 106.2Z" />
      <path d="M137.9 63.9002C136.9 67.8002 136.2 71.8002 134.3 75.4002C134.3 74.9002 134.2 74.4002 134.3 73.9002C134.6 72.5002 135.1 71.1002 135.4 69.7002C135.8 67.5002 136.1 65.4002 136.6 63.2002C136.8 62.0002 138.4 61.4002 139.4 62.2002C140.2 62.9002 141 63.7002 141.5 64.6002C144 68.8002 146.4 73.1002 148.2 77.7002C148.7 79.0002 149.4 80.3002 149.9 81.6002C150 81.9002 150 82.6002 149.8 82.8002C149.6 83.0002 149 83.1002 148.6 83.0002C147.5 82.7002 146.8 82.0002 146.4 80.9002C145.8 79.1002 145 77.3002 144.2 75.5002C143 72.8002 141.8 70.2002 140.5 67.6002C139.9 66.3002 139.1 65.1002 138.4 63.9002C138.2 63.8002 138.1 63.8002 137.9 63.9002Z" />
      <path d="M4.7 120.3C3.9 122.5 3.2 124.5 2.5 126.5C2 127.8 1.4 129.1 0.9 130.4C0.8 130.6 0.5 130.7 0.3 130.9C0.2 130.6 0 130.3 0 130.1C0.4 128.8 1 127.4 1.4 126.1C2.2 123.4 3 120.6 3.6 117.9C3.8 117 4.1 116.6 5 116.5C5.9 116.4 6.3 117 6.6 117.6C7.5 119.3 8.4 120.9 9.2 122.6C10.1 124.3 10.9 125.9 11.8 127.5C12.7 129.2 13.7 130.8 14.7 132.4C15.2 133.4 15.7 134.4 16.2 135.4C16.5 136 16.5 136.7 17.4 136.8C17.5 136.8 17.7 137.4 17.7 137.6C17.5 138.2 16.6 138.6 15.9 138.4C15.4 138.2 15 138 14.5 137.9C13.7 137.6 13.5 136.9 13.1 136.3C11.8 133.7 10.3 131.2 8.9 128.7C7.9 126.9 7 125.1 6.1 123.3C5.7 122.2 5.3 121.4 4.7 120.3Z" />
      <path d="M100.6 114.6C99.3004 120.4 97.2004 125.7 93.6004 130.4C93.5004 130.4 93.4004 130.3 93.4004 130.3C93.5004 129.9 93.6004 129.5 93.8004 129.1C94.2004 128.2 94.8004 127.4 95.2004 126.5C95.8004 125.3 96.2004 124 96.7004 122.7C97.9004 119.5 98.7004 116.1 99.3004 112.7C99.5004 111.7 100.9 111.3 101.8 112C102.3 112.4 102.9 112.9 103.2 113.5C104.7 115.9 106 118.4 107.6 120.7C109.2 123.1 111 125.4 112.7 127.7C113.5 128.8 114.2 129.9 114.8 131.1C115 131.4 115 132 114.8 132.3C114.6 132.5 114.1 132.5 113.7 132.6C112.5 132.7 112.1 131.8 111.5 131C110.6 129.5 109.5 128.2 108.5 126.8C106.8 124.1 105.1 121.3 103.4 118.5C102.8 117.4 102.2 116.3 101.6 115.3C101.5 115.1 101.3 114.8 101.2 114.6C100.9 114.5 100.7 114.5 100.6 114.6Z" />
      <path d="M64.7002 131.2C65.0002 129.9 65.4002 128.6 65.7002 127.3C66.3002 124.8 66.8002 122.3 67.3002 119.7C67.6002 118.2 67.5002 116.8 67.7002 115.3C67.7002 115 68.0002 114.5 68.3002 114.4C69.0002 114.2 69.7002 113.8 70.3002 114.6C71.3002 115.9 72.2002 117.2 73.1002 118.5C74.8002 121.1 76.4002 123.8 78.2002 126.3C79.3002 127.8 80.6002 129.2 81.8002 130.6C82.2002 131.1 82.5002 131.7 82.9002 132.2C83.1002 132.5 83.4002 132.7 83.6002 133C83.8002 133.2 84.1002 133.7 84.1002 133.8C83.8002 134.1 83.4002 134.6 83.0002 134.6C81.2002 134.7 80.4002 133.3 79.6002 132C79.2002 131.3 78.7002 130.7 78.2002 130C76.7002 127.9 75.1002 125.8 73.6002 123.6C72.5002 122 71.4002 120.3 70.3002 118.6C70.1002 118.4 69.9002 118.1 69.6002 117.7C69.3002 118.9 69.2002 120 68.9002 121C68.0002 124.1 67.2002 127.1 66.2002 130.1C66.1002 130.6 65.5002 130.9 65.2002 131.3C64.9002 131.3 64.8002 131.3 64.7002 131.2Z" />
      <path d="M35.7996 119.5C34.3996 123.7 34.1996 128 32.1996 132.1C31.9996 131.6 31.7996 131.3 31.8996 131.1C32.7996 128.2 32.8996 125.1 33.6996 122.2C34.0996 120.8 34.0996 119.4 34.3996 118C34.4996 117.7 34.7996 117.2 35.0996 117.1C35.5996 117 36.3996 116.9 36.7996 117.2C37.3996 117.5 37.7996 118.2 38.1996 118.7C40.3996 121.4 42.6996 124.1 44.8996 126.8C46.3996 128.6 47.7996 130.4 49.2996 132.2C49.8996 132.9 50.4996 133.7 51.0996 134.3C51.2996 134.5 51.6996 134.6 51.9996 134.7C52.6996 134.8 52.9996 135.3 52.6996 135.9C52.2996 136.6 51.7996 136.9 50.9996 136.7C49.5996 136.4 48.5996 135.8 47.8996 134.5C47.1996 133.4 46.2996 132.5 45.4996 131.5C44.8996 130.7 44.2996 129.9 43.5996 129.1C43.1996 128.6 42.7996 128.3 42.3996 127.8C40.7996 126 39.2996 124.2 37.7996 122.4C37.2996 121.8 36.8996 121.2 36.4996 120.5C36.1996 120.2 36.0996 120 35.7996 119.5Z" />
      <path d="M127.7 115.8C126.7 119.7 126 123.7 124.1 127.3C124.1 126.8 124 126.3 124.1 125.8C124.4 124.4 124.9 123 125.2 121.6C125.6 119.4 125.9 117.3 126.4 115.1C126.6 113.9 128.2 113.3 129.2 114.1C130 114.8 130.8 115.6 131.3 116.5C133.8 120.7 136.2 125 138 129.6C138.5 130.9 139.2 132.2 139.7 133.5C139.8 133.8 139.8 134.5 139.6 134.7C139.4 134.9 138.8 135 138.4 134.9C137.3 134.6 136.6 133.9 136.2 132.8C135.6 131 134.8 129.2 134 127.4C132.8 124.7 131.6 122.1 130.3 119.5C129.7 118.2 128.9 117 128.2 115.8C127.9 115.8 127.8 115.8 127.7 115.8Z" />
      <path d="M27.7001 35.9C27.0001 36 26.6001 36.1 26.2001 36C25.0001 35.7 24.4001 34.9 23.9001 33.8C23.1001 31.9 22.1001 30.3 20.7001 28.8C19.6001 27.7 18.8001 26.4 17.9001 25.2C17.1001 24.1 16.4001 22.9 15.8001 21.6C15.2001 20.3 14.8001 19 14.2001 17.6C14.0001 18.3 13.8001 18.8 13.7001 19.3C13.2001 22.1 12.8001 24.9 12.1001 27.7C11.6001 29.9 10.7001 32 9.90011 34.2C9.80011 34.6 9.20011 34.9 8.90011 35.2C8.80011 34.8 8.60011 34.3 8.70011 33.9C9.10011 32.3 9.70011 30.7 10.1001 29C10.7001 26.3 11.2001 23.5 11.7001 20.7C12.0001 19.2 12.2001 17.7 12.4001 16.2C12.5001 15.6 12.7001 14.9 13.4001 14.7C14.2001 14.5 14.7001 14.6 15.0001 15.4C15.8001 17.5 16.4001 19.6 17.5001 21.5C18.8001 23.8 20.3001 26 22.0001 28.1C23.3001 29.7 24.5001 31.3 25.3001 33.2C25.6001 34 26.4001 34.6 26.9001 35.2C27.2001 35.3 27.4001 35.5 27.7001 35.9Z" />
      <path d="M105.8 13.6001C104.6 19.3001 103.5 24.9001 101.2 30.3001C101 30.0001 100.7 29.8001 100.8 29.6001C101 28.9001 101.3 28.2001 101.5 27.5001L101.8 26.2001C101.8 25.4001 102 25.0001 103 24.0001C101.8 23.2001 102.8 22.2001 102.9 21.4001C103.3 18.3001 104 15.1001 104.6 12.0001C104.7 11.5001 104.7 11.1001 105.4 11.0001C106.1 10.9001 106.7 11.0001 107 11.6001C107.5 12.5001 107.9 13.4001 108.3 14.3001C108.9 15.6001 109.4 17.0001 110 18.3001C111.3 21.0001 112.5 23.6001 113.9 26.2001C114.7 27.8001 115.8 29.2001 116.7 30.8001C117 31.3001 117.3 31.7001 117.5 32.2001C117.6 32.4001 117.6 32.8001 117.4 33.0001C117.3 33.1001 116.9 33.1001 116.7 33.1001C115.5 32.8001 114.8 32.0001 114.3 30.9001C113.2 28.8001 111.9 26.7001 110.7 24.6001C110.4 24.1001 110.3 23.6001 110.1 23.1001C108.8 20.1001 107.4 17.0001 106.1 14.0001C106 13.9001 105.9 13.9001 105.8 13.6001Z" />
      <path d="M37.4996 93C36.3996 96.7 35.1996 100.3 34.0996 103.9C33.9996 103.9 33.8996 103.9 33.7996 103.9C33.6996 103.6 33.5996 103.2 33.5996 102.9C33.6996 102.3 33.8996 101.7 33.9996 101.1C34.5996 98.4 35.1996 95.7 35.7996 93C35.8996 92.6 35.8996 92.1 35.9996 91.7C36.0996 90.5 37.2996 89.9 38.2996 90.5C38.6996 90.7 39.0996 91 39.2996 91.4C40.4996 93.3 41.6996 95.2 42.8996 97C44.7996 99.9 46.7996 102.7 48.7996 105.5C49.2996 106.2 49.8996 107 50.3996 107.7C50.4996 107.9 50.4996 108.3 50.3996 108.5C50.1996 108.8 49.7996 109.1 49.4996 109C48.5996 108.9 47.7996 108.5 47.1996 107.6C45.8996 105.6 44.4996 103.7 43.0996 101.7C41.7996 99.8 40.6996 97.8 39.3996 95.8C38.7996 94.9 38.0996 94 37.4996 93Z" />
      <path d="M125.2 87.2002C124.2 90.3002 123.2 93.3002 122.2 96.4002C121.4 95.5002 121.7 94.7002 121.9 94.1002C122.2 92.8002 122.7 91.5002 123.1 90.2002C123.4 89.2002 123.4 88.2002 123.7 87.2002C123.9 86.5002 124.3 85.8002 124.9 85.4002C125.7 84.8002 126.8 85.3002 127.4 86.4002C129.1 89.6002 130.7 92.9002 132.5 96.0002C133.6 98.0002 135 99.9002 136.3 101.9C136.6 102.3 136.9 102.4 137.5 102.3C137.8 102.2 138.2 102.4 138.6 102.5C138.4 102.8 138.3 103.2 138.1 103.4C137.3 104.3 136.4 104.5 135.3 104.2C135 104.1 134.6 103.9 134.4 103.6C133.6 102.3 132.8 100.9 132 99.6002C130.8 97.4002 129.6 95.1002 128.4 92.8002C127.5 91.1002 126.8 89.4002 125.9 87.8002C125.7 87.7002 125.5 87.6002 125.2 87.2002Z" />
      <path d="M12.9005 44.3002C11.8005 47.3002 10.6005 50.3002 9.40047 53.6002C9.10047 53.0002 8.80047 52.7002 8.90047 52.5002C9.20047 51.4002 9.70048 50.3002 10.1005 49.2002C10.2005 49.0002 10.2005 48.8002 10.3005 48.6002C10.8005 46.9002 11.2005 45.1003 11.9005 43.4003C12.3005 42.3003 14.5005 42.4003 15.2005 43.4003C16.3005 45.0003 17.4005 46.7002 18.5005 48.2002C20.8005 51.2002 23.0005 54.2002 25.4005 57.1002C26.1005 58.0002 27.2005 58.5003 28.1005 59.2003C28.8005 59.8003 28.9005 60.2002 28.3005 60.6002C28.1005 60.7002 27.7005 60.8003 27.5005 60.7003C25.3005 60.4003 23.9005 58.9002 22.6005 57.3002C20.4005 54.5002 18.3005 51.7002 16.3005 48.8002C15.4005 47.6002 14.7005 46.2002 13.9005 45.0002C13.7005 44.7002 13.5005 44.5002 13.4005 44.2002C13.2005 44.3002 13.0005 44.3002 12.9005 44.3002Z" />
      <path d="M146.2 56.1002C144.7 55.6002 143.5 54.5002 142.5 53.3002C140.5 50.8002 139 48.0002 137.6 45.2002C136.9 43.8002 136.2 42.4002 135.5 41.0002C135.4 40.8002 135.3 40.7002 134.9 40.3002C134 43.4002 133.2 46.2002 132.3 49.2002C131.8 48.7002 131.6 48.2002 131.8 47.6002C132.5 44.8002 133.2 42.0002 133.9 39.2002C134 38.9002 134 38.6002 134 38.3002C134 37.7002 134.4 37.3002 134.9 37.2002C135.3 37.2002 135.9 37.4002 136.1 37.6002C136.5 38.0002 136.6 38.6002 136.9 39.1002C138.9 42.7002 140.9 46.2002 143 49.8002C143.8 51.1002 144.9 52.2002 145.8 53.3002C146.2 53.7002 146.5 54.2002 146.8 54.7002C147.2 55.4002 146.7 55.7002 146.2 56.1002Z" />
      <path d="M65.6002 90.6001C64.5002 92.6001 64.9002 95.0001 63.6002 96.7001C63.5002 96.7001 63.4002 96.6001 63.2002 96.6001C63.5002 94.8001 63.7002 93.0001 64.0002 91.1001C64.1002 90.6001 64.3002 90.2001 64.4002 89.7001C64.5002 88.7001 66.2002 88.3001 66.9002 89.2001C67.7002 90.2001 68.4002 91.4001 69.0002 92.6001C71.7002 97.2001 74.3002 101.9 77.0002 106.5C77.2002 106.8 77.5002 107.1 77.9002 107.2C78.4002 107.3 78.6002 107.5 78.6002 108.1C78.5002 108.7 78.4002 109.2 77.7002 109.3C77.0002 109.4 76.3002 109.3 75.9002 108.6C75.1002 107.3 74.3002 106.1 73.5002 104.8C71.8002 101.9 70.2002 99.1001 68.5002 96.2001C67.6002 94.6001 66.9002 93.0001 66.0002 91.4001C66.0002 91.2001 65.8002 91.0001 65.6002 90.6001Z" />
      <path d="M42.1995 45.2C41.6995 47.2 41.2995 48.9 40.8995 50.6C40.7995 50.6 40.6995 50.6 40.4995 50.5C40.4995 49.7 40.3995 48.9 40.4995 48.1C40.5995 46.9 40.8995 45.7 40.9995 44.5C40.9995 44 40.9995 43.4 41.5995 43.2C42.2995 42.9 42.9995 42.8 43.4995 43.4C44.3995 44.4 45.1995 45.4 46.0995 46.3C47.9995 48.4 49.8995 50.6 51.8995 52.7C53.1995 54.1 54.6995 55.3 56.0995 56.6C56.3995 56.9 56.6995 57.2 56.8995 57.6C57.0995 57.9 57.2995 58.4 57.1995 58.5C56.8995 58.8 56.3995 59.1 56.0995 59C55.1995 58.6 54.2995 58.2 53.5995 57.6C52.6995 56.9 51.8995 56.1 51.1995 55.3C49.2995 53.2 47.2995 51 45.3995 48.9C44.3995 47.7 43.3995 46.5 42.1995 45.2Z" />
      <path d="M97.3997 89.4001C96.6997 91.6001 96.0997 93.5001 95.4997 95.5001C95.3997 95.5001 95.2997 95.5001 95.1997 95.4001C95.1997 94.9001 95.0997 94.5001 95.1997 94.1001C95.3997 93.1001 95.5997 92.1001 95.7997 91.1001C95.9997 90.2001 96.0997 89.3001 96.1997 88.4001C96.2997 87.5001 97.2997 87.1001 97.9997 87.6001C98.3997 87.9001 98.8997 88.3001 99.0997 88.8001C100.7 91.5001 102.3 94.2001 103.8 96.9001C104.9 98.9001 106.1 100.9 107.7 102.5C108 102.8 108.4 103.2 108.5 103.7C108.6 104 108.6 104.5 108.4 104.8C108.2 105 107.7 105.1 107.4 105C105.9 104.8 105.3 103.4 104.5 102.4C103.8 101.5 103.2 100.5 102.6 99.5001C101.6 97.8001 100.7 96.0001 99.7997 94.3001C99.1997 92.7001 98.3997 91.1001 97.3997 89.4001Z" />
      <path d="M74.2996 14.1001C73.5996 14.6001 74.1996 16.1001 72.5996 16.0001C72.7996 14.9001 72.9996 13.8001 73.0996 12.7001C73.1996 12.0001 73.5996 11.6001 74.1996 11.6001C74.5996 11.6001 75.1996 11.9001 75.3996 12.3001C76.1996 13.7001 76.9996 15.2001 77.5996 16.7001C78.8996 20.0001 80.7996 22.9001 83.0996 25.5001C84.5996 27.2001 85.9996 29.1001 87.4996 30.8001C87.9996 31.4001 88.5996 31.9001 89.0996 32.4001C89.4996 32.8001 89.9996 33.3001 89.4996 33.9001C88.9996 34.4001 88.4996 34.1001 87.9996 33.7001C86.7996 32.8001 85.9996 31.7001 85.0996 30.5001C83.8996 28.8001 82.3996 27.4001 81.0996 25.7001C80.0996 24.4001 79.2996 22.9001 78.3996 21.5001C77.9996 20.9001 77.3996 20.4001 77.0996 19.8001C76.4996 18.4001 75.9996 17.0001 75.2996 15.6001C75.0996 15.1001 74.6996 14.7001 74.2996 14.1001Z" />
      <path d="M42.4 19.1999C42.3 18.8999 42.1 18.4999 42 18.1999C41.9 17.8999 41.7 17.3999 41.9 17.1999C42.5 16.2999 42.3 15.2999 42.4 14.3999C42.5 13.6999 42.8 13.1999 43.5 13.2999C44 13.3999 44.6 13.6999 44.8 14.1999C45.4 15.5999 45.7 17.0999 46.4 18.3999C47.8 21.1999 49.1 23.9999 50.7 26.5999C52.4 29.2999 54.5 31.8999 56.4 34.4999C56.5 34.6999 56.6 35.0999 56.5 35.2999C56.4 35.4999 56 35.5998 55.8 35.5998C54.9 35.5998 54.3 35.1999 53.7 34.3999C52.4 32.4999 51 30.7999 49.8 28.7999C47.6 25.1999 45.6 21.3999 44 17.4999C43.9 17.1999 43.8 16.8999 43.7 16.4999C43.6 16.1999 43.5 15.8999 43.4 15.5999C43.3 15.5999 43.2 15.5999 43.1 15.5999C42.9 16.8999 42.6 17.9999 42.4 19.1999Z" />
      <path d="M73.3998 16.4001C73.3998 16.6001 73.4998 16.8001 73.3998 16.9001C72.3998 18.0001 73.0998 19.5001 72.5998 20.8001C71.9998 22.3001 71.5998 23.8001 71.0998 25.3001C70.7998 26.3001 70.4998 27.2001 69.8998 28.2001C69.8998 27.9001 69.7998 27.7001 69.7998 27.4001C70.1998 25.8001 70.6998 24.2001 71.1998 22.5001C71.2998 22.2001 71.1998 22.0001 71.2998 21.7001C71.5998 20.1001 71.8998 18.6001 72.2998 17.0001C72.3998 16.6001 72.8998 16.4001 73.1998 16.1001C73.2998 16.3001 73.2998 16.4001 73.3998 16.4001Z" />
      <path d="M42.2995 19.2003C42.0995 20.5003 42.1995 21.8003 41.2995 22.9003C41.1995 23.0003 41.3995 23.4003 41.2995 23.6003C41.1995 23.8003 40.9995 23.9003 40.7995 24.1003C40.6995 23.9003 40.4995 23.6003 40.5995 23.4003C40.7995 22.3003 41.0995 21.3003 41.1995 20.2003C41.3995 19.5003 41.5995 19.1003 42.2995 19.2003C42.2995 19.2003 42.3995 19.2003 42.2995 19.2003Z" />
      <path d="M120 100.7C120.4 99.2003 120.9 97.8003 121.3 96.3003C122 96.6003 122.1 97.1003 121.8 97.7003C121.3 98.7003 120.9 99.8003 120.4 100.8C120.2 100.8 120.1 100.8 120 100.7Z" />
      <path d="M93.7002 98.7003C94.0002 97.6003 94.4002 96.5003 94.7002 95.3003C95.5002 96.9003 95.0002 98.5003 93.7002 98.7003Z" />
      <path d="M40.5998 26.3001C40.4998 26.0001 40.2998 25.8001 40.2998 25.6001C40.3998 25.2001 40.1998 24.4001 41.1998 24.7001C41.0998 25.2001 40.8998 25.7001 40.7998 26.3001C40.7998 26.3001 40.6998 26.3001 40.5998 26.3001Z" />
      <path d="M100.2 30.8003C100.4 31.0003 100.6 31.1003 100.8 31.3003C100.4 31.7003 100 32.1003 99.7 32.5003C99.6 32.4003 99.6 32.4003 99.5 32.3003C99.6 31.8003 99.9 31.3003 100.2 30.8003Z" />
      <path d="M40.9004 81.9C41.1004 81.4 41.1004 80.7 42.2004 81.1C41.7004 81.5 41.4004 81.8 41.1004 82.1C41.1004 82.1 41.0004 82 40.9004 81.9Z" />
      <path d="M94.1998 99.2C93.9998 99.7 93.7998 100.1 93.5998 100.6C93.4998 100.6 93.3998 100.5 93.2998 100.5C93.4998 100 93.5998 99.5 93.7998 99C93.8998 99.1 94.0998 99.2 94.1998 99.2Z" />
    </>
  )
}

export default Path21
