import { ColorTheme, TagTone } from '@types'
import { rgba } from 'polished'
import React, { FunctionComponent, ReactNode } from 'react'
import { Box, BoxOwnProps, ThemeUIStyleObject } from 'theme-ui'
import { COLORS } from '../../constants'
import Icon from '../Icon'

type Props = {
  closeIcon?: boolean
  colorTheme?: ColorTheme
  isLink?: boolean
  label: string
  large?: boolean
  onClick?: () => void
  sx?: ThemeUIStyleObject
  tone?: TagTone
}

const Tag: FunctionComponent<BoxOwnProps & Props> = (props: Props) => {
  const {
    closeIcon,
    colorTheme,
    isLink,
    label,
    large,
    onClick,
    sx,
    tone = 'blue',
    ...rest
  } = props

  let bg
  let bgHover
  let color

  switch (tone) {
    case 'colorTheme':
      bg = colorTheme?.text || 'white'
      color = colorTheme?.background || 'midnight'
      break
    case 'dark':
      bg = 'midnight'
      bgHover = rgba(COLORS.midnight, 0.8)
      color = 'white'
      break
    case 'gray':
      bg = 'stone'
      bgHover = rgba(COLORS.stone, 0.8)
      color = 'midnight'
      break
    case 'light':
      bg = rgba(COLORS.white, 0.1)
      bgHover = rgba(COLORS.white, 0.3)
      color = 'white'
      break
    case 'blue':
    default:
      bg = rgba(COLORS.tagLightBlue, 0.1)
      bgHover = rgba(COLORS.tagLightBlue, 0.3)
      color = 'tagBlue'
      break
  }

  return (
    <Box
      bg={bg}
      color={color}
      onClick={onClick}
      sx={{
        borderRadius: '2px',
        // fontSize: ['xxs', null, null, large ? 'xs' : 'xxs'],
        fontSize: large ? 's' : 'xxs',
        display: 'inline-flex',
        lineHeight: 'single',
        px: large ? 2 : 1,
        py: large ? 2 : 1,
        transition: '150ms all',
        ...(isLink
          ? {
              cursor: 'pointer',
              '@media (hover: hover) and (pointer: fine)': {
                '&:hover': {
                  bg: bgHover,
                },
              },
            }
          : {}),
        ...sx,
      }}
      {...rest}
    >
      {label}
      {closeIcon && (
        <Box
          ml={large ? 2 : '6px'}
          sx={{ position: 'relative', width: large ? '8px' : '6px' }}
        >
          <Icon type="close" />
        </Box>
      )}
    </Box>
  )
}

export default Tag
