import React from 'react'

const Path15 = () => {
  return (
    <>
      <path d="M1.90874 56.875C5.14503 56.8582 7.25238 58.5124 9.28446 60.4017C21.3432 71.6031 30.8262 84.6519 37.5413 99.7242C38.135 101.068 38.779 102.394 39.8577 103.671C40.3427 100.27 40.2173 96.9113 40.0668 93.5609C39.5316 81.7129 38.9127 69.865 38.4277 58.0086C38.0598 48.8896 37.6918 39.7706 37.6082 30.6432C37.5497 23.9257 38.5532 17.3006 40.8194 10.9274C42.0403 7.49309 42.4752 6.88852 45.7449 4C49.023 5.66258 50.7624 8.53432 52.0837 11.8847C59.7437 31.1891 65.472 50.9636 66.9522 72.8459C67.8303 71.1413 68.3236 70.3184 68.6916 69.4451C75.5488 53.382 82.4228 37.3271 89.2048 21.2387C90.3671 18.493 92.2905 16.8472 95.0752 15.8732C97.4836 17.8548 98.3784 20.3403 98.3701 23.4471C98.3282 37.2935 98.4286 51.1316 98.4704 64.978C98.4788 66.6238 98.4704 68.2612 98.4704 70.8726C100.017 69.4368 101.004 68.6474 101.832 67.7154C106.072 62.946 110.303 58.1766 114.459 53.34C116.408 51.0728 118.657 49.4186 121.793 48.8644C123.901 51.2995 124.461 53.9361 123.315 57.1857C119.962 66.6322 116.91 76.1878 113.531 85.6259C112.26 89.1777 110.404 92.5197 108.681 96.2647C110.696 96.6258 111.842 95.2823 113.121 94.5014C122.053 89.0434 130.9 83.4679 139.873 78.0771C142.29 76.6244 145.024 75.7176 147.709 74.5252C150.217 78.1695 150.644 81.2008 149.097 85.0969C142.85 100.799 131.636 111.732 116.784 118.802C105.871 124 94.5317 128.374 83.2005 132.623C70.7237 137.292 58.0461 141.431 45.4439 145.739C38.6451 148.065 36.8221 147.46 32.9169 141.49C20.8247 122.958 11.2496 103.142 3.991 82.2588C1.9171 76.297 1.38194 69.7978 0.161013 63.5505C-0.324011 61.1322 0.294784 59.0078 1.90874 56.875Z" />
    </>
  )
}

export default Path15
