import { ColorTheme, SanityImage } from '@types'
import React from 'react'
import { Box, Flex } from 'theme-ui'
import BoxResponsive from '../BoxResponsive'
import Caption from '../Caption'
import NextImage from '../NextImage'

type LayoutOptions = 'largeLeft' | 'largeRight' | 'sameSize'

type Props = {
  colorTheme?: ColorTheme
  imageLarge: SanityImage
  imageSmall: SanityImage
  layout: LayoutOptions
}

const getColumnProps = (layout: LayoutOptions) => {
  const marginX = [0, null, null, 10]
  const marginY = [5, null, null, 0]

  switch (layout) {
    case 'largeLeft':
      return {
        column1: {
          mb: marginY,
          ml: 0,
          mr: marginX,
        },
        column2: {
          ml: marginX,
          mr: 0,
          mt: 0,
        },
      }
    case 'largeRight':
      return {
        column1: {
          mb: 0,
          ml: marginX,
          mr: 0,
        },
        column2: {
          ml: 0,
          mr: marginX,
          mt: marginY,
        },
      }
    default:
      return {
        column1: {
          mr: marginX,
        },
        column2: {
          mt: [8, null, null, 0],
          ml: marginX,
        },
      }
  }
}

const ImagePair = (props: Props) => {
  const { colorTheme, imageLarge, imageSmall, layout } = props

  /**
   * Only LargeRight should should reverse flex order
   */
  const isLargeRight = layout === 'largeRight'
  const isSameSize = layout === 'sameSize'

  const { column1, column2 } = getColumnProps(layout)

  return (
    <Flex
      mx="auto"
      sx={{
        flexDirection: [
          'column',
          null,
          null,
          isLargeRight ? 'row-reverse' : 'row',
        ],
        maxWidth: [
          'moduleTextOneColMaxWidth',
          null,
          null,
          'moduleTextTwoColMaxWidth',
        ],
      }}
    >
      {/* Column 1 */}
      <Flex sx={{ alignItems: 'center', flex: 1 }} {...column1}>
        {imageLarge && (
          <Box sx={{ flex: 1 }}>
            {/* Image */}
            <BoxResponsive
              aspect={!isSameSize ? [645 / 705] : [591 / 340]}
              sx={{
                borderRadius: 1,
                overflow: 'hidden',
              }}
            >
              <NextImage
                image={imageLarge}
                layout="fill"
                sizes={['100vw', null, null, '40vw']}
              />
            </BoxResponsive>

            {/* Caption */}
            <Caption color={colorTheme?.text} text={imageLarge?.caption} />
          </Box>
        )}
      </Flex>

      {/* Column 2 */}
      <Flex
        sx={{
          alignItems: 'center',
          flex: 1,
        }}
        {...column2}
      >
        {imageSmall && (
          <Box sx={{ flex: 1 }}>
            {/* Image */}
            <BoxResponsive
              aspect={[591 / 340]}
              sx={{
                borderRadius: 1,
                overflow: 'hidden',
              }}
            >
              <NextImage
                image={imageSmall}
                layout="fill"
                sizes={['100vw', null, null, '40vw']}
              />
            </BoxResponsive>

            {/* Caption */}
            <Caption color={colorTheme?.text} text={imageSmall?.caption} />
          </Box>
        )}
      </Flex>
    </Flex>
  )
}

export default ImagePair
