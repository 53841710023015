import isEmpty from 'lodash/fp/isEmpty'
import React, { Fragment } from 'react'
import { Box } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import { selectSectionEvents } from '../../redux/modules/menu'
import DividerHorizontalRandom from '../DividerHorizontalRandom'
import EventCard from '../EventCard'
import MenuSectionWrapper from '../MenuSectionWrapper'

const MenuSectionEvents = () => {
  const sectionEvents = useTypedSelector(selectSectionEvents)

  // Filter out empty items
  const filteredEventTypes = sectionEvents?.events?.filter(
    event => !isEmpty(event)
  )

  return (
    <MenuSectionWrapper
      documentTypeIndex="eventIndex"
      documentTypeLabel="View events overview"
    >
      {filteredEventTypes?.map((eventType, index) => (
        <Fragment key={index}>
          <Box>
            <EventCard event={eventType} />
          </Box>

          {index !== filteredEventTypes.length - 1 && (
            <DividerHorizontalRandom color="stone" my={8} />
          )}
        </Fragment>
      ))}
    </MenuSectionWrapper>
  )
}

export default MenuSectionEvents
