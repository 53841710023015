import React, { useState } from 'react'
import { Box, Flex } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import ButtonIcon from '../ButtonIcon'
import Eyebrow from '../Eyebrow'
import LinkSanity from '../LinkSanity'

const Announcements = () => {
  // Redux
  const announcements = useTypedSelector(
    state => state.navigation.header.announcements
  )

  // State
  const [currentIndex, setCurrentIndex] = useState(0)

  const numAnnouncements = announcements?.length || 0

  // Callbacks
  const handleShowNext = () => {
    setCurrentIndex((currentIndex + 1) % numAnnouncements)
  }
  const handleShowPrevious = () => {
    setCurrentIndex((currentIndex - 1 + numAnnouncements) % numAnnouncements)
  }

  return (
    <Flex
      sx={{
        alignItems: 'center',
        position: 'relative',
        textAlign: 'center',
        width: '400px',
      }}
    >
      {/* Left arrow */}
      <Flex
        mr="auto"
        onClick={handleShowPrevious}
        px={1}
        sx={{
          alignItems: 'center',
          flexShrink: 0,
          justifyContent: 'center',
          userSelect: 'none',
        }}
      >
        <ButtonIcon iconSize="10px" size="20px" type="chevronLeft" />
      </Flex>

      {/* Items */}
      <Box sx={{ flexGrow: 1 }}>
        {announcements?.map((announcement, index) => (
          <Eyebrow
            key={index}
            px={2}
            sx={{
              display: index === currentIndex ? 'block' : 'none',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <LinkSanity link={announcement}>{announcement.title}</LinkSanity>
          </Eyebrow>
        ))}
      </Box>

      {/* Right arrow */}
      <Flex
        ml="auto"
        onClick={handleShowNext}
        px={1}
        sx={{
          alignItems: 'center',
          flexShrink: 0,
          justifyContent: 'center',
          userSelect: 'none',
        }}
      >
        <ButtonIcon iconSize="10px" size="20px" type="chevronRight" />
      </Flex>
    </Flex>
  )
}

export default Announcements
