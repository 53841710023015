import React from 'react'

const Graph = () => {
  return (
    <>
      <path d="M30.6374 6.11565C30.6351 6.0236 30.6185 5.94151 30.5955 5.86415C30.5485 5.45561 30.2642 5.06708 29.7395 5.03842C28.2439 4.95686 26.748 4.94873 25.2508 4.94873C25.0134 4.94873 24.8277 5.02649 24.6874 5.14647C24.3985 5.26685 24.1737 5.52806 24.1734 5.9364C24.1707 14.1057 24.0862 22.2746 24.0835 30.4439C24.0835 30.5464 24.1015 30.637 24.1274 30.7216C24.184 31.1201 24.4684 31.4926 24.9811 31.5213C26.5965 31.6112 28.2109 31.6944 29.829 31.701C29.8596 31.701 29.8852 31.6934 29.9142 31.691C29.9456 31.6944 29.9765 31.701 30.0085 31.701C31.3742 31.701 30.8415 29.6488 30.8761 28.8314C30.9234 27.719 30.955 26.6063 30.9746 25.4938C31.0133 23.3396 31.0046 21.1848 30.9713 19.0308C30.9047 14.7244 30.7356 10.421 30.6374 6.11565ZM29.029 29.8932C27.9789 29.8736 26.9302 29.829 25.8811 29.7737C25.8874 22.0985 25.96 14.4234 25.9673 6.74777C26.9318 6.75131 27.896 6.76042 28.8598 6.79589C29.0517 14.495 29.4239 22.1943 29.029 29.8932Z" />
      <path d="M22.2878 11.9509C22.2805 11.7599 22.2212 11.6034 22.1347 11.4752C22.0019 11.2117 21.7545 11.0016 21.3902 10.9632C19.9166 10.8074 18.4598 10.8762 16.9916 11.0527C16.8232 11.0729 16.6823 11.133 16.5633 11.2148C16.2207 11.3006 15.9527 11.5728 16.0038 12.0403C16.684 18.2449 16.3973 24.5017 16.9912 30.7124C16.9967 30.7686 17.0089 30.8203 17.0222 30.8712C17.0131 31.3267 17.3008 31.7898 17.8891 31.7898C18.862 31.7898 23.105 32.4653 22.9165 30.7148C22.2339 24.4787 22.5369 18.2059 22.2878 11.9509ZM18.7294 29.9948C18.241 24.259 18.4325 18.4872 17.8711 12.7567C18.7534 12.6798 19.6344 12.6512 20.5186 12.6941C20.7097 18.4626 20.4873 24.2444 21.049 29.9948H18.7294Z" />
      <path d="M14.4115 16.9313C14.3376 16.5717 14.0634 16.2874 13.5803 16.35C12.2979 16.5157 11.0097 16.617 9.72037 16.7091C9.65245 16.7139 9.58905 16.7257 9.52912 16.7419C9.07181 16.7309 8.61888 17.0172 8.64301 17.6065C8.82227 22.0046 8.99726 26.4024 9.092 30.8029C9.09613 30.99 9.14957 31.1431 9.2312 31.2703C9.30279 31.6456 9.58446 31.9728 10.0793 31.9702C11.1874 31.9641 12.2931 31.8866 13.4011 31.8803C13.5412 31.8796 13.6633 31.8516 13.7697 31.8048C14.2967 31.9609 14.9544 31.6895 14.9274 30.9827C14.7546 26.4657 14.6587 21.9465 14.5683 17.4276C14.5639 17.2263 14.5039 17.0621 14.4115 16.9313ZM10.871 30.1595C10.7803 26.2535 10.6306 22.3501 10.4722 18.4464C11.2453 18.3876 12.0182 18.3229 12.7891 18.238C12.8688 22.1893 12.9579 26.1409 13.1007 30.0902C12.3571 30.1022 11.6142 30.1382 10.871 30.1595Z" />
      <path d="M5.8143 24.3298C4.9337 24.2555 4.05074 24.2499 3.16699 24.2495C3.06812 24.2495 2.98124 24.2669 2.89929 24.2905C2.49298 24.3387 2.11074 24.6231 2.08956 25.1471C2.00787 27.1813 2 29.2166 2 31.2518C2 31.343 2.01429 31.425 2.03514 31.5016C2.06379 31.9257 2.34913 32.3292 2.89758 32.3292H5.94996C6.41842 32.3292 6.69392 32.0346 6.78316 31.6846C6.9295 31.5409 7.02739 31.3388 7.02739 31.0727V25.1478C7.02706 24.4303 6.33869 24.1586 5.8143 24.3298ZM5.23169 30.534H3.79838C3.80107 29.0384 3.81136 27.5436 3.85627 26.0494C4.31523 26.0527 4.77372 26.06 5.23169 26.084V30.534Z" />
      <path d="M3.86249 16.5638C4.09905 16.5638 4.32826 16.482 4.54299 16.3204C7.39008 14.1782 13.7998 8.682 16.768 5.97705C16.7513 6.47508 16.742 6.93607 16.7404 7.37136C16.739 7.70869 16.8538 8.00347 17.0734 8.2237C17.3002 8.45108 17.6284 8.58208 17.9751 8.58208C18.59 8.58208 19.2142 8.16705 19.2175 7.37457C19.2242 5.91877 19.3115 4.43739 19.3967 2.99424C19.4166 2.65429 19.3157 2.35662 19.1047 2.13311C18.89 1.90541 18.5703 1.77303 18.2251 1.77002C18.1482 1.75192 18.0736 1.7411 17.9983 1.73618C16.56 1.65265 15.1301 1.64551 13.6703 1.64551C12.8766 1.64551 12.4614 2.26844 12.4611 2.8837C12.4607 3.49916 12.8762 4.12242 13.6703 4.12275C14.1547 4.12275 14.6388 4.12412 15.1232 4.12858C12.1934 6.80658 5.9643 12.1651 3.22743 14.2247C2.76513 14.5725 2.59499 15.1378 2.78244 15.6999C2.95487 16.2168 3.38885 16.5638 3.86249 16.5638Z" />
    </>
  )
}

export default Graph
