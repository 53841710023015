import React from 'react'
import { Box } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import { selectSectionCourses } from '../../redux/modules/menu'
import getSingletonHref from '../../utils/getSingletonHref'
import CardSlimList from '../CardSlimList'
import DividerHorizontalRandom from '../DividerHorizontalRandom'
import MenuSectionWrapper from '../MenuSectionWrapper'

const MenuSectionCourses = () => {
  const sectionCourses = useTypedSelector(selectSectionCourses)

  return (
    <MenuSectionWrapper
      documentTypeIndex="courses"
      documentTypeLabel="View courses overview"
    >
      {sectionCourses?.onlineCourses && (
        <CardSlimList
          headerIllustrationType="keyboard"
          href={getSingletonHref('onlineCourseIndex')}
          items={sectionCourses.onlineCourses}
          title="Online Courses"
        />
      )}

      <DividerHorizontalRandom color="stone" my={8} />

      {sectionCourses?.workshops && (
        <Box>
          <CardSlimList
            headerIllustrationType="education"
            href={getSingletonHref('workshopIndex')}
            items={sectionCourses.workshops}
            title="Workshops"
          />
        </Box>
      )}
    </MenuSectionWrapper>
  )
}

export default MenuSectionCourses
