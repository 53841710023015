import { SanityImage } from '@types'
import React from 'react'
import ImagePair from '../ImagePair'
import PortableTextBlockWrapper from '../PortableTextBlockWrapper'

type PortableTextBlockImagePair = {
  node: {
    imageLarge: SanityImage
    imageSmall: SanityImage
    layout: 'largeLeft' | 'largeRight'
  }
}

const PortableTextBlockImagePair = (props: PortableTextBlockImagePair) => {
  const { node } = props

  return (
    <PortableTextBlockWrapper my={[8, null, null, 12]}>
      <ImagePair
        imageLarge={node?.imageLarge}
        imageSmall={node?.imageSmall}
        layout={node?.layout}
      />
    </PortableTextBlockWrapper>
  )
}

export default PortableTextBlockImagePair
