import { decode } from 'shopify-gid'
import { CheckoutLineItemFragment } from '../../types/codegen/shopify'

// Product removed from cart
export const trackCartRemoveItem = (lineItem: CheckoutLineItemFragment) => {
  // GTM
  // https://developers.google.com/tag-manager/enhanced-ecommerce#cart
  if (window.dataLayer) {
    // Measure the removal of a product from a shopping cart.
    window.dataLayer.push({
      ecommerce: {
        remove: {
          // 'remove' actionFieldObject measures.
          products: [
            {
              // brand: '',
              // category: '',
              name: lineItem?.title,
              id: decode(lineItem?.variant?.id)?.id, // Variant ID
              price:
                lineItem?.variant?.presentmentPrices?.edges[0]?.node?.price
                  ?.amount, // TODO: refactor
              quantity: lineItem.quantity,
              variant: lineItem?.variant?.title,
            },
          ],
        },
      },
      event: 'eec.remove',
    })
  }
}
