import { SanityImage } from '@types'
import React from 'react'
import { AspectRatio, ThemeUIStyleObject } from 'theme-ui'
import getSanityCroppedImageAspect from '../../utils/getSanityCroppedImageAspect'
import NextImage from '../NextImage'

type Props = {
  image: SanityImage
  sizes?: string | (string | null)[]
  sx?: ThemeUIStyleObject
}

const NextImageResponsive = (props: Props) => {
  const { image, sizes = '100vw', sx } = props

  if (!image) {
    return null
  }

  const aspectRatio = getSanityCroppedImageAspect(image)

  return (
    <AspectRatio ratio={aspectRatio} sx={sx}>
      <NextImage image={image} layout="responsive" sizes={sizes} />
    </AspectRatio>
  )
}

export default NextImageResponsive
