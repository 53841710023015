import { Color } from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import Button from '../Button'
import IllustrationThin from '../IllustrationThin'
import LinkModalNewsletter from '../LinkModalNewsletter'

type Props = {
  background?: Color
  color?: Color
  transparent?: boolean
  label?: string
}

const ButtonSubscribe = (props: Props) => {
  const {
    background,
    color,
    transparent,
    label = 'Subscribe for ideas',
  } = props

  return (
    <LinkModalNewsletter>
      <Button background={background} color={color} transparent={transparent}>
        <Box
          mr={1}
          sx={{
            height: '18px',
            width: '18px',
          }}
        >
          <IllustrationThin type="lightbulb" />
        </Box>
        {label}
      </Button>
    </LinkModalNewsletter>
  )
}

export default ButtonSubscribe
