import { LinkVariant } from '@types'
import React, { ReactNode } from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import Link from '../Link'

type Props = {
  children: ReactNode
  sx?: ThemeUIStyleObject
  variant?: LinkVariant
}

const LinkIubendaTrackingPreferences = (props: Props) => {
  const { children, sx, variant } = props

  const loaded = useTypedSelector(state => state.iubenda.id)

  const handleShowPreferences = () => {
    if (window._iub) {
      window._iub.cs.api.openPreferences()
    }
  }

  if (!loaded) {
    return null
  }

  return (
    <Link onClick={handleShowPreferences} sx={sx} variant={variant}>
      {children}
    </Link>
  )
}

export default LinkIubendaTrackingPreferences
