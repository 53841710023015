import * as Sentry from '@sentry/react'
import deepmerge from 'deepmerge'
import { AppProps } from 'next/app'
import React, { useState } from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'theme-ui'
import GlobalStyles from '../components/GlobalStyles'
import Main from '../components/Main'
import MaintenancePage from '../components/MaintenancePage'
import { IS_PRODUCTION } from '../constants'
import settings from '../settings.preval'
import { initStore } from '../redux/store'
import { initialState as navigationInitialState } from '../redux/modules/navigation'
import { initialState as storeInitialState } from '../redux/modules/store'
import { initialState as systemInitialState } from '../redux/modules/system'
import theme from '../styled/theme'
import IubendaConfig from '../components/IubendaConfig'

type Props = AppProps & {}

// Initialise Sentry (client only)
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: IS_PRODUCTION,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.VERCEL_GITHUB_COMMIT_SHA,
  })
}

const MyApp = (props: Props) => {
  const { Component, pageProps } = props

  // Initialize redux store with preloaded state
  const [store] = useState(
    initStore({
      navigation: deepmerge(navigationInitialState, settings.navigation),
      store: deepmerge(storeInitialState, settings.store),
      system: deepmerge(systemInitialState, settings.settings),
    })
  )

  const isMaintenance = process.env.NEXT_PUBLIC_MAINTENANCE_MODE === '1'

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <IubendaConfig />

        {isMaintenance ? (
          <MaintenancePage />
        ) : (
          <Main Component={Component} pageProps={pageProps} />
        )}

        {/* Global styles */}
        <GlobalStyles />
      </ThemeProvider>
    </Provider>
  )
}

export default MyApp
