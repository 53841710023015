import { createAsyncThunk } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { Checkout } from '@types'
import replaceLineItems from '../../../../services/shopify/replaceLineItems'
import { CheckoutLineItemFragment } from '../../../../types/codegen/shopify'
import getLineItemInputs from '../../../../utils/getLineItemInputs'
import { RootState } from '../../../store'

const lineItemRemove = createAsyncThunk<
  // Return type of the payload creator
  { checkout: Checkout; lineItem: CheckoutLineItemFragment },
  // First argument to the payload creator
  {
    productVariantId: string // base64 encoded value
  },
  {
    rejectValue: string
    state: RootState
  }
>(
  'store/lineItemRemove',
  async ({ productVariantId }, { getState, rejectWithValue }) => {
    // Get existing checkout from store
    const checkout = getState()?.store?.checkout
    const checkoutId = checkout?.id

    // Get line items (inputs)
    const lineItemInputs = getLineItemInputs(
      checkout?.lineItems?.edges?.map(edge => edge.node)
    )

    // If we're unable to get a checkout ID, our checkout hasn't been created yet
    if (!checkoutId) {
      Sentry.captureMessage(
        'A user has tried removing a line item in their cart, but was unable to retrieve their checkout object.'
      )
      return rejectWithValue('Unable to retrieve checkout')
    }

    // Remove line items with matching variant ID
    const filteredLineItems = lineItemInputs.filter(
      lineItem => lineItem.variantId !== productVariantId
    )

    // Get removed line item
    const removedLineItem = checkout?.lineItems?.edges.find(
      edge => edge.node.variant?.id === productVariantId
    )?.node

    const result = await replaceLineItems({
      checkoutId,
      lineItems: filteredLineItems,
    })

    if (result.errors) {
      const message = `Unable to retrieve checkout: ${result.errors
        .map(err => err.message)
        .join(',')}`
      Sentry.captureMessage(
        'A user has tried adding a line item in their cart, but was unable to retrieve their checkout object after mutation.'
      )
      return rejectWithValue(message)
    }

    const updatedCheckout = result.data?.checkoutLineItemsReplace?.checkout

    return {
      checkout: updatedCheckout as Checkout,
      lineItem: removedLineItem as CheckoutLineItemFragment,
    }
  }
)

export default lineItemRemove
