import React from 'react'

const Path01 = () => {
  return (
    <>
      <path
        // relative coords
        d="M0.899951 0.169975C0.985919 0.273614 1.01649 0.424939 0.99165 0.575629C0.966178 0.726318 0.885304 0.875736 0.757307 0.943133C0.629947 1.01117 0.454826 0.996542 0.311545 0.924695C0.168902 0.852847 0.058735 0.722503 0.0179797 0.569271C-0.0227757 0.416038 0.00588039 0.23928 0.102674 0.133733C0.200105 0.0275511 0.36631 -0.00805484 0.51978 0.00148248C0.673249 0.0116556 0.814619 0.066972 0.899951 0.169975Z"
        fill="currentColor"
      />
    </>
  )
}

export default Path01
