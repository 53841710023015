import { Reference, Refinement, SanityDocumentType } from '@types'
import { ALGOLIA_FACET_MAP } from '../constants'

const getRefinementFromReference = (reference: Reference) => {
  const facetName = ALGOLIA_FACET_MAP[reference._type as SanityDocumentType]
  const refinement = {
    name: facetName,
    slug: reference._type,
    value: reference.title,
  } as Refinement

  return refinement
}

export default getRefinementFromReference
