import React from 'react'

const Path02 = () => {
  return (
    <>
      <path d="M138.937 0C142.992 31.1304 146.952 61.5245 151 92.6103C146.952 91.9112 143.282 91.4279 139.672 90.6396C117.87 85.8726 96.0608 81.1279 74.3034 76.1601C69.3265 75.0223 64.9959 75.9222 60.7766 78.3465C50.5034 84.2365 40.2673 90.1785 29.9866 96.0535C25.5519 98.5895 21.0578 101.029 16.5712 103.468C15.5387 104.026 14.4021 104.398 12.9462 105C11.8171 99.296 10.6137 93.9341 9.70749 88.5126C6.49106 69.2662 3.36376 49.9975 0.243899 30.7362C-0.699488 24.9132 1.02388 23.5151 6.63962 25.7982C19.4682 31.004 32.4379 35.9494 44.9545 41.8394C53.2147 45.7288 60.019 45.5206 67.9523 40.6346C87.652 28.4978 107.842 17.1715 127.883 5.59246C131.241 3.65146 134.814 2.07486 138.937 0Z" />
    </>
  )
}

export default Path02
