// Based off: https://gist.github.com/reecelucas/cd110ece696cca8468db895281fa28cb
import { useEffect, useState } from 'react'
import useTypedSelector from './useTypedSelector'

type SCROLL_UP = 'up'
type SCROLL_DOWN = 'down'
type SCROLL_DIR = SCROLL_DOWN | SCROLL_UP

const useScrollDirection = ({
  active = true,
  threshold = 0,
  yOffset = 0,
}: {
  active?: boolean
  threshold: number
  yOffset?: number
}): SCROLL_DIR | undefined => {
  const [scrollDir, setScrollDir] = useState<SCROLL_DIR>()

  const subheaderVisible = useTypedSelector(
    state => state.navigation.subheader.visible
  )

  useEffect(() => {
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (!active || subheaderVisible) {
        setScrollDir(undefined)
        return
      }

      if (scrollY < yOffset) {
        ticking = false
        return
      }

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }

      setScrollDir(scrollY > lastScrollY ? 'down' : 'up')
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [active, subheaderVisible, threshold])

  return scrollDir
}

export default useScrollDirection
