import * as Sentry from '@sentry/react'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { Box, Flex } from 'theme-ui'
import { menuActions } from '../../redux/modules/menu'
import checkoutGet from '../../redux/modules/store/thunks/checkoutGet'
import { useAppDispatch } from '../../redux/store'
import Footer from '../Footer'
import ModalNewsletter from '../ModalNewsletter'
import ModalSearch from '../ModalSearch'
import Navigation from '../Navigation'
import PanelCart from '../PanelCart'
import PanelTeamMember from '../PanelTeamMember'
import PanelProductVariantSelector from '../PanelProductVariantSelector'
import Subscribe from '../Subscribe'

type Props = {
  Component: any
  pageProps: any
}

const Main = (props: Props) => {
  const { Component, pageProps } = props
  const router = useRouter()

  // Store: redux
  const dispatch = useAppDispatch()

  // Effects
  useEffect(() => {
    // Don't retain scroll position on reload
    if (history.scrollRestoration) {
      history.scrollRestoration = 'manual'
    }

    // Create checkout
    // TODO: clarify empty object arg
    // https://github.com/reduxjs/redux-toolkit/issues/489#issuecomment-611214385
    dispatch(checkoutGet({}))
  }, [])

  // Fetch menu at runtime
  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const response = await fetch('/api/menu', {
          headers: { 'Content-Type': 'application/json' },
        })
        const result = await response.json()

        // Store in redux store
        dispatch(menuActions.hydrate({ sections: result.sections }))
      } catch (err) {
        console.error(err)

        // Sentry: capture exception
        Sentry.captureException(err)
      }
    }

    fetchMenu()
  }, [])

  return (
    <>
      <Flex
        sx={{
          flexDirection: 'column',
          fontFamily: 'sans',
          minHeight: '100vh',
          zIndex: 'content',
        }}
      >
        {/* Navigation (header + menu) */}
        <Navigation />

        {/* Page components */}
        {/* Key by path but not query params */}
        <Box sx={{ flex: 1, minHeight: '100vh' }}>
          <Component {...pageProps} key={router.asPath.split('?')[0]} />
        </Box>
      </Flex>

      {/* Modal: Search */}
      <ModalSearch />

      {/* Modal: Newsletter */}
      <ModalNewsletter />

      {/* Panel: Variant selector */}
      <PanelProductVariantSelector />

      {/* Panel: Cart */}
      <PanelCart />

      {/* Panel: Team member */}
      <PanelTeamMember />

      <Box>
        <Subscribe />

        {/* Footer */}
        <Footer />
      </Box>
    </>
  )
}

export default Main
