import { ColorTheme, SanityImage } from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import Caption from '../Caption'
import NextImageResponsive from '../NextImageResponsive'
import PortableTextBlockWrapper from '../PortableTextBlockWrapper'

type PortableTextBlockImage = {
  colorTheme?: ColorTheme
  node: {
    image: SanityImage
  }
  singleColumn?: boolean
}

const PortableTextBlockImage = (props: PortableTextBlockImage) => {
  const { colorTheme, singleColumn, node } = props

  if (!node.image) {
    return null
  }

  return (
    <PortableTextBlockWrapper
      singleColumn={singleColumn}
      my={[8, null, null, 12]}
    >
      <Box>
        {/* Image */}
        <NextImageResponsive
          image={node.image}
          sizes={['100vw', null, null, '50vw']}
          sx={{
            borderRadius: 1,
            overflow: 'hidden',
          }}
        />

        {/* Caption */}
        <Caption color={colorTheme?.text} text={node.image?.caption} />
      </Box>
    </PortableTextBlockWrapper>
  )
}

export default PortableTextBlockImage
