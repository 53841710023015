import { ColorTheme, SanityLinkExternal, SanityLinkInternal } from '@types'
import React from 'react'
import Buttons from '../Buttons'
import PortableTextBlockWrapper from '../PortableTextBlockWrapper'

type PortableTextBlockButtons = {
  colorTheme?: ColorTheme
  node: {
    links: (SanityLinkExternal | SanityLinkInternal)[]
  }
  singleColumn?: boolean
}

const PortableTextBlockButtons = (props: PortableTextBlockButtons) => {
  const { colorTheme, node, singleColumn } = props

  return (
    <PortableTextBlockWrapper
      singleColumn={singleColumn} //
      mb={8}
      mt={2}
    >
      {/* Links */}
      <Buttons align="left" colorTheme={colorTheme} links={node?.links} />
    </PortableTextBlockWrapper>
  )
}

export default PortableTextBlockButtons
