import { Color } from '@types'
import { rgba } from 'polished'
import React from 'react'
import { Box } from 'theme-ui'
import { COLORS } from '../../constants'

type Props = {
  color?: Color
  text?: string
}

const Caption = (props: Props) => {
  const { color, text } = props

  if (!text) {
    return null
  }

  return (
    <Box
      color={rgba(COLORS[color || 'midnight'], 0.75)}
      mt={2}
      sx={{ fontSize: 'xxs' }}
    >
      {text}
    </Box>
  )
}

export default Caption
