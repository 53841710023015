import React from 'react'

import theme from '../../../styled/theme'

const Path05 = () => {
  return (
    <g fill={theme.colors.sky}>
      <path d="M88.7 64.8001C86 59.5001 81.5 56.8001 75.7 56.3001C74.1 56.2001 72.5 56.2001 70.8 56.2001C68.5 56.1001 66.2 56.0001 63.7 55.9001C65.3 52.5001 66.9 49.5001 68.1 46.4001C71.1 38.5001 65.9 26.7001 53.8 26.9001C50.8 26.9001 47.8 27.1001 44.8 27.2001C43.7 27.2001 42.6 27.1001 41.2 27.0001C42 25.1001 42.7 23.8001 43.1 22.3001C43.9 19.3001 45 16.3001 45.3 13.2001C45.9 6.4001 42.6 1.7001 35.9 0.300098C35.5 0.200098 35.1 0.200098 34.7 0.100098H15.9C10.6 0.200098 5.3 0.600098 0 0.900098V94.0001H74.4C75.4 93.9001 76.4 93.7001 77.4 93.6001C83.4 92.8001 87.2 89.2001 89.5 83.8001C92.5 77.3001 91.9 71.0001 88.7 64.8001Z" />
    </g>
  )
}

export default Path05
