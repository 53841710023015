import React from 'react'

const Path05 = () => {
  return (
    <>
      <path
        d="M1066.21 7.16957C1044.12 7.12972 1022.08 7.10612 999.987 7.05034C976.091 6.98855 952.15 6.87225 928.255 6.81684C902.415 6.75766 876.621 6.73068 850.873 6.71675C815.956 6.69417 781.177 6.72673 746.26 6.68184C702.591 6.62476 658.829 6.60209 615.022 6.40381C564.731 6.17703 514.303 5.78036 463.921 5.44138C409.695 5.07924 355.516 4.7174 301.29 4.31701C277.535 4.14142 253.826 3.85778 230.025 3.66275C221.365 3.59064 212.659 3.66801 203.953 3.62746C183.577 3.53561 162.877 3.55311 142.873 3.27572C108.376 2.79699 75.309 3.11861 41.4584 2.89932C32.8452 2.84347 24.0474 2.71303 15.6202 2.54369C5.01683 2.33394 -2.20217 1.56721 0.626716 0.980931C3.54837 0.372976 11.5604 0.303579 22.9055 0.400474C40.4557 0.552654 58.0531 0.568094 75.6501 0.650465C107.093 0.792683 138.49 0.912272 169.933 1.09912C187.622 1.20443 205.634 1.55103 223.046 1.56838C262.872 1.60867 302.417 2.10927 342.009 2.47311C374.284 2.77086 406.42 3.21427 438.649 3.4862C482.687 3.8582 526.818 4.18939 570.856 4.42751C612.209 4.65041 653.516 4.75506 694.823 4.87245C733.398 4.98067 771.973 5.06021 810.41 5.10373C837.778 5.13514 864.916 5.05976 892.238 5.08129C917.986 5.10159 943.78 5.17321 969.62 5.24196C999.35 5.32143 1029.17 5.43977 1058.9 5.50331C1094.93 5.57814 1131.14 5.68612 1166.89 5.63473C1195.88 5.59437 1224.27 5.34269 1252.94 5.18854C1270.45 5.09496 1287.86 4.95612 1305.6 4.95019C1309.3 4.95008 1315.23 5.41786 1317.86 5.71964C1318.88 5.83501 1314.39 6.20902 1311.01 6.25279C1262.98 6.91495 1213.56 7.27116 1162.67 7.29206C1130.58 7.30478 1098.39 7.27544 1066.25 7.26234C1066.35 7.23428 1066.25 7.20176 1066.21 7.16957Z"
        fill="currentColor"
      />
    </>
  )
}

export default Path05
