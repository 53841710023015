import { IllustrationThickType } from '@types'
import React, { FunctionComponent } from 'react'
import Adventure from './patterns/Adventure'
import Apple from './patterns/Apple'
import Bag from './patterns/Bag'
import Beer from './patterns/Beer'
import Book from './patterns/Book'
import Cart from './patterns/Cart'
import Chat from './patterns/Chat'
import Coffee from './patterns/Coffee'
import Create from './patterns/Create'
import Document from './patterns/Document'
import Education from './patterns/Education'
import Fire from './patterns/Fire'
import Food from './patterns/Food'
import Globe from './patterns/Globe'
import Graph from './patterns/Graph'
import Info from './patterns/Info'
import Keyboard from './patterns/Keyboard'
import Lightbulb from './patterns/Lightbulb'
import Like from './patterns/Like'
import List from './patterns/List'
import Lock from './patterns/Lock'
import MagnifyingGlass from './patterns/MagnifyingGlass'
import Map from './patterns/Map'
import Music from './patterns/Music'
import PaperPlane from './patterns/PaperPlane'
import Present from './patterns/Present'
import Print from './patterns/Print'
import Question from './patterns/Question'
import Rocket from './patterns/Rocket'
import Smile from './patterns/Smile'
import Speaker from './patterns/Speaker'
import Star from './patterns/Star'
import Sun from './patterns/Sun'
import Tick from './patterns/Tick'
import Video from './patterns/Video'

export type Props = {
  type: IllustrationThickType
}

const IllustrationThick = (props: Props) => {
  const { type } = props

  const Pattern: Record<IllustrationThickType, FunctionComponent> = {
    adventure: Adventure,
    apple: Apple,
    bag: Bag,
    beer: Beer,
    book: Book,
    cart: Cart,
    chat: Chat,
    coffee: Coffee,
    create: Create,
    document: Document,
    education: Education,
    fire: Fire,
    food: Food,
    globe: Globe,
    graph: Graph,
    info: Info,
    keyboard: Keyboard,
    lightbulb: Lightbulb,
    like: Like,
    list: List,
    lock: Lock,
    magnifyingGlass: MagnifyingGlass,
    map: Map,
    music: Music,
    paperPlane: PaperPlane,
    present: Present,
    print: Print,
    question: Question,
    rocket: Rocket,
    smile: Smile,
    speaker: Speaker,
    star: Star,
    sun: Sun,
    tick: Tick,
    video: Video,
  }

  const PatternComponent = Pattern[type]

  if (!PatternComponent) {
    return null
  }

  return (
    <svg
      height="100%"
      preserveAspectRatio="xMidYMid meet"
      style={{
        display: 'block',
      }}
      width="100%"
      viewBox="0 0 34 34"
    >
      <g fill="currentColor">
        <PatternComponent />
      </g>
    </svg>
  )
}

export default IllustrationThick
