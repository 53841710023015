import NextLink from 'next/link'
import { useRouter } from 'next/router'
import React, { ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import { Box } from 'theme-ui'
import { searchActions } from '../../redux/modules/search'

type Props = {
  children: ReactNode
  query: string
}

const SearchQueryLink = (props: Props) => {
  const { children, query } = props

  // Redux
  const dispatch = useDispatch()

  const router = useRouter()
  const onSearchPage = router.route === '/search'

  // Callbacks
  const handleClick = () => {
    dispatch(
      searchActions.setSearchState({
        searchState: {
          page: 1,
          query,
        },
      })
    )
  }

  if (onSearchPage) {
    return (
      <Box onClick={handleClick} sx={{ display: 'inline-block' }}>
        {children}
      </Box>
    )
  }

  return (
    <NextLink href={`/search?q=${query}`}>
      <a>{children}</a>
    </NextLink>
  )
}

export default SearchQueryLink
