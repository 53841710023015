import React from 'react'
import { Box } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import { selectSectionTalks } from '../../redux/modules/menu'
import getSingletonHref from '../../utils/getSingletonHref'
import CardSlimList from '../CardSlimList'
import DividerHorizontalRandom from '../DividerHorizontalRandom'
import MenuSectionWrapper from '../MenuSectionWrapper'
import PillList from '../PillList'

const MenuSectionTalks = () => {
  const sectionTalks = useTypedSelector(selectSectionTalks)

  return (
    <MenuSectionWrapper
      documentTypeIndex="talks"
      documentTypeLabel="View talks overview"
    >
      {/* Talks */}
      {sectionTalks?.talks && (
        <CardSlimList
          headerIllustrationType="speaker"
          href={getSingletonHref('talks')}
          items={sectionTalks.talks}
          title="Talks"
        />
      )}

      {/* Divider */}
      <DividerHorizontalRandom color="stone" my={8} />

      {/* Playlists */}
      <Box mt={3}>
        <PillList
          headerIllustrationType="video"
          href={getSingletonHref('playlistIndex')}
          items={sectionTalks?.playlists}
          title="Playlists"
        />
      </Box>
    </MenuSectionWrapper>
  )
}

export default MenuSectionTalks
