import React from 'react'

const Path09 = () => {
  return (
    <>
      <path d="M91.8327 36.2608C92.4453 32.6902 93.0305 29.4583 93.5608 26.2082C93.9357 23.9194 94.1095 21.5848 94.6032 19.3234C96.4044 11.1385 100.985 5.20582 108.995 2.28527C118.358 -1.12968 127.51 -0.928251 136.224 4.32692C143.346 8.62078 147.818 14.645 147.955 23.2876C148.165 36.7185 148.403 50.1586 148.485 63.5895C148.613 83.832 147.927 104.047 146.09 124.225C145.532 130.332 145.203 136.466 144.599 142.573C144.023 148.432 142.231 150.071 136.269 149.998C115.724 149.732 95.1792 149.339 74.6249 149.018C53.4123 148.679 32.2088 148.368 10.9962 148.048C10.3379 148.038 9.67039 148.048 9.01207 148.02C3.35233 147.745 1.30426 145.621 1.51455 139.872C1.70656 134.571 2.12713 129.279 2.45629 123.978C3.35234 109.412 4.11123 94.8367 5.24501 80.2889C5.61989 75.5464 6.44282 70.694 8.01548 66.2354C11.2522 57.0526 18.5029 53.0792 28.0303 55.1666C32.3277 56.1096 36.4148 58.1146 40.5201 59.8083C42.486 60.6232 44.2781 61.85 46.8016 63.2507C47.1582 61.2549 47.524 59.7443 47.6794 58.2062C48.1092 53.9123 48.3926 49.6093 48.8497 45.3246C50.7058 28.0942 68.4166 22.7292 79.096 28.3963C83.3111 30.6028 87.2245 33.3769 91.8327 36.2608Z" />
    </>
  )
}

export default Path09
