import React from 'react'

const Path04 = () => {
  return (
    <>
      <path
        d="M0 1.88537C4.64869 0.735729 15.6935 0.972079 28.6386 1.14628C47.6317 1.40601 67.08 1.44084 86.3681 1.58498C105.867 1.73082 125.328 1.9132 144.839 2.04807C158.678 2.14468 172.571 2.19011 186.442 2.25748C217.711 2.40217 248.976 2.55051 280.248 2.69155C300.276 2.78265 320.311 2.86645 340.35 2.94659C364.425 3.04077 388.518 3.15721 412.598 3.20719C443.949 3.27513 475.306 3.29889 506.654 3.32994C521.818 3.34504 537.027 3.35682 552.156 3.32372C568.824 3.28826 585.423 3.19694 602.064 3.14651C624.863 3.07928 647.688 3.027 670.467 2.97804C684.613 2.94809 698.823 2.93709 712.991 2.92575C734.155 2.91172 755.327 2.89039 776.509 2.89862C797.223 2.90677 817.978 2.95581 838.71 2.98623C866.464 3.02891 894.211 3.07889 921.977 3.11061C948.041 3.13968 974.157 3.19869 1000.18 3.15C1027.22 3.10216 1054.19 2.96157 1081.12 2.82066C1128.54 2.57553 1175.9 2.31145 1223.23 2.02876C1241.73 1.91586 1260.05 1.61718 1278.58 1.62991C1286.55 1.63504 1295.36 1.33358 1304.23 1.80679C1311.69 2.2023 1316.78 2.65987 1314.37 3.19712C1312.75 3.55639 1305.84 3.95788 1300.31 4.02033C1269.27 4.37882 1238.02 4.6914 1206.65 4.91823C1173.34 5.15898 1139.81 5.29835 1106.34 5.453C1063.62 5.64749 1020.96 5.86824 978.128 5.966C943.248 6.04662 908.165 5.95971 873.168 5.95092C858.124 5.94415 843.125 5.9746 828.082 5.92729C792.554 5.81837 757.039 5.6174 721.586 5.55701C694.22 5.51008 666.888 5.62932 639.52 5.66347C591.876 5.7188 544.211 5.83295 496.534 5.79954C454.227 5.76879 411.788 5.6227 369.443 5.42944C332.359 5.26008 295.306 4.9435 258.243 4.71532C226.476 4.5187 194.684 4.38455 162.948 4.15868C136.627 3.9727 110.377 3.72092 84.1541 3.44357C63.0729 3.22233 41.9849 2.96786 21.1526 2.63435C13.3607 2.5053 6.40171 2.11752 0 1.88537Z"
        fill="currentColor"
      />
    </>
  )
}

export default Path04
