import { Refinement } from '@types'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import React, { ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import { Box } from 'theme-ui'
import { everythingActions } from '../../redux/modules/everything'
import getRefinementHref from '../../utils/getRefinementHref'

type Props = {
  children: ReactNode
  refinements: Refinement[]
}

const RefinementLink = (props: Props) => {
  const { children, refinements } = props

  // Redux
  const dispatch = useDispatch()

  const router = useRouter()
  const onEverythingPage = router.route === '/everything'

  if (!refinements) {
    return null
  }

  // Callbacks
  const handleClick = () => {
    dispatch(everythingActions.addRefinements({ refinements }))
  }

  if (onEverythingPage) {
    return (
      <Box onClick={handleClick} sx={{ display: 'inline-block' }}>
        {children}
      </Box>
    )
  }

  return (
    <NextLink href={getRefinementHref(refinements)} passHref>
      {children}
    </NextLink>
  )
}

export default RefinementLink
