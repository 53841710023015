import { useEffect, useState } from 'react'
import theme from '../styled/theme'

function useBreakpointIndex() {
  const [value, setValue] = useState<number>()

  useEffect(() => {
    const mediaQueryLists = theme.breakpoints.map(width =>
      window.matchMedia(`(max-width: ${width})`)
    )

    const getBreakpointIndex = () => {
      const index = mediaQueryLists.findIndex(mql => mql.matches)

      return index >= 0 ? index : theme.breakpoints?.length - 1
    }

    const handleBreakpoint = () => {
      setValue(getBreakpointIndex)
    }

    mediaQueryLists.forEach(mql =>
      mql.addEventListener('change', handleBreakpoint)
    )
    return () => {
      mediaQueryLists.forEach(mql =>
        mql.removeEventListener('change', handleBreakpoint)
      )
    }
  }, [])

  return value
}

export default useBreakpointIndex
