import React from 'react'

const MagnifyingGlass = () => {
  return (
    <>
      <path d="M15.4225 5.27051C11.6625 5.27051 8.60376 8.3295 8.60376 12.0892C8.60376 15.8489 11.6628 18.9079 15.4225 18.9079C19.1821 18.9079 22.2413 15.8489 22.2413 12.0892C22.2413 8.3295 19.1824 5.27051 15.4225 5.27051ZM15.4225 16.9465C12.7445 16.9465 10.5655 14.7677 10.5655 12.0895C10.5655 9.41146 12.7444 7.23258 15.4225 7.23258C18.1007 7.23258 20.2795 9.41146 20.2795 12.0895C20.2795 14.7677 18.101 16.9465 15.4225 16.9465Z" />
      <path d="M23.0272 19.2733C24.8031 17.3961 25.8954 14.8657 25.8954 12.0837C25.8954 10.3497 25.1735 8.42292 24.8631 7.67498C23.3365 3.99563 19.8425 1.79883 15.5165 1.79883C13.9407 1.79883 12.3015 2.09958 10.7755 2.66857C6.64622 4.20853 4.90159 8.77354 5.00427 12.6147C5.14839 18.0051 9.91856 22.5586 15.4202 22.5586C16.7314 22.5586 17.9861 22.3143 19.1438 21.8725C20.1561 24.0239 21.2308 26.1504 22.3407 28.2513C22.8854 29.2827 23.305 30.5187 23.979 31.4689C25.0752 33.0136 28.596 30.7777 29.5712 29.9647C29.5763 29.9602 29.5792 29.9555 29.5843 29.9513C30.0205 29.8083 30.3391 29.3465 30.0042 28.8656C27.7444 25.6183 25.1939 22.5838 23.0272 19.2733ZM6.9654 12.562C6.88145 9.41817 8.23863 5.70826 11.4608 4.50677C12.7696 4.01851 14.1719 3.76052 15.5165 3.76052C19.0745 3.76052 21.8208 5.46139 23.0511 8.42657C23.6038 9.75822 23.9336 11.125 23.9336 12.0834C23.9336 16.7771 20.1149 20.5965 15.4205 20.5965C10.9541 20.5965 7.0821 16.9169 6.9654 12.562ZM20.5475 21.2124C21.0082 20.9525 21.447 20.659 21.8614 20.3346C22.7669 21.6997 23.7325 23.0209 24.7159 24.33C24.0413 24.7448 23.394 25.1994 22.7675 25.6832C22.0098 24.202 21.2611 22.716 20.5475 21.2124ZM25.1511 30.3651C24.6086 29.2744 24.0537 28.1897 23.4971 27.1057C24.1903 26.5571 24.9109 26.0491 25.6696 25.5935C26.5346 26.7366 27.3989 27.8803 28.2307 29.0479C27.3242 29.7373 26.2715 30.1767 25.1511 30.3651Z" />
    </>
  )
}

export default MagnifyingGlass
