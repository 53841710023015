import NextLink from 'next/link'
import { rgba } from 'polished'
import React from 'react'
import { Box, Flex } from 'theme-ui'
import { COLORS } from '../../constants'
import useTypedSelector from '../../hooks/useTypedSelector'
import { navigationActions } from '../../redux/modules/navigation'
import { useAppDispatch } from '../../redux/store'
import { HEADER_HEIGHT_SMALL } from '../../styled/theme'
import BoxMotion from '../BoxMotion'
import ButtonCart from '../ButtonCart'
import ButtonMenuToggle from '../ButtonMenuToggle'
import ButtonSearch from '../ButtonSearch'
import FlexMotion from '../FlexMotion'
import Link from '../Link'
import Logo from '../Logo'

const HeaderSmall = () => {
  // Redux
  const menuSmallVisible = useTypedSelector(state => state.menu.small.visible)
  const navigationHeaderAtTop = useTypedSelector(
    state => state.navigation?.header?.atTop
  )
  const navigationHeaderCustomColor = useTypedSelector(
    state => state.navigation?.header?.customColor
  )
  const navigationHeaderTransparent = useTypedSelector(
    state => state.navigation?.header?.transparent
  )
  const navigationHeaderVisible = useTypedSelector(
    state => state.navigation?.header?.visible
  )
  const navigationSubheaderVisible = useTypedSelector(
    state => state.navigation.subheader.visible
  )
  const searchVisible = useTypedSelector(state => state.search?.visible)
  const dispatch = useAppDispatch()

  const useTransparency =
    navigationHeaderTransparent && !menuSmallVisible && !searchVisible

  const backgroundColor =
    navigationHeaderCustomColor && useTransparency
      ? rgba(COLORS[navigationHeaderCustomColor || 'white'], 0.02)
      : rgba(COLORS.white, 1.0)
  const borderColor =
    navigationHeaderCustomColor && useTransparency
      ? rgba(COLORS[navigationHeaderCustomColor || 'midnight'], 0.2)
      : rgba(COLORS.stone, 1.0)
  const color =
    navigationHeaderCustomColor && useTransparency
      ? rgba(COLORS[navigationHeaderCustomColor || COLORS.white], 1)
      : rgba(COLORS.midnight, 1)

  // Callbacks
  const handleMouseEnter = () => {
    dispatch(navigationActions.setHeaderTransparent({ transparent: false }))
    dispatch(navigationActions.setHeaderHover({ hover: true }))
  }

  const handleMouseLeave = () => {
    dispatch(navigationActions.setHeaderHover({ hover: false }))

    if (navigationHeaderAtTop) {
      dispatch(navigationActions.setHeaderTransparent({ transparent: true }))
    }

    // Hide if subheader is active (this may occur if you scroll down the page with your mouse over the header)
    if (navigationSubheaderVisible) {
      dispatch(navigationActions.setHeaderVisible({ visible: false }))
    }
  }

  return (
    <BoxMotion
      // framer-motion
      animate={{
        // NOTE: we use `top` over `translate` due to visual bugs with `backdrop-filter: blur`
        // when the element has negative Y values.
        top: navigationHeaderVisible ? 0 : -HEADER_HEIGHT_SMALL,
        // y: navigationHeaderVisible || menuSmallVisible ? '0%' : '-100%',
      }}
      transition={{
        damping: 30,
        stiffness: 150,
        type: 'spring',
      }}
      // theme-ui
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        backgroundColor,
        backdropFilter: 'blur(100px)',
        borderBottom: '1px solid',
        borderColor,
        flexDirection: 'column',
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 'header',
      }}
    >
      <Flex
        px={4}
        sx={{
          alignItems: 'center',
          height: HEADER_HEIGHT_SMALL,
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        {/* Logo */}
        <Box sx={{ color, width: '64px' }}>
          <NextLink href="/" passHref>
            <Link>
              <Logo />
            </Link>
          </NextLink>
        </Box>

        <FlexMotion
          // framer-motion
          animate={{ color }}
          initial={{ color }}
        >
          {/* Search toggle button */}
          <Box>
            <ButtonSearch iconSize={24} />
          </Box>

          {/* Cart button */}
          <Box ml={2}>
            <ButtonCart iconSize={24} />
          </Box>

          {/* Menu button */}
          <Box ml={2}>
            <ButtonMenuToggle iconSize={22} />
          </Box>
        </FlexMotion>
      </Flex>
    </BoxMotion>
  )
}

export default HeaderSmall
