import { DOCUMENT_SLUG_MAP } from '../constants'
import { SanityDocumentTypeSingleton } from '../types'

const getSingletonHref = (
  singletonDocumentType: SanityDocumentTypeSingleton
) => {
  return JSON.parse(DOCUMENT_SLUG_MAP[singletonDocumentType])
}

export default getSingletonHref
