import { createAsyncThunk } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { Checkout } from '@types'
import replaceLineItems from '../../../../services/shopify/replaceLineItems'
import {
  AttributeInput,
  CheckoutLineItemFragment,
} from '../../../../types/codegen/shopify'
import getLineItemInputs from '../../../../utils/getLineItemInputs'
import { RootState } from '../../../store'

const lineItemAdd = createAsyncThunk<
  // Return type of the payload creator
  { checkout: Checkout; lineItem: CheckoutLineItemFragment },
  // First argument to the payload creator
  {
    customAttributes?: AttributeInput[]
    productVariantId: string // base64 encoded value
    quantity: number
  },
  {
    rejectValue: string
    state: RootState
  }
>(
  'store/lineItemAdd',
  async (
    { customAttributes, productVariantId, quantity },
    { getState, rejectWithValue }
  ) => {
    // Get existing checkout from store
    const checkout = getState()?.store?.checkout
    const checkoutId = checkout?.id

    // Get existing line items in cart (inputs)
    const lineItems = getLineItemInputs(
      checkout?.lineItems?.edges?.map(edge => edge.node)
    )

    // If we're unable to get a checkout ID, our checkout hasn't been created yet
    if (!checkoutId) {
      Sentry.captureMessage(
        'A user has tried adding a line item in their cart, but was unable to retrieve their checkout object.'
      )
      return rejectWithValue('Unable to retrieve checkout')
    }

    // See if added variantID already exists in our checkout
    // If so, update quantities
    // If not, create a new checkout input item
    const lineItemIndex = lineItems?.findIndex(
      lineItem => lineItem?.variantId === productVariantId
    )

    if (lineItemIndex >= 0) {
      lineItems[lineItemIndex].quantity += quantity
    } else {
      lineItems.push({
        customAttributes,
        quantity,
        variantId: productVariantId,
      })
    }

    const result = await replaceLineItems({ checkoutId, lineItems })
    if (result.errors) {
      const message = `Unable to retrieve checkout: ${result.errors
        .map(err => err.message)
        .join(',')}`
      console.error(message)

      Sentry.captureMessage(
        'A user has tried adding a line item in their cart, but was unable to retrieve their checkout object after mutation.'
      )
      return rejectWithValue(message)
    }

    const updatedCheckout = result.data?.checkoutLineItemsReplace?.checkout

    // Get added line item
    const addedLineItem = updatedCheckout?.lineItems?.edges.find(
      edge => edge.node.variant?.id === productVariantId
    )?.node

    return {
      checkout: updatedCheckout as Checkout,
      lineItem: addedLineItem as CheckoutLineItemFragment,
    }
  }
)

export default lineItemAdd
