import React from 'react'
import { connectStateResults } from 'react-instantsearch-dom'
import { Box, ThemeUIStyleObject } from 'theme-ui'

type Props = {
  allSearchResults: any
  children?: any
  error: any
  searching: boolean
  searchResults: any
  searchState: any
  // (custom)
  sx?: ThemeUIStyleObject
}

const CustomResultsFeedback = (props: Props) => {
  const {
    allSearchResults,
    children,
    error,
    searching,
    searchResults,
    searchState,
    // (custom)
    sx,
  } = props

  const noResults = !searching && allSearchResults?.nbHits === 0

  if (error) {
    return (
      <Box sx={{ fontSize: 'xs', ...sx }}>Sorry, an error has occurred</Box>
    )
  }

  if (noResults) {
    return <Box sx={{ fontSize: 'xs', ...sx }}>No results</Box>
  }

  return null
}

const AlgoliaCustomResultsFeedback: any = connectStateResults(
  CustomResultsFeedback
)

export default AlgoliaCustomResultsFeedback
