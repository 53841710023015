import { PlaylistPreview } from '@types'
import NextLink from 'next/link'
import pluralize from 'pluralize'
import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import Artboard from '../Artboard'
import PlaylistPlayButton from '../PlaylistPlayButton'

export type Props = {
  playlist: PlaylistPreview
}

const PlaylistPill = (props: Props) => {
  const { playlist } = props

  return (
    <NextLink href={playlist.slug}>
      <a>
        <Flex
          bg={playlist?.artboard?.colorTheme?.background || 'white'}
          color={playlist?.artboard?.colorTheme?.text || 'midnight'}
          p={5}
          sx={{
            alignItems: 'center',
            borderRadius: 1,
            height: '80px',
            overflow: 'hidden',
            position: 'relative',
            transition: 'background 300ms',
            width: '100%',
            '@media (hover: hover) and (pointer: fine)': {
              '&:hover:not(:disabled)': {
                // bg: 'stone',
              },
            },
          }}
        >
          {/* Artboard */}
          {playlist?.artboard && <Artboard artboard={playlist.artboard} />}

          {/* Play button */}
          <Box
            sx={{
              position: 'relative',
              transform: 'translate3d(0,0,0)', // Force depth on Safari
            }}
          >
            <PlaylistPlayButton colorTheme={playlist?.artboard?.colorTheme} />
          </Box>

          <Box
            ml={2}
            sx={{
              position: 'relative',
              transform: 'translate3d(0,0,0)', // Force depth on Safari
            }}
          >
            {/* Title */}
            {playlist?.title && (
              <Box
                sx={{
                  fontSize: 'm',
                  fontWeight: 'semibold',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {playlist.title}
              </Box>
            )}

            {/* Talk count */}
            {playlist?.talkCount && (
              <Text
                sx={{
                  fontSize: 'xxs',
                }}
              >
                {playlist.talkCount} {pluralize('talk', playlist.talkCount)}
              </Text>
            )}
          </Box>
        </Flex>
      </a>
    </NextLink>
  )
}

export default PlaylistPill
