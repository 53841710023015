import gql from 'graphql-tag'

import { initializeApollo } from '../../lib/apolloClient'
import { CHECKOUT_FRAGMENT } from '../../lib/shopifyFragments'
import {
  AttributeInput,
  ShopifyCheckoutCreateMutation,
} from '../../types/codegen/shopify'

const updateCheckoutAttributes = async ({
  checkoutId,
  customAttributes,
}: {
  checkoutId: string
  customAttributes: AttributeInput[]
}) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.mutate<ShopifyCheckoutCreateMutation>({
    mutation: gql`
      ${CHECKOUT_FRAGMENT}
      mutation ShopifyCheckoutUpdateAttributes(
        $checkoutId: ID!
        $customAttributes: [AttributeInput!]
      ) {
        checkoutAttributesUpdateV2(
          checkoutId: $checkoutId
          input: { customAttributes: $customAttributes }
        ) {
          checkout {
            ...checkout
          }
        }
      }
    `,
    variables: {
      checkoutId,
      customAttributes,
    },
  })

  return result
}

export default updateCheckoutAttributes
