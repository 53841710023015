import gql from 'graphql-tag'

export const CHECKOUT_LINE_ITEM_FRAGMENT = gql`
  fragment checkoutLineItem on CheckoutLineItem {
    customAttributes {
      key
      value
    }
    id
    title
    variant {
      id
      title
      image {
        transformedSrc(maxWidth: 400)
      }
      price {
        amount
        currencyCode
      }
   compareAtPrice {
      amount
      currencyCode
    }
    

  
    }
    quantity
  }
`

export const CHECKOUT_FRAGMENT = gql`
  ${CHECKOUT_LINE_ITEM_FRAGMENT}
  fragment checkout on Checkout {
    completedAt
    currencyCode
    customAttributes {
      key
      value
    }
    id
    lineItems(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...checkoutLineItem
        }
      }
    }
    subtotalPriceV2 {
      amount
      currencyCode
    }
    totalTaxV2 {
      amount
      currencyCode
    }
    totalPriceV2 {
      amount
      currencyCode
    }
    webUrl
  }
`

const PRODUCT_VARIANT_FRAGMENT = gql`
  fragment productVariant on ProductVariant {
    availableForSale
    id
    price {
        amount
        currencyCode
      }
   compareAtPrice {
      amount
      currencyCode
    }
    selectedOptions {
      name
      value
    }
    sku
    title
  }
`

export const PRODUCT_FRAGMENT = gql`
  ${PRODUCT_VARIANT_FRAGMENT}
  fragment product on Product {
    availableForSale
    compareAtPriceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    id
    options {
      id
      name
      values
    }
    presentmentPriceRanges(first: 10) {
      edges {
        cursor
        node {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
      }
    }
    productType
    tags
    title
    variants(first: 50) {
      edges {
        node {
          ...productVariant
        }
      }
    }
  }
`
