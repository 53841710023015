import { useEffect, useState } from 'react'

const useImageLoaded = (src: string, load = true) => {
  const [isLoaded, setLoaded] = useState(false)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    let image: HTMLImageElement

    const handleImageLoaded = () => {
      setLoaded(true)
    }

    if (!!load && !isLoading) {
      image = new Image()
      image.addEventListener('load', handleImageLoaded)
      image.src = src
      setLoading(true)
    }

    return () => {
      if (image) {
        image.removeEventListener('load', handleImageLoaded)
      }
    }
  }, [load]) // Use effect on mount, unmount and when `load` changes

  return isLoaded
}

export default useImageLoaded
