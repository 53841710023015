import { MenuSectionType } from '@types'
import { AnimatePresence } from 'framer-motion'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex } from 'theme-ui'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'
import useTypedSelector from '../../hooks/useTypedSelector'
import { menuActions } from '../../redux/modules/menu'
import { useAppDispatch } from '../../redux/store'
import theme from '../../styled/theme'
import { trackCourseLoginClick } from '../../utils/analytics/trackCourseLoginClick'
import BoxMotion from '../BoxMotion'
import ButtonIcon from '../ButtonIcon'
import Icon from '../Icon'
import Link from '../Link'
import LinkModalNewsletter from '../LinkModalNewsletter'
import MenuSection from '../MenuSection'

const MenuSmall = () => {
  // Refs
  const refContainer = useRef<HTMLDivElement>(null)
  const refFirstLevelContainer = useRef<HTMLDivElement>(null)
  const refSecondLevelContainer = useRef<HTMLDivElement>(null)

  // Redux
  const menuSmallVisible = useTypedSelector(state => state.menu.small.visible)
  const navigationHeaderSections = useTypedSelector(
    state => state.navigation?.header?.sections
  )
  const menuSmallActiveSection = useTypedSelector(
    state => state.menu.small.activeSection
  )
  const subscribeLabel =
    useTypedSelector(state => state.navigation.header.subscribeLabel) ??
    'Subscribe for ideas'
  const courseLoginUrl = useTypedSelector(state => state.system.courseLoginUrl)
  const everythingSearchState = useTypedSelector(
    state => state.everything.searchState
  )

  const router = useRouter()

  // State
  const dispatch = useAppDispatch()
  const [activeLevel, setActiveLevel] = useState(0)

  // Callbacks
  const handleClose = () => {
    dispatch(menuActions.setSmallVisible({ visible: false }))
  }

  const handleShowSection = (sectionType: MenuSectionType) => {
    dispatch(menuActions.setSmallActiveSection({ activeSection: sectionType }))
  }

  const handleTrackCourseLoginClick = () => {
    trackCourseLoginClick()
  }

  // Effects
  // - Reset form on route change
  useEffect(() => {
    router.events.on('routeChangeComplete', handleClose)

    return () => {
      router.events.off('routeChangeComplete', handleClose)
    }
  }, [])

  // - Reset second level scroll position (when active)
  useEffect(() => {
    if (activeLevel === 1) {
      if (refSecondLevelContainer?.current) {
        refSecondLevelContainer.current.scrollTo(0, 0)
      }
    }
  }, [activeLevel])

  // Close menu when 'everything' search state has changed (i.e. if a refinement link has been clicked) and the following conditions are met
  // - A menu section is current active
  useDeepCompareEffectNoCheck(() => {
    if (menuSmallActiveSection) {
      handleClose()
    }
  }, [everythingSearchState])

  return (
    <>
      {/* Background */}
      <AnimatePresence>
        {menuSmallVisible && (
          <BoxMotion
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            // theme-ui
            bg="white"
            sx={{
              bottom: 0,
              height: '100vh',
              left: 0,
              position: 'fixed',
              width: '100%',
              zIndex: 1,
            }}
          />
        )}
      </AnimatePresence>

      <AnimatePresence
        onExitComplete={() => {
          // Reset internal state
          setActiveLevel(0)
        }}
      >
        {menuSmallVisible && (
          <BoxMotion
            // framer-motion
            animate={{ opacity: 1, x: `${-activeLevel * 100}%` }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            transition={{
              damping: 30,
              stiffness: 250,
              type: 'spring',
            }}
            // theme-ui
            bg="white"
            ref={refContainer}
            sx={{
              bottom: 0,
              height: 'auto',
              left: 0,
              position: 'fixed',
              top: theme.sizes.headerHeight,
              width: '100%',
              zIndex: 'menu',
            }}
          >
            {/* First level */}
            <Flex
              bg="white"
              ref={refFirstLevelContainer}
              sx={{
                borderRight: '1px solid',
                borderColor: 'stone',
                flexDirection: 'column',
                height: '100%',
                left: 0,
                overflowY: 'auto',
                position: 'absolute',
                top: 0,
                width: '100%',
              }}
            >
              {/* Primary navigation */}
              <Box
                mt={2}
                p={4}
                sx={{
                  flex: 1,
                  position: 'relative',
                }}
              >
                {navigationHeaderSections?.map(section => {
                  if (section._type !== 'linkExternal') {
                    return (
                      <Box
                        key={section._type}
                        mb={3}
                        sx={{ cursor: 'default' }}
                      >
                        <Link
                          onClick={() => {
                            setActiveLevel(1)
                            handleShowSection(section._type)
                          }}
                          sx={{
                            cursor: 'pointer',
                            fontSize: 'l',
                            fontWeight: 'semibold',
                          }}
                          variant="opacity"
                        >
                          {section.title}
                        </Link>
                      </Box>
                    )
                  } else {
                    return (
                      <Box
                        key={section._type}
                        mb={3}
                        sx={{ cursor: 'default' }}
                      >
                        <Link
                          sx={{
                            cursor: 'pointer',
                            fontSize: 'l',
                            fontWeight: 'semibold',
                          }}
                          href={section.url}
                          rel="noopener noreferrer"
                          target={section.newWindow ? '_blank' : '_self'}
                          variant="opacity"
                        >
                          {section.title}
                        </Link>
                      </Box>
                    )
                  }
                })}

                {/* Course login URL */}
                {courseLoginUrl && (
                  <Box mt={9}>
                    <Link
                      href={courseLoginUrl}
                      onClick={handleTrackCourseLoginClick}
                      sx={{
                        alignItems: 'center',
                        display: 'inline-flex',
                        fontSize: 'xs',
                        fontWeight: 'semibold',
                        lineHeight: 'single',
                      }}
                      target="_blank"
                      variant="opacity"
                    >
                      <Box mr={2}>
                        <Icon type="learn" />
                      </Box>
                      Course Login
                    </Link>
                  </Box>
                )}
              </Box>

              {/* Subscribe link */}
              <Box
                // theme-ui
                sx={{
                  bottom: 0,
                  flexShrink: 0,
                  height: '110px',
                  overflow: 'hidden',
                  position: 'relative',
                  width: '100%',
                  svg: {
                    fill: 'racing',
                  },
                }}
              >
                <LinkModalNewsletter>
                  <BoxMotion
                    animate={{ y: '0%' }}
                    initial={{ y: '50%' }}
                    whileHover={{ opacity: 0.9 }}
                    transition={{
                      damping: 35,
                      stiffness: 250,
                      type: 'spring',
                    }}
                  >
                    <Box
                      color="white"
                      sx={{
                        bottom: 11,
                        fontSize: 'm',
                        fontWeight: 'semibold',
                        lineHeight: 'single',
                        left: '50%',
                        // pointerEvents: 'none',
                        position: 'absolute',
                        textAlign: 'center',
                        transform: 'translateX(-50%)',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {subscribeLabel}
                    </Box>
                    <svg
                      fill="none"
                      height="110"
                      preserveAspectRatio="none"
                      style={{ display: 'block' }}
                      width="100%"
                      viewBox="0 0 375 115"
                    >
                      <path d="M375 39.4053V115H0V51.5391C9.16401 43.4354 20.1053 36.5889 32.9199 30.997C86.5138 7.59425 142.369 -2.12071 200.521 0.383288C247.128 2.38559 292.819 9.84705 337.864 22.2673C352.266 26.2337 364.586 31.9808 375 39.4053Z" />
                    </svg>
                  </BoxMotion>
                </LinkModalNewsletter>
              </Box>
            </Flex>

            {/* Second level */}
            <Box
              bg="white"
              sx={{
                borderRight: '1px solid',
                borderColor: 'stone',
                height: '100%',
                left: '100%',
                position: 'absolute',
                top: 0,
                width: '100%',
              }}
            >
              <Box
                ref={refSecondLevelContainer}
                sx={{
                  height: '100%',
                  left: 0,
                  maskImage: `linear-gradient(
                  to bottom,
                rgba(255, 255, 255, 1) calc(100% - 80px),
                  rgba(255, 255, 255, 0) 100%
                )`,
                  overflowY: 'auto',
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                }}
              >
                {navigationHeaderSections?.map((section, index) => (
                  <Box
                    key={index}
                    pb={10} // Account for gradient mask
                    sx={{
                      display:
                        section._type === menuSmallActiveSection
                          ? 'block'
                          : 'none',
                      left: 0,
                      position: 'absolute',
                      top: 0,
                      width: '100%',
                      zIndex: section._type === menuSmallActiveSection ? 1 : 0,
                    }}
                  >
                    <Flex
                      bg="white"
                      sx={{
                        alignItems: 'center',
                        borderBottom: '1px solid',
                        borderColor: 'stone',
                        height: '52px',
                        justifyContent: 'space-between',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                      }}
                    >
                      <Box px={0}>
                        <ButtonIcon
                          iconSize="18px"
                          onClick={() => setActiveLevel(0)}
                          type="chevronLeft"
                        />
                      </Box>

                      <Box
                        sx={{
                          fontSize: 'm',
                          fontWeight: 'semibold',
                          left: '50%',
                          lineHeight: 'single',
                          position: 'absolute',
                          transform: 'translateX(-50%)',
                        }}
                      >
                        {section.title}
                      </Box>
                    </Flex>

                    <Box px={4} py={8}>
                      <MenuSection type={section._type} />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </BoxMotion>
        )}
      </AnimatePresence>
    </>
  )
}

export default MenuSmall
