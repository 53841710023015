import { IubendaPreferences } from '@types'
import { useEffect } from 'react'
import { iubendaActions } from '../../redux/modules/iubenda'
import { useAppDispatch } from '../../redux/store'

const IubendaConfig = () => {
  const dispatch = useAppDispatch()

  // Initialise iubenda after initial mount (as the script manipulates the DOM)
  useEffect(() => {
    window._iub = window._iub || []
    window._iub.csConfiguration = {
      banner: {
        acceptButtonDisplay: true,
        applyStyles: false,
        backgroundColor: 'white',
        customizeButtonDisplay: true,
        position: 'float-bottom-center',
        rejectButtonDisplay: true,
        content:
          '<div class="iubenda-banner-content iubenda-custom-content" role="document" tabindex="0"><div id="iubenda-cs-title">Notice</div><div id="iubenda-cs-paragraph"><p class="iub-p">We want to make this experience as valuable to you as we can. So we use cookies. Click accept to agree with our settings and dive straight in, or click learn more and customise if you’d like to read about them and set your own.</p></div></div>',
      },
      callback: {
        onPreferenceExpressedOrNotNeeded: function (
          preferences: IubendaPreferences
        ) {
          // Store iubenda preferences in redux store
          dispatch(iubendaActions.setPreferences(preferences))
        },
      },
      consentOnContinuedBrowsing: false,
      cookiePolicyId: process.env.NEXT_PUBLIC_IUBENDA_POLICY_ID,
      countryDetection: true,
      enableRemoteConsent: true,
      floatingPreferencesButtonDisplay: false,
      lang: 'en',
      perPurposeConsent: true,
      priorConsent: true,
      siteId: 2204503,
    }

    const script = document.createElement('script')
    script.async = true
    script.src = '//cdn.iubenda.com/cs/iubenda_cs.js'
    document.body.appendChild(script)
  }, [])

  return null
}

export default IubendaConfig
