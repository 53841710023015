import React from 'react'
import { Box, Flex } from 'theme-ui'
import DividerVertical from '../DividerVertical'
import H3 from '../H3'
import PortableTextBlockWrapper from '../PortableTextBlockWrapper'

type PortableTextBlockQuote = {
  node: {
    attribution?: string
    quote?: string
  }
  singleColumn?: boolean
}

const PortableTextBlockQuote = (props: PortableTextBlockQuote) => {
  const { singleColumn, node } = props

  return (
    <PortableTextBlockWrapper
      py={[6, null, null, 12]}
      my={[4, null, null, 8]}
      singleColumn={singleColumn}
    >
      <Flex
        mx={[4, null, null, 14]}
        sx={{
          alignItems: 'stretch',
          flexDirection: 'row',
          position: 'relative',
        }}
      >
        {/* Vertical divider */}
        <Box sx={{ height: '100%', left: 0, position: 'absolute' }}>
          <DividerVertical />
        </Box>

        <Box as="figure" ml={[8, null, null, 12]}>
          {/* Quote */}
          {node?.quote && <H3 as="blockquote">“{node.quote.trim()}”</H3>}

          {/* Attribution */}
          {node?.attribution && (
            <Box
              as="figcaption"
              mt={4}
              sx={{
                fontSize: ['xs', null, null, 's'],
                lineHeight: 'body',
              }}
            >
              {node.attribution.trim()}
            </Box>
          )}
        </Box>
      </Flex>
    </PortableTextBlockWrapper>
  )
}

export default PortableTextBlockQuote
