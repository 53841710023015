import { SanityImage } from '@types'
import imageUrlBuilder from '@sanity/image-url'
import Image, { ImageLoaderProps } from 'next/image'
import React from 'react'
import { sanityClientRead } from '../../lib/sanity'
import theme from '../../styled/theme'
import { findLastNonNullValue } from '../../utils/findLastNonNullValue'
import { useMemo } from 'react'

type ImageLayout = 'fill' | 'fixed' | 'intrinsic' | 'responsive'

export type Props = {
  image: SanityImage
  layout?: ImageLayout
  objectFit?: 'cover' | 'contain'
  sizes?: string | (string | null)[]
}

// Generate srcset sizes based off theme breakpoints
const generateSrcSetSizes = (sizes?: string | (string | null)[]) => {
  if (!sizes) {
    return undefined
  }

  if (typeof sizes === 'string') {
    return sizes
  }

  if (sizes.length === 1 && sizes[0] !== null) {
    return sizes[0]
  }

  return sizes
    .map((val, i) => {
      if (i === sizes.length - 1) {
        return sizes[i]
      }

      let current = val
      if (val === null) {
        current = findLastNonNullValue(sizes, i)
      }

      return `(max-width: ${theme.breakpoints?.[i]}) ${current}`
    })
    .join(', ')
}

const useSanityImage = (image: SanityImage) => {
  const imageProps = useMemo(() => {
    const builder = imageUrlBuilder(sanityClientRead())
    const urlFor = builder.image(image)

    const loader = ({ quality, width }: ImageLoaderProps) => {
      return (
        urlFor
          .width(width)
          .auto('format')
          .quality(quality || 75)
          .toString() || ''
      )
    }

    return {
      blurDataURL:
        urlFor //
          .auto('format')
          .blur(50)
          .quality(30)
          .width(64)
          .toString() || '',
      height: image?.dimensions?.height || undefined,
      loader,
      placeholder: 'blur' as 'blur',
      src:
        urlFor //
          .auto('format')
          .quality(20)
          .width(800)
          .toString() || '',
      width: image?.dimensions?.width || undefined,
    }
  }, [])

  return imageProps
}

const NextImage = (props: Props) => {
  const {
    image,
    layout = 'intrinsic',
    objectFit = 'cover',
    sizes = '100vw',
  } = props

  const imageProps = useSanityImage(image)

  if (!imageProps) {
    return null
  }

  // TODO: Strip out width + height props when using `layout === 'fill'`
  const filteredImageProps = { ...imageProps }
  if (layout === 'fill') {
    delete filteredImageProps.width
    delete filteredImageProps.height
  }

  if (!filteredImageProps?.src) {
    return null
  }

  return (
    <Image
      {...filteredImageProps}
      alt={image.altText}
      layout={layout}
      objectFit={objectFit}
      sizes={generateSrcSetSizes(sizes)}
    />
  )
}

export default NextImage
