import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Checkout } from '@types'
import {
  AttributeInput,
  CheckoutLineItemFragment,
  CurrencyCode,
} from '../../../types/codegen/shopify'
import { trackCartAddItem } from '../../../utils/analytics/trackCartAddItem'
import { trackCartRemoveItem } from '../../../utils/analytics/trackCartRemoveItem'
import checkoutGet from './thunks/checkoutGet'
import checkoutProceed from './thunks/checkoutProceed'
import lineItemAdd from './thunks/lineItemAdd'
import lineItemRemove from './thunks/lineItemRemove'
import lineItemUpdate from './thunks/lineItemUpdate'
import presentmentCurrencySet from './thunks/presentmentCurrencySet'

type StoreState = {
  checkout: Checkout
  checkoutUpdating: boolean
  customAttributes?: AttributeInput[]
  defaultCurrency?: string
  detectedCurrency?: CurrencyCode
  lineItemAdding: boolean
  lineItemRemoving: boolean
  lineItemUpdating: boolean
  presentmentCurrencies?: CurrencyCode[]
}

export const initialState = {
  checkout: {},
  checkoutUpdating: false,
  customAttributes: [],
  defaultCurrency: undefined,
  // TODO: geolocate and set on mount
  detectedCurrency: 'GBP' as CurrencyCode,
  lineItemAdding: false,
  lineItemRemoving: false,
  lineItemUpdating: false,
  presentmentCurrencies: undefined,
} as StoreState

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // Checkout: get
      .addCase(checkoutGet.pending, state => {
        state.checkoutUpdating = true
      })
      .addCase(checkoutGet.rejected, state => {

      })
      .addCase(
        checkoutGet.fulfilled,
        (state, action: PayloadAction<{ checkout: Checkout }>) => {
          state.checkout = action.payload.checkout
          state.checkoutUpdating = false
        }
      )

      // Checkout: proceed
      .addCase(checkoutProceed.pending, state => {
        state.checkoutUpdating = true
      })
      .addCase(checkoutProceed.rejected, state => {
        state.checkoutUpdating = false
      })
      .addCase(checkoutProceed.fulfilled, state => {
        state.checkoutUpdating = false
      })

      // Line items: add
      .addCase(lineItemAdd.pending, state => {
        state.checkoutUpdating = true
        state.lineItemAdding = true
      })
      .addCase(lineItemAdd.rejected, state => {
        state.checkoutUpdating = false
        state.lineItemAdding = false
      })
      .addCase(
        lineItemAdd.fulfilled,
        (
          state,
          action: PayloadAction<{
            checkout: Checkout
            lineItem: CheckoutLineItemFragment
          }>
        ) => {
          const lineItem = action?.payload?.lineItem

          // (Analytics)
          trackCartAddItem(lineItem)

          state.checkout = action.payload.checkout
          state.checkoutUpdating = false
          state.lineItemAdding = false
        }
      )

      // Line items: remove
      .addCase(lineItemRemove.pending, state => {
        state.checkoutUpdating = true
        state.lineItemRemoving = true
      })
      .addCase(lineItemRemove.rejected, state => {
        state.checkoutUpdating = false
        state.lineItemRemoving = false
      })
      .addCase(
        lineItemRemove.fulfilled,
        (
          state,
          action: PayloadAction<{
            checkout: Checkout
            lineItem: CheckoutLineItemFragment
          }>
        ) => {
          const lineItem = action?.payload?.lineItem

          // (Analytics)
          trackCartRemoveItem(lineItem)

          state.checkout = action.payload.checkout
          state.checkoutUpdating = false
          state.lineItemRemoving = false
        }
      )

      // Line items: update
      .addCase(lineItemUpdate.pending, state => {
        state.checkoutUpdating = true
        state.lineItemUpdating = true
      })
      .addCase(lineItemUpdate.rejected, state => {
        state.checkoutUpdating = false
        state.lineItemUpdating = false
      })
      .addCase(
        lineItemUpdate.fulfilled,
        (
          state,
          action: PayloadAction<{
            checkout: Checkout
            lineItem: CheckoutLineItemFragment
          }>
        ) => {
          const lineItem = action?.payload?.lineItem

          // TODO: add analytics hook for lineItem

          state.checkout = action.payload.checkout
          state.checkoutUpdating = false
          state.lineItemUpdating = false
        }
      )

      // Presentment currency: set
      .addCase(presentmentCurrencySet.pending, state => {
        state.checkoutUpdating = true
      })
      .addCase(presentmentCurrencySet.rejected, state => {
        state.checkoutUpdating = false
      })
      .addCase(
        presentmentCurrencySet.fulfilled,
        (state, action: PayloadAction<{ checkout: Checkout }>) => {
          state.checkout = action.payload.checkout
          state.checkoutUpdating = false
        }
      )
  },
})

export const storeActions = storeSlice.actions

export default storeSlice.reducer
