import { ColorTheme } from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import DividerHorizontalRandom from '../DividerHorizontalRandom'
import ListicleItem from '../ListicleItem'
import PortableTextBlockWrapper from '../PortableTextBlockWrapper'

type PortableTextBlockListicle = {
  colorTheme?: ColorTheme
  node: {
    items: ListicleItem[]
  }
  singleColumn?: boolean
}

const PortableTextBlockListicle = (props: PortableTextBlockListicle) => {
  const { colorTheme, singleColumn, node } = props

  if (!node.items) {
    return null
  }

  return (
    <PortableTextBlockWrapper
      singleColumn={singleColumn}
      my={[12, null, null, 16]}
    >
      {node?.items?.map((item, index) => {
        return (
          <Box key={index}>
            <ListicleItem colorTheme={colorTheme} item={item} />

            {index !== node.items.length - 1 && (
              <DividerHorizontalRandom
                color={colorTheme?.text}
                my={[6, null, null, 12]}
                sx={{
                  opacity: 0.2,
                }}
              />
            )}
          </Box>
        )
      })}
    </PortableTextBlockWrapper>
  )
}

export default PortableTextBlockListicle
