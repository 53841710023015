import React from 'react'
import { Box } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import { trackSearchSubmit } from '../../utils/analytics/trackSearchSubmit'
import Eyebrow from '../Eyebrow'
import SearchQueryLink from '../SearchQueryLink'
import Tag from '../Tag'

type Props = {}

const AlgoliaRecommendedSearches = () => {
  // Redux
  const recommendedSearches = useTypedSelector(
    state => state.system.recommendedSearches
  )

  if (!recommendedSearches) {
    return null
  }

  const handleSearchSubmit = (query: string) => {
    // Analytics
    trackSearchSubmit(query)
  }

  return (
    <Box>
      <Eyebrow>Popular searches</Eyebrow>
      <Box mt={3}>
        {recommendedSearches.map((search, index) => (
          <SearchQueryLink key={index} query={search}>
            <Tag
              isLink
              label={search}
              large
              mb={1}
              mr={1}
              onClick={handleSearchSubmit.bind(undefined, search)}
              tone="gray"
            />
          </SearchQueryLink>
        ))}
      </Box>
    </Box>
  )
}

export default AlgoliaRecommendedSearches
