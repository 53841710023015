import React, { ReactNode } from 'react'
import { FunctionComponent } from 'react'
import { Box, BoxOwnProps, ThemeUIStyleObject } from 'theme-ui'

type Props = {
  children: ReactNode
  singleColumn?: boolean
  sx?: ThemeUIStyleObject
}

const PortableTextBlockWrapper: FunctionComponent<BoxOwnProps & Props> = (
  props: Props
) => {
  const { singleColumn, children, sx, ...rest } = props

  return (
    <Box
      mx={singleColumn ? 'auto' : 0}
      px={singleColumn ? [4, null, null, 6] : 0}
      sx={{
        maxWidth: singleColumn ? 'moduleTextOneColMaxWidth' : 'none',
        position: 'relative',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default PortableTextBlockWrapper
