import BlockContent from '@sanity/block-content-to-react'
import { normalizeBlock } from '@sanity/block-tools'
import { SanityBlock } from '@types'
import React, { FunctionComponent } from 'react'
import { Box, BoxOwnProps, Text, ThemeUIStyleObject } from 'theme-ui'
import H1 from '../H1'
import isPortableTextGuard from '../../utils/isPortableTextGuard'

type Props = {
  sx?: ThemeUIStyleObject
  title: SanityBlock[] | string
}

const HeroTitle: FunctionComponent<BoxOwnProps & Props> = (props: Props) => {
  const { sx, title, ...rest } = props

  const blocks = isPortableTextGuard(title)
    ? title
    : [
        normalizeBlock({
          _type: 'block',
          children: [
            {
              _type: 'span',
              text: title,
            },
          ],
        }),
      ]

  return (
    <Box sx={{ maxWidth: '980px', ...sx }} {...rest}>
      <BlockContent
        blocks={blocks}
        renderContainerOnSingleChild
        serializers={{
          // Marks
          marks: {
            glyph: (props: any) => {
              return <Text sx={{ fontFamily: 'DO' }}>{props.children}</Text>
            },
          },
          // Block types
          types: {
            block: (props: any) => {
              const { children } = props
              return <H1>{children}</H1>
            },
          },
        }}
      />
    </Box>
  )
}

export default HeroTitle
