import React, { FunctionComponent, ReactNode } from 'react'
import { Box, BoxOwnProps, ThemeUIStyleObject } from 'theme-ui'

type Props = {
  children?: ReactNode
  sx?: ThemeUIStyleObject
}

const H3: FunctionComponent<BoxOwnProps> = (props: Props) => {
  const { children, sx, ...rest } = props
  return (
    <Box
      as="h3"
      sx={{
        fontSize: ['m', null, null, 'l'],
        fontWeight: 'semibold',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default H3
