import { ButtonVariant, Color, ShopifyProductVariant } from '@types'
import React from 'react'
import { encode } from 'shopify-gid'
import { ThemeUIStyleObject } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import lineItemAdd from '../../redux/modules/store/thunks/lineItemAdd'
import { systemActions } from '../../redux/modules/system'
import { useAppDispatch } from '../../redux/store'
import { AttributeInput, CurrencyCode } from '../../types/codegen/shopify'
import formatIntlCurrency from '../../utils/formatIntlCurrency'
import Button from '../Button'

type Props = {
  background?: Color
  color?: Color
  label?: string
  onClick?: () => void
  productVariant?: ShopifyProductVariant
  showPrice?: boolean
  significantFigures?: number
  singlePurchase?: boolean
  sx?: ThemeUIStyleObject
  variant?: ButtonVariant
}

const ButtonAddVariantToCart = (props: Props) => {
  const {
    background,
    color,
    label = 'Add to bag',
    onClick,
    productVariant,
    showPrice,
    significantFigures,
    singlePurchase,
    sx,
    variant,
  } = props



  // Store: redux
  const dispatch = useAppDispatch()
  const checkoutUpdating = useTypedSelector(
    state => state.store.checkoutUpdating
  )
  const defaultCurrency = useTypedSelector(
    state => state.store.defaultCurrency
  ) as CurrencyCode
  const lineItems = useTypedSelector(state => state.store.checkout.lineItems)
  const lineItemAdding = useTypedSelector(state => state.store.lineItemAdding)
  const lineItemVariantIds = lineItems?.edges?.map(
    lineItem => lineItem?.node?.variant?.id
  )

  let customAttributes: AttributeInput[]
  if (singlePurchase) {
    // Keys starting with underscores are 'private' and hidden from Shopify checkout
    customAttributes = [
      {
        key: '_singlePurchase',
        value: '1',
      },
    ]
  }

  // Base64 encode product variant ID for use with Storefront API
  const productVariantId = encode('ProductVariant', productVariant?.id)

  let availableForSale = true
  let buttonLabel = label
  let disabled = checkoutUpdating || lineItemAdding

  const singlePurchaseInCart =
    singlePurchase && lineItemVariantIds?.includes(productVariantId)

  // Callbacks
  const handleClick = () => {
    // Show cart if this is a single purchase item and is already
    // present in cart
    if (singlePurchaseInCart) {
      dispatch(systemActions.setCartVisible({ cartVisible: true }))
      return
    }

    dispatch(
      lineItemAdd({
        customAttributes,
        productVariantId,
        quantity: 1,
      })
    )

    if (onClick) {
      onClick()
    }
  }

  if (!productVariant) {
    disabled = true
  }

  if (productVariant && !productVariant.inStock) {
    availableForSale = false
    buttonLabel = 'Sold out'
    disabled = true
  }

  if (singlePurchaseInCart) {
    availableForSale = false
    buttonLabel = 'In your bag'
    disabled = false
  }

  const displayPrice =
    showPrice && defaultCurrency && productVariant?.price
      ? formatIntlCurrency({
        amount: productVariant.price,
        currencyCode: defaultCurrency,
        locale: 'en',
        significantFigures,
      })
      : undefined

  return (
    <Button
      background={background}
      color={color}
      disabled={disabled}
      onClick={handleClick}
      sx={sx}
      variant={variant}
    >
      {buttonLabel}

      {availableForSale && displayPrice && <> - {displayPrice}</>}
    </Button>
  )
}

export default ButtonAddVariantToCart
