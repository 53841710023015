import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { TeamMemberPreview } from '../../../types'

type TeamMemberPanelState = {
  teamMember?: TeamMemberPreview
  visible: boolean
}

export const initialState = {
  teamMember: undefined,
  visible: false,
} as TeamMemberPanelState

const teamMemberPanelSlice = createSlice({
  name: 'teamMemberPanel',
  initialState,
  reducers: {
    setVisible(
      state,
      action: PayloadAction<{
        teamMember?: TeamMemberPreview
        visible: boolean
      }>
    ) {
      if (action.payload?.teamMember) {
        state.teamMember = action.payload.teamMember
      }
      state.visible = action.payload?.visible
    },
  },
})

export const teamMemberPanelActions = teamMemberPanelSlice.actions

export default teamMemberPanelSlice.reducer
