import React from 'react'

const DividerVertical = () => {
  return (
    <svg
      height="100%"
      // preserveAspectRatio="xMidYMid meet"
      preserveAspectRatio="none"
      style={{ display: 'block' }}
      viewBox="0 0 8 216"
      width="6"
    >
      <path
        d="M3.14868 174.923C3.03063 171.32 2.89572 167.724 2.79453 164.121C2.6866 160.223 2.63601 156.318 2.52133 152.42C2.39653 148.205 2.23799 143.997 2.06597 139.797C1.83662 134.101 1.54992 128.428 1.34417 122.732C1.08783 115.609 0.794407 108.47 0.686474 101.324C0.561676 93.1202 0.615627 84.8938 0.60888 76.675C0.59876 67.8292 0.58865 58.9909 0.619005 50.1451C0.632496 46.2699 0.760651 42.4022 0.794379 38.5194C0.80787 37.1068 0.662853 35.6866 0.642616 34.2664C0.592022 30.9426 0.42337 27.566 0.571776 24.3026C0.828114 18.6748 0.247979 13.2812 0.234486 7.75919C0.231113 6.35413 0.305315 4.91886 0.423366 3.54402C0.5684 1.81413 1.3273 0.635699 1.96815 1.0965C2.63261 1.5724 2.76415 2.87926 2.74391 4.73001C2.71018 7.593 2.82148 10.4635 2.86195 13.3341C2.93953 18.4633 3.04071 23.585 3.07107 28.7142C3.08794 31.5998 2.85185 34.5384 2.95978 37.3787C3.206 43.8752 2.96315 50.3264 2.86533 56.7851C2.78439 62.0503 2.54829 67.2928 2.49433 72.5505C2.42013 79.7344 2.38978 86.9334 2.45723 94.1174C2.52132 100.863 2.71019 107.601 2.88558 114.34C3.05085 120.632 3.24648 126.925 3.47921 133.195C3.64448 137.659 3.92106 142.086 4.09645 146.543C4.26173 150.743 4.37302 154.95 4.4877 159.165C4.61924 164.015 4.71032 168.88 4.85873 173.73C5.04087 179.607 5.18927 185.514 5.50295 191.346C5.75591 196.075 6.22812 200.705 6.59913 205.381C6.82512 208.237 7.09832 211.077 7.23324 213.97C7.26022 214.575 6.80825 215.542 6.50806 215.972C6.39338 216.138 5.96504 215.406 5.89421 214.854C4.84525 207.021 4.10995 198.96 3.7187 190.658C3.47248 185.423 3.27009 180.173 3.05085 174.931C3.08121 174.946 3.11495 174.931 3.14868 174.923Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default DividerVertical
