import React from 'react'

const Path03 = () => {
  return (
    <>
      <path
        d="M1316 2.84939C1311.57 3.60166 1301.22 3.72217 1289.46 3.81827C1241.64 4.21381 1194.26 4.71783 1146.26 5.06742C1123.38 5.23456 1099.47 5.15512 1076.01 5.16491C1046.83 5.17562 1017.65 5.18954 988.386 5.14523C967.269 5.1146 946.062 4.98406 924.9 4.91791C890.005 4.80776 855.153 4.76197 820.259 4.60375C791.082 4.47025 761.818 4.17916 732.597 4.01334C679.693 3.71174 626.79 3.39733 573.93 3.18573C530.75 3.01302 487.076 2.77966 444.702 2.9323C380.984 3.16512 317.007 2.63999 253.115 2.43908C204.216 2.28332 155.317 2.18847 106.419 1.99425C75.0815 1.8702 43.7004 1.61448 12.3184 1.42287C1.73769 1.35934 1.15901 0.871892 0.128772 0.477892C-0.991035 0.0480991 4.99606 0.167388 9.9939 0.187779C32.6868 0.279152 55.4247 0.370793 78.0733 0.413824C114.005 0.482117 149.892 0.489252 185.823 0.563954C210.543 0.616215 235.352 0.761953 260.071 0.827033C281.594 0.88574 303.072 0.864061 324.595 0.919563C357.6 1.00554 390.783 1.18873 423.698 1.22289C455.802 1.25538 487.684 1.1295 519.744 1.12968C535.054 1.12865 550.632 1.25421 566.075 1.31489C595.522 1.43078 624.969 1.52424 654.416 1.65937C687.78 1.8116 721.232 2.03169 754.596 2.15829C801.288 2.33285 847.935 2.46227 894.583 2.58848C926.822 2.67627 959.106 2.77715 991.211 2.7808C1034.71 2.78554 1078.2 2.78069 1121.34 2.63905C1157.05 2.52332 1192.13 2.18267 1227.66 1.99214C1249.5 1.87648 1271.61 1.8105 1293.77 1.8185C1298.86 1.82021 1304.75 2.20495 1309.97 2.43821C1312.44 2.54922 1314.11 2.71625 1316 2.84939Z"
        fill="currentColor"
      />
    </>
  )
}

export default Path03
