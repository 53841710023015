import React from 'react'

const Path02 = () => {
  return (
    <>
      <path
        // relative coords
        d="M0.965807 0.332379C1.03838 0.525907 0.996242 0.777194 0.83158 0.899462C0.667699 1.02173 0.381298 1.01498 0.204151 0.88596C0.0262229 0.756941 -0.0432314 0.505654 0.0270033 0.315876C0.0972379 0.125348 0.307162 -0.00367089 0.50616 7.96628e-05C0.705158 0.00308011 0.893231 0.13885 0.965807 0.332379Z"
        fill="currentColor"
      />
    </>
  )
}

export default Path02
