import React from 'react'

const Path04 = () => {
  return (
    <>
      <path d="M45.8658 92.6405C44.7376 91.7324 43.5607 90.8891 42.4974 89.8999C35.9799 83.8594 30.3471 77.1215 26.3375 69.143C16.0133 48.6294 21.1754 27.0373 39.9732 13.8048C54.3881 3.65337 70.329 1.28579 86.9597 7.16421C112.072 16.0345 122.981 42.5158 112.372 67.7403C109.613 74.2998 105.83 80.2107 101.204 85.6026C100.279 86.681 99.4023 87.7999 98.201 89.2756C105.271 90.2162 111.821 90.954 118.322 92C122.956 92.7378 127.567 93.7351 132.12 94.9027C134.798 95.5919 137.436 96.6459 139.904 97.9027C147.062 101.559 150.804 108.492 149.854 116.446C149.473 119.624 148.743 122.819 147.736 125.86C144.944 134.244 139.262 139.092 130.302 140.625C111.147 143.892 91.9514 146.454 72.488 146.917C54.7859 147.338 37.2217 146.179 19.7875 143.049C12.6125 141.76 6.87413 138.338 3.01067 132.046C-1.61574 124.506 -0.869042 115.87 4.96673 109.205C7.83185 105.938 11.395 103.635 15.3234 101.868C23.0016 98.4135 31.0126 96.0622 39.3239 94.7162C41.353 94.3919 43.366 94.0027 45.387 93.6459C45.5493 93.3216 45.7116 92.9811 45.8658 92.6405Z" />
    </>
  )
}

export default Path04
