import {
  ArticlePreview,
  EventPreview,
  ManifestoPreview,
  OnlineCoursePreview,
  PlaylistPreview,
  ProductPreview,
  ReadingListPreview,
  Reference,
  TalkPreview,
  WorkshopPreview,
} from '@types'
import NextLink from 'next/link'
import React from 'react'
import { Text } from 'theme-ui'
import { aliasDocumentType } from '../../utils/aliasDocumentType'
import { trackCategoryTagClick } from '../../utils/analytics/trackCategoryTagClick'
import getRefinementFromReference from '../../utils/getRefinementFromReference'
import Link from '../Link'
import RefinementLink from '../RefinementLink'

type Props = {
  item:
    | ArticlePreview
    | EventPreview
    | ManifestoPreview
    | OnlineCoursePreview
    | PlaylistPreview
    | ProductPreview
    | ReadingListPreview
    | TalkPreview
    | WorkshopPreview
  speakerPrefix?: string
}

const ReferencesPrimary = (props: Props) => {
  const { item, speakerPrefix } = props

  // Speaker
  let firstCategory
  let firstSpeaker
  let references: Reference[] = []

  if (item._type === 'article') {
    firstCategory = item?.categories?.[0]
    firstSpeaker = item?.speakers?.[0]
    references = [
      ...references,
      // First category
      ...(firstCategory ? [firstCategory] : []),
    ]
  }
  if (item._type === 'event') {
    firstCategory = item?.categories?.[0]
    references = [
      ...references,
      // First category
      ...(firstCategory ? [firstCategory] : []),
    ]
  }
  if (item._type === 'manifesto') {
    firstCategory = item?.categories?.[0]
    firstSpeaker = item?.speakers?.[0]
    references = [
      ...references,
      // First category
      ...(firstCategory ? [firstCategory] : []),
    ]
  }
  if (item._type === 'onlineCourse') {
    firstCategory = item?.categories?.[0]
    firstSpeaker = item?.speakers?.[0]
    references = [
      ...references,
      // Online course type
      // ...(item?.onlineCourseType ? [item.onlineCourseType] : []),
      // First category
      ...(firstCategory ? [firstCategory] : []),
    ]
  }
  if (item._type === 'product') {
    firstCategory = item?.categories?.[0]
    const productType = item?.productType
    references = [
      ...references,
      // Product type
      ...(productType ? [productType] : []),
      // First category
      ...(firstCategory ? [firstCategory] : []),
    ]
  }
  if (item._type === 'readingList') {
    firstCategory = item?.categories?.[0]
    firstSpeaker = item?.speakers?.[0]
    references = [
      ...references,
      // First category
      ...(firstCategory ? [firstCategory] : []),
    ]
  }
  if (item._type === 'talk') {
    firstCategory = item?.categories?.[0]
    firstSpeaker = item?.speakers?.[0]
    references = [
      ...references,
      // First category
      ...(firstCategory ? [firstCategory] : []),
    ]
  }
  if (item._type === 'workshop') {
    firstCategory = item?.categories?.[0]
    firstSpeaker = item?.speakers?.[0]
    references = [
      ...references,
      // First category
      ...(firstCategory ? [firstCategory] : []),
    ]
  }

  // Filter out null values
  const filteredReferences = references.filter(v => v !== null)

  const handleClick = (reference: Reference) => {
    const title = reference.title
    switch (reference._type) {
      case 'category':
        // Analytics
        trackCategoryTagClick(title)
        break
      default:
        break
    }
  }

  let points = []

  // First speaker
  if (firstSpeaker) {
    points.push(
      <>
        {speakerPrefix && `${speakerPrefix} `}
        <NextLink href={firstSpeaker.slug} passHref>
          <Link variant="simple">{firstSpeaker.name}</Link>
        </NextLink>
      </>
    )
  }

  // References
  filteredReferences?.forEach(reference => {
    const refinement = getRefinementFromReference(reference)
    points.push(
      <RefinementLink
        refinements={[
          refinement,
          {
            name: 'type',
            slug: 'type',
            value: aliasDocumentType(item._type),
          },
        ]}
      >
        <Link onClick={handleClick.bind(undefined, reference)} variant="simple">
          {reference.title}
        </Link>
      </RefinementLink>
    )
  })

  return points.reduce((acc, val, index) => {
    const Point = () => val
    acc.push(<Point key={index} />)

    if (index !== points.length - 1) {
      acc.push(
        <Text key={`divider-${index}`} px="0.4em" sx={{ fontFamily: 'Arial' }}>
          •
        </Text>
      )
    }
    return acc
  }, [] as any)
}

export default ReferencesPrimary
