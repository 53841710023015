import { ArtboardProps } from '@types'
import React from 'react'
import { Box, ThemeProvider } from 'theme-ui'
import theme from '../../styled/theme'
import BoxMotion from '../BoxMotion'
import Mark from '../Mark'
import Shape from '../Shape'

type Props = {
  artboard?: ArtboardProps
  scaleMultiplier?: number
}

const DEBUG_MODE = false

const Artboard = (props: Props) => {
  const { artboard, scaleMultiplier = 1 } = props

  if (!artboard) {
    return null
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        bg={artboard?.colorTheme?.background || 'white'}
        sx={{
          height: '100%',
          left: 0,
          overflow: 'hidden',
          position: 'absolute',
          top: 0,
          width: '100%',
        }}
      >
        {/* Shape */}
        {artboard?.shape && (
          <Box
            sx={{
              border: DEBUG_MODE && '1px solid rgba(255, 255, 255, 0.1)',
              left: `${artboard?.shape?.x}%`,
              position: 'absolute',
              top: `${artboard?.shape?.y}%`,
              transform: `translate(-50%, -50%)`,
              width: ['200px', null, '400px'],
            }}
          >
            <Box
              sx={{
                border: DEBUG_MODE && '1px solid rgba(255, 255, 255, 0.1)',
                position: 'relative',
                transform: `
                  rotate(${artboard?.shape.rotation}deg) 
                  scale(${artboard?.shape.scale * scaleMultiplier}) 
                `,
              }}
            >
              <BoxMotion
                animate={{ opacity: 1, rotate: 0, y: '0%' }}
                initial={{ opacity: 0, rotate: 4, y: '-5%' }}
                transition={{ duration: 1 }}
              >
                <Shape
                  color={artboard?.colorTheme?.shape || 'black'}
                  patternIndex={artboard?.shape?.patternIndex}
                />
              </BoxMotion>
            </Box>
            {/* Debug info */}
            {DEBUG_MODE && (
              <Box
                color="white"
                sx={{
                  fontSize: 'xxs',
                  left: '50%',
                  opacity: 0.75,
                  position: 'absolute',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                color (shape):{' '}
                {artboard?.colorTheme?.shape?.toString() || 'black'}
                <br />
                rotation: {artboard?.shape.rotation}
                <br />
                scale: {artboard?.shape.scale}
                <br />
                translate: ({artboard?.shape.x}, {artboard?.shape.y})
              </Box>
            )}
          </Box>
        )}

        {/* Mark */}
        {artboard?.mark && (
          <Box
            sx={{
              border: DEBUG_MODE && '1px solid rgba(255, 255, 255, 0.1)',
              left: `${artboard?.mark?.x}%`,
              position: 'absolute',
              top: `${artboard?.mark?.y}%`,
              transform: `translate(-50%, -50%)`,
              width: ['75px', null, '120px'],
            }}
          >
            <Box
              sx={{
                border: DEBUG_MODE && '1px solid rgba(255, 255, 255, 0.1)',
                position: 'relative',
                transform: `
                  rotate(${artboard?.mark.rotation}deg) 
                  scale(${artboard?.mark.scale * scaleMultiplier}) 
                `,
              }}
            >
              <BoxMotion
                animate={{ opacity: 1, rotate: 0, y: '0%' }}
                initial={{ opacity: 0, rotate: -2, y: '5%' }}
                transition={{
                  delay: 0.25,
                  duration: 1,
                }}
              >
                <Mark
                  color={artboard?.colorTheme?.mark || 'black'}
                  patternIndex={artboard?.mark?.patternIndex}
                />
              </BoxMotion>
            </Box>
            {/* Debug info */}
            {DEBUG_MODE && (
              <Box
                color="white"
                sx={{
                  fontSize: 'xxs',
                  left: '50%',
                  opacity: 0.75,
                  position: 'absolute',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                color (mark):{' '}
                {artboard?.colorTheme?.mark?.toString() || 'black'}
                <br />
                rotation: {artboard?.mark.rotation}deg
                <br />
                scale: {artboard?.mark.scale}
                <br />
                translate: ({artboard?.mark.x}%, {artboard?.mark.y}%)
              </Box>
            )}
          </Box>
        )}
      </Box>
    </ThemeProvider>
  )
}

export default Artboard
