import { intervalToDuration } from 'date-fns'
import React, { useMemo } from 'react'
import Tag from '../Tag'

export type Props = {
  duration: number
}

const leftPad = (num: number) => {
  return String(num).padStart(2, '0')
}

const VideoTimecode = (props: Props) => {
  const { duration } = props

  const timecode = useMemo(() => {
    const { hours = 0, minutes = 0, seconds = 0 } = intervalToDuration({
      start: 0,
      end: duration * 1000,
    })

    const durations: (number | string)[] = []
    if (hours > 0) {
      durations.push(hours)
    }
    if (minutes >= 0) {
      durations.push(hours > 0 ? leftPad(minutes) : minutes)
    }
    if (seconds >= 0) {
      durations.push(leftPad(seconds))
    }

    return durations.join(':')
  }, [])

  return <Tag label={timecode} tone="dark" />
}

export default VideoTimecode
