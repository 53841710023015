import React from 'react'
import { connectStateResults } from 'react-instantsearch-dom'
import AlgoliaCustomInfiniteHits from '../AlgoliaCustomInfiniteHitsGrid'

type Props = {
  allSearchResults: any
  children?: any
  error: any
  searchResults: any
  searchState: any
  // custom props,
  allowPagination?: boolean
  columnGap?: (number | null) | (number | null)[]
  gridTemplateColumns?: (string | null)[]
  hitComponent: any
  rowGap?: (number | null) | (number | null)[]
}

const CustomResults = (props: Props) => {
  const {
    allSearchResults,
    children,
    error,
    searchResults,
    searchState,
    // custom props,
    allowPagination,
    columnGap,
    gridTemplateColumns,
    hitComponent,
    rowGap,
  } = props

  return (
    <>
      {allSearchResults && allSearchResults.nbHits > 0 && (
        <AlgoliaCustomInfiniteHits
          allowPagination={allowPagination}
          columnGap={columnGap}
          gridTemplateColumns={gridTemplateColumns}
          hitComponent={hitComponent}
          rowGap={rowGap}
        />
      )}
    </>
  )
}

const AlgoliaCustomResults = connectStateResults(CustomResults)

export default AlgoliaCustomResults
