import BlockContent from '@sanity/block-content-to-react'
import { ColorTheme, SanityBlock } from '@types'
import { linearGradient, rgba } from 'polished'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Box, BoxOwnProps, Text, ThemeUIStyleObject } from 'theme-ui'
import { COLORS } from '../../constants'
import BoxMotion from '../BoxMotion'
import Button from '../Button'
import Link from '../Link'
import LinkSanity from '../LinkSanity'
import PortableTextBlock from '../PortableTextBlock'
import PortableTextBlockButtons from '../PortableTextBlockButtons'
import PortableTextBlockImage from '../PortableTextBlockImage'
import PortableTextBlockImagePair from '../PortableTextBlockImagePair'
import PortableTextBlockListicle from '../PortableTextBlockListicle'
import PortableTextBlockQuote from '../PortableTextBlockQuote'
import PortableTextList from '../PortableTextList'

type Props = {
  blocks: SanityBlock[]
  collapsible?: boolean
  collapsibleHeight?: number
  colorTheme?: ColorTheme
  singleColumn?: boolean
  sx?: ThemeUIStyleObject
  variant?: 'body'
}

const portableTextMarks = {
  annotationLinkEmail: (props: any) => {
    return (
      <Link href={`mailto:${props?.mark?.email}`} variant="underline">
        {props.children}
      </Link>
    )
  },
  annotationLinkExternal: (props: any) => {
    return (
      <Link
        href={props?.mark?.url}
        rel="noopener noreferrer"
        target="_blank"
        variant="underline"
      >
        {props.children}
      </Link>
    )
  },
  annotationLinkInternal: (props: any) => {
    const { children, mark } = props

    return (
      <LinkSanity link={mark} variant="underline">
        {children}
      </LinkSanity>
    )
  },
  glyph: (props: any) => {
    return <Text sx={{ fontFamily: 'DO' }}>{props.children}</Text>
  },
  strong: (props: any) => {
    return <strong>{props.children}</strong>
  },
}

const PortableText: FunctionComponent<BoxOwnProps & Props> = (props: Props) => {
  const {
    blocks,
    collapsible,
    collapsibleHeight = 400, // px
    colorTheme,
    singleColumn,
    sx,
    variant,
    ...rest
  } = props

  const [collapsed, setCollapsed] = useState(collapsible)
  const [needsCollapsing, setNeedsCollapsing] = useState<boolean>()
  const refContent = useRef<HTMLDivElement>(null)

  const themeColor = COLORS[colorTheme?.background || 'white']

  const gradientColorStops = [
    `${rgba(themeColor, 1.0)} 25%`,
    `${rgba(themeColor, 0)} 100%`,
  ]

  useEffect(() => {
    if (refContent?.current) {
      const contentHeight = refContent.current.getBoundingClientRect().height

      const needsCollapsing = contentHeight > collapsibleHeight
      setNeedsCollapsing(needsCollapsing)
    }
  }, [refContent])

  return (
    <BoxMotion
      // framer-motion
      animate={{
        height: collapsed ? `${collapsibleHeight}px` : 'auto',
      }}
      initial={{
        height: collapsible ? `${collapsibleHeight}px` : 'auto',
      }}
      transition={{
        damping: 35,
        stiffness: 130,
        type: 'spring',
      }}
      // theme-ui
      sx={{
        overflow: collapsible ? 'hidden' : 'visible',
        position: 'relative',
        ...(variant === 'body'
          ? {
              li: {
                fontSize: 's',
              },
              p: {
                fontSize: 's',
                lineHeight: 'body',
                mb: '1.4em',
              },
            }
          : {}),
        ...sx,
      }}
      {...rest}
    >
      <Box ref={refContent}>
        <BlockContent
          blocks={blocks}
          className="block-content"
          renderContainerOnSingleChild
          serializers={{
            // Lists
            list: (props: PortableTextList) => (
              <PortableTextList singleColumn={singleColumn} {...props} />
            ),
            // Marks
            marks: portableTextMarks,
            // Block types
            types: {
              block: (props: PortableTextBlock) => (
                <PortableTextBlock singleColumn={singleColumn} {...props} />
              ),
              blockButtons: (props: PortableTextBlockButtons) => (
                <PortableTextBlockButtons
                  colorTheme={colorTheme}
                  singleColumn={singleColumn}
                  {...props}
                />
              ),
              blockImage: (props: PortableTextBlockImage) => (
                <PortableTextBlockImage
                  colorTheme={colorTheme}
                  singleColumn={singleColumn}
                  {...props}
                />
              ),
              blockImagePair: (props: PortableTextBlockImagePair) => (
                <PortableTextBlockImagePair {...props} />
              ),
              blockListicle: (props: PortableTextBlockListicle) => (
                <PortableTextBlockListicle
                  colorTheme={colorTheme}
                  singleColumn={singleColumn}
                  {...props}
                />
              ),
              blockQuote: (props: PortableTextBlockQuote) => (
                <PortableTextBlockQuote
                  singleColumn={singleColumn}
                  {...props}
                />
              ),
            },
          }}
        />
      </Box>

      {/* Gradient */}
      {collapsible && (
        <BoxMotion
          // framer-motion
          animate={{
            opacity: needsCollapsing && collapsed ? 1 : 0,
          }}
          initial={{
            opacity: needsCollapsing && collapsed ? 1 : 0,
          }}
          transition={{
            damping: 35,
            stiffness: 110,
            type: 'spring',
          }}
          // theme-ui
          sx={{
            ...linearGradient({
              colorStops: gradientColorStops,
              toDirection: 'to top',
              fallback: 'transparent',
            }),
            bottom: 0,
            height: '150px',
            pointerEvents: 'none',
            position: 'absolute',
            width: '100%',
          }}
        />
      )}
      {collapsible && collapsed && needsCollapsing && (
        <Box sx={{ bottom: 0, position: 'absolute' }}>
          <Button
            background={colorTheme?.text}
            color={colorTheme?.background}
            onClick={() => setCollapsed(false)}
            variant="outline"
          >
            See more
          </Button>
        </Box>
      )}
    </BoxMotion>
  )
}

export default PortableText
