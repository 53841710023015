import * as Sentry from '@sentry/react'
import { decode } from 'shopify-gid'
import { CheckoutLineItemFragment } from '../../types/codegen/shopify'

// Product added to cart
export const trackCartAddItem = (lineItem: CheckoutLineItemFragment) => {
  if (!lineItem) {
    Sentry.captureMessage(
      'A user has tried adding an item to their cart, but no valid item was returned via Shopify. Please ensure that the product has been added to the correct sales channel.'
    )
    return
  }

  // GTM
  // https://developers.google.com/tag-manager/enhanced-ecommerce#add
  // TODO: Conditionally add actionField (e.g. Added from PDP, PLP etc)
  if (window.dataLayer) {
    window.dataLayer.push({
      ecommerce: {
        add: {
          /*
          actionField: {
            list: ''
          },
          */
          products: [
            {
              // brand: '',
              // category: '',
              name: lineItem.title,
              id: decode(lineItem?.variant?.id)?.id, // Variant ID
              price:
                lineItem?.variant?.presentmentPrices?.edges[0]?.node?.price
                  ?.amount, // TODO: refactor
              quantity: 1,
              variant: lineItem?.variant?.title,
            },
          ],
        },
        currencyCode:
          lineItem?.variant?.presentmentPrices?.edges[0]?.node?.price
            ?.currencyCode, // TODO: refactor
      },
      event: 'eec.add',
    })
  }
}
