import React, { ReactNode } from 'react'
import { SanityBlock } from '@types'
import { Paragraph } from 'theme-ui'
import Eyebrow from '../Eyebrow'
import H2 from '../H2'
import H3 from '../H3'
import H4 from '../H4'

type PortableTextBlock = {
  children: ReactNode
  node: SanityBlock
  singleColumn?: boolean
}

const PortableTextBlock = (props: PortableTextBlock) => {
  const { singleColumn, children, node } = props

  if (node.style === 'eyebrow') {
    return (
      <Eyebrow
        mb={3}
        mt={5}
        mx={singleColumn ? 'auto' : 0}
        px={singleColumn ? [4, null, null, 6] : 0}
        sx={{
          maxWidth: singleColumn ? 'moduleTextOneColMaxWidth' : 'none',
        }}
      >
        {children}
      </Eyebrow>
    )
  }

  if (node.style === 'h2') {
    return (
      <H2
        mx={singleColumn ? 'auto' : 0}
        my={6}
        px={singleColumn ? [4, null, null, 6] : 0}
        sx={{
          maxWidth: singleColumn ? 'moduleTextOneColMaxWidth' : 'none',
        }}
      >
        {children}
      </H2>
    )
  }

  if (node.style === 'h3') {
    return (
      <H3
        mb={3}
        mt={8}
        mx={singleColumn ? 'auto' : 0}
        px={singleColumn ? [4, null, null, 6] : 0}
        sx={{
          maxWidth: singleColumn ? 'moduleTextOneColMaxWidth' : 'none',
        }}
      >
        {children}
      </H3>
    )
  }

  if (node.style === 'h4') {
    return (
      <H4
        mb={3}
        mt={5}
        mx={singleColumn ? 'auto' : 0}
        px={singleColumn ? [4, null, null, 6] : 0}
        sx={{
          maxWidth: singleColumn ? 'moduleTextOneColMaxWidth' : 'none',
        }}
      >
        {children}
      </H4>
    )
  }

  return (
    <Paragraph
      mx={singleColumn ? 'auto' : 0}
      mb={2}
      mt={4}
      px={singleColumn ? [4, null, null, 6] : 0}
      sx={{
        maxWidth: singleColumn ? 'moduleTextOneColMaxWidth' : 'none',
      }}
    >
      {children}
    </Paragraph>
  )

  // (Optional) Fall back to default handling
  // return BlockContent.defaultSerializers.types.block(props)
}

export default PortableTextBlock
