import React, { forwardRef, RefObject } from 'react'
import { ChangeHandler, FieldError } from 'react-hook-form'
import { Box, Input, ThemeUIStyleObject } from 'theme-ui'
import theme from '../../styled/theme'

type Props = {
  autoComplete?: string
  description?: string
  dirty?: boolean
  disabled: boolean
  honeypot?: boolean
  error?: FieldError
  label?: string
  name: string
  onBlur: ChangeHandler
  onChange: ChangeHandler
  placeholder?: string
  ref: RefObject<HTMLInputElement>
  sx?: ThemeUIStyleObject
  tabIndex?: number
  variant?: 'default' | 'eyebrow'
}

type Ref = HTMLInputElement

const FormFieldTextInput = forwardRef<Ref, Props>((props, ref) => {
  const {
    autoComplete = 'off',
    description,
    dirty,
    disabled,
    error,
    honeypot,
    label,
    name,
    onBlur,
    onChange,
    placeholder,
    sx,
    tabIndex,
    variant = 'default',
  } = props

  return (
    <Box
      sx={{
        ...sx,
        display: honeypot ? 'none' : 'block',
      }}
    >
      {/* Label */}
      {label && (
        <>
          <label htmlFor={name}>{label}</label>
          <br />
        </>
      )}

      {/* Description */}
      {description && <Box my={1}>{description}</Box>}

      {/* Input */}
      <Input
        autoComplete={honeypot ? 'off' : autoComplete}
        disabled={disabled}
        id={name}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        ref={ref}
        sx={{
          px: 4,
          ...(variant === 'default'
            ? {
                borderRadius: '100px',
                fontFamily: 'sans',
                fontSize: 's',
                height: '36px',
                lineHeight: '36px',
                textAlign: ['center', null, null, 'left'],
              }
            : {}),
          ...(variant === 'eyebrow'
            ? {
                border: 'none',
                borderBottom: '1px solid',
                borderColor: 'stone',
                borderRadius: 0,
                fontSize: 'xxs',
                fontWeight: 'semibold',
                lineHeight: '2em',
                textAlign: 'center',
                textTransform: 'uppercase',
                // Force computed values for letter-spacing
                '--letter-spacing': theme.letterSpacings.loose,
                letterSpacing: 'var(--letter-spacing)',
              }
            : {}),
          '::placeholder': {
            color: 'currentColor',
            opacity: 0.5,
          },
          '&:disabled': {
            opacity: 0.5,
          },
          // '&:focus': {
          //   color: alpha('white', 1.0),
          // },
        }}
        tabIndex={honeypot ? -1 : tabIndex}
      />

      {/* Error */}
      {error && (
        <Box my={2} sx={{ fontSize: 'xxs', textAlign: 'center' }}>
          {error.message}
        </Box>
      )}
    </Box>
  )
})

export default FormFieldTextInput
