import React from 'react'

import useTypedSelector from '../../hooks/useTypedSelector'
import lineItemRemove from '../../redux/modules/store/thunks/lineItemRemove'
import lineItemUpdate from '../../redux/modules/store/thunks/lineItemUpdate'
import { useAppDispatch } from '../../redux/store'
import ButtonIcon from '../ButtonIcon'

type Props = {
  disabled?: boolean
  productVariantId: string
  quantity: number
}

const ButtonRemoveSingleQuantity = (props: Props) => {
  const { disabled, productVariantId, quantity } = props

  // Store: redux
  const dispatch = useAppDispatch()
  const checkoutUpdating = useTypedSelector(
    state => state.store.checkoutUpdating
  )
  const lineItemRemoving = useTypedSelector(
    state => state.store.lineItemRemoving
  )
  const lineItemUpdating = useTypedSelector(
    state => state.store.lineItemUpdating
  )

  // Callbacks
  const handleUpdate = () => {
    if (quantity - 1 === 0) {
      dispatch(lineItemRemove({ productVariantId }))
    } else {
      dispatch(lineItemUpdate({ productVariantId, quantity: quantity - 1 }))
    }
  }

  return (
    <ButtonIcon
      disabled={
        checkoutUpdating || disabled || lineItemRemoving || lineItemUpdating
      }
      iconSize={12}
      onClick={handleUpdate}
      size={12}
      type="minus"
    />
  )
}

export default ButtonRemoveSingleQuantity
