import { Color, IconType } from '@types'
import { rgba } from 'polished'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { Box, IconButton, ThemeUIStyleObject } from 'theme-ui'
import { COLORS } from '../../constants'
import Icon from '../Icon'

export type Props = {
  background?: Color
  borderColor?: Color
  color?: Color
  disabled?: boolean
  hoverVariant?: 'background' | 'simple'
  onClick?: () => void
  iconSize?: number | number[] | string | string[]
  size?: number | number[] | string | string[]
  sx?: ThemeUIStyleObject
  transparent?: boolean
  type: IconType
}

const ButtonIcon = (props: Props) => {
  const {
    background,
    borderColor,
    color = 'currentColor',
    disabled,
    hoverVariant = 'simple',
    iconSize = '20px',
    onClick,
    size = '40px',
    sx,
    transparent,
    type,
  } = props

  const refContainer = useRef<HTMLButtonElement>(null)
  const [computedColor, setComputedColor] = useState<string>()

  useEffect(() => {
    if (refContainer.current) {
      const computedColor = window
        .getComputedStyle(refContainer.current)
        .getPropertyValue('color')
      setComputedColor(computedColor)
    }
  }, [])

  return (
    <IconButton
      bg={
        background && (transparent ? rgba(COLORS[background], 0.2) : background)
      }
      onClick={disabled ? undefined : onClick}
      p={0}
      ref={refContainer}
      sx={{
        alignItems: 'center',
        backdropFilter: transparent ? 'blur(20px)' : 'none',
        borderColor,
        borderRadius: '100px',
        cursor: disabled ? 'default' : 'pointer',
        display: 'flex',
        height: size,
        justifyContent: 'center',
        opacity: disabled ? 0.5 : 1,
        // transition: 'opacity 150ms',
        width: size,
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover:not(:disabled)': {
            ...(hoverVariant === 'background' && {
              bg: computedColor && rgba(computedColor, 0.2),
            }),
            ...(hoverVariant === 'simple' && {
              opacity: 0.5,
            }),
          },
        },
        ...sx,
      }}
    >
      <Box
        color={color}
        sx={{
          height: iconSize,
          width: iconSize,
        }}
      >
        <Icon type={type} />
      </Box>
    </IconButton>
  )
}

export default ButtonIcon
