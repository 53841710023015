import React from 'react'

const Path18 = () => {
  return (
    <>
      <path d="M37.8996 0.400134C38.9996 0.200134 40.4996 1.20013 42.0996 1.00013C42.7996 2.00013 44.1996 1.50013 44.8996 2.50013C45.4996 3.40013 46.2996 4.10013 46.7996 5.00013C47.4996 6.20013 47.7996 7.60013 47.9996 9.00013C48.0996 9.70013 48.5996 10.4001 48.1996 11.1001C47.5996 12.2001 47.5996 13.6001 47.0996 14.8001C46.5996 15.8001 45.6996 16.7001 45.3996 17.6001C44.9996 18.6001 43.9996 19.0001 43.3996 19.3001C42.1996 19.9001 40.7996 20.8001 39.1996 20.4001C37.6996 20.2001 36.0996 20.4001 34.6996 19.8001C33.4996 19.3001 32.0996 19.1001 31.5996 17.6001C31.3996 17.0001 30.4996 16.6001 30.1996 16.0001C29.4996 13.9001 28.9996 11.8001 29.7996 9.70013C29.9996 9.10013 29.9996 8.50013 30.1996 7.80013C30.3996 6.30013 31.3996 5.20013 31.8996 4.00013C32.0996 3.50013 31.9996 3.10013 32.2996 2.80013C33.0996 1.90013 36.3996 0.400134 37.8996 0.400134ZM38.6996 15.4001C39.1996 15.2001 39.7996 15.4001 40.2996 15.0001C41.4996 14.4001 42.1996 13.4001 42.6996 12.2001C43.1996 11.5001 43.3996 10.5001 43.3996 9.60013C43.2996 7.60013 42.2996 6.60013 40.3996 5.90013C39.3996 5.50013 38.3996 5.70013 37.3996 5.40013C36.9996 5.20013 36.7996 5.50013 36.9996 5.90013C37.0996 6.10013 36.9996 6.50013 36.8996 6.50013C36.2996 6.10013 36.1996 6.70013 35.8996 7.00013C35.1996 7.60013 34.2996 8.50013 33.8996 9.40013C33.2996 10.5001 33.7996 11.8001 33.8996 13.0001C33.8996 13.2001 33.9996 13.6001 34.3996 13.9001C35.6996 14.8001 36.8996 15.6001 38.6996 15.4001Z" />
      <path d="M80.7998 79.3002C81.1998 79.9002 80.9998 80.7002 80.7998 81.5002C80.5998 82.5002 80.3998 83.5002 80.2998 84.6002C80.1998 85.5002 79.7998 86.1002 79.1998 86.7002C79.0998 86.8002 78.7998 86.9002 78.6998 87.1002C78.5998 88.1002 77.9998 88.6002 77.2998 89.2002C76.8998 89.6002 76.3998 89.7002 76.0998 89.7002C74.4998 89.6002 72.8998 89.8002 71.4998 88.8002C70.5998 88.2002 69.4998 87.7002 69.5998 86.3002C69.5998 86.2002 69.4998 86.1002 69.3998 86.1002C68.6998 86.1002 68.6998 85.4002 68.3998 85.0002C67.9998 84.1002 67.6998 83.3002 66.7998 82.6002C66.3998 82.4002 66.2998 81.9002 66.1998 81.4002C66.0998 81.0002 65.7998 80.4002 65.4998 80.3002C64.5998 80.1002 64.8998 79.4002 64.8998 78.9002C64.8998 78.7002 64.7998 78.0002 64.8998 77.8002C65.2998 76.9002 64.3998 75.9002 64.8998 75.3002C65.3998 74.7002 65.2998 73.9002 65.3998 73.3002C65.3998 73.2002 65.4998 73.2002 65.5998 73.2002C67.0998 72.0002 68.3998 70.7002 70.6998 70.8002C71.5998 70.9002 72.3998 70.8002 73.2998 70.7002C74.4998 70.7002 75.4998 71.3002 76.3998 71.9002C76.5998 72.1002 76.8998 72.4002 77.2998 72.4002C77.8998 72.5002 78.4998 72.8002 78.3998 73.5002C78.3998 74.0002 78.8998 73.7002 78.9998 74.1002C79.6998 75.1002 80.4998 76.0002 80.6998 77.3002C80.6998 77.4002 80.7998 77.7002 80.6998 77.8002C80.3998 78.5002 80.7998 78.8002 80.7998 79.3002ZM75.1998 81.1002C75.1998 80.4002 74.9998 79.7002 74.0998 79.5002C73.5998 79.4002 72.9998 79.1002 72.6998 78.6002C72.2998 78.1002 71.9998 78.2002 71.5998 78.6002C70.5998 79.7002 70.9998 81.2002 70.6998 82.6002C70.6998 82.7002 70.7998 83.0002 70.8998 83.1002C71.4998 84.0002 72.3998 84.6002 73.3998 85.1002C74.0998 85.5002 74.7998 85.0002 74.7998 84.7002C74.9998 83.7002 75.9998 83.0002 75.2998 81.9002C75.1998 81.4002 75.1998 81.2002 75.1998 81.1002Z" />
      <path d="M55.3999 50.1998C55.3999 51.1998 55.5999 52.3998 54.7999 53.1998C54.1999 53.7998 53.7999 54.7998 53.0999 55.2998C52.6999 55.4998 52.5999 55.9998 52.3999 56.3998C51.3999 57.3998 50.2999 57.7998 49.0999 58.2998C47.8999 58.7998 46.5999 58.8998 45.3999 59.2998C44.9999 59.3998 44.7999 59.3998 44.4999 59.3998C42.9999 59.3998 41.3999 59.3998 39.9999 58.8998C38.8999 58.3998 37.4999 57.9998 36.8999 56.6998C36.6999 56.1998 36.1999 55.6998 35.8999 55.1998C34.9999 53.2998 35.2999 51.4998 35.7999 49.5998C35.9999 48.4998 36.8999 47.8998 37.6999 47.0998C38.6999 46.1998 39.8999 45.5998 40.8999 44.6998C42.2999 43.4998 43.6999 42.9998 45.3999 43.0998C46.4999 43.1998 47.5999 43.5998 48.6999 43.4998C49.5999 43.4998 50.1999 44.0998 51.0999 43.6998C51.2999 43.5998 51.6999 43.7998 51.7999 43.8998C53.2999 45.2998 54.9999 46.4998 55.3999 48.5998C55.4999 49.1998 55.3999 49.6998 55.3999 50.1998ZM44.3999 56.1998C46.2999 55.7998 48.1999 55.5998 49.8999 54.0998C50.7999 53.3998 51.2999 52.4998 52.0999 51.8998C52.9999 51.2998 52.4999 48.6998 51.4999 48.1998C50.4999 47.6998 49.4999 46.7998 48.2999 46.9998C47.7999 47.0998 47.1999 46.8998 46.6999 46.8998C45.5999 46.7998 44.2999 46.3998 43.4999 47.0998C42.7999 47.7998 41.7999 48.1998 40.9999 48.9998C40.7999 49.1998 40.4999 49.1998 40.0999 49.0998C39.1999 48.8998 38.5999 49.1998 38.3999 50.0998C37.9999 51.7998 37.9999 53.3998 39.4999 54.6998C39.6999 54.8998 39.6999 55.3998 40.1999 55.5998C41.4999 55.8998 42.8999 55.8998 44.3999 56.1998Z" />
      <path d="M111.1 30.5002C110.1 30.5002 108.9 30.7002 108.1 29.9002C107.5 29.3002 106.5 28.9002 106 28.2002C105.8 27.8002 105.3 27.7002 104.9 27.5002C103.9 26.5002 103.5 25.4002 103 24.2002C102.5 23.0002 102.4 21.7002 102 20.6002C101.9 20.4002 101.9 20.0002 101.9 19.7002C101.9 18.2002 101.9 16.6002 102.5 15.4002C103 14.3002 103.4 12.9002 104.7 12.3002C105.2 11.9002 105.6 11.4002 106.1 11.2002C108 10.3002 109.8 10.6002 111.7 11.1002C112.8 11.3002 113.4 12.2002 114.2 13.0002C115.1 14.0002 115.7 15.2002 116.6 16.2002C117.8 17.6002 118.3 19.0002 118.2 20.7002C118.1 21.8002 117.7 22.9002 117.8 24.0002C117.8 24.9002 117.2 25.5002 117.6 26.4002C117.7 26.6002 117.5 27.0002 117.4 27.1002C116 28.6002 114.8 30.2002 112.7 30.7002C112.1 30.6002 111.6 30.5002 111.1 30.5002ZM112.2 15.2002C112.4 14.3002 112.1 13.7002 111.2 13.5002C109.5 13.1002 107.9 13.1002 106.6 14.6002C106.4 14.8002 105.9 14.8002 105.7 15.3002C105.2 16.7002 105.3 18.0002 105 19.5002C105.4 21.4002 105.7 23.2002 107.1 25.0002C107.8 25.7002 108.7 26.4002 109.3 27.2002C109.9 28.1002 112.5 27.6002 113 26.6002C113.5 25.6002 114.4 24.6002 114.2 23.4002C114.1 22.9002 114.3 22.3002 114.3 21.8002C114.4 20.7002 114.8 19.4002 114.1 18.6002" />
      <path d="M25.0004 42.5002C22.5004 42.5002 20.5004 41.4002 19.2004 39.5002C18.5004 38.6002 18.6004 37.5002 18.5004 36.5002C18.3004 35.0002 18.6004 33.5002 19.4004 32.2002C19.8004 31.6002 19.9004 30.8002 20.3004 30.1002C20.5004 29.6002 20.8004 29.1002 21.2004 29.0002C21.9004 28.6002 21.6004 27.8002 22.2004 27.4002C22.7004 27.2002 23.2004 26.7002 23.9004 26.8002C24.9004 27.0002 25.9004 27.2002 27.0004 27.2002C27.7004 27.2002 28.1004 27.8002 28.6004 28.2002C29.3004 28.7002 29.8004 29.6002 30.6004 30.1002C30.8004 30.2002 31.1004 30.3002 31.1004 30.7002C31.1004 31.8002 31.8004 32.6002 32.3004 33.4002C32.4004 33.6002 32.4004 33.9002 32.4004 34.1002C32.4004 35.0002 32.3004 35.7002 32.3004 36.6002C32.3004 36.8002 32.3004 37.0002 32.2004 37.2002C31.3004 38.2002 31.5004 40.0002 30.0004 40.7002C29.9004 41.6002 29.4004 42.1002 28.5004 42.1002C27.0004 41.8002 26.1004 43.0002 25.0004 42.5002ZM24.4004 38.8002C25.8004 38.9002 26.8004 38.6002 27.2004 37.2002C27.2004 37.1002 27.3004 37.0002 27.4004 36.8002C27.9004 36.4002 27.6004 36.1002 27.3004 35.8002C26.8004 35.3002 26.2004 34.9002 25.8004 34.4002C25.3004 33.7002 24.7004 33.3002 23.9004 33.3002C23.5004 33.3002 23.3004 33.2002 22.9004 32.9002C22.3004 32.5002 22.0004 32.8002 21.8004 33.4002C21.6004 34.1002 21.6004 34.9002 21.6004 35.6002C21.7004 37.7002 22.6004 38.6002 24.4004 38.8002Z" />
      <path d="M86.1 42.5002C83.9 42.5002 82.3 41.6002 81 40.1002C80.4 39.4002 80.5 38.5002 80.4 37.7002C80.3 36.5002 80.4 35.3002 81.1 34.2002C81.3 33.7002 81.6 33.1002 81.8 32.6002C82 32.2002 82.2 31.9002 82.5 31.7002C83.1 31.5002 82.7 30.7002 83.4 30.5002C83.9 30.3002 84.3 29.9002 84.9 30.0002C85.8 30.1002 86.6 30.4002 87.6 30.4002C88.2 30.4002 88.6 30.9002 89 31.1002C89.6 31.5002 90 32.2002 90.7 32.6002C90.8 32.7002 91.2 32.8002 91.1 33.1002C91.1 34.0002 91.7 34.6002 92.2 35.2002C92.3 35.4002 92.3 35.6002 92.2 35.8002C92.2 36.4002 92.1 37.0002 92.1 37.8002C92.1 37.9002 92.1 38.2002 92 38.3002C91.3 39.2002 91.4 40.5002 90 41.0002C89.9 41.7002 89.4 42.0002 88.6 42.0002C87.8 41.9002 87.1 42.9002 86.1 42.5002ZM85.6 39.6002C86.7 39.7002 87.7 39.4002 88.1 38.2002C88.1 38.1002 88.2 38.0002 88.3 38.0002C88.7 37.8002 88.5 37.4002 88.2 37.1002C87.8 36.7002 87.2 36.5002 87 36.0002C86.6 35.5002 86 35.3002 85.4 35.1002C85.2 35.1002 84.8 35.0002 84.5 34.9002C84 34.7002 83.8 34.8002 83.6 35.3002C83.4 35.9002 83.5 36.5002 83.5 37.0002C83.3 38.7002 84 39.3002 85.6 39.6002Z" />
      <path d="M102.1 81.6999C99.9 81.6999 98.3 80.7999 97 79.2999C96.4 78.5999 96.5 77.6999 96.4 76.8999C96.3 75.6999 96.4 74.4999 97.1 73.3999C97.3 72.8999 97.6 72.2999 97.8 71.7999C98 71.3999 98.2 71.0999 98.5 70.8999C99.1 70.6999 98.7 69.8999 99.4 69.6999C99.9 69.4999 100.3 69.0999 100.9 69.1999C101.8 69.2999 102.6 69.5999 103.6 69.5999C104.2 69.5999 104.6 70.0999 105 70.2999C105.6 70.6999 106 71.3999 106.7 71.7999C106.8 71.8999 107.2 71.9999 107.1 72.2999C107.1 73.1999 107.7 73.7999 108.2 74.3999C108.3 74.5999 108.3 74.7999 108.2 74.9999C108.2 75.5999 108.1 76.1999 108.1 76.9999C108.1 77.0999 108.1 77.3999 108 77.4999C107.3 78.3999 107.4 79.6999 106 80.1999C105.9 80.8999 105.4 81.1999 104.6 81.1999C103.9 81.0999 103.1 81.8999 102.1 81.6999ZM101.6 78.5999C102.7 78.6999 103.7 78.3999 104.1 77.1999C104.1 77.0999 104.2 76.9999 104.3 76.9999C104.7 76.7999 104.5 76.3999 104.2 76.0999C103.8 75.6999 103.2 75.4999 103 74.9999C102.6 74.4999 102 74.2999 101.4 74.0999C101.2 74.0999 100.8 73.9999 100.5 73.8999C100 73.6999 99.8 73.7999 99.6 74.2999C99.4 74.8999 99.5 75.4999 99.5 75.9999C99.2 77.7999 100 78.4999 101.6 78.5999Z" />
      <path d="M76.3996 63.2999C75.3996 62.8999 74.7996 62.2999 73.9996 61.3999C73.3996 60.7999 72.4996 60.1999 71.7996 59.4999C71.5996 59.3999 71.3996 59.2999 71.2996 58.9999C70.6996 57.8999 69.6996 56.8999 69.3996 55.4999C68.9996 53.5999 69.3996 51.9999 70.4996 50.6999C70.9996 50.1999 71.4996 49.6999 72.0996 49.2999C73.9996 48.0999 75.7996 48.6999 77.6996 48.8999C78.7996 48.9999 79.7996 49.4999 80.7996 49.9999C81.4996 50.3999 82.1996 50.8999 82.4996 51.4999C82.9996 52.5999 84.0996 53.1999 84.5996 54.2999C85.1996 55.9999 85.5996 57.8998 84.5996 59.5998C84.5996 59.5998 84.5996 59.5999 84.5996 59.6999C84.5996 59.6999 84.5996 59.6999 84.5996 59.5998H84.4996C84.0996 59.6999 84.2996 60.1999 83.9996 60.4999C83.4996 61.1999 82.8996 61.8999 82.0996 62.1999C81.1996 62.5999 80.4996 63.1999 79.5996 63.3999C79.0996 63.4999 78.5996 63.4999 78.0996 63.2999C78.0996 63.1999 78.0996 63.0998 78.0996 63.0998C78.2996 62.8998 78.0996 62.5999 77.8996 62.4999C77.7996 62.3999 77.3996 62.3999 77.3996 62.6999C77.3996 63.3999 76.8996 63.1999 76.6996 63.0998C76.5996 63.0998 76.5996 63.1999 76.3996 63.2999ZM77.2996 52.5998C76.8996 52.5998 76.6996 52.4999 76.2996 52.4999C75.3996 52.5999 74.3996 52.3999 73.7996 53.1999C73.6996 53.5999 73.3996 53.7999 73.1996 53.8999C72.7996 54.0999 72.5996 54.4999 72.4996 54.8999C72.2996 55.4999 72.2996 56.0998 71.7996 56.5998C71.6996 56.6999 71.5996 57.0999 71.7996 57.1999C72.1996 57.2999 72.1996 57.7998 72.4996 58.0998C72.6996 58.2998 72.8996 58.7999 73.1996 58.9999C73.6996 59.0999 74.0996 59.4999 74.5996 59.6999C75.4996 60.2999 76.2996 61.0999 77.5996 61.1999C78.9996 61.3999 80.0996 60.7999 81.1996 60.1999C82.3996 59.4999 82.7996 57.4999 82.0996 56.1999C81.6996 55.5999 81.3996 54.9999 80.8996 54.2999C79.8996 53.2999 78.9996 52.1998 77.2996 52.5998Z" />
      <path d="M60.8003 23.8999C61.7003 23.8999 62.4003 24.3999 63.2003 24.8999C63.8003 25.2999 64.7003 25.5999 65.4003 25.8999C65.6003 25.9999 65.8003 25.9999 66.0003 26.2999C66.9003 26.9999 67.7003 27.6999 68.4003 28.6999C69.1003 30.1999 69.3003 31.4999 68.6003 32.8999C68.4003 33.3999 68.0003 33.9999 67.6003 34.3999C66.5003 35.8999 64.8003 35.8999 63.1003 36.0999C62.1003 36.1999 61.2003 36.0999 60.3003 35.9999C59.6003 35.8999 58.9003 35.5999 58.4003 35.0999C57.7003 34.3999 56.7003 34.0999 56.0003 33.1999C55.0003 31.7999 54.3003 30.4999 54.6003 28.8999C54.6003 28.8999 54.6003 28.8999 54.6003 28.7999C54.6003 28.7999 54.6003 28.7999 54.7003 28.7999C54.9003 28.5999 54.8003 28.1999 54.8003 27.8999C55.0003 27.1999 55.3003 26.4999 55.9003 25.9999C56.5003 25.4999 56.9003 24.7999 57.6003 24.3999C58.0003 24.1999 58.5003 23.9999 58.8003 24.1999C58.8003 24.2999 58.9003 24.2999 58.8003 24.3999C58.7003 24.7999 58.9003 24.7999 59.2003 24.8999C59.3003 24.8999 59.7003 24.8999 59.6003 24.4999C59.4003 23.8999 59.8003 23.9999 60.1003 23.9999C60.7003 24.1999 60.7003 24.0999 60.8003 23.8999ZM62.7003 33.0999C62.9003 32.9999 63.3003 32.9999 63.4003 32.9999C64.0003 32.5999 65.0003 32.5999 65.1003 31.7999C65.2003 31.5999 65.2003 31.1999 65.5003 31.0999C65.7003 30.8999 65.9003 30.4999 65.9003 30.0999C65.9003 29.5999 65.9003 28.9999 66.1003 28.4999C66.2003 28.3999 66.2003 27.9999 65.9003 27.9999C65.6003 27.9999 65.4003 27.5999 65.2003 27.4999C65.0003 27.3999 64.7003 26.8999 64.3003 26.9999C63.8003 26.9999 63.4003 26.7999 62.9003 26.5999C62.0003 26.3999 61.2003 25.9999 60.2003 26.0999C59.0003 26.1999 58.2003 26.9999 57.5003 27.6999C56.6003 28.5999 56.9003 30.2999 57.9003 31.1999C58.4003 31.6999 58.9003 32.0999 59.4003 32.3999C60.5003 32.9999 61.5003 33.6999 62.7003 33.0999Z" />
      <path d="M14.4996 70.9C14.5996 70 15.1996 69.4 15.6996 68.7C16.1996 68.1 16.5996 67.3 17.0996 66.6C17.1996 66.4 17.2996 66.2 17.4996 66.1C18.3996 65.5 19.0996 64.6 20.1996 64.1C21.6996 63.6 23.1996 63.6 24.4996 64.5C24.9996 64.9 25.4996 65.2 25.8996 65.7C27.0996 67.1 26.8996 68.8 26.8996 70.4C26.8996 71.4 26.4996 72.3 26.2996 73.1C26.0996 73.7 25.7996 74.3 25.1996 74.8C24.2996 75.4 23.9996 76.4 22.9996 76.9C21.4996 77.6 19.9996 78.1 18.3996 77.5C18.3996 77.5 18.3996 77.5 18.2996 77.5C18.2996 77.5 18.2996 77.5 18.2996 77.4C18.0996 77.2 17.7996 77.3 17.3996 77.2C16.6996 76.8 15.9996 76.5 15.6996 75.8C15.2996 75.1 14.5996 74.6 14.2996 73.8C14.1996 73.3 14.0996 72.9 14.1996 72.4C14.2996 72.4 14.2996 72.3 14.3996 72.4C14.5996 72.5 14.8996 72.3 14.8996 72.2C14.8996 72.1 14.8996 71.7 14.6996 71.8C14.0996 71.9 14.1996 71.4 14.2996 71.2C14.5996 71 14.5996 71 14.4996 70.9ZM23.7996 70.4C23.7996 70.2 23.7996 69.8 23.7996 69.7C23.5996 69 23.6996 68.1 22.7996 67.7C22.5996 67.6 22.2996 67.5 22.0996 67.2C21.8996 66.8 21.4996 66.7 21.0996 66.7C20.5996 66.6 19.9996 66.6 19.5996 66.2C19.4996 66.1 19.0996 66.1 19.0996 66.3C18.9996 66.8 18.5996 66.8 18.4996 67C18.2996 67.2 17.8996 67.4 17.8996 67.7C17.8996 68.2 17.4996 68.6 17.3996 68.9C16.9996 69.8 16.3996 70.5 16.3996 71.5C16.3996 72.7 16.9996 73.6 17.5996 74.3C18.2996 75.3 20.0996 75.3 21.0996 74.5C21.5996 74.1 22.0996 73.6 22.5996 73.3C23.3996 72.6 24.2996 71.8 23.7996 70.4Z" />
      <path d="M8.20042 56.5001C7.30042 56.5001 6.60042 56.0001 5.80042 55.5001C5.20042 55.1001 4.30042 54.8001 3.60042 54.5001C3.40042 54.4001 3.20042 54.4001 3.00042 54.1001C2.10042 53.4001 1.30042 52.7001 0.600417 51.7001C-0.0995834 50.2001 -0.299583 48.9001 0.400417 47.5001C0.600417 47.0001 1.00042 46.5001 1.40042 46.0001C2.60042 44.5001 4.20042 44.5001 5.90042 44.4001C6.90042 44.3001 7.80042 44.4001 8.70042 44.6001C9.40042 44.7001 10.1004 45.0001 10.6004 45.5001C11.3004 46.2001 12.3004 46.5001 13.0004 47.4001C14.0004 48.8001 14.7004 50.1001 14.4004 51.7001C14.4004 51.7001 14.4004 51.7001 14.4004 51.8001C14.4004 51.8001 14.4004 51.8001 14.3004 51.8001C14.1004 52.0001 14.2004 52.4001 14.2004 52.7001C14.0004 53.4001 13.7004 54.1001 13.1004 54.6001C12.5004 55.1001 12.1004 55.8001 11.4004 56.2001C11.0004 56.4001 10.5004 56.6001 10.2004 56.4001C10.2004 56.3001 10.1004 56.3001 10.2004 56.2001C10.2004 55.6001 9.80042 55.5001 9.70042 55.5001C9.60042 55.5001 9.20042 55.5001 9.30042 55.9001C9.50042 56.5001 9.10042 56.4001 8.80042 56.4001C8.30042 56.3001 8.30042 56.4001 8.20042 56.5001ZM6.30042 47.4001C6.10042 47.5001 5.70042 47.5001 5.60042 47.5001C5.00042 47.9001 4.00042 47.9001 3.70042 48.7001C3.60042 48.9001 3.60042 49.3001 3.30042 49.4001C3.10042 49.6001 2.90042 50.0001 2.90042 50.4001C2.90042 50.9001 2.90042 51.5001 2.70042 52.0001C2.60042 52.1001 2.60042 52.5001 2.90042 52.5001C3.20042 52.5001 3.40042 52.9001 3.60042 53.0001C3.80042 53.1001 4.10042 53.6001 4.50042 53.5001C5.00042 53.5001 5.40042 53.7001 5.90042 53.9001C6.80042 54.1001 7.60042 54.5001 8.60042 54.5001C9.80042 54.4001 10.6004 53.6001 11.3004 52.9001C12.2004 52.0001 11.9004 50.3001 11.1004 49.4001C10.6004 48.9001 10.1004 48.5001 9.60042 48.2001C8.60042 47.5001 7.60042 46.7001 6.30042 47.4001Z" />
      <path d="M88.6998 11.9002C88.2998 14.7002 87.7998 17.4002 85.4998 19.2002C84.8998 19.7002 84.0998 20.1002 83.2998 20.1002C81.7998 20.2002 80.2998 20.1002 78.9998 19.2002C78.3998 18.8002 77.4998 18.6002 76.8998 18.1002C74.7998 16.7002 73.5998 14.6002 72.5998 12.4002C72.0998 11.2002 72.3998 9.9002 72.5998 8.7002C72.6998 8.2002 72.7998 7.7002 72.7998 7.2002C72.7998 6.5002 72.8998 5.7002 73.4998 5.2002C73.8998 5.0002 73.8998 4.5002 74.0998 4.1002C75.0998 2.9002 76.4998 2.7002 77.7998 2.7002C78.7998 2.7002 79.7998 2.9002 80.7998 2.8002C81.1998 2.8002 81.3998 3.0002 81.7998 3.2002C83.3998 3.7002 84.4998 4.9002 85.4998 6.0002C85.9998 6.6002 86.3998 7.4002 86.6998 8.0002C87.0998 8.6002 87.3998 9.1002 87.8998 9.5002C88.6998 10.2002 88.5998 11.1002 88.6998 11.9002ZM74.6998 10.2002C74.7998 10.9002 74.6998 11.7002 75.0998 12.3002C75.5998 13.3002 76.5998 13.8002 76.7998 14.9002C76.8998 15.6002 77.7998 15.5002 77.9998 16.0002C78.6998 16.9002 79.6998 17.2002 80.5998 17.6002C82.5998 18.5002 84.1998 18.2002 85.2998 16.7002C86.1998 15.5002 86.4998 14.0002 86.7998 12.6002C86.8998 11.6002 86.9998 10.5002 85.7998 10.0002C85.3998 9.8002 85.1998 9.4002 85.0998 9.0002C83.8998 6.3002 80.3998 4.8002 77.7998 5.7002C77.5998 5.80019 77.1998 5.8002 76.7998 5.8002C76.1998 5.7002 75.7998 6.0002 75.5998 6.5002C74.7998 7.7002 74.9998 9.0002 74.6998 10.2002Z" />
      <path d="M46.5997 86.3C45.6997 86.3 44.8998 86.3 44.1998 85.7C43.5998 85.2 42.9997 85 42.2997 84.7C40.3997 83.8 39.1997 81.2 39.2997 79C39.3997 77.9 39.2997 76.9 39.7997 75.9C39.8997 75.7 39.9998 75.4 40.1998 75C40.5998 74.5 40.8998 74.1 41.1998 73.4C41.6998 71.8 42.8998 71.2 44.4998 71.5C44.6997 71.6 45.0998 71.6 45.4998 71.5C46.3998 71.1 47.1997 71.3 47.9998 71.3C49.0998 71.4 49.9998 72 50.6998 72.8C51.5998 73.5 52.0997 74.8 53.0997 75.5C53.6997 75.9 53.9998 76.6 53.6998 77.4C53.4998 78 53.6997 78.6 53.5997 79.3C52.9997 82.8 50.8998 84.9 47.6998 86.1C47.2998 86.3 46.9997 86.3 46.5997 86.3ZM41.5997 78.7C41.5997 78.9 41.5997 79.2 41.5997 79.3C41.5997 80 41.6997 81 42.0997 81.4C43.0997 82.1 43.6997 83.5 45.2997 83.8C46.1997 83.9 46.8997 84.2 47.7997 83.9C49.1997 83.5 49.8997 82.5 50.7997 81.7C51.6997 80.8 51.7998 79.7 51.9998 78.6C52.1997 77.5 52.4998 76.4 50.9998 75.8C50.8997 75.8 50.8997 75.6 50.7997 75.3C50.1997 74.4 49.2997 73.7 48.0997 73.8C47.1997 73.9 46.3998 73.7 45.4998 73.6C44.2998 73.2 43.5997 73.6 43.0997 74.8C43.0997 74.9 42.9998 74.9 42.8998 75C42.0998 76.1 41.2997 77.3 41.5997 78.7Z" />
      <path d="M105.2 58.9001C104.3 58.9001 103.5 58.9001 102.8 58.3001C102.2 57.8001 101.6 57.6001 100.9 57.3001C99.0002 56.4001 97.7002 53.8001 97.9002 51.6001C98.0002 50.5001 97.9002 49.5001 98.4002 48.5001C98.5002 48.3001 98.6002 48.0001 98.8002 47.6001C99.2002 47.1001 99.5002 46.7001 99.8002 46.0001C100.3 44.4001 101.5 43.8001 103.1 44.1001C103.3 44.2001 103.7 44.2001 104.1 44.1001C105 43.7001 105.8 43.9001 106.6 43.9001C107.7 44.0001 108.6 44.6001 109.3 45.4001C110.2 46.1001 110.7 47.4001 111.7 48.1001C112.3 48.5001 112.6 49.2001 112.3 50.0001C112.1 50.6001 112.3 51.2001 112.2 51.9001C111.6 55.4001 109.5 57.5001 106.3 58.7001C105.8 58.8001 105.4 58.8001 105.2 58.9001ZM100.1 51.2001C100.1 51.4001 100.1 51.7001 100.1 51.8001C100.1 52.5001 100.2 53.5001 100.6 53.9001C101.6 54.6001 102.2 56.0001 103.8 56.3001C104.7 56.4001 105.4 56.7001 106.3 56.4001C107.7 56.0001 108.4 55.0001 109.3 54.2001C110.2 53.3001 110.3 52.2001 110.5 51.1001C110.7 50.0001 111 48.9001 109.5 48.3001C109.4 48.3001 109.4 48.1001 109.3 47.8001C108.7 46.9001 107.8 46.2001 106.6 46.3001C105.6 46.4001 104.9 46.2001 104 46.1001C102.8 45.7001 102.1 46.1001 101.6 47.3001C101.6 47.4001 101.5 47.4001 101.4 47.5001C100.6 48.7001 99.9002 49.8001 100.1 51.2001Z" />
      <path d="M76.4004 63.3001C76.5004 63.2001 76.5004 63.1001 76.8004 63.2001C77.0004 63.4001 77.5004 63.7001 77.5004 62.8001C77.5004 62.4001 77.9004 62.4001 78.0004 62.6001C78.2004 62.7001 78.5004 62.8001 78.2004 63.2001C78.1004 63.3001 78.2004 63.4001 78.2004 63.4001C77.7004 63.7001 77.1004 63.7001 76.4004 63.3001Z" />
      <path d="M91.1001 92.1C92.2001 91.9 93.7001 92.7 95.2001 92.7C95.9001 93.7 97.3001 93.2 98.0001 94.2C98.6001 95.1 99.4002 95.8 99.9002 96.7C100.6 97.9 100.9 99.3 101.1 100.7C101.2 101.4 101.7 102.1 101.3 102.8C100.7 103.9 100.7 105.3 100.2 106.5C99.7001 107.5 98.8001 108.4 98.5002 109.3C98.1002 110.3 97.1002 110.7 96.5001 111C95.3001 111.6 93.9002 112.5 92.3002 112.1C90.8002 111.9 89.2002 112.1 87.8002 111.5C86.6002 111 85.2001 110.8 84.7001 109.3C84.5001 108.7 83.6002 108.3 83.3002 107.7C82.6002 105.6 82.1002 103.5 82.9002 101.4C83.1002 100.8 83.1002 100.2 83.3002 99.5C83.5002 98 84.5001 96.9 85.0001 95.7C85.2001 95.2 85.1002 94.8 85.4002 94.5C86.4002 93.4 89.6001 92 91.1001 92.1ZM91.8002 107.1C92.3002 106.9 92.9002 107.1 93.4002 106.7C94.6002 106.1 95.3002 105.1 95.8002 103.9C96.2002 103 96.4002 102.2 96.4002 101.3C96.3002 99.3 95.3002 98.3 93.4002 97.6C92.4002 97.2 91.4002 97.4 90.4002 97.1C90.0002 97 89.8001 97.2 90.0001 97.6C90.1001 97.8 90.0002 98.2 89.9002 98.2C89.3002 97.8 89.2002 98.4 88.9002 98.7C88.2002 99.3 87.3002 100.2 86.9002 101.1C86.3002 102.2 86.8002 103.5 86.9002 104.7C86.9002 104.9 87.0002 105.3 87.4002 105.6C88.8002 106.3 90.1002 107.3 91.8002 107.1Z" />
      <path d="M49.2998 116.4C48.3998 115.9 47.2998 115.5 46.8998 114.4C46.6998 113.5 45.9998 112.8 45.8998 111.8C45.8998 111.3 45.3998 111.1 45.2998 110.6C44.8998 109.4 45.1998 108 45.2998 106.8C45.3998 105.4 45.8998 104.3 46.1998 103.1C46.2998 102.9 46.2998 102.6 46.5998 102.2C47.2998 100.8 48.0998 99.5002 49.1998 98.6002C50.1998 97.9002 51.1998 96.7002 52.6998 96.9002C53.2998 96.9002 53.8998 96.7002 54.5998 96.7002C56.5998 96.8002 58.0998 97.9002 59.5998 99.2002C60.4998 99.9002 60.5998 100.9 60.7998 102C60.9998 103.4 60.9998 104.7 61.2998 106C61.7998 107.7 61.3998 109.3 60.5998 110.7C59.9998 111.7 59.0998 112.4 58.6998 113.4C58.2998 114.1 57.4998 114.5 57.2998 115.4C57.2998 115.6 56.8998 115.9 56.6998 115.9C54.7998 116.6 52.9998 117.5 50.8998 116.9C50.0998 117 49.6998 116.6 49.2998 116.4ZM49.2998 103.7C48.6998 105.6 48.0998 107.4 48.5998 109.5C48.7998 110.5 49.2998 111.5 49.4998 112.6C49.5998 113.6 52.0998 114.5 53.0998 113.8C54.0998 113.2 55.1998 112.8 55.6998 111.4C55.7998 110.9 56.2998 110.5 56.5998 110C57.1998 109.1 58.1998 108.1 57.7998 107C57.5998 106 57.5998 104.9 57.2998 103.9C57.1998 103.5 57.2998 103.4 57.4998 103C58.0998 102.4 58.0998 101.6 57.4998 101C56.2998 99.9002 54.7998 99.1002 52.9998 99.8002C52.5998 99.9002 52.2998 99.7002 51.8998 100C50.8998 101.4 50.2998 102.6 49.2998 103.7Z" />
      <path d="M78.1996 134.2C75.6996 134.2 73.6997 133.1 72.3997 131.2C71.6997 130.3 71.7996 129.2 71.6996 128.2C71.4996 126.7 71.7996 125.2 72.5996 123.9C72.9996 123.3 73.0997 122.5 73.4997 121.8C73.6997 121.3 73.9997 120.8 74.3997 120.7C75.0997 120.3 74.7997 119.5 75.3997 119.1C75.8997 118.9 76.3997 118.4 77.0996 118.5C78.0996 118.7 79.0996 118.9 80.1996 118.9C80.8996 118.9 81.2997 119.5 81.7997 119.9C82.4997 120.4 82.9997 121.3 83.7997 121.8C83.9997 121.9 84.2997 122 84.2997 122.4C84.2997 123.5 84.9997 124.3 85.4997 125.1C85.5997 125.3 85.5996 125.6 85.5996 125.8C85.5996 126.7 85.4997 127.4 85.4997 128.3C85.4997 128.5 85.4997 128.7 85.3997 128.9C84.4997 129.9 84.6996 131.7 83.1996 132.4C83.0996 133.3 82.5996 133.8 81.6996 133.8C80.1996 133.5 79.2996 134.6 78.1996 134.2ZM77.5996 130.4C78.9997 130.5 79.9997 130.2 80.3997 128.8C80.3997 128.7 80.4996 128.6 80.5996 128.4C81.0996 128 80.7997 127.7 80.4997 127.4C79.9997 126.9 79.3997 126.5 78.9997 126C78.4997 125.3 77.8997 125 77.0996 124.9C76.6996 124.9 76.4997 124.8 76.0996 124.5C75.4997 124.1 75.1997 124.4 74.9997 125C74.7997 125.7 74.7997 126.5 74.7997 127.2C74.7997 129.4 75.6996 130.1 77.5996 130.4Z" />
      <path d="M21.3998 105.8C18.8998 105.8 16.8998 104.7 15.5998 102.8C14.8998 101.9 14.9998 100.8 14.8998 99.8C14.6998 98.3 14.9998 96.8 15.7998 95.5C16.1998 94.9 16.2998 94.1 16.6998 93.4C16.8998 92.9 17.1998 92.4 17.5998 92.3C18.2998 91.9 17.9998 91.1 18.5998 90.7C19.0998 90.5 19.5998 90 20.2998 90.1C21.2998 90.3 22.2998 90.5 23.3998 90.5C24.0998 90.5 24.4998 91.1 24.9998 91.5C25.6998 92 26.1998 92.9 26.9998 93.4C27.1998 93.5 27.4998 93.6 27.4998 94C27.4998 95.1 28.1998 95.9 28.6998 96.7C28.7998 96.9 28.7998 97.2 28.7998 97.4C28.7998 98.3 28.6998 99 28.6998 99.9C28.6998 100.1 28.6998 100.3 28.5998 100.5C27.6998 101.5 27.8998 103.3 26.3998 104C26.2998 104.9 25.7998 105.4 24.8998 105.4C23.3998 105.1 22.5998 106.2 21.3998 105.8ZM20.7998 102.1C22.1998 102.2 23.1998 101.9 23.5998 100.5C23.5998 100.4 23.6998 100.3 23.7998 100.1C24.2998 99.7 23.9998 99.4 23.6998 99.1C23.1998 98.6 22.5998 98.2 22.1998 97.7C21.6998 97 21.0998 96.7 20.2998 96.6C19.8998 96.6 19.6998 96.5 19.2998 96.2C18.6998 95.8 18.3998 96.1 18.1998 96.7C17.9998 97.4 17.9998 98.2 17.9998 98.9C18.0998 101 18.9998 101.9 20.7998 102.1Z" />
      <path d="M61.3 148.1C60.4 148.1 59.7 147.6 58.9 147.1C58.3 146.7 57.4 146.4 56.7 146.1C56.5 146 56.3 146 56.1 145.7C55.2 145 54.4 144.3 53.7 143.3C53 141.8 52.8 140.5 53.5 139.1C53.7 138.6 54.1 138.1 54.5 137.6C55.7 136.1 57.3 136.1 59 136C60 135.9 60.9 136 61.8 136.2C62.5 136.3 63.2 136.6 63.7 137.1C64.4 137.8 65.4 138.1 66.1 139C67.1 140.4 67.8 141.7 67.5 143.3C67.5 143.3 67.5 143.3 67.5 143.4C67.5 143.4 67.5 143.4 67.4 143.4C67.2 143.6 67.3 144 67.3 144.3C67.1 145 66.8 145.7 66.2 146.2C65.6 146.7 65.2 147.4 64.5 147.8C64.1 148 63.6 148.2 63.3 148C63.3 147.9 63.2 147.9 63.3 147.8C63.4 147.6 63.2 147.4 62.9 147.3C62.8 147.3 62.4 147.3 62.5 147.7C62.7 148.3 62.3 148.2 62 148.1C61.5 147.8 61.5 148 61.3 148.1ZM59.5 138.9C59.3 138.9 58.9 138.9 58.8 139C58.2 139.4 57.2 139.4 56.9 140.2C56.8 140.4 56.8 140.8 56.5 140.9C56.3 141.1 56.1 141.5 56.1 141.9C56.1 142.4 56.1 143 55.9 143.5C55.8 143.6 55.8 144 56.1 144C56.4 144 56.6 144.4 56.8 144.5C57 144.6 57.3 145.1 57.7 145C58.2 145 58.6 145.2 59.1 145.4C60 145.6 60.8 146 61.8 146C63 145.9 63.8 145.1 64.5 144.4C65.4 143.5 65.1 141.8 64.3 140.9C63.8 140.4 63.3 140 62.8 139.7C61.7 139 60.7 138.3 59.5 138.9Z" />
      <path d="M40.9996 125.5C41.0996 126.4 40.8996 127.2 40.4996 128.1C40.2996 128.8 40.0996 129.7 39.8996 130.6C39.8996 130.8 39.7996 131 39.6996 131.2C39.0996 132.2 38.6996 133.2 37.7996 133.9C36.5996 134.9 35.1996 135.3 33.6996 134.9C33.0996 134.8 32.5996 134.5 31.9996 134.3C30.3996 133.4 29.9996 131.7 29.4996 130.2C29.2996 129.3 29.0996 128.3 29.0996 127.4C29.0996 126.7 29.1996 126 29.5996 125.4C30.1996 124.5 30.1996 123.4 30.9996 122.7C32.0996 121.6 33.1996 120.5 34.9996 120.6C34.9996 120.6 34.9996 120.6 34.9996 120.7C35.1996 120.9 35.5996 120.7 35.8996 120.7C36.5996 120.8 37.3996 120.9 37.9996 121.4C38.5996 121.9 39.3996 122.1 39.8996 122.9C40.0996 123.3 40.3996 123.6 40.3996 124.1C40.2996 124.1 40.2996 124.2 40.1996 124.2C39.7996 124.1 39.7996 124.4 39.7996 124.6C39.7996 124.7 39.8996 125.1 40.1996 124.8C40.7996 124.4 40.7996 124.9 40.6996 125.3C40.7996 125.5 40.8996 125.5 40.9996 125.5ZM32.4996 129.2C32.5996 129.4 32.5996 129.7 32.8996 129.9C33.3996 130.5 33.4996 131.4 34.3996 131.4C34.5996 131.4 34.9996 131.4 35.2996 131.6C35.6996 131.8 35.9996 131.8 36.3996 131.7C36.8996 131.6 37.3996 131.5 37.9996 131.6C38.0996 131.6 38.4996 131.6 38.3996 131.4C38.3996 131 38.5996 130.8 38.7996 130.5C38.8996 130.1 39.2996 129.9 39.1996 129.5C39.0996 129 39.1996 128.6 39.2996 128.1C39.3996 127.1 39.6996 126.2 39.2996 125.3C38.8996 124.1 38.0996 123.6 37.1996 122.9C36.0996 122.3 34.4996 122.8 33.8996 123.9C33.4996 124.5 33.2996 125 32.9996 125.6C32.0996 127 31.5996 128 32.4996 129.2Z" />
      <path d="M134.7 91.7C134.3 94.5 133.8 97.2 131.5 99C130.9 99.5 130.1 99.9 129.3 99.9C127.8 100 126.3 99.9 125 99C124.4 98.6 123.5 98.4 122.9 97.9C120.8 96.5 119.6 94.4 118.6 92.2C118.1 91 118.4 89.7 118.6 88.5C118.7 88 118.8 87.5 118.8 87C118.8 86.3 118.9 85.5 119.5 85C119.9 84.8 119.9 84.3 120.1 83.9C121.1 82.7 122.5 82.5 123.8 82.5C124.8 82.5 125.8 82.7 126.8 82.6C127.2 82.6 127.4 82.8 127.8 83C129.4 83.5 130.4 84.7 131.5 85.8C132 86.4 132.4 87.2 132.7 87.8C133.1 88.4 133.4 88.9 133.9 89.3C134.7 90.1 134.5 91 134.7 91.7ZM120.7 90.1C120.8 90.8 120.7 91.6 121.1 92.2C121.6 93.2 122.6 93.7 122.8 94.8C122.9 95.5 123.8 95.4 124 95.9C124.7 96.8 125.7 97.1 126.6 97.5C128.6 98.4 130.2 98.1 131.3 96.6C132.2 95.4 132.5 93.9 132.8 92.5C132.9 91.5 133 90.4 131.8 89.9C131.6 89.8 131.2 89.4 131.1 89C129.9 86.3 126.4 84.8 123.8 85.7C123.6 85.8 123.2 85.8 122.8 85.8C122.2 85.7 121.8 86 121.6 86.5C120.8 87.6 120.9 88.9 120.7 90.1Z" />
      <path d="M125.5 59.7C125.5 58.7 125.3 57.5 126.1 56.7C126.7 56.1 127.1 55.1 127.8 54.6C128.2 54.4 128.3 53.9 128.5 53.5C129.5 52.5 130.6 52.1 131.8 51.6C133 51.1 134.3 51 135.5 50.6C135.7 50.5 136.1 50.5 136.4 50.5C137.9 50.5 139.5 50.5 140.9 51C142 51.5 143.4 51.9 144 53.2C144.2 53.7 144.7 54.2 145 54.7C145.9 56.6 145.6 58.4 145.1 60.3C144.9 61.4 144 62 143.2 62.8C142.2 63.7 141 64.3 140 65.2C138.6 66.4 137.2 66.9 135.4 66.8C134.3 66.7 133.2 66.3 132.1 66.4C131.2 66.4 130.6 65.8 129.7 66.2C129.5 66.3 129.1 66.1 129 66C127.5 64.6 125.8 63.4 125.4 61.3C125.5 60.7 125.5 60.2 125.5 59.7ZM136.7 53.8C134.8 54.2 132.9 54.4 131.2 55.9C130.3 56.6 129.8 57.5 129 58.1C128.1 58.7 128.6 61.3 129.6 61.8C130.6 62.3 131.6 63.2 132.8 63C133.3 62.9 133.9 63.1 134.4 63.1C135.5 63.2 136.8 63.6 137.6 62.9C138.3 62.2 139.3 61.8 140.1 61C140.3 60.8 140.6 60.8 141 60.9C141.9 61.1 142.5 60.8 142.7 59.9C143.1 58.2 143.1 56.6 141.6 55.3C141.4 55.1 141.4 54.6 140.9 54.4C139.5 54.2 138.1 54.2 136.7 53.8Z" />
      <path d="M134.3 23.7002C135.2 23.7002 136 23.7002 136.7 24.3002C137.3 24.8002 137.9 25.0002 138.6 25.3002C140.5 26.2002 141.7 28.8002 141.6 31.0002C141.5 32.1002 141.6 33.1002 141.1 34.1002C141 34.3002 140.9 34.6002 140.7 35.0002C140.3 35.5002 140 35.9002 139.7 36.6002C139.2 38.2002 138 38.8002 136.4 38.5002C136.2 38.4002 135.8 38.4002 135.4 38.5002C134.5 38.9002 133.7 38.7002 132.9 38.7002C131.8 38.6002 130.9 38.0002 130.2 37.2002C129.3 36.5002 128.8 35.2002 127.8 34.5002C127.2 34.1002 126.9 33.4002 127.2 32.6002C127.4 32.0002 127.2 31.4002 127.3 30.7002C127.9 27.2002 130 25.1002 133.2 23.9002C133.7 23.7002 134.1 23.8002 134.3 23.7002ZM139.4 31.4002C139.4 31.2002 139.4 30.9002 139.4 30.8002C139.4 30.1002 139.3 29.1002 138.9 28.7002C137.9 28.0002 137.3 26.6002 135.7 26.3002C134.8 26.2002 134.1 25.9002 133.2 26.2002C131.8 26.6002 131.1 27.6002 130.2 28.4002C129.3 29.3002 129.2 30.4002 129 31.5002C128.8 32.6002 128.5 33.7002 130 34.3002C130.1 34.3002 130.1 34.5002 130.2 34.8002C130.8 35.7002 131.7 36.4002 132.9 36.3002C133.9 36.2002 134.6 36.4002 135.5 36.5002C136.7 36.9002 137.4 36.5002 137.9 35.3002C137.9 35.2002 138 35.2002 138.1 35.1002C138.9 33.9002 139.6 32.7002 139.4 31.4002Z" />
    </>
  )
}

export default Path18
