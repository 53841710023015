import React from 'react'

const Path17 = () => {
  return (
    <>
      <path d="M58.7002 62.6004C58.7002 61.8004 59.1002 60.7004 59.3002 60.1004C60.0002 58.5004 62.6002 57.8004 64.0002 58.5004C65.1002 59.0004 66.8002 58.4004 67.1002 60.0004C67.2002 60.6004 67.0002 61.3004 67.4002 61.8004C67.5002 61.9004 67.6002 61.9004 67.6002 62.0004C67.7002 62.3004 67.2002 62.6004 67.2002 62.9004C67.2002 63.2004 67.7002 63.4004 67.7002 63.7004C67.7002 63.9004 67.6002 64.0004 67.5002 64.1004C66.4002 65.3004 64.6002 66.6004 62.9002 66.2004C62.7002 66.2004 62.5002 66.1004 62.4002 65.9004C62.3002 65.8004 62.3002 65.7004 62.2002 65.6004C62.0002 65.5004 61.8002 65.6004 61.6002 65.7004C61.0002 65.9004 60.5002 65.2004 60.0002 64.8004C59.6002 64.3004 59.1002 63.9004 58.8002 63.3004C58.7002 63.2004 58.7002 62.9004 58.7002 62.6004Z" />
      <path d="M124.2 61.9C123.9 61.7 123.7 61.4 123.5 61.1C123.5 61.6 123.5 62 123.5 62.5C123.5 63.7 121.6 64.8 120.5 64.9C120.4 64.9 120.2 64.9 120.1 64.8C120 64.7 120 64.6 120 64.5C119.9 64.4 119.8 64.4 119.7 64.3C118.4 63.9 116.8 63.6 116.5 62C116.4 61.3 116.2 60.6 116 59.9C115.9 59.7 116.1 59.2 116.3 59C117.1 57.7 118 56.5 119.7 56.6C120.1 56.6 120.5 56.7 120.9 56.6C121.1 56.5 121.3 56.4 121.4 56.4C121.9 56.2 122.3 56.6 122.6 56.9C123.3 57.6 123.8 58.5 124.1 59.4C124.2 59.8 124.3 62.1 124.4 62.1C124.4 61.9 124.3 61.9 124.2 61.9Z" />
      <path d="M129.3 57.5004V57.4004C129.6 55.9004 130.6 56.4004 131.6 56.5004C132.7 56.6004 133.9 55.7004 134.9 56.5004C135.8 57.2004 135.4 58.8004 136.2 59.5004C136.7 60.0004 137.1 60.4004 136.5 61.1004C136.1 61.5004 135.9 61.6004 135.6 62.1004C134.9 63.1004 134.1 63.9004 132.8 64.0004C132.7 64.0004 132.5 64.0004 132.4 63.9004C132.4 63.8004 132.4 63.7004 132.4 63.7004C132.5 63.4004 132.3 63.0004 132 62.8004C131.9 62.8004 131.9 62.7004 131.8 62.7004C131.6 62.7004 131.5 62.9004 131.5 63.1004C131.5 63.3004 131.5 63.5004 131.4 63.7004C130.8 64.8004 129.7 62.4004 129.5 62.1004C129.3 61.7004 129 61.3004 128.4 61.3004C128.4 61.0004 128.4 60.6004 128.4 60.3004C128.4 60.1004 128.4 59.8004 128.7 59.7004C128.8 59.7004 129 59.7004 129.1 59.6004C129.1 59.6004 129.2 59.5004 129.2 59.4004C129.3 58.6004 129.2 58.1004 129.3 57.5004Z" />
      <path d="M146.7 60.5002C146.2 60.9002 145.8 61.3002 145.3 61.7002C144.8 62.1002 143.1 62.1002 142.5 61.8002C141 61.3002 140.8 59.9002 140.2 58.8002C140.1 58.7002 140.1 58.5002 140.1 58.4002C140.9 57.6002 141 56.2002 142.4 56.0002C143.1 55.9002 143.9 55.9002 144.4 56.2002C145 56.5002 145.9 56.6002 146.3 57.5002C146.7 58.3002 147.1 58.9002 146.6 59.8002C146.4 60.0002 146.6 60.3002 146.7 60.5002Z" />
      <path d="M48.9004 61.1005C49.3004 60.6005 49.7004 60.1005 50.2004 59.7005C51.8004 58.3005 54.0004 59.5005 54.6004 60.8005C54.8004 61.4005 54.6004 62.2005 54.4004 62.9005C54.2004 63.6005 54.0004 64.2005 52.9004 64.4005C52.3004 64.5005 51.7004 65.0005 50.9004 64.7005C50.6004 64.6005 50.4004 64.4005 50.2004 64.2005C50.1004 64.1005 50.1004 64.0005 50.0004 64.0005C49.1004 63.6005 48.8004 63.1005 49.1004 62.1005C49.1004 61.7005 49.0004 61.4005 48.9004 61.1005Z" />
      <path d="M9.49991 64.9004C7.99991 64.9004 6.89991 64.1004 6.39991 62.8004C6.29991 62.6004 6.39991 62.4004 6.39991 62.2004C6.39991 61.8004 6.29991 61.4004 6.39991 61.1004C6.49991 60.8004 6.89991 60.5004 7.19991 60.2004C7.39991 60.0004 7.79991 59.9004 8.09991 59.7004C9.59991 58.8004 11.7999 59.9004 12.0999 61.7004C12.2999 63.4004 10.9999 65.0004 9.49991 64.9004Z" />
      <path d="M29.6996 65.2004C27.9996 65.1004 26.9996 64.0004 27.2996 62.3004C27.2996 62.1004 27.0996 61.8004 27.0996 61.6004C27.0996 61.0004 28.6996 59.9004 29.2996 60.0004C29.4996 60.0004 29.7996 60.1004 29.9996 60.0004C31.4996 59.8004 32.6996 60.5004 32.9996 61.9004C33.2996 63.2004 32.5996 64.5004 30.9996 64.9004C30.4996 65.0004 30.0996 65.1004 29.6996 65.2004Z" />
      <path d="M86.9004 57.5002C87.3004 57.6002 87.8004 57.7002 88.2004 57.9002C88.5004 58.1002 88.7004 58.5002 88.8004 58.9002C89.0004 59.2002 89.0004 59.6002 89.3004 60.0002C89.2004 61.6002 88.4004 62.9002 86.6004 63.1002C86.0004 63.2002 85.1004 62.8002 84.6004 62.4002C84.1004 61.9002 83.6004 61.1002 83.7004 60.5002C83.7004 59.7002 84.1004 58.7002 84.7004 58.2002C85.3004 57.6002 86.2004 57.5002 87.0004 57.2002C86.9004 57.3002 86.9004 57.4002 86.9004 57.5002Z" />
      <path d="M99.4 62.8004C98.3 62.9004 96.5 61.5004 96.5 60.5004C96.5 59.4004 97.6 57.4004 98.7 57.4004C99.6 57.5004 100.7 57.4004 101.3 57.9004C101.9 58.4004 102 59.5004 102.1 60.3004C102.4 61.2004 100.6 62.7004 99.4 62.8004Z" />
      <path d="M45.2999 62.7003C44.9999 62.8003 44.6999 62.8003 44.4999 62.8003C44.4999 63.1003 44.5999 63.4003 44.4999 63.5003C44.0999 63.8003 43.6999 64.2003 43.2999 64.1003C42.2999 64.0003 41.2999 63.8003 40.6999 62.8003C40.4999 62.4003 40.4999 61.8003 40.5999 61.3003C40.6999 60.8003 41.0999 60.4003 41.2999 59.9003C41.4999 59.5003 42.6999 58.8003 43.1999 58.9003C45.0999 59.4003 45.6999 60.9003 45.2999 62.7003Z" />
      <path d="M75.6001 63.4003C75.4001 63.4003 75.2001 63.3003 75.1001 63.3003C73.4001 63.0003 72.7001 61.9003 73.2001 60.2003C73.2001 60.1003 73.2001 60.0003 73.2001 60.0003C73.3001 59.6003 73.4001 59.0003 73.6001 58.9003C74.3001 58.6003 75.0001 58.1003 75.9001 58.3003C77.0001 58.6003 77.8001 60.5003 77.3001 61.7003C77.0001 62.4003 76.8001 63.0003 75.9001 63.0003C75.8001 63.0003 75.7001 63.3003 75.6001 63.4003Z" />
      <path d="M22.9001 62.2002C22.9001 63.4002 22.1001 64.4002 20.6001 64.9002C20.1001 65.1002 18.8001 64.6002 18.4001 64.0002C18.3001 63.8002 18.2001 63.6002 18.2001 63.4002C18.1001 63.1002 18.0001 62.8002 18.0001 62.5002C18.0001 62.3002 17.8001 62.1002 17.9001 62.0002C18.1001 60.9002 19.4001 60.2002 20.5001 60.1002C22.0001 60.0002 22.3001 61.0002 22.9001 62.0002C22.9001 62.1002 22.9001 62.2002 22.9001 62.2002Z" />
      <path d="M106.8 59.6004C107.1 59.2004 107.5 58.8004 107.9 58.4004C108.3 57.9004 108.8 57.8004 109.4 58.1004H109.5C111.1 57.7004 110.9 59.2004 111.4 60.0004C111.7 60.6004 111.4 60.9004 111.1 61.3004C111 61.5004 110.8 61.6004 110.6 61.8004C110.4 62.1004 108.9 62.3004 108.5 62.1004C107.5 61.8004 107.3 60.9004 107.2 60.1004C107.1 60.0004 106.9 59.8004 106.8 59.6004Z" />
      <path d="M31.8998 77.7C31.4998 78.3 30.9998 78.9 30.3998 79.3C29.6998 79.7 28.7998 79.9 28.0998 79.5C27.6998 79.3 27.2998 78.8 26.7998 78.7C26.4998 78.6 26.1998 78.6 25.8998 78.6C24.4998 78.3 24.5998 73.1 26.2998 73.3C26.3998 73.3 26.4998 73.3 26.4998 73.3C26.5998 73.2 26.5998 73.1 26.5998 72.9C26.6998 71.8 27.6998 71.9 28.3998 72.2C28.7998 72.3 29.2998 72.4 29.7998 72.4C29.9998 72.4 30.2998 72.4 30.4998 72.4C31.3998 72.6 31.2998 73.1 31.4998 73.8C31.6998 74.5 32.5998 75.1 32.6998 75.8C32.7998 76.2 32.5998 76.5 32.3998 76.8C32.2998 77 32.0998 77.4 31.8998 77.7Z" />
      <path d="M54.2999 77.4004C53.6999 77.9004 53.0999 78.2004 52.7999 78.3004C52.5999 78.4004 52.4999 78.4004 52.2999 78.4004C52.0999 78.4004 51.9999 78.3004 51.8999 78.3004C51.0999 78.0004 49.8999 77.8004 49.3999 77.0004C48.7999 76.2004 49.0999 74.8004 49.1999 73.9004C49.3999 72.9004 50.3999 72.9004 50.9999 72.3004C51.5999 71.7004 52.2999 71.8004 52.9999 72.1004C53.4999 72.4004 54.3999 72.7004 54.7999 73.1004C55.1999 73.4004 55.0999 74.0004 55.1999 74.5004C55.2999 75.0004 55.6999 75.5004 55.5999 76.0004C55.3999 76.5004 54.6999 77.1004 54.2999 77.4004Z" />
      <path d="M13.6002 77.8001C13.2002 77.7001 12.9002 77.7001 12.5002 77.6001C12.4002 77.6001 12.3002 77.6001 12.3002 77.5001C12.3002 77.4001 12.2002 77.4001 12.2002 77.3001C12.2002 76.7001 12.5002 73.9001 12.2002 73.7001C12.5002 73.9001 12.7002 74.1001 12.8002 74.4001C12.9002 74.2001 13.7002 72.5001 14.0002 72.5001C14.9002 71.8001 15.3002 72.7001 16.0002 73.0001C16.6002 73.3001 17.2002 73.3001 17.7002 73.8001C18.1002 74.3001 18.3002 75.1001 18.3002 75.8001C18.2002 77.8001 16.2002 79.3001 14.3002 78.8001C14.1002 78.7001 13.9002 78.7001 13.7002 78.5001C13.6002 78.3001 13.5002 78.0001 13.6002 77.8001Z" />
      <path d="M86.6999 78.4004C86.1999 78.4004 85.5999 78.5004 85.0999 78.4004C84.6999 78.3004 84.2999 77.9004 83.8999 77.6004C82.5999 76.3004 82.4999 75.9004 83.4999 74.3004C83.7999 73.8004 84.0999 73.3004 84.4999 72.9004C85.3999 72.0004 86.4999 72.3004 87.5999 72.4004C88.5999 72.5004 88.8999 73.3004 88.9999 74.2004C88.9999 74.7004 89.2999 75.2004 89.3999 75.6004C89.4999 76.3004 88.8999 76.7004 88.4999 77.1004C88.1999 77.5004 87.6999 77.8004 87.2999 78.1004C87.0999 78.3004 86.8999 78.4004 86.6999 78.5004C86.6999 78.6004 86.6999 78.5004 86.6999 78.4004Z" />
      <path d="M77.2002 71.8003C77.4002 71.8003 77.5002 71.8003 77.7002 71.9003C77.9002 72.0003 78.0002 72.1003 78.1002 72.3003C78.7002 73.1003 79.2002 74.1003 79.2002 75.1003C79.2002 75.9003 78.8002 76.7003 78.1002 77.2003C77.1002 77.9003 76.5002 77.3003 75.4002 77.3003C75.0002 77.3003 74.7002 77.4003 74.4002 77.1003C74.0002 76.8003 74.1002 76.1003 74.0002 75.7003C73.8002 75.1003 73.2002 74.8003 73.2002 74.1003C73.3002 73.4003 74.0002 72.8003 74.4002 72.3003C74.9002 71.8003 75.4002 72.2003 76.0002 72.1003C76.4002 72.0003 76.8002 71.8003 77.2002 71.8003Z" />
      <path d="M95.9998 75.3003C95.6998 74.8003 95.2998 74.5003 95.2998 74.1003C95.2998 73.8003 95.6998 73.6003 95.9998 73.3003C96.0998 73.2003 96.1998 73.2003 96.2998 73.1003C96.4998 72.6003 96.6998 72.1003 96.8998 71.6003C96.9998 71.7003 96.9998 71.7003 97.0998 71.8003C97.4998 71.8003 98.0998 71.9003 98.3998 71.7003C99.0998 71.2003 99.4998 71.9003 99.9998 72.0003C100.8 72.2003 101.1 72.9003 101.2 73.6003C101.3 74.5003 101.3 75.4003 100.6 76.1003C99.7998 76.9003 98.9998 77.6003 97.6998 77.0003C97.2998 76.8003 96.8998 76.9003 96.3998 76.5003C95.8998 76.1003 95.8998 75.7003 95.9998 75.3003Z" />
      <path d="M137.7 75.8002C136.4 75.8002 136.2 74.7002 135.7 73.8002C135.1 72.8002 135.1 72.0002 136.2 71.5002C136.3 71.5002 136.3 71.3002 136.3 71.1002C136.5 70.3002 137.1 70.1002 137.9 70.1002C139.4 70.0002 140.5 70.7002 141.1 72.0002C141.3 72.5002 140.9 73.4002 140.8 74.1002C140.7 74.4002 140.1 74.6002 140.5 75.1002C140 75.3002 139.5 75.7002 139 75.6002C138.2 75.4002 138.2 75.3002 137.7 75.8002Z" />
      <path d="M127.5 75.1C127.5 74.8 127.6 74.5 127.9 74.4C127.4 74.5 127 74.4 126.6 74.1C125.3 73.1 126.8 71.4 127.4 70.4C127.5 70.2 127.9 70 127.9 70C128.8 70.5 130 70.1 130.8 70.6C131.7 71.2 131.8 72.5 131.6 73.5C131.2 75.1 129.7 75.7 128.2 75.6C128 75.6 127.8 75.6 127.6 75.5C127.6 75.4 127.5 75.2 127.5 75.1Z" />
      <path d="M41.1999 72.8001C41.1999 72.7001 42.4999 73.5001 42.5999 73.6001C43.2999 74.1001 43.7999 74.9001 44.1999 75.6001C44.2999 75.7001 44.2999 75.8001 44.2999 75.9001C44.2999 76.0001 44.1999 76.1001 44.0999 76.2001C43.9999 76.3001 43.9999 76.5001 44.0999 76.5001C43.4999 76.8001 42.9999 77.4001 42.4999 77.9001C41.9999 78.4001 41.7999 78.9001 40.8999 78.5001C40.4999 78.3001 40.1999 78.0001 39.8999 77.7001C39.8999 77.7001 39.0999 77.8001 38.8999 77.5001C38.6999 77.3001 38.5999 76.7001 38.5999 76.4001C38.4999 75.7001 38.4999 74.8001 38.6999 74.1001C38.7999 73.6001 39.3999 73.3001 39.7999 72.9001C39.8999 72.8001 40.0999 72.9001 40.0999 72.9001C40.6999 73.6001 40.8999 73.3001 41.1999 72.8001Z" />
      <path d="M66.1997 73.8002C66.0997 74.0002 65.8997 74.1002 65.5997 74.3002C65.9997 74.5002 66.4997 74.8002 66.8997 75.0002C66.5997 75.5002 66.3997 76.0002 66.0997 76.4002C65.4997 77.4002 64.4997 77.6002 63.3997 77.6002C63.0997 77.6002 62.8997 77.5002 62.6997 77.4002C61.9997 77.0002 61.5997 75.8002 61.2997 75.1002C61.0997 74.7002 61.1997 74.4002 61.6997 74.1002C62.8997 73.4002 62.7997 72.1002 64.2997 72.5002C64.5997 72.6002 66.4997 73.0002 66.1997 73.8002Z" />
      <path d="M118.1 73.5004C118.1 73.4004 118.1 73.3004 118.1 73.2004C118.2 72.6004 118.2 72.0004 118.5 71.4004C119.1 70.1004 120.9 70.0004 122.1 70.7004C121.9 70.8004 121.9 71.1004 122.1 71.2004C122.2 71.3004 122.4 71.2004 122.5 71.3004C122.9 71.4004 123 72.0004 123 72.4004C122.9 73.3004 122.5 74.3004 121.7 74.9004C121 75.4004 120 75.3004 119.3 74.8004C118.9 74.5004 118.7 74.1004 118.4 73.8004C118.2 73.7004 118.1 73.7004 118.1 73.5004Z" />
      <path d="M106.9 73.5001C107.1 72.4001 108.2 71.1001 109 71.1001C110.4 71.1001 111.8 72.3001 111.8 73.5001C111.8 74.5001 110.8 75.7001 110 75.8001C109 76.0001 108.2 75.9001 107.6 75.1001C107.4 74.6001 107.2 74.0001 106.9 73.5001Z" />
      <path d="M149.7 74.1003C149 74.3003 148.4 74.5003 147.8 74.7003C147.6 74.8003 147.3 74.7003 147.1 74.6003C145.7 73.9003 145.2 72.5003 145.9 71.4003C146 71.2003 146.3 71.1003 146.5 71.0003C146.7 70.9003 146.9 70.7003 147.2 70.6003C147.5 70.5003 147.8 70.3003 148.1 70.4003C148.6 70.5003 149.1 70.7003 149.5 71.1003C149.8 71.4003 150.4 71.7003 149.8 72.4003C149.5 72.8003 149.7 73.5003 149.7 74.1003Z" />
      <path d="M0 75.8001C0.1 75.5001 0.1 75.2001 0.2 75.0001C0.7 74.3001 1.2 73.6001 1.8 73.1001C2.6 72.4001 3.8 72.6001 4.7 73.3001C4.9 73.5001 5.3 73.4001 5.5 73.5001C6.1 74.0001 6.4 74.5001 6.3 75.4001C6.2 75.8001 6.4 76.2001 6.3 76.6001C6.2 77.2001 6 78.0001 5.6 78.2001C5.2 78.4001 4.6 78.6001 4.1 78.8001C3.6 79.0001 3 79.0001 2.4 78.6001C2 78.4001 1.6 78.2001 1.2 78.0001C0.8 77.8001 0.7 77.6001 0.6 77.2001C0.4 76.8001 0.2 76.3001 0 75.8001Z" />
    </>
  )
}

export default Path17
