import React from 'react'

const Map = () => {
  return (
    <>
      <path d="M29.8624 4.15714C29.7128 4.06122 29.5412 4.00995 29.3521 4.00542C25.1408 3.89954 21.2093 5.36414 17.4132 6.78267C13.8224 8.12468 10.1088 9.51247 6.21727 9.51277C6.21697 9.51277 6.21667 9.51277 6.21602 9.51277C5.27646 9.51277 4.34013 9.43124 3.43268 9.27074C3.10429 9.21213 2.81638 9.29862 2.58271 9.50351C2.27856 9.66956 2.09795 9.96965 2.08613 10.3303C1.95644 14.3152 2.00055 18.3652 2.04292 22.2819C2.0628 24.0949 2.08297 25.9697 2.08613 27.814C2.08679 28.1668 2.24997 28.4631 2.53574 28.6326C2.71079 28.8669 2.9746 29.0026 3.28162 29.015C3.63907 29.0296 4.00536 29.0369 4.36937 29.0369C4.36937 29.0369 4.36937 29.0369 4.36967 29.0369C9.02741 29.0369 13.5399 27.8212 17.9033 26.6456C21.746 25.6106 25.7197 24.5398 29.7754 24.305C30.067 24.2883 30.3131 24.1673 30.4873 23.9555C30.6656 23.7398 30.752 23.4397 30.7214 23.1482C30.7453 23.0596 30.7574 22.9793 30.759 22.8972C30.7856 21.5782 30.8238 20.2592 30.8623 18.9405C30.9947 14.4015 31.1311 9.70776 30.7586 5.08977C30.7129 4.52491 30.2822 4.21807 29.8624 4.15714ZM28.8747 19.0342C28.8426 20.1534 28.8108 21.273 28.7856 22.3923C25.0423 22.7263 21.3663 23.7152 17.8097 24.6717C13.3699 25.8655 8.76706 27.0981 4.06713 27.0475C4.06092 25.4797 4.04481 23.9119 4.02833 22.3444C3.99073 18.7417 3.95164 15.0179 4.04516 11.3536C4.8074 11.4436 5.5839 11.4892 6.35628 11.4892C9.38599 11.4892 12.5171 10.8139 16.2099 9.3632L17.1426 8.99512C21.0281 7.45836 24.6995 6.00617 28.8311 5.98976C29.1233 10.3357 28.9969 14.7573 28.8747 19.0342Z" />
      <path d="M6.97278 22.7593C6.88021 22.8033 6.71757 22.8518 6.56985 22.8906C6.39331 22.9372 6.21359 22.9712 6.03448 23.0069C5.70275 23.0733 5.51618 23.4618 5.60093 23.7704C5.69535 24.1137 6.03299 24.2705 6.36442 24.2038C6.79248 24.1183 7.20269 24.0195 7.599 23.831C7.90452 23.6855 7.98181 23.2549 7.82192 22.9818C7.6406 22.6721 7.27849 22.6142 6.97278 22.7593Z" />
      <path d="M10.2319 21.362C9.9394 21.5735 9.61514 21.7139 9.29385 21.8424C8.98021 21.9681 8.7632 22.2521 8.8603 22.6059C8.94261 22.9043 9.30949 23.1653 9.62379 23.0392C10.049 22.8689 10.4848 22.7043 10.8584 22.4337C11.1319 22.2355 11.2646 21.8975 11.0813 21.5844C10.9225 21.3141 10.5064 21.1632 10.2319 21.362Z" />
      <path d="M13.4181 19.0351C13.1103 18.8548 12.7603 18.9793 12.5687 19.2576C12.5638 19.2652 12.5269 19.3136 12.5119 19.3346C12.4707 19.3846 12.4278 19.4334 12.3848 19.4813C12.2758 19.6019 12.16 19.7155 12.0452 19.8304C11.8059 20.0699 11.8059 20.4693 12.0452 20.7082C12.2842 20.9473 12.6839 20.9476 12.9229 20.7082C13.1818 20.4493 13.4328 20.1872 13.6407 19.884C13.8326 19.6048 13.6937 19.1966 13.4181 19.0351Z" />
      <path d="M13.2604 16.0796C12.9221 16.0796 12.6396 16.3621 12.6396 16.7001V17.3208C12.6396 17.659 12.9221 17.9415 13.2604 17.9415C13.5986 17.9415 13.8811 17.659 13.8811 17.3208V16.7001C13.8811 16.3621 13.5986 16.0796 13.2604 16.0796Z" />
      <path d="M13.2503 11.9889C12.6639 12.2112 12.0512 12.5498 11.8501 13.19C11.6568 13.8051 11.8988 14.4178 12.2589 14.918C12.456 15.1921 12.7955 15.324 13.1082 15.1409C13.3792 14.982 13.5289 14.5667 13.3312 14.2918C13.1979 14.1068 12.9773 13.8166 13.0238 13.5957C13.067 13.3908 13.3963 13.2561 13.5811 13.1863C14.3217 12.9054 14.0004 11.7051 13.2503 11.9889Z" />
      <path d="M15.6559 11.1356L14.9576 11.3683C14.6371 11.4751 14.4307 11.7926 14.524 12.1319C14.6101 12.445 14.9667 12.6724 15.2875 12.5653L15.9858 12.3325C16.3065 12.2258 16.5129 11.9084 16.4196 11.5691C16.3334 11.2563 15.9772 11.0288 15.6559 11.1356Z" />
      <path d="M19.477 10.9032C19.0312 10.7329 18.5472 10.6547 18.0705 10.6485C17.2695 10.6379 17.2707 11.8793 18.0705 11.8899C18.4445 11.8948 18.797 11.9669 19.1467 12.1007C19.4634 12.2213 19.8271 11.9696 19.9105 11.6671C20.0069 11.317 19.7925 11.024 19.477 10.9032Z" />
      <path d="M21.565 14.3653C21.8433 14.2024 21.9769 13.797 21.7881 13.5162C21.5917 13.224 21.3784 12.9441 21.1674 12.6627C20.9647 12.3927 20.6352 12.2542 20.3179 12.4402C20.0509 12.5965 19.8918 13.018 20.0952 13.2892C20.3061 13.5708 20.5195 13.851 20.7159 14.1427C20.904 14.4227 21.2593 14.5444 21.565 14.3653Z" />
      <path d="M22.3378 15.8468C22.2972 15.5109 22.0863 15.2261 21.7171 15.2261C21.4096 15.2261 21.0558 15.5101 21.0965 15.8468C21.2068 16.7574 21.5956 17.5366 22.2869 18.1476C22.884 18.6759 23.7656 17.8012 23.1647 17.2699C22.7388 16.8934 22.4082 16.4251 22.3378 15.8468Z" />
      <path d="M27.6169 20.2764C27.3023 20.0679 26.9829 19.8664 26.6649 19.6635C26.8868 19.232 27.0956 18.7928 27.2817 18.3394C27.5854 17.5986 26.384 17.2792 26.0845 18.0093C25.9454 18.349 25.7875 18.6789 25.6211 19.0046C25.3041 18.8076 24.9867 18.6104 24.6685 18.4145C23.9851 17.9936 23.3616 19.0677 24.042 19.4865C24.3645 19.685 24.6861 19.8854 25.008 20.0848C24.8037 20.4106 24.5916 20.7318 24.3627 21.0422C23.8869 21.6878 24.9649 22.3057 25.4347 21.6687C25.656 21.3686 25.8599 21.0598 26.0551 20.7464C26.3676 20.9462 26.6816 21.1434 26.9905 21.3486C27.6596 21.7921 28.2806 20.7164 27.6169 20.2764Z" />
    </>
  )
}

export default Map
