import React from 'react'

const Path03 = () => {
  return (
    <>
      <path
        // relative coords
        d="M1 0.480209C1 0.727199 0.768248 0.987097 0.518248 0.987097C0.268248 0.987097 0 0.727199 0 0.480209C0 0.23408 0.268248 0 0.518248 0C0.768248 0 1 0.23408 1 0.480209Z"
        fill="currentColor"
      />
    </>
  )
}

export default Path03
