import React from 'react'

import { useAppDispatch } from '../../redux/store'
import useTypedSelector from '../../hooks/useTypedSelector'
import checkoutProceed from '../../redux/modules/store/thunks/checkoutProceed'
import Button from '../Button'

const ButtonCheckout = () => {
  // Store: redux
  const dispatch = useAppDispatch()
  const checkout = useTypedSelector(state => state.store?.checkout)
  const checkoutUpdating = useTypedSelector(
    state => state.store.checkoutUpdating
  )
  // Callbacks
  const handleClick = async () => {
    // Augment our checkout with custom attributes
    // TODO: clarify empty object arg
    // https://github.com/reduxjs/redux-toolkit/issues/489#issuecomment-611214385
    const resultAction = await dispatch(checkoutProceed({}))

    if (!resultAction) {
      return
    }

    if (checkoutProceed.fulfilled.match(resultAction)) {
      const url = resultAction.payload
      window.location.href = url
    } else {
      // TODO: dispatch sentry error
    }
  }

  const hasItems = !!((checkout?.lineItems?.edges?.length || 0) > 0)

  return (
    <Button disabled={checkoutUpdating || !hasItems} onClick={handleClick}>
      Continue to checkout
    </Button>
  )
}

export default ButtonCheckout
