import gql from 'graphql-tag'

import { initializeApollo } from '../../lib/apolloClient'
import { CHECKOUT_FRAGMENT } from '../../lib/shopifyFragments'
import {
  AttributeInput,
  CheckoutLineItemInput,
  CurrencyCode,
  ShopifyCheckoutCreateMutation,
} from '../../types/codegen/shopify'

const createCheckout = async ({
  currencyCode,
  customAttributes,
  lineItems,
}: {
  currencyCode: CurrencyCode
  customAttributes?: AttributeInput[]
  lineItems?: CheckoutLineItemInput[]
}) => {
  const apolloClient = initializeApollo()

  const result = await apolloClient.mutate<ShopifyCheckoutCreateMutation>({
    mutation: gql`
      ${CHECKOUT_FRAGMENT}
      mutation ShopifyCheckoutCreate(
        $currencyCode: CurrencyCode
        $customAttributes: [AttributeInput!]
        $lineItems: [CheckoutLineItemInput!]
      ) {
        checkoutCreate(
          input: {
            customAttributes: $customAttributes
            lineItems: $lineItems
            presentmentCurrencyCode: $currencyCode
          }
        ) {
          checkout {
            ...checkout
          }
        }
      }
    `,
    variables: {
      currencyCode,
      customAttributes,
      lineItems: lineItems || [],
    },
  })

  return result
}

export default createCheckout
