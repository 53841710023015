import React from 'react'

const Path17 = () => {
  return (
    <>
      <path d="M148 150C99.4424 150 51.409 150 3 150C3.26603 148.714 3.39907 147.633 3.71987 146.621C14.9164 111.337 26.1913 76.077 37.3175 40.7695C40.9949 29.1049 47.2386 18.7886 53.8501 8.73097C58.6777 1.3857 66.5021 -0.464351 74.8506 0.0922281C81.1335 0.507702 86.5635 3.04757 91.1251 7.53156C96.5473 12.8622 100.78 19.0237 104.489 25.6086C121.037 54.9662 132.703 86.26 141.036 118.839C143.391 128.043 145.371 137.34 147.499 146.598C147.726 147.531 147.789 148.511 148 150Z" />
    </>
  )
}

export default Path17
