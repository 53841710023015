import React from 'react'

const PaperPlane = () => {
  return (
    <>
      <path d="M4.99659 31.1996C5.15957 31.2947 5.36576 31.3346 5.61554 31.2796C9.65526 30.3943 13.6467 28.656 17.6353 27.5672C17.6841 27.5983 17.7343 27.6281 17.7929 27.653C21.2243 29.0901 24.7512 30.9074 28.3175 31.9573C28.4994 32.0107 28.6696 32.01 28.8266 31.9773C29.4603 31.9666 30.082 31.4061 29.7303 30.6749C25.3156 21.5026 21.3428 12.1333 17.7195 2.62134C17.5673 2.22133 17.2576 2.04733 16.9331 2.03241C16.5246 1.92674 16.0859 2.07034 15.9202 2.59821C12.9827 11.9619 9.97209 21.4683 4.35151 29.6244C3.88189 30.306 4.39071 31.0608 4.99659 31.1996ZM27.0317 29.5379C24.1686 28.6531 21.337 27.019 18.5691 25.8599C18.5041 25.8324 18.4403 25.8146 18.3782 25.8014C18.3491 20.1416 18.3685 15.1442 18.3086 9.48442C21.0156 16.2545 23.9113 22.9461 27.0317 29.5379ZM16.3388 7.69647C16.4301 13.9792 16.4067 19.5999 16.4383 25.8833C13.3454 26.7251 10.2489 28.2111 7.12757 28.938C11.2655 22.3632 13.9513 15.0719 16.3388 7.69647Z" />
    </>
  )
}

export default PaperPlane
