import React, { ReactNode } from 'react'
import { Box } from 'theme-ui'

type PortableTextList = {
  children: ReactNode
  level: number
  singleColumn?: boolean
  type: 'bullet' | 'number'
}

const PortableTextList = (props: PortableTextList) => {
  const { singleColumn, children, level, type } = props

  return (
    <Box
      mx={singleColumn ? 'auto' : 0}
      my={4}
      px={singleColumn ? [4, null, null, 6] : 0}
      sx={{
        maxWidth: singleColumn ? 'moduleTextOneColMaxWidth' : 'none',
      }}
    >
      <Box as={type === 'bullet' ? 'ul' : 'ol'}>{children}</Box>
    </Box>
  )
}

export default PortableTextList
