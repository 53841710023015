import NextLink from 'next/link'
import React from 'react'
import { Box } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import { selectSectionShop } from '../../redux/modules/menu'
import getSingletonHref from '../../utils/getSingletonHref'
import Button from '../Button'
import CardSlimList from '../CardSlimList'
import DividerHorizontalRandom from '../DividerHorizontalRandom'
import MenuSectionWrapper from '../MenuSectionWrapper'

const MenuSectionShop = () => {
  const menuShop = useTypedSelector(selectSectionShop)

  return (
    <MenuSectionWrapper
      documentTypeIndex="shop"
      documentTypeLabel="View shop overview"
    >
      <Box>
        {menuShop?.collections?.map((collection, index) => (
          <Box key={index}>
            <CardSlimList
              headerIllustrationType={collection?.illustration}
              href={collection.slug}
              items={collection.products}
              title={collection.title}
            />

            {index !== menuShop.collections.length - 1 && (
              <DividerHorizontalRandom color="stone" my={8} />
            )}
          </Box>
        ))}
      </Box>

      <Box
        mt={10}
        sx={{
          display: ['none', null, null, 'block'],
        }}
      >
        <NextLink href={getSingletonHref('productIndex')}>
          <a>
            <Button sx={{ width: '100%' }} variant="outline">
              Shop all products
            </Button>
          </a>
        </NextLink>
      </Box>
    </MenuSectionWrapper>
  )
}

export default MenuSectionShop
