import React, { useEffect, useState } from 'react'
import Path01 from './paths/Path01'
import Path02 from './paths/Path02'
import Path03 from './paths/Path03'

const PATH_COMPONENTS = [Path01, Path02, Path03]

type Props = {
  maskId: string
  patternIndex?: number
}

const MaskCircleRandom = (props: Props) => {
  const { maskId, patternIndex } = props

  // Generate random ID
  const [pathIndex, setPathIndex] = useState<number>(-1)

  const numPaths = PATH_COMPONENTS.length

  // Randomly pick a path if no patternIndex is provided
  useEffect(() => {
    const index = patternIndex
      ? patternIndex % numPaths
      : Math.floor(Math.random() * numPaths)

    setPathIndex(index)
  }, [])

  const Paths = PATH_COMPONENTS[pathIndex]

  if (!Paths) {
    return null
  }

  return (
    <svg
      height={0}
      preserveAspectRatio="xMidYMid meet"
      style={{ display: 'block' }}
      width={0}
      viewBox="0 0 310 306"
    >
      <defs>
        <clipPath clipPathUnits="objectBoundingBox" id={maskId}>
          <Paths />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MaskCircleRandom
