import React from 'react'
import { Flex, Heading } from 'theme-ui'
import ButtonIcon from '../ButtonIcon'

type Props = {
  onClose: () => void
  title?: string
}

const PanelHeader = (props: Props) => {
  const { onClose, title } = props
  return (
    <Flex
      mx={[4, null, null, 6]}
      py={[3, null, null, 6]}
      sx={{
        alignItems: 'center',
        borderBottom: '1px solid',
        borderColor: 'stone',
        display: 'flex',
        flexShrink: 0,
        justifyContent: 'space-between',
      }}
    >
      <Heading
        sx={{
          fontSize: ['m', null, null, 'l'],
          fontWeight: 'semibold',
          lineHeight: 'single',
        }}
      >
        {title}
      </Heading>
      <ButtonIcon
        background="stone"
        iconSize="12px"
        onClick={onClose}
        size="34px"
        sx={{
          alignSelf: 'flex-start',
          flexShrink: 0,
        }}
        type="close"
      />
    </Flex>
  )
}

export default PanelHeader
