import { LinkVariant } from '@types'
import React, { forwardRef, FunctionComponent, ReactNode } from 'react'
import { BoxOwnProps, Link as ThemeLink, ThemeUIStyleObject } from 'theme-ui'

type Props = {
  active?: boolean
  children?: ReactNode
  disabled?: boolean
  href?: string
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  rel?: string
  sx?: ThemeUIStyleObject
  target?: string
  variant?: LinkVariant
}

type Ref = HTMLAnchorElement

const Link: FunctionComponent<BoxOwnProps & Props> = forwardRef<Ref, Props>(
  (props: Props, ref) => {
    const {
      active,
      children,
      disabled,
      href,
      onClick,
      onMouseEnter,
      onMouseLeave,
      rel,
      sx,
      target,
      variant = 'default',
      ...rest
    } = props

    let styles = {}
    let hoverStyles = {}

    switch (variant) {
      case 'opacity':
        {
          hoverStyles = {
            opacity: 0.5,
          }
          styles = {}
        }
        break
      case 'simple':
        {
          hoverStyles = disabled
            ? {}
            : {
                borderBottom: '1px solid currentColor',
              }
          styles = {
            borderBottom: '1px solid transparent',
            opacity: disabled ? 0.5 : 1,
          }
        }
        break
      case 'underline':
        {
          hoverStyles = disabled
            ? {}
            : {
                opacity: 0.5,
              }
          styles = {
            borderBottom: '1px solid currentColor',
            opacity: disabled ? 0.5 : 1,
          }
        }
        break
      default:
        break
    }

    return (
      <ThemeLink
        href={disabled ? undefined : href}
        onClick={disabled ? undefined : onClick}
        onMouseEnter={disabled ? undefined : onMouseEnter}
        onMouseLeave={disabled ? undefined : onMouseLeave}
        ref={ref}
        rel={rel}
        sx={{
          borderBottom: '1px solid transparent',
          color: 'currentColor',
          cursor: disabled ? 'default' : 'pointer',
          textDecoration: 'none',
          transition: '150ms all',
          ...styles,
          '@media (hover: hover) and (pointer: fine)': {
            '&:focus': hoverStyles,
            '&:hover': hoverStyles,
          },
          ...(active && hoverStyles),
          ...sx,
        }}
        target={target}
        {...rest}
      >
        {children}
      </ThemeLink>
    )
  }
)

export default Link
