import React from 'react'

const Tick = () => {
  return (
    <>
      <path d="M29.5998 4.58308C29.3868 4.20717 29.0467 4 28.6422 4C28.415 4 28.1858 4.0664 27.9956 4.18381C27.8622 4.24689 27.7489 4.32588 27.6495 4.42529C21.6075 10.4651 16.4678 15.4758 11.0627 20.2627L8.22535 16.8179C7.94995 16.4835 7.64305 16.4133 7.43438 16.4133C7.18352 16.4133 6.92677 16.515 6.73135 16.6842L6.68517 16.7087C5.14812 17.6969 3.71055 18.5263 2.29124 19.2437C1.91298 19.4345 1.69257 19.7644 1.68233 20.153C1.50706 20.5826 1.60947 21.0357 1.95955 21.3702C3.22929 22.5851 4.40999 23.9279 5.55208 25.2267C6.80069 26.6467 8.09143 28.1153 9.50826 29.4274C9.55849 29.4747 9.6138 29.5171 9.67584 29.5582C9.88633 29.8397 10.1814 30 10.4895 30C10.7086 30 10.9232 29.9219 11.1278 29.7675C15.0881 26.7795 18.6212 23.4066 21.2571 20.8022C22.0305 20.0373 22.8076 19.2735 23.5853 18.5097C26.309 15.8332 29.1254 13.0654 31.7898 10.2563C31.9356 10.1031 32.0346 9.92537 32.0843 9.72831C32.3175 9.36662 32.3065 8.94192 32.0522 8.55805C31.1321 7.172 30.3295 5.8716 29.5998 4.58308ZM29.5882 9.11816C27.6256 11.1658 25.569 13.1931 23.5797 15.155C22.5293 16.1901 21.4797 17.2253 20.4393 18.2667C17.468 21.2405 14.2218 24.3769 10.6064 27.1881C9.42438 26.0334 8.3136 24.7738 7.23844 23.5545C6.3996 22.6032 5.53395 21.6221 4.63582 20.6914C5.44713 20.2488 6.27044 19.7665 7.14275 19.2236L10.0862 22.7969C10.362 23.1325 10.6699 23.2027 10.8795 23.2027C11.1475 23.2027 11.4157 23.0894 11.6378 22.8841C11.7165 22.8393 11.7871 22.7894 11.8523 22.7324C17.6324 17.6682 23.1825 12.2339 28.3245 7.1058C28.6965 7.72351 29.1119 8.38499 29.5882 9.11816Z" />
    </>
  )
}

export default Tick
