import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { Box } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import { teamMemberPanelActions } from '../../redux/modules/teamMemberPanel'
import { useAppDispatch } from '../../redux/store'
import { customScrollbarStyles } from '../../styled/theme'
import ButtonIcon from '../ButtonIcon'
import Panel from '../Panel'
import PortableText from '../PortableText'
import TeamMemberProfile from '../TeamMemberProfile'

const PanelTeamMember = () => {
  const router = useRouter()

  // Redux
  const dispatch = useAppDispatch()
  const teamMember = useTypedSelector(state => state.teamMemberPanel.teamMember)
  const visible = useTypedSelector(state => state.teamMemberPanel.visible)

  // Callbacks
  const handleClose = () => {
    dispatch(teamMemberPanelActions.setVisible({ visible: false }))
  }

  // Close on route change
  useEffect(() => {
    router.events.on('routeChangeComplete', handleClose)

    return () => {
      router.events.off('routeChangeComplete', handleClose)
    }
  }, [])

  return (
    <Panel onClickOutside={handleClose} visible={visible}>
      {/* Close button */}
      <Box
        sx={{
          position: 'fixed',
          right: [4, null, null, 6],
          top: [4, null, null, 6],
          zIndex: 1,
        }}
      >
        <ButtonIcon
          background="stone"
          iconSize="12px"
          onClick={handleClose}
          size="34px"
          type="close"
        />
      </Box>

      <Box
        sx={{
          height: '100%',
          overflowY: 'auto',
          ...customScrollbarStyles(),
        }}
      >
        <Box mt={20} px={[4, null, null, 6]}>
          {/* Profile */}
          {teamMember && <TeamMemberProfile teamMember={teamMember} />}

          {/* Bio */}
          {teamMember?.bio && (
            <Box
              mt={5}
              sx={{
                li: {
                  fontSize: 's',
                },
                p: {
                  fontSize: 's',
                  lineHeight: 'body',
                  mb: '1.4em',
                },
              }}
            >
              <PortableText blocks={teamMember.bio} />
            </Box>
          )}
        </Box>
      </Box>
    </Panel>
  )
}

export default PanelTeamMember
