import { css, Global } from '@emotion/react'
import iubenda from '../../styled/iubenda'
import theme from '../../styled/theme'

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'DO';
          src: url('/fonts/DO-Semibold.woff2') format('woff2'),
            url('/fonts/DO-Semibold.woff') format('woff');
          font-weight: 600;
          font-style: normal;
        }

        @font-face {
          font-family: 'Spezia';
          src: url('/fonts/Spezia-Medium.woff2') format('woff2'),
            url('/fonts/Spezia-Medium.woff') format('woff');
          font-weight: 500;
          font-style: normal;
        }

        @font-face {
          font-family: 'Spezia';
          src: url('/fonts/Spezia-MediumItalic.woff2') format('woff2'),
            url('/fonts/Spezia-MediumItalic.woff') format('woff');
          font-weight: 500;
          font-style: italic;
        }

        @font-face {
          font-family: 'Spezia';
          src: url('/fonts/Spezia-Semibold.woff2') format('woff2'),
            url('/fonts/Spezia-Semibold.woff') format('woff');
          font-weight: 600;
          font-style: normal;
        }

        * {
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          box-sizing: border-box;
          /* letter-spacing: ${theme.letterSpacings.default}; */

          ::selection {
            background: #eee;
          }
        }

        a {
          color: currentColor;
          text-decoration: none;
        }

        body {
          margin: 0;
          --letter-spacing: ${theme.letterSpacings.default};
        }

        body * {
          // Force computed values for letter-spacing
          letter-spacing: var(--letter-spacing);
        }

        html {
          background: ${theme.colors.white};
          color: ${theme.colors.midnight};
          font-family: ${theme.fonts.sans};
          font-weight: ${theme.fontWeights.medium};
          // Convert to number to ensure value is computed per-element
          line-height: ${parseFloat(theme.lineHeights.default)};
          overflow-x: hidden;
        }

        ol {
          line-height: ${theme.lineHeights.body};
          padding-left: 1.5em;
        }

        strong {
          font-weight: ${theme.fontWeights.semibold};
        }

        ul {
          line-height: ${theme.lineHeights.body};
          padding-left: 1.5em;
        }

        #__next {
          height: 100%;
        }

        /* Iubenda overrides */
        ${iubenda}

        /* embla-carousel */
        .embla {
          overflow: hidden;
        }
        .embla__container {
          display: flex;
        }
        .embla__slide {
          position: relative;
          flex: 0 0 100%;
        }
        .embla__viewport {
          overflow: hidden;
          width: 100%;
        }
        .embla__viewport.is-draggable {
          cursor: move;
          cursor: grab;
        }
        .embla__viewport.is-dragging {
          cursor: grabbing;
        }

        /* Plyr overrides */
        /* https://github.com/sampotts/plyr#customizing-the-css */
        :root {
          --plyr-control-icon-size: 16px;
          --plyr-control-radius: 0px;
          --plyr-control-spacing: 6px;
          --plyr-color-main: rgba(255, 255, 255, 0.05);
          --plyr-font-size-time: 12px;
          /* --plyr-menu-arrow-color: rgba(255, 255, 255, 0.8); */
          /* --plyr-menu-background: rgba(30, 30, 30, 0.8); */
          --plyr-menu-back-border-shadow-color: rgba(255, 255, 255, 0);
          /* --plyr-menu-color: rgba(255, 255, 255, 0.8); */
          /* --plyr-menu-item-arrow-color: rgba(255, 255, 255, 0.5); */
          --plyr-range-thumb-height: 8px;
          --plyr-range-track-height: 2px;
          --plyr-range-fill-background: rgba(255, 255, 255, 0.6);
          --plyr-video-control-color-hover: rgba(0, 0, 0, 1);
        }

        // Don't display plyr controls when stopped (before playback)
        .plyr.plyr--stopped .plyr__controls {
          display: none;
        }

        .plyr--video {
          background: #000000;
        }

        // Hide linear-gradient beneath controls
        .plyr--video .plyr__controls {
          background-image: none;
        }

        /* Use blurred background for centered play button, force large size */
        .plyr__control--overlaid {
          background: rgba(0, 0, 0, 0.2);
          backdrop-filter: blur(20px);
          padding: 30px;
          &:hover {
            opacity: 0.75;
          }

          svg {
            height: 40px;
            width: 40px;
          }
        }

        .plyr__progress {
          flex: 1;
        }

        .plyr--video {
          height: 100%;
        }

        .plyr--fullscreen-fallback .plyr__video-wrapper {
          height: 100% !important;
        }

        .plyr__video-wrapper,
        .plyr__video-embed {
          height: 100%;
          padding-bottom: 0 !important;
        }

        .plyr__video-embed__container {
          height: 100% !important;
          transform: none !important;
          padding-bottom: 0 !important;
        }

        .plyr__video-wrapper {
          background: none;
        }

        /* Hide controls at smaller breakpoints */
        @media screen and (max-width: ${theme.breakpoints?.[1]}) {
          .plyr__control[data-plyr='play'] {
            display: none;
          }
          .plyr__volume {
            display: none;
          }
        }
      `}
    />
  )
}

export default GlobalStyles
