import React, { FunctionComponent, ReactNode } from 'react'
import { Box, BoxOwnProps, ThemeUIStyleObject } from 'theme-ui'
import { findLastNonNullValue } from '../../utils/findLastNonNullValue'

type Props = {
  aspect: ('auto' | number | null)[]
  children?: ReactNode
  sx?: ThemeUIStyleObject
}

const BoxResponsive: FunctionComponent<BoxOwnProps & Props> = (
  props: Props
) => {
  const { aspect, children, sx, ...rest } = props

  const items = aspect

  const containerPaddingBottom = items.map((val, index) => {
    let current = val
    if (val === null) {
      current = findLastNonNullValue(items, index)
    }

    if (current === 'auto') {
      return 0
    }

    if (typeof current === 'number') {
      return `${100 / current}%`
    }
  })

  const containerHeight = items.map((val, index) => {
    let current = val
    if (val === null) {
      current = findLastNonNullValue(items, index)
    }

    if (current === 'auto') {
      return 'auto'
    }

    if (typeof current === 'number') {
      return 0
    }
  })

  const childPosition = items.map((val, index) => {
    let current = val
    if (val === null) {
      current = findLastNonNullValue(items, index)
    }

    if (current === 'auto') {
      return 'relative'
    }

    if (typeof current === 'number') {
      return 'absolute'
    }
  })

  return (
    <Box
      sx={{
        height: containerHeight,
        pb: containerPaddingBottom,
        position: 'relative',
        width: '100%',
        ...sx,
      }}
      {...rest}
    >
      <Box sx={{ height: '100%', position: childPosition, width: '100%' }}>
        {children}
      </Box>
    </Box>
  )
}

export default BoxResponsive
