import {
  ArticlePreview,
  EventPreview,
  ManifestoPreview,
  OnlineCoursePreview,
  PlaylistPreview,
  ProductPreview,
  ReadingListPreview,
  Reference,
  TagTone,
  TalkPreview,
  WorkshopPreview,
} from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import Tags from '../Tags'

type Props = {
  collapsed?: boolean
  item:
    | ArticlePreview
    | EventPreview
    | ManifestoPreview
    | OnlineCoursePreview
    | PlaylistPreview
    | ProductPreview
    | ReadingListPreview
    | TalkPreview
    | WorkshopPreview
  tone?: TagTone
}

const ReferencesTags = (props: Props) => {
  const { collapsed = true, item, tone } = props

  let references: Reference[] = []

  if (item._type !== 'playlist') {
    references = [
      // Tags
      ...(item?.tags ? item.tags : []),
      // Moods
      // ...(item?.moods ? item.moods : []),
    ]
  }

  if (item._type === 'talk') {
    references = [
      // Event type
      ...(item?.eventType ? [item.eventType] : []),
      // Event year
      ...(item?.eventYear ? [item.eventYear] : []),
      ...references,
    ]
  }

  // Filter out null values
  const filteredReferences = references.filter(v => v !== null)

  return (
    <Box>
      <Tags collapsed={collapsed} references={filteredReferences} tone={tone} />
    </Box>
  )
}

export default ReferencesTags
