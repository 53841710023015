import { AnimatePresence } from 'framer-motion'
import React, { ReactNode, useRef, useState } from 'react'
import { useEffect } from 'react'
import useClickOutside from '../../hooks/useClickOutside'
import BoxMotion from '../BoxMotion'
import FlexMotion from '../FlexMotion'
import Portal from '../Portal'

type Props = {
  children: ReactNode
  onClickOutside?: () => void
  onUnmount?: () => void
  visible?: boolean
}

const Panel = (props: Props) => {
  const { children, onClickOutside, onUnmount, visible } = props

  // Refs
  const refContainer = useRef<HTMLDivElement>(null)

  // State
  const [mounted, setMounted] = useState(false)

  // Effects
  useEffect(() => {
    setMounted(true)
  }, [])

  // Close when clicked outside
  useClickOutside(refContainer, () => {
    if (onClickOutside) {
      onClickOutside()
    }
  })

  // Don't render portals on initial mount
  if (!mounted) {
    return null
  }

  return (
    <Portal id="portal-panel">
      <AnimatePresence
        onExitComplete={() => {
          if (onUnmount) {
            onUnmount()
          }
        }}
      >
        {/* Overlay */}
        {visible && (
          <BoxMotion
            // framer-motion
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            transition={{
              damping: 20,
              stiffness: 125,
              type: 'spring',
            }}
            // theme-ui
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              left: 0,
              position: 'fixed',
              size: '100%',
              top: 0,
              zIndex: 'cart',
            }}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {/* Cart */}
        {visible && (
          <FlexMotion
            // framer-motion
            animate={{ x: '0%' }}
            exit={{ x: '100%' }}
            initial={{ x: '100%' }}
            transition={{
              damping: 20,
              stiffness: 125,
              type: 'spring',
            }}
            // theme-ui
            bg="white"
            ref={refContainer}
            sx={{
              flexDirection: 'column',
              height: '100%',
              position: 'fixed',
              right: 0,
              top: 0,
              maxWidth: ['none', null, '530px'],
              width: '100%',
              zIndex: 'cart',
            }}
          >
            {children}
          </FlexMotion>
        )}
      </AnimatePresence>
    </Portal>
  )
}

export default Panel
