import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SearchState } from '@types'
import { menuActions } from '../menu'
import { setVisible } from './actions'

type SearchReducerState = {
  searchState?: SearchState
  visible: boolean
}

export const initialState = {
  visible: false,
} as SearchReducerState

const searchSlice = createSlice({
  name: 'search',
  initialState,
  extraReducers: builder => {
    builder
      // This action is referenced in other reducers, and is imported to avoid circular dependency issues
      .addCase(setVisible, (state, action) => {
        state.visible = action.payload.visible
      })
      // Close search panel when menu is visible
      .addCase(menuActions.setSmallVisible, (state, action) => {
        if (action.payload.visible) {
          state.visible = false
        }
      })
      .addCase(menuActions.setLargeActiveSection, (state, action) => {
        state.visible = false
      })
  },
  reducers: {
    clearSearchState(state) {
      delete state.searchState
    },
    setSearchState(state, action: PayloadAction<{ searchState: SearchState }>) {
      const updatedSearchState = {
        _updatedAt: new Date().getTime(), // Touch with current time to force update
        ...action.payload?.searchState,
      }

      state.searchState = updatedSearchState
    },
  },
})

export const searchActions = {
  ...searchSlice.actions,
  setVisible,
}

export default searchSlice.reducer
