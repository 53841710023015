import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Box, Flex } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import { systemActions } from '../../redux/modules/system'
import { useAppDispatch } from '../../redux/store'
import { customScrollbarStyles } from '../../styled/theme'
import { ShopifyProductVariant } from '../../types'
import ButtonAddVariantToCart from '../ButtonAddVariantToCart'
import Panel from '../Panel'
import PanelHeader from '../PanelHeader'
import PillProductVariant from '../PillProductVariant'

const PanelProductVariantSelector = () => {
  const router = useRouter()

  // State
  const [
    selectedVariant,
    setSelectedVariant,
  ] = useState<ShopifyProductVariant>()

  // Redux
  const dispatch = useAppDispatch()
  const productPanel = useTypedSelector(
    state => state.system.productVariantPanel
  )

  // Callbacks
  const handleClose = () => {
    dispatch(systemActions.hideProductVariantPanel())
  }

  // Close on route change
  useEffect(() => {
    router.events.on('routeChangeComplete', handleClose)

    return () => {
      router.events.off('routeChangeComplete', handleClose)
    }
  }, [])

  const options = productPanel.product?.options
    ?.map(option => option.name.toLowerCase())
    .join(' & ')

  return (
    <Panel
      onClickOutside={handleClose}
      onUnmount={() => setSelectedVariant(undefined)}
      visible={productPanel.visible}
    >
      {/* Header */}
      <PanelHeader onClose={handleClose} title={productPanel.title} />

      <Box
        sx={{
          overflowY: 'auto',
          ...customScrollbarStyles(),
        }}
      >
        <Box mt={6} px={[4, null, null, 6]}>
          <Box
            color="slate"
            sx={{
              fontSize: ['xs', null, null, 'm'],
              fontWeight: 'semibold',
            }}
          >
            Select {options}
          </Box>
          <Box mt={4}>
            {productPanel.product?.variants?.map((variant, index) => (
              <Box key={index}>
                <PillProductVariant
                  label={variant.title}
                  onClick={() => setSelectedVariant(variant)}
                  productVariant={variant}
                  selected={variant.id === selectedVariant?.id}
                  singlePurchase={productPanel.singlePurchase}
                  sx={{ mb: 2 }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      {/* Footer */}
      <Flex
        mt="auto"
        px={[4, null, null, 6]}
        py={6}
        sx={{
          width: '100%',
        }}
      >
        <ButtonAddVariantToCart
          onClick={handleClose}
          productVariant={selectedVariant}
          singlePurchase={productPanel.singlePurchase}
          sx={{ width: '100%' }}
        />
      </Flex>
    </Panel>
  )
}

export default PanelProductVariantSelector
