import { LinkedLayout } from '@types'
import React, { useState } from 'react'
import { Box, Flex } from 'theme-ui'
import Artboard from '../Artboard'
import NextImageZoomable from '../NextImageZoomable'

type Props = {
  linkedLayout?: LinkedLayout
  title: string
}

const Pill = (props: Props) => {
  const { linkedLayout, title } = props

  // State
  const [hover, setHover] = useState(false)

  return (
    <Flex
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      p={2}
      sx={{
        alignItems: 'center',
        borderRadius: 1,
        height: '80px',
        overflow: 'hidden',
        position: 'relative',
        transition: 'background 300ms',
        width: '100%',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover:not(:disabled)': {
            bg: 'stone',
          },
        },
      }}
    >
      {/* Artboard */}
      {linkedLayout?.artboard && (
        <Artboard artboard={linkedLayout.artboard} scaleMultiplier={0.5} />
      )}

      {/* Image */}
      {linkedLayout?.image && (
        <Box
          sx={{
            borderRadius: 1,
            height: '64px',
            overflow: 'hidden',
            position: 'relative',
            transform: 'translate3d(0,0,0)', // Force depth on Safari
            width: '64px',
          }}
        >
          <NextImageZoomable
            image={linkedLayout.image}
            layout="fill"
            sizes="64px"
            zoomed={hover}
          />
        </Box>
      )}

      {/* Title */}
      <Box
        color={linkedLayout?.artboard?.colorTheme?.text}
        ml={3}
        sx={{
          fontSize: 'm',
          fontWeight: 'semibold',
          lineHeight: 'single',
          position: 'relative',
          transform: 'translate3d(0,0,0)', // Force depth on Safari
        }}
      >
        {title}
      </Box>
    </Flex>
  )
}

export default Pill
