import { Reference, TagTone } from '@types'
import React, { useState } from 'react'
import { Box } from 'theme-ui'
import { trackMoodTagClick } from '../../utils/analytics/trackMoodTagClick'
import { trackThemeTagClick } from '../../utils/analytics/trackThemeTagClick'
import getRefinementFromReference from '../../utils/getRefinementFromReference'
import RefinementLink from '../RefinementLink'
import Tag from '../Tag'

type Props = {
  collapsed?: boolean
  large?: boolean
  references: Reference[]
  tone?: TagTone
}

const COLLAPSED_COUNT = 3

const Tags = (props: Props) => {
  const { collapsed, large, references, tone } = props

  // State
  const [expanded, setExpanded] = useState(!collapsed)

  const visibleReferences = expanded
    ? references
    : references.slice(0, COLLAPSED_COUNT)

  const handleClick = (reference: Reference) => {
    const title = reference.title
    // Analytics
    switch (reference._type) {
      case 'mood':
        trackMoodTagClick(title)
        break
      case 'tag':
        trackThemeTagClick(title)
        break
      default:
        break
    }
  }

  return (
    <Box>
      {visibleReferences?.map((reference, index) => {
        const refinement = getRefinementFromReference(reference)
        return (
          <RefinementLink refinements={[refinement]} key={index}>
            <a onClick={handleClick.bind(undefined, reference)}>
              <Tag
                isLink
                label={reference?.title}
                large={large}
                mr={large ? 1 : '2px'}
                mt={large ? 1 : '2px'}
                tone={tone}
              />
            </a>
          </RefinementLink>
        )
      })}

      {/* Show more */}
      {!expanded && references.length > visibleReferences.length && (
        <a>
          <Tag
            isLink
            label="..."
            large={large}
            onClick={() => setExpanded(true)}
            tone={tone}
          />
        </a>
      )}
    </Box>
  )
}

export default Tags
