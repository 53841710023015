import React from 'react'
import { Box, Flex } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import {
  CheckoutLineItemFragment,
  ProductVariantPricePair,
} from '../../types/codegen/shopify'
import BoxResponsive from '../BoxResponsive'
import ButtonRemoveItem from '../ButtonRemoveItem'
import CartQuantityStepper from '../CartQuantityStepper'
import ImageBasic from '../ImageBasic'
import PortableText from '../PortableText'
import ProductVariantPresentmentPrice from '../ProductVariantPresentmentPrice'

type Props = {
  lineItem: CheckoutLineItemFragment
}

const CartLineItem = (props: Props) => {
  const { lineItem } = props

  // Redux
  const singlePurchaseLineItemText = useTypedSelector(
    state => state.system.cartSinglePurchaseLineItemText
  )

  const productVariant = lineItem?.variant

  if (!productVariant) {
    return null
  }

  // @ts-ignore
  const productVariantPresentmentPrices: ProductVariantPricePair[] = [productVariant?.price]





  const isSinglePurchase =
    lineItem?.customAttributes?.findIndex(
      attribute =>
        attribute.key === '_singlePurchase' && attribute.value === '1'
    ) >= 0

  return (
    <Flex
      mx={6}
      py={4}
      sx={{
        borderBottom: '1px solid',
        borderColor: 'stone',
        flexDirection: 'row',
        overflow: 'hidden',
      }}
    >
      {/* Image */}
      <Box
        sx={{
          maxWidth: '100px',
          position: 'relative',
          width: '100%',
        }}
      >
        <BoxResponsive aspect={[112 / 98]}>
          <Box
            // bg="lightGray"
            sx={{
              height: '100%',
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
            }}
          >
            {productVariant?.image?.transformedSrc && (
              <ImageBasic
                src={productVariant.image.transformedSrc}
                sx={{
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            )}
          </Box>
        </BoxResponsive>
      </Box>

      <Box
        ml={8}
        sx={{
          flex: 1,
          fontSize: ['xs'],
          fontWeight: 'semibold',
        }}
      >
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Box mr={2}>
            {/* Product title */}
            <Box>{lineItem?.title}</Box>

            {/* Variant title */}
            {productVariant.title !== 'Default Title' && (
              <Box
                color="slate"
                mt={1}
                sx={{ fontSize: 'xxs', fontWeight: 'medium' }}
              >
                {productVariant.title}
              </Box>
            )}
          </Box>

          {/* Price */}
          <ProductVariantPresentmentPrice
            pricePairs={productVariantPresentmentPrices}
            quantity={lineItem?.quantity}
          />
        </Flex>

        {/* Quantity and stepper */}
        <Flex
          sx={{
            alignItems: ['flex-start'],
            flexDirection: ['column'],
            justifyContent: ['auto'],
          }}
        >
          <Box my={4}>
            {!isSinglePurchase && (
              <CartQuantityStepper
                productVariantId={productVariant.id}
                quantity={lineItem?.quantity}
              />
            )}
          </Box>

          {/* Single purchase text */}
          {isSinglePurchase && singlePurchaseLineItemText && (
            <Box
              mb={2}
              sx={{
                fontSize: 'xxs',
                fontWeight: 'medium',
                lineHeight: 'default',
              }}
            >
              <PortableText blocks={singlePurchaseLineItemText} />
            </Box>
          )}

          {/* Remove */}
          <ButtonRemoveItem productVariantId={productVariant.id} />
        </Flex>
      </Box>
    </Flex>
  )
}

export default CartLineItem
