import { IllustrationThinType } from '@types'
import React, { FunctionComponent } from 'react'
import Lightbulb from './patterns/Lightbulb'
import WineGlasses from './patterns/WineGlasses'

export type Props = {
  type: IllustrationThinType
}

const IllustrationThin = (props: Props) => {
  const { type } = props

  const Pattern: Record<IllustrationThinType, FunctionComponent> = {
    lightbulb: Lightbulb,
    wineGlasses: WineGlasses,
  }

  const PatternComponent = Pattern[type]

  if (!PatternComponent) {
    return null
  }

  return (
    <svg
      height="100%"
      preserveAspectRatio="xMidYMid meet"
      style={{
        display: 'block',
      }}
      width="100%"
      viewBox="0 0 68 68"
    >
      <g fill="currentColor" stroke="currentColor">
        <PatternComponent />
      </g>
    </svg>
  )
}

export default IllustrationThin
