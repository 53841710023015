import {
  ArticlePreview,
  IllustrationThickType,
  ManifestoPreview,
  OnlineCoursePreview,
  ProductPreview,
  TalkPreview,
  WorkshopPreview,
} from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import CardSlim from '../CardSlim'
import IndexSubheader from '../IndexSubheader'

type Props = {
  href?: string
  headerIllustrationType?: IllustrationThickType
  items: (
    | ArticlePreview
    | OnlineCoursePreview
    | ManifestoPreview
    | ProductPreview
    | TalkPreview
    | WorkshopPreview
    | null
  )[]
  title: string
}

const CardSlimList = (props: Props) => {
  const { headerIllustrationType, href, items, title } = props

  return (
    <Box>
      <IndexSubheader
        illustrationType={headerIllustrationType}
        href={href}
        title={title}
      />

      <Box>
        {items?.map((item, index) =>
          item ? (
            <Box
              key={index}
              mb={4}
              sx={{
                '&:last-of-type': {
                  mb: 0,
                },
              }}
            >
              <CardSlim item={item} />
            </Box>
          ) : null
        )}
      </Box>
    </Box>
  )
}

export default CardSlimList
