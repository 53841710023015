import React from 'react'

const Star = () => {
  return (
    <>
      <path d="M1.5123 14.4805C4.07029 16.8249 6.77178 18.9933 9.71322 20.838C8.74349 24.1305 7.68635 27.394 6.55363 30.6344C6.34614 31.2281 6.74751 31.6381 7.22733 31.7302C7.46754 31.8763 7.77305 31.8936 8.08543 31.6707C10.9282 29.6405 13.8478 27.7216 16.7229 25.7373C19.6301 27.8734 22.4716 30.0964 25.5163 32.0378C26.1908 32.4685 26.8261 31.8863 26.866 31.267C26.8995 31.132 26.9052 30.9825 26.8643 30.8178C26.0603 27.5473 25.1155 24.3252 23.6643 21.2856C26.7873 19.2899 29.3138 16.5489 32.2272 14.2717C32.7738 13.8448 32.5481 13.2271 32.0836 12.9325C31.9361 12.7433 31.7082 12.613 31.3949 12.613H21.5846C20.2731 9.31493 18.9347 6.02821 17.8691 2.63973C17.5521 1.63081 16.0993 1.91493 16.0577 2.80024C14.5296 6.06112 13.1271 9.3697 12.3109 12.8832C8.82005 12.861 5.33068 12.7154 1.83953 12.7048C0.778661 12.7017 0.671808 14.172 1.5123 14.4805ZM12.7622 14.7242C12.843 14.7245 12.9173 14.7146 12.9869 14.6989C13.3933 14.7577 13.8138 14.5734 13.9226 14.0504C14.5488 11.0465 15.6273 8.2097 16.8678 5.42563C17.8442 8.20871 18.9594 10.9403 20.0462 13.6834C20.0495 13.6922 20.0549 13.6987 20.0588 13.7072C20.1249 14.1005 20.4157 14.4489 20.9313 14.4489H29.153C26.8806 16.4229 24.7294 18.5397 22.1205 20.0814C21.9271 20.1957 21.8045 20.3458 21.7383 20.5097C21.5749 20.7612 21.5243 21.0882 21.6995 21.4291C22.9684 23.9022 23.831 26.5234 24.5507 29.1936C22.1035 27.5102 19.7543 25.6908 17.356 23.9364C17.2875 23.8865 17.2199 23.8513 17.1525 23.8234C16.9051 23.6506 16.5798 23.6127 16.2457 23.8446C13.8957 25.4754 11.5119 27.0564 9.16024 28.6838C10.0346 26.0865 10.8608 23.4742 11.628 20.8429C11.7505 20.4226 11.5858 20.0926 11.3139 19.8993C11.2517 19.7245 11.1309 19.5623 10.9307 19.4387C8.59901 18.0016 6.4331 16.3528 4.36887 14.57C7.16674 14.6198 9.96396 14.7156 12.7622 14.7242Z" />
    </>
  )
}

export default Star
