import { ColorTheme, SanityBlock, SanityImage } from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import Caption from '../Caption'
import NextImageResponsive from '../NextImageResponsive'
import PortableText from '../PortableText'

type ListicleItem = {
  body: SanityBlock[]
  image: SanityImage
}

type Props = {
  colorTheme?: ColorTheme
  item: ListicleItem
}

const ListicleItem = (props: Props) => {
  const { colorTheme, item } = props

  return (
    <Box>
      {/* Image */}
      {item.image && (
        <Box>
          <NextImageResponsive
            image={item.image}
            sx={{
              borderRadius: 1,
              overflow: 'hidden',
            }}
            sizes={['100vw', null, null, '50vw']}
          />

          {/* Caption */}
          <Caption color={colorTheme?.text} text={item.image?.caption} />
        </Box>
      )}

      {/* Body */}
      {item?.body && (
        <Box mt={8}>
          <PortableText
            blocks={item.body}
            colorTheme={colorTheme}
            variant="body"
          />
        </Box>
      )}
    </Box>
  )
}

export default ListicleItem
