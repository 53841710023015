import React from 'react'

const Path16 = () => {
  return (
    <>
      <path d="M150 66.3452C139.539 78.5526 129.903 90.3134 119.694 101.553C112.285 109.711 103.212 115.822 92.6252 119.284C77.6059 124.204 63.1063 122.224 49.2898 115.093C44.5531 112.651 39.9055 109.681 35.9261 106.161C24.1363 95.7248 12.7029 84.887 1.14332 74.1907C0.764684 73.8408 0.475154 73.3793 0 72.7988C0.616215 72.0618 1.09135 71.2207 1.78923 70.6774C15.2123 60.3309 28.0489 49.2103 42.3184 39.9803C53.3211 32.8717 65.1777 28.5917 78.3929 29.0308C90.0639 29.4179 101.007 32.8568 110.718 39.2061C123.874 47.8108 136.614 57.0557 150 66.3452Z" />
    </>
  )
}

export default Path16
