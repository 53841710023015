import { createAsyncThunk } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import updateCheckoutAttributes from '../../../../services/shopify/updateCheckoutAttributes'
import { trackCheckout } from '../../../../utils/analytics/trackCheckout'
import { getGoogleClientId } from '../../../../utils/getGoogleClientId'
import { RootState } from '../../../store'

const checkoutProceed = createAsyncThunk<
  // Return type of the payload creator
  string,
  // First argument to the payload creator
  any,
  // thunkApi field types
  { state: RootState }
>('store/checkoutProceed', async (_, { getState, rejectWithValue }) => {
  // Get existing checkout from store
  const checkout = getState()?.store?.checkout
  const checkoutId = checkout?.id

  // If we're unable to get a checkout ID, our checkout hasn't been created yet
  if (!checkoutId) {
    Sentry.captureMessage(
      'A user has tried proceeding to checkout, but was unable to retrieve their checkout object.'
    )

    return rejectWithValue('Unable to retrieve checkout')
  }

  // Determine GA client ID
  const gaClientId = await getGoogleClientId()

  // If we're able to obtain a GA client id: update checkout with custom attributes for littledata
  if (gaClientId) {
    const { errors } = await updateCheckoutAttributes({
      checkoutId,
      customAttributes: [
        {
          key: 'google-clientID',
          value: gaClientId,
        },
      ],
    })

    if (errors) {
      Sentry.captureMessage(
        'A user has tried proceeding to checkout (with custom attributes), but was unable to retrieve their checkout object.'
      )

      // dispatch({ type: StoreActionTypes.CHECKOUT_PROCEED_ERROR })
      return rejectWithValue('Unable to retrieve checkout')
    }
  }

  // (Analytics)
  trackCheckout()

  return checkout.webUrl as string
})

export default checkoutProceed
