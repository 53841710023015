import React from 'react'
import { Box, Flex, Text } from 'theme-ui'

import { useAppDispatch } from '../../redux/store'
import useTypedSelector from '../../hooks/useTypedSelector'
import { systemActions } from '../../redux/modules/system'
import ButtonIcon from '../ButtonIcon'

type Props = {
  iconSize?: number
}

const ButtonCart = (props: Props) => {
  const { iconSize = 20 } = props

  // Redux
  const dispatch = useAppDispatch()
  const checkoutLineItems = useTypedSelector(
    state => state.store.checkout?.lineItems
  )

  // Callbacks
  const handleCartShow = () => {
    dispatch(systemActions.setCartVisible({ cartVisible: true }))
  }

  // Iterate through all line items and accumulate count
  const totalItems =
    checkoutLineItems?.edges?.reduce((acc, val) => {
      acc += val?.node?.quantity
      return acc
    }, 0) || 0

  return (
    <Box
      // bg="debug"
      sx={{
        position: 'relative',
      }}
    >
      <ButtonIcon
        iconSize={iconSize}
        onClick={handleCartShow}
        size={40}
        type="cart"
      />

      {/* Badge */}
      {totalItems > 0 && (
        <Flex
          bg="coral"
          color="midnight"
          sx={{
            alignItems: 'center',
            borderRadius: '20px',
            height: '20px',
            justifyContent: 'center',
            pointerEvents: 'none',
            position: 'absolute',
            right: '-2px',
            top: 1,
            width: '20px',
          }}
        >
          <Text
            sx={{
              fontSize: 'xxxs',
              fontWeight: 'semibold',
              lineHeight: 'single',
            }}
          >
            {totalItems > 9 ? '9+' : totalItems}
          </Text>
        </Flex>
      )}
    </Box>
  )
}

export default ButtonCart
