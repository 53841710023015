import client from '@sanity/client'

import { SANITY_API_VERSION } from '../constants'

export const sanityClientRead = (preview?: boolean) =>
  client({
    apiVersion: SANITY_API_VERSION,
    dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
    projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
    token: preview ? process.env.SANITY_READ_AUTH_TOKEN : undefined,
    useCdn: true,
  })

export const sanityClientWrite = client({
  apiVersion: SANITY_API_VERSION,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  token: process.env.SANITY_ADMIN_AUTH_TOKEN, // admin token has write access
  useCdn: false,
})
