import React from 'react'
import hashCode from '../../utils/hashCode'
import Path01 from './paths/Path01'
import Path02 from './paths/Path02'
import Path03 from './paths/Path03'
import Path04 from './paths/Path04'
import Path05 from './paths/Path05'
import Path06 from './paths/Path06'
import Path07 from './paths/Path07'

export type Props = {
  stringToHash: string
}

const PATTERN_COMPONENTS = [
  Path01,
  Path02,
  Path03,
  Path04,
  Path05,
  Path06,
  Path07,
]

const TalkMark = (props: Props) => {
  const { stringToHash } = props

  const numPatterns = PATTERN_COMPONENTS.length

  // Generate a number between 0-numPatterns, based on the input name
  // Hashes can be negative, so we force absolute numbers
  const hash = Math.abs(hashCode(stringToHash))

  const patternIndex = hash % numPatterns

  const Component = PATTERN_COMPONENTS[patternIndex]
  return (
    <svg
      fill="none"
      height="100%"
      preserveAspectRatio="xMidYMid meet"
      style={{ display: 'block' }}
      viewBox="0 0 94 94"
      width="100%"
    >
      <Component />
    </svg>
  )
}

export default TalkMark
