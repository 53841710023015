import React from 'react'
import { useDispatch } from 'react-redux'
import { Box } from 'theme-ui'
import { searchActions } from '../../redux/modules/search'
import { trackSearchClick } from '../../utils/analytics/trackSearchClick'
import ButtonIcon from '../ButtonIcon'

type Props = {
  iconSize?: number
}

const ButtonSearch = (props: Props) => {
  const { iconSize = 18 } = props

  // Redux
  const dispatch = useDispatch()

  // Callbacks
  const handleShowSearch = () => {
    dispatch(searchActions.setVisible({ visible: true }))

    // Analytics
    trackSearchClick()
  }

  return (
    <Box onClick={handleShowSearch}>
      <ButtonIcon iconSize={iconSize} type="search" />
    </Box>
  )
}

export default ButtonSearch
