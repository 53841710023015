import React from 'react'

const Bag = () => {
  return (
    <>
      <path d="M32.1173 31.5538C32.7793 31.4423 32.8897 31.1077 32.8897 30.8846C32.8897 30.7731 32.8897 30.6615 32.8897 30.55C31.3448 22.8538 30.0207 15.9385 28.2552 8.2423C28.1448 7.90768 27.9242 7.68461 27.5931 7.68461C27.5931 7.57307 27.3724 7.46153 27.1517 7.46153C25.9379 7.46153 24.6138 7.46153 23.2897 7.46153C22.0759 0.546147 13.469 2.33076 11.3724 7.68461C10.0483 7.68461 8.8345 7.68461 7.6207 7.68461C7.6207 7.68461 7.6207 7.68461 7.51036 7.68461C7.06898 7.68461 6.73794 7.68461 6.29657 7.79615C6.07588 7.79615 5.85519 8.01922 5.85519 8.2423C5.85519 8.35384 5.74484 8.35384 5.74484 8.46538C3.97932 15.9385 3.4276 22.8538 1.2207 30.2154C1.2207 30.3269 1.2207 30.55 1.2207 30.6615C1.44139 31.3308 2.4345 31.2192 10.7104 31.5538H32.1173ZM21.7448 7.57307C18.8759 7.57307 15.8966 7.68461 13.1379 7.68461C15.0138 4.11538 20.531 2.99999 21.7448 7.57307ZM11.2621 9.35768C16.3379 9.24615 22.2966 9.13461 26.8207 9.13461C28.3655 16.3846 29.6897 22.8538 31.1241 30.1038C22.6276 30.3269 11.5931 30.2154 2.98622 29.7692C4.86208 22.8538 5.52415 16.3846 7.06898 9.35768C7.06898 9.35768 7.06898 9.35768 7.17932 9.35768C7.17932 9.35768 7.40001 9.35768 7.28967 9.35768C7.40001 9.35768 7.40001 9.35768 7.51036 9.35768H7.6207C8.28277 9.35768 9.05519 9.35768 9.71726 9.35768" />
    </>
  )
}

export default Bag
