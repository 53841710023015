// Based on: https://github.com/littledata/headless-shopify-demo/blob/master/client/buyNow/getGoogleClientId.js

const TIMEOUT_INTERVAL = 3000 // ms to wait before assuming GA is blocked

export const getGoogleClientId = (): Promise<string | undefined> => {
  return new Promise(resolve => {
    // Universal Analytics only makes 'ga' function available after the library loads
    // so we have to stub it if undefined here
    window.ga =
      window.ga ||
      function () {
        ;(window.ga.q = window.ga.q || []).push(arguments) //eslint-disable-line
      }
    window.ga.l = +new Date()

    let intervalRetry: ReturnType<typeof setTimeout>

    // Assume GA is blocked if no value has been found in 3 seconds
    const timeoutFallback = window.setTimeout(function () {
      resolve(undefined)
    }, TIMEOUT_INTERVAL)

    const getClientId = () => {
      const tracker = window.ga.getAll()[0]
      const clientId = tracker && tracker.get('clientId')

      if (clientId) {
        window.clearInterval(intervalRetry)
        window.clearTimeout(timeoutFallback)
        console.log('clientId retrived:', clientId)
        return resolve(clientId)
      }
    }

    // Once GA is loaded, try and obtain our GA client ID.
    // Note that GA being available on the window (at `window.ga`) doesn't mean that our tracker
    // is ready yet.
    // Since (AFAIK) there isn't a reliable way to determine when the tracker is ready, we just
    // call this on an interval until it's either loaded, or `TIMEOUT_INTERVAL` elapses.
    window.ga(function () {
      intervalRetry = setInterval(getClientId, 500)
    })
  })
}
