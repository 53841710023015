import React from 'react'
import useTypedSelector from '../../hooks/useTypedSelector'
import { ProductVariantPricePair } from '../../types/codegen/shopify'
import FormattedMoney from '../FormattedMoney/FormattedMoney'

type Props = {
  pricePairs: ProductVariantPricePair[]
  quantity?: number
  significantFigures?: number
}

const ProductVariantPresentmentPrice = (props: Props) => {
  const { pricePairs, quantity = 1, significantFigures } = props

  // Store: redux
  const checkoutCurrency = useTypedSelector(
    state => state.store.checkout?.currencyCode
  )
  const defaultCurrency = useTypedSelector(
    state => state.store?.defaultCurrency
  )

  const activeCurrency = checkoutCurrency || defaultCurrency

  const pricePair = pricePairs.find(
    // @ts-ignore
    pair => pair.currencyCode === activeCurrency
  )


  return (
    <FormattedMoney
      compareAtPrice={pricePair?.compareAtPrice}
      // @ts-ignore
      price={pricePair?.price}
      quantity={quantity}
      significantFigures={significantFigures}
    />
  )
}

export default ProductVariantPresentmentPrice
