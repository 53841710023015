import { combineReducers } from '@reduxjs/toolkit'
import everything from './everything'
import iubenda from './iubenda'
import menu from './menu'
import navigation from './navigation'
import playlists from './playlists'
import search from './search'
import store from './store'
import system from './system'
import teamMemberPanel from './teamMemberPanel'

export const rootReducer = combineReducers({
  everything,
  iubenda,
  menu,
  navigation,
  playlists,
  search,
  store,
  system,
  teamMemberPanel,
})
