import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Refinement, SearchState } from '@types'

type EverythingReducerState = {
  searchState?: SearchState
}

export const initialState = {} as EverythingReducerState

const everythingSlice = createSlice({
  name: 'everything',
  initialState,
  reducers: {
    addRefinements(
      state,
      action: PayloadAction<{ refinements: Refinement[] }>
    ) {
      const refinements = action.payload.refinements
      const updatedRefinements = refinements.reduce((acc, val) => {
        acc[val.name] = [val.value]
        return acc
      }, {} as Record<string, string | string[]>)

      const updatedSearchState = {
        ...state.searchState,
        _updatedAt: new Date().getTime(), // Touch with current time to force update
        page: 1,
        refinementList: updatedRefinements,
      }

      state.searchState = updatedSearchState
    },
    clearSearchState(state) {
      delete state.searchState
    },
    setSearchState(state, action: PayloadAction<{ searchState: SearchState }>) {
      state.searchState = action.payload?.searchState
    },
  },
})

export const everythingActions = everythingSlice.actions

export default everythingSlice.reducer
