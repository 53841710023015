import { Color } from '@types'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Box, BoxOwnProps, ThemeUIStyleObject } from 'theme-ui'
import Path01 from './paths/Path01'
import Path02 from './paths/Path02'
import Path03 from './paths/Path03'
import Path04 from './paths/Path04'
import Path05 from './paths/Path05'

const PATH_COMPONENTS = [Path01, Path02, Path03, Path04, Path05]

type Props = {
  color?: Color
  sx?: ThemeUIStyleObject
}

const DividerHorizontalRandom: FunctionComponent<BoxOwnProps & Props> = (
  props: Props
) => {
  const { color, sx, ...rest } = props

  const [pathIndex, setPathIndex] = useState<number>(-1)

  const numPaths = PATH_COMPONENTS.length

  // Randomly pick a path
  useEffect(() => {
    setPathIndex(Math.floor(Math.random() * numPaths))
  }, [])

  const Paths = PATH_COMPONENTS[pathIndex]

  if (!Paths) {
    return null
  }

  return (
    <Box color={color || 'midnight'} sx={sx} {...rest}>
      <svg
        height="6"
        // preserveAspectRatio="xMidYMid meet"
        preserveAspectRatio="none"
        style={{ display: 'block' }}
        width="100%"
        viewBox="0 0 1318 6"
      >
        <Paths />
      </svg>
    </Box>
  )
}

export default DividerHorizontalRandom
