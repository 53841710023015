// Obtain a numerical hash code from input string
// Source: https://stackoverflow.com/a/8831937/286171

const hashCode = (str: string) => {
  var hash = 0
  if (!str || str.length == 0) {
    return hash
  }
  for (var i = 0; i < str.length; i++) {
    var char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash // Convert to 32bit integer
  }
  return hash
}

export default hashCode
