import {
  CheckoutLineItemFragment,
  CheckoutLineItemInput,
} from '../types/codegen/shopify'

const getLineItemInputs = (items: CheckoutLineItemFragment[] | undefined) => {
  // Get line items from current checkout
  // - reduce as CheckoutLineItemInputs
  const lineItemInputs: CheckoutLineItemInput[] | undefined = items?.reduce(
    (acc: CheckoutLineItemInput[], val) => {
      if (val.variant) {
        acc.push({
          customAttributes: val?.customAttributes?.map(attribute => ({
            key: String(attribute.key),
            value: String(attribute.value),
          })),
          quantity: val.quantity,
          variantId: val.variant.id,
        })
      }
      return acc
    },
    []
  )

  return lineItemInputs || []
}

export default getLineItemInputs
