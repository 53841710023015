import React from 'react'

const Smile = () => {
  return (
    <>
      <path d="M25.8395 5.97807C24.0328 4.4148 21.78 3.78378 19.4516 3.50498C13.2497 1.52683 7.71758 5.61793 5.30202 11.2906C2.69837 17.4048 3.93713 24.2301 9.58277 28.043C15.3465 31.9353 23.2676 30.3354 27.689 25.2115C32.5208 19.6112 31.328 10.7281 25.8395 5.97807ZM15.2166 28.1958C11.9469 27.7304 8.77432 25.7186 7.11747 22.8501C5.63625 20.2853 5.64865 17.0736 6.31264 14.2723C7.33333 9.9657 10.4217 6.07893 14.4582 5.14866C14.4642 5.14895 14.4692 5.1507 14.4753 5.1507C15.9281 5.19373 17.462 5.19623 18.9616 5.3584C19.1036 5.40289 19.2458 5.44708 19.3886 5.49932C19.6251 5.58549 19.8318 5.57443 20.0051 5.50345C21.6227 5.77579 23.1677 6.29414 24.4859 7.33137C27.4019 9.62588 28.9391 13.7634 28.8549 17.3873C28.697 24.218 21.6869 29.1166 15.2166 28.1958Z" />
      <path d="M13.6113 15.3396C14.9668 15.3396 14.9668 13.2373 13.6113 13.2373C12.2559 13.2372 12.2559 15.3396 13.6113 15.3396Z" />
      <path d="M20.8639 15.4445C22.2193 15.4445 22.2193 13.3423 20.8639 13.3423C19.5082 13.3423 19.5082 15.4445 20.8639 15.4445Z" />
      <path d="M21.3823 19.0107C18.8639 21.5131 15.6149 21.2636 13.0933 18.9055C12.1045 17.9808 10.6147 19.4643 11.6067 20.3922C14.9622 23.5303 19.5295 23.8153 22.8686 20.4971C23.8314 19.5406 22.3444 18.0545 21.3823 19.0107Z" />
    </>
  )
}

export default Smile
