import React from 'react'

const Path03 = () => {
  return (
    <>
      <path d="M77.2296 143.851C73.7547 144.49 67.326 142.914 60.8279 142.191C52.0502 141.213 43.349 139.977 35.03 136.825C26.9473 133.756 19.4831 129.569 13.1518 123.681C10.4344 121.147 7.84209 118.169 6.16022 114.898C1.97639 106.775 -0.636747 98.1519 0.134689 88.7927C0.683729 82.1621 0.62116 75.4412 1.67754 68.9009C3.59571 57.0075 8.84288 46.461 16.2931 37.0046C21.86 29.9366 28.3998 23.8823 35.5721 18.4806C48.4293 8.79508 63.0449 5.75403 78.7516 7.44119C84.4783 8.05217 90.2189 9.13529 95.7719 10.6558C106.447 13.5788 115.558 19.3832 123.217 27.3052C136.554 41.094 145.901 57.0422 149.112 76.2119C150.245 82.9744 150.405 89.7022 148.938 96.4508C147.18 104.553 145.887 112.746 142.246 120.342C138.639 127.861 132.975 133.061 125.302 136.193C117.643 139.317 109.519 140.428 101.408 141.442C94.4166 142.316 87.3834 142.858 77.2296 143.851ZM72.2257 102.429C72.2327 102.352 72.2465 102.283 72.2535 102.207C73.6365 102.207 75.0265 102.29 76.4026 102.193C87.4529 101.408 97.141 90.4937 95.8066 80.2666C94.7989 72.5182 92.0676 65.4363 87.5988 59.0765C86.6745 57.7573 84.9092 56.7228 83.3247 56.2437C80.4613 55.3759 77.459 54.9523 74.4914 54.4733C65.2341 52.9944 57.9507 56.6048 52.363 63.6797C47.2548 70.1506 46.2193 77.3436 50.2224 84.8004C52.4186 88.8829 54.8719 92.8404 57.3738 96.7494C58.9862 99.2766 61.4395 100.887 64.4001 101.436C66.9785 101.922 69.6125 102.116 72.2257 102.429Z" />
    </>
  )
}

export default Path03
