import { LinkVariant } from '@types'
import React, { ReactNode } from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import { trackCourseLoginClick } from '../../utils/analytics/trackCourseLoginClick'
import Link from '../Link'

type Props = {
  children: ReactNode
  sx?: ThemeUIStyleObject
  variant?: LinkVariant
}

const LinkCourseLogin = (props: Props) => {
  const { children, sx, variant } = props

  const courseLoginUrl = useTypedSelector(state => state.system.courseLoginUrl)

  if (!courseLoginUrl) {
    return null
  }

  const handleTrackClick = () => {
    // Analytics
    trackCourseLoginClick()
  }

  return (
    <Link
      href={courseLoginUrl}
      onClick={handleTrackClick}
      sx={sx}
      variant={variant}
    >
      {children}
    </Link>
  )
}

export default LinkCourseLogin
