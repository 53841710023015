import React from 'react'

const Book = () => {
  return (
    <>
      <path d="M32.9341 7.97646C32.9372 7.83264 32.8967 7.71661 32.8289 7.62769C32.7884 7.54681 32.729 7.47125 32.6433 7.40758C28.1029 4.0397 21.6187 2.2587 17.0941 6.42965C12.5692 2.2587 6.08515 4.0397 1.54482 7.40758C1.45909 7.47125 1.40008 7.54681 1.35921 7.62769C1.29155 7.71661 1.25075 7.83231 1.25374 7.97646C1.36951 13.597 1.19705 19.2169 1.18848 24.8378C1.18848 24.9361 1.20888 25.0207 1.24384 25.0933C1.14847 25.4983 1.44361 25.9805 1.93227 25.7899C6.72554 23.9216 12.1468 23.5082 16.5176 26.5198C16.6175 26.7563 16.8404 26.9274 17.0945 26.8687C17.3485 26.9271 17.5714 26.756 17.6707 26.5198C22.0417 23.5082 27.4628 23.9215 32.2563 25.7899C32.7449 25.9805 33.0399 25.4982 32.9446 25.0933C32.9791 25.0207 33 24.9361 33 24.8378C32.9909 19.2169 32.8184 13.597 32.9341 7.97646ZM17.0941 25.4993C12.7202 22.4853 7.23593 22.6689 2.36551 24.3883C2.38173 18.9925 2.53577 13.5975 2.43183 8.20168C6.58624 5.21255 12.55 3.4459 16.6335 7.61081C16.7761 7.75622 16.9402 7.80014 17.0941 7.77968C17.2478 7.80041 17.412 7.75622 17.5548 7.61081C21.6377 3.4459 27.6018 5.21221 31.756 8.20168C31.6521 13.5975 31.8066 18.9925 31.8225 24.3887C26.9521 22.6689 21.4676 22.4853 17.0941 25.4993Z" />
      <path d="M32.5215 26.6752C27.5546 24.6757 20.6051 23.9221 16.9558 28.2489C13.3065 23.9225 6.35686 24.6757 1.38994 26.6752C0.512835 27.0279 1.27012 28.2185 2.13912 27.8689C6.63406 26.0593 13.3412 25.2535 16.2969 29.7803C16.4614 30.0324 16.7123 30.1222 16.9558 30.1053C17.1998 30.1222 17.4504 30.0324 17.6147 29.7803C20.5703 25.2531 27.2775 26.0593 31.7723 27.8689C32.6413 28.2188 33.3982 27.0279 32.5215 26.6752Z" />
      <path d="M4.3938 13.228C4.50731 13.4878 4.79912 13.6642 5.10516 13.4647C5.89388 12.9516 6.73464 12.5776 7.65966 12.3938C7.96238 12.3337 8.06393 12.0883 8.02365 11.8543C8.03329 11.8133 8.0402 11.7694 8.0402 11.7214V8.78403C8.0402 8.43919 7.76447 8.27357 7.49738 8.28474C7.45165 8.27291 7.40374 8.265 7.34904 8.26566C6.28632 8.28387 5.26971 8.56313 4.40935 9.20046C4.24061 9.32513 4.17821 9.50111 4.18785 9.67038C4.17097 9.71644 4.15748 9.76542 4.15256 9.82078C4.07002 10.7984 4.06616 11.7778 4.06616 12.7581C4.06616 13.0087 4.21157 13.1651 4.3938 13.228ZM5.18232 9.92538C5.7415 9.57116 6.34626 9.3795 7.00346 9.32181V11.4813C6.33829 11.6522 5.70302 11.9198 5.10417 12.2556C5.10742 11.478 5.12125 10.7012 5.18232 9.92538Z" />
      <path d="M10.0787 9.45628C11.3535 9.01959 12.5841 9.13835 13.7397 9.83608C14.3136 10.1827 14.8342 9.28601 14.2628 8.9411C12.8861 8.10991 11.3261 7.93506 9.80298 8.45702C9.17449 8.67221 9.44431 9.6738 10.0787 9.45628Z" />
      <path d="M9.99254 11.7027C11.2723 11.4335 12.4693 11.6235 13.5672 12.3417C14.1285 12.7087 14.6472 11.8108 14.0903 11.4467C12.7592 10.5762 11.2594 10.3786 9.71688 10.7034C9.06267 10.841 9.34053 11.8402 9.99254 11.7027Z" />
      <path d="M14.6088 14.7297C11.1465 12.7844 7.08003 13.538 3.89139 15.6799C3.33946 16.0507 3.85783 16.9491 4.41448 16.5749C7.2921 14.6422 10.9354 13.8546 14.0857 15.6246C14.6694 15.9527 15.1915 15.0568 14.6088 14.7297Z" />
      <path d="M14.6088 17.1485C11.1465 15.2034 7.07999 15.957 3.89135 18.0989C3.33948 18.4697 3.85785 19.3681 4.4145 18.9938C7.29212 17.0611 10.9355 16.2734 14.0857 18.0435C14.6694 18.3716 15.1915 17.4758 14.6088 17.1485Z" />
      <path d="M14.6088 19.568C11.1465 17.6228 7.08003 18.3764 3.89139 20.5184C3.33946 20.8892 3.85783 21.7876 4.41448 21.4134C7.2921 19.4806 10.9354 18.693 14.0857 20.463C14.6694 20.791 15.1915 19.8954 14.6088 19.568Z" />
      <path d="M19.149 16.0332C22.1414 14.0073 25.837 14.4885 28.8658 16.1753C29.4514 16.5015 29.8931 15.5633 29.3127 15.2399C25.9568 13.3706 21.8417 12.9581 18.5531 15.1848C17.9993 15.5595 18.5946 16.4085 19.149 16.0332Z" />
      <path d="M19.3502 18.4434C22.3425 16.4172 26.0382 16.8986 29.067 18.5855C29.6525 18.9118 30.0942 17.9733 29.5135 17.6501C26.1573 15.7808 22.0425 15.3682 18.7539 17.595C18.2007 17.9696 18.7957 18.8186 19.3502 18.4434Z" />
      <path d="M19.149 11.1949C22.1414 9.16893 25.837 9.65015 28.8658 11.337C29.4514 11.6632 29.8931 10.725 29.3127 10.4015C25.9568 8.53226 21.8417 8.11969 18.5531 10.3465C17.9993 10.7211 18.5946 11.5702 19.149 11.1949Z" />
      <path d="M18.7541 12.7569C18.2006 13.1319 18.7956 13.9806 19.3501 13.6053C22.3424 11.5791 26.0381 12.0606 29.0669 13.7473C29.6524 14.0736 30.0941 13.1353 29.5134 12.8119C26.1578 10.9429 22.0427 10.5304 18.7541 12.7569Z" />
      <path d="M29.7155 20.0605C26.3597 18.1915 22.2443 17.7788 18.956 20.0058C18.4025 20.3808 18.9978 21.2294 19.5519 20.8539C22.5443 18.8281 26.2403 19.3093 29.2687 20.996C29.8542 21.3226 30.296 20.3841 29.7155 20.0605Z" />
    </>
  )
}

export default Book
