import { IllustrationThickType, PlaylistPreview } from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import IndexSubheader from '../IndexSubheader'
import PlaylistPill from '../PlaylistPill'

type Props = {
  headerIllustrationType?: IllustrationThickType
  href?: string
  items: PlaylistPreview[]
  title: string
}

const PillList = (props: Props) => {
  const { headerIllustrationType, href, items, title } = props

  return (
    <Box>
      <IndexSubheader
        illustrationType={headerIllustrationType}
        href={href}
        title={title}
      />

      <Box>
        {items?.map((item, index) => (
          <Box
            key={index}
            mb={2}
            sx={{
              '&:last-of-type': {
                mb: 0,
              },
            }}
          >
            {item._type === 'playlist' && <PlaylistPill playlist={item} />}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default PillList
