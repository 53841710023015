import {
  ArticlePreview,
  EventPreview,
  ManifestoPreview,
  OnlineCoursePreview,
  PlaylistPreview,
  ProductPreview,
  ReadingListPreview,
  TalkPreview,
  WorkshopPreview,
} from '@types'

const isProductGuard = (
  item:
    | ArticlePreview
    | EventPreview
    | ManifestoPreview
    | OnlineCoursePreview
    | PlaylistPreview
    | ProductPreview
    | ReadingListPreview
    | TalkPreview
    | WorkshopPreview
): item is ProductPreview => {
  return item._type === 'product'
}

export default isProductGuard
