import NextLink from 'next/link'
import React from 'react'
import { Box } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import { selectSectionIdeas } from '../../redux/modules/menu'
import getSingletonHref from '../../utils/getSingletonHref'
import CardSlimList from '../CardSlimList'
import DividerHorizontalRandom from '../DividerHorizontalRandom'
import MenuSectionWrapper from '../MenuSectionWrapper'
import Pill from '../Pill'

const MenuSectionIdeas = () => {
  const sectionIdeas = useTypedSelector(selectSectionIdeas)

  return (
    <MenuSectionWrapper
      documentTypeIndex="ideas"
      documentTypeLabel="View ideas overview"
    >
      {/* Articles */}
      {sectionIdeas?.articles && (
        <>
          <CardSlimList
            headerIllustrationType="lightbulb"
            href={getSingletonHref('articleIndex')}
            items={sectionIdeas.articles}
            title="Thoughts & Opinions"
          />
          {/* Divider */}
          <DividerHorizontalRandom color="stone" my={8} />
        </>
      )}

      {/* Manifestos */}
      {sectionIdeas?.manifestos && (
        <>
          <CardSlimList
            headerIllustrationType="fire"
            href={getSingletonHref('manifestoIndex')}
            items={sectionIdeas.manifestos}
            title="Manifestos"
          />
          {/* Divider */}
          <DividerHorizontalRandom color="stone" my={8} />
        </>
      )}

      {/* Links */}
      {sectionIdeas?.links.map((link, index) => {
        return (
          <Box key={index} mb={2}>
            <NextLink href={link.slug}>
              <a>
                <Pill linkedLayout={link.linkedLayout} title={link.title} />
              </a>
            </NextLink>
          </Box>
        )
      })}
    </MenuSectionWrapper>
  )
}

export default MenuSectionIdeas
