import { ButtonVariant, Color } from '@types'
import { rgba } from 'polished'
import React, { FunctionComponent, ReactNode } from 'react'
import {
  Box,
  BoxOwnProps,
  Button as ThemeButton,
  ThemeUIStyleObject,
} from 'theme-ui'
import { COLORS } from '../../constants'

type Props = {
  active?: boolean
  background?: Color
  children?: ReactNode
  color?: Color
  disabled?: boolean
  icon?: ReactNode
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  sx?: ThemeUIStyleObject
  transparent?: boolean
  type?: any
  variant?: ButtonVariant
}

const Button: FunctionComponent<BoxOwnProps & Props> = (props: Props) => {
  const {
    active,
    background = 'midnight',
    children,
    color = 'white',
    disabled,
    icon,
    onClick,
    onMouseEnter,
    onMouseLeave,
    sx,
    transparent,
    type,
    variant,
    ...rest
  } = props

  let hoverStyles
  let styles
  switch (variant) {
    case 'ghost':
      hoverStyles = {
        opacity: 0.7,
      }
      styles = {
        bg: 'transparent',
        color: background,
        px: 0,
      }
      break
    case 'outline':
      hoverStyles = {
        bg: background,
        color: color,
      }
      styles = {
        bg: 'transparent',
        border: '1px solid',
        borderColor: background,
        color: background,
        px: '15px',
      }
      break
    default:
      hoverStyles = {
        opacity: 0.7,
      }
      styles = {
        bg: transparent
          ? rgba(COLORS[background], 0.2) //
          : background,
        color,
        px: '15px',
      }
      break
  }

  return (
    <ThemeButton
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{
        alignItems: 'center',
        borderRadius: '100px',
        cursor: 'pointer',
        display: 'inline-flex',
        flexShrink: 0,
        fontSize: 'xs',
        height: '36px',
        justifyContent: 'center',
        lineHeight: 'single',
        whiteSpace: 'nowrap',
        '&:disabled': {
          cursor: 'default',
          opacity: 0.5,
        },
        ...styles,
        '@media (hover: hover) and (pointer: fine)': {
          '&:focus': hoverStyles,
          '&:hover:not(:disabled)': hoverStyles,
        },
        ...(active && hoverStyles),
        ...sx,
      }}
      type={type}
      {...rest}
    >
      {icon && (
        <Box mr={2} sx={{ flexShrink: 0 }}>
          {icon}
        </Box>
      )}
      {children}
    </ThemeButton>
  )
}

export default Button
