import React from 'react'

const List = () => {
  return (
    <>
      <path d="M9.77452 7.11149C9.78489 6.90899 9.73612 6.72253 9.63319 6.5699C9.47939 6.27996 9.19999 6.10376 8.86388 6.08589C7.41622 6.00684 5.97247 6.00028 4.49778 6C4.24541 6 4.02663 6.08727 3.86136 6.25309C3.5189 6.46696 3.35363 6.83937 3.42915 7.2355C3.63563 8.32349 3.7352 9.47222 3.74231 10.852C3.74259 10.9263 3.75268 11.0009 3.77216 11.0789C3.81629 11.5634 4.1743 11.9188 4.65218 11.9465C6.17255 12.036 7.54138 12.1236 8.89979 12.2865C8.93973 12.2914 8.97807 12.2933 9.01503 12.2933C9.13815 12.2933 9.25455 12.2687 9.36156 12.2199C9.76492 12.1055 9.99269 11.7593 9.94393 11.3295C9.76597 9.76481 9.71092 8.38506 9.77452 7.11149ZM7.9935 10.335C7.13968 10.2528 6.31068 10.1951 5.57805 10.1491C5.55339 9.35042 5.49288 8.59473 5.39441 7.85538C6.37175 7.85957 7.16688 7.87347 7.90232 7.89951C7.89322 8.67974 7.92334 9.48204 7.9935 10.335Z" />
      <path d="M10.7756 10.8518C10.7759 10.927 10.7852 11.0001 10.8054 11.0793C10.8498 11.5638 11.2076 11.9189 11.6852 11.947C12.2574 11.9808 14.9612 12.0148 18.0919 12.0543C22.7691 12.1131 28.5903 12.1862 29.429 12.2869C29.4691 12.2918 29.5074 12.2938 29.5444 12.2938C29.6673 12.2938 29.7839 12.2691 29.8908 12.2204C30.2942 12.106 30.522 11.7597 30.473 11.33C30.2954 9.76497 30.2399 8.38527 30.3041 7.11198C30.3142 6.90948 30.2655 6.72302 30.1626 6.57038C30.0089 6.28044 29.7295 6.10425 29.3932 6.08638C27.8674 6.00319 11.6939 6.00049 11.5306 6.00049C11.2782 6.00049 11.059 6.08776 10.8942 6.25358C10.5516 6.46745 10.3865 6.83986 10.4618 7.23599C10.6688 8.32277 10.7681 9.47155 10.7756 10.8518ZM28.5247 10.3501C27.1932 10.2979 22.7914 10.2555 18.8568 10.2177C16.1074 10.1912 13.4916 10.1657 12.611 10.1428C12.5861 9.34628 12.5256 8.59274 12.4274 7.85531L13.0659 7.85614C21.9555 7.86706 27.1142 7.88306 28.4319 7.90408C28.4229 8.68652 28.4535 9.4923 28.5247 10.3501Z" />
      <path d="M30.3041 14.7133C30.3144 14.5108 30.2654 14.3244 30.1626 14.1719C30.0088 13.8818 29.7298 13.7058 29.3932 13.6875C27.8934 13.6056 12.1987 13.6018 11.5312 13.6016C11.2784 13.6016 11.059 13.6891 10.8942 13.8547C10.5516 14.0685 10.3865 14.4409 10.4618 14.8371C10.6684 15.9242 10.7677 17.0731 10.7751 18.4527C10.7754 18.5286 10.7848 18.6014 10.805 18.6805C10.8494 19.165 11.2071 19.5203 11.6847 19.5483C12.2575 19.5819 14.9639 19.6161 18.0976 19.6552C22.7722 19.714 28.5901 19.7874 29.4287 19.888C29.4688 19.8928 29.5072 19.895 29.5442 19.895C29.6672 19.895 29.7837 19.8704 29.8907 19.8217C30.294 19.7071 30.5218 19.3608 30.4728 18.9311C30.2954 17.3664 30.2399 15.9866 30.3041 14.7133ZM28.5242 17.9518C27.1922 17.8994 22.7868 17.8571 18.8488 17.819C16.1031 17.7925 13.4908 17.7672 12.6105 17.7443C12.5856 16.9474 12.5251 16.1938 12.4269 15.4564L13.069 15.4572C21.9564 15.4681 27.114 15.4841 28.4314 15.5052C28.4224 16.2882 28.453 17.0938 28.5242 17.9518Z" />
      <path d="M30.3041 22.3151C30.3144 22.1125 30.2654 21.9263 30.1626 21.7737C30.0088 21.4837 29.7298 21.3075 29.3932 21.2893C27.8662 21.2058 11.6942 21.2036 11.53 21.2036C11.278 21.2036 11.0588 21.291 10.8942 21.4568C10.5516 21.6703 10.3865 22.0428 10.4618 22.4389C10.6684 23.5258 10.7677 24.6748 10.7751 26.0548C10.7754 26.1293 10.7852 26.2038 10.805 26.2825C10.8494 26.7668 11.2071 27.1226 11.6847 27.15C12.2567 27.1836 14.9579 27.2178 18.0853 27.257C22.7653 27.3158 28.5893 27.3892 29.4285 27.4901C29.4686 27.4951 29.507 27.4969 29.5439 27.4969C29.6669 27.4969 29.7834 27.4722 29.8904 27.4234C30.2938 27.3092 30.5215 26.9629 30.4725 26.5332C30.2954 24.9678 30.2399 23.588 30.3041 22.3151ZM28.5242 25.5535C27.1922 25.5012 22.7868 25.4589 18.8488 25.4208C16.1031 25.3942 13.4908 25.369 12.6105 25.346C12.5856 24.5494 12.5251 23.7956 12.4269 23.058L13.159 23.0592C16.5584 23.063 26.472 23.0754 28.4314 23.1068C28.4224 23.8899 28.453 24.6955 28.5242 25.5535Z" />
      <path d="M9.7745 14.7133C9.78487 14.5108 9.73611 14.3244 9.63317 14.1719C9.47938 13.8821 9.20025 13.7058 8.86386 13.6875C7.41813 13.6087 5.97383 13.6018 4.49848 13.6016C4.24567 13.6016 4.02661 13.6891 3.86134 13.8547C3.51916 14.0685 3.35361 14.4409 3.42913 14.8371C3.63561 15.9253 3.73518 17.0743 3.7423 18.4538C3.74257 18.5281 3.75267 18.6025 3.77214 18.6807C3.81655 19.165 4.17456 19.5208 4.65189 19.5483C6.22897 19.6413 7.54302 19.7258 8.89949 19.8883C8.93943 19.8934 8.97777 19.895 9.01479 19.895C9.13791 19.895 9.25431 19.8704 9.36127 19.8217C9.76463 19.7074 9.9924 19.3611 9.94369 18.9314C9.76595 17.3666 9.7109 15.987 9.7745 14.7133ZM7.99349 17.937C7.13017 17.8541 6.27999 17.795 5.57803 17.7516C5.55338 16.9521 5.49286 16.1966 5.39439 15.4575C6.12134 15.4604 6.99966 15.4698 7.9023 15.5017C7.8932 16.2816 7.92332 17.0837 7.99349 17.937Z" />
      <path d="M9.77452 22.0085C9.78489 21.8057 9.73612 21.6194 9.63319 21.4668C9.47939 21.1768 9.20026 21.0006 8.86388 20.9827C7.41848 20.9037 5.97413 20.897 4.4985 20.8965C4.24568 20.8965 4.02663 20.9841 3.86136 21.1497C3.5189 21.3637 3.35363 21.7357 3.42915 22.1323C3.63563 23.2201 3.7352 24.3691 3.74231 25.7487C3.74259 25.8233 3.75268 25.8977 3.77216 25.9756C3.81656 26.4602 4.17458 26.8154 4.65218 26.8434C6.17018 26.933 7.5373 27.0199 8.89979 27.1834C8.93973 27.1882 8.97807 27.1902 9.01503 27.1902C9.13815 27.1902 9.25455 27.1655 9.36156 27.1168C9.76492 27.0025 9.99269 26.6562 9.94393 26.2266C9.76597 24.6612 9.71092 23.2817 9.77452 22.0085ZM7.9935 25.2316C7.13019 25.1486 6.28001 25.0898 5.57805 25.0461C5.55339 24.2467 5.49288 23.4914 5.39441 22.752C6.12191 22.7553 7.00078 22.7643 7.90232 22.7962C7.89322 23.5766 7.92334 24.3786 7.9935 25.2316Z" />
    </>
  )
}

export default List
