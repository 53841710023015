import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  MenuSection,
  MenuSectionCourses,
  MenuSectionEvents,
  MenuSectionIdeas,
  MenuSectionInfo,
  MenuSectionShop,
  MenuSectionTalks,
  MenuSectionType,
} from '@types'

import { RootState } from '../../store'
import { setVisible } from '../search/actions'

type MenuReducerState = {
  large: {
    activeSection?: MenuSectionType
  }
  sections?: MenuSection[]
  sectionsLoaded: boolean
  small: {
    activeSection?: MenuSectionType
    visible: boolean
  }
}

export const initialState = {
  large: {
    activeSection: undefined,
  },
  sections: undefined,
  sectionsLoaded: false,
  small: {
    activeSection: undefined,
    visible: false,
  },
} as MenuReducerState

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  extraReducers: builder => {
    // (This action is referenced in other reducers, and is imported to avoid circular dependency issues)
    // Close (small) menu when search panel is visible
    builder.addCase(setVisible, (state, action) => {
      if (action.payload.visible) {
        state.small.visible = false
      }
    })
  },
  reducers: {
    clearLargeActiveSection(state) {
      delete state.large.activeSection
    },
    clearSmallActiveSection(state) {
      delete state.small.activeSection
    },
    hydrate(state, action: PayloadAction<{ sections: MenuSection[] }>) {
      state.sections = action.payload.sections
      state.sectionsLoaded = true
    },
    setLargeActiveSection(
      state,
      action: PayloadAction<{ activeSection: MenuSectionType }>
    ) {
      state.large.activeSection = action.payload.activeSection
    },
    setSmallActiveSection(
      state,
      action: PayloadAction<{ activeSection: MenuSectionType }>
    ) {
      state.small.activeSection = action.payload.activeSection
    },
    setSmallVisible(state, action: PayloadAction<{ visible: boolean }>) {
      state.small.visible = action.payload.visible
    },
  },
})

// Selectors
const selectSelf = (state: RootState) => state

export const selectSectionCourses = createSelector(
  selectSelf,
  state =>
    state.menu.sections?.find(
      section => section._type === 'sectionCourses'
    ) as MenuSectionCourses
)

export const selectSectionEvents = createSelector(
  selectSelf,
  state =>
    state.menu.sections?.find(
      section => section._type === 'sectionEvents'
    ) as MenuSectionEvents
)

export const selectSectionIdeas = createSelector(
  selectSelf,
  state =>
    state.menu.sections?.find(
      section => section._type === 'sectionIdeas'
    ) as MenuSectionIdeas
)

export const selectSectionInfo = createSelector(
  selectSelf,
  state =>
    state.menu.sections?.find(
      section => section._type === 'sectionInfo'
    ) as MenuSectionInfo
)

export const selectSectionShop = createSelector(
  selectSelf,
  state =>
    state.menu.sections?.find(
      section => section._type === 'sectionShop'
    ) as MenuSectionShop
)

export const selectSectionTalks = createSelector(
  selectSelf,
  state =>
    state.menu.sections?.find(
      section => section._type === 'sectionTalks'
    ) as MenuSectionTalks
)

export const menuActions = menuSlice.actions

export default menuSlice.reducer
