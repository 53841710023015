import React from 'react'

const Path08 = () => {
  return (
    <>
      <path d="M102.5 75.9527C101.78 79.401 100.98 82.6273 100.437 85.889C98.4191 97.9406 96.4632 110.01 94.5251 122.07C92.1069 137.126 76.2195 144.049 63.5593 139.899C58.1272 138.121 55.0066 133.526 54.9889 127.199C54.9622 119.795 55.2289 112.392 55.5134 104.997C55.9224 94.3855 56.438 83.7827 56.9626 73.1709C57.1048 70.3446 56.2869 68.1849 53.2819 66.7451C52.8462 67.6872 52.2506 68.4427 52.1617 69.2603C50.4902 84.1204 48.9255 98.9982 47.2274 113.858C46.7295 118.169 46.045 122.47 45.1292 126.701C43.5289 134.06 39.0926 139.17 31.8201 141.232C27.9972 142.316 23.9342 142.734 19.9423 142.974C15.1148 143.267 11.1852 141.063 8.23349 137.313C2.81026 130.407 -0.425884 122.79 0.0453145 113.725C0.400936 106.988 0.000867223 100.207 0.134225 93.4523C0.489847 76.0149 4.27723 59.2796 11.6742 43.5219C22.0494 21.4184 40.4528 10.5667 64.0483 7.43825C73.3745 6.20288 82.3628 7.59823 90.9333 11.3843C98.8726 14.8861 106.838 18.37 114.564 22.3072C118.69 24.4047 122.468 27.2753 126.158 30.1016C136.266 37.8427 142.738 47.9746 145.21 60.4972C146.766 68.3805 148.331 76.2815 149.362 84.2448C150.58 93.7279 150.153 103.22 147.219 112.436C145.317 118.409 143.21 124.319 141.307 130.291C140.516 132.762 138.987 134.46 136.755 135.642C129.865 139.295 120.397 138.308 114.484 133.251C111.008 130.282 109.061 126.488 108.946 121.884C108.768 114.649 108.661 107.406 108.545 100.162C108.457 94.5277 108.457 88.9019 108.305 83.2672C108.217 79.7299 106.598 77.2058 102.5 75.9527Z" />
    </>
  )
}

export default Path08
