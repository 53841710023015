// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import { Box, Flex } from 'theme-ui'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'
import { ALGOLIA_INDEX, ALGOLIA_OPTIONAL_FILTERS } from '../../constants'
import useTypedSelector from '../../hooks/useTypedSelector'
import { algoliaClientRead } from '../../lib/algolia'
import { searchActions } from '../../redux/modules/search'
import { useAppDispatch } from '../../redux/store'
import { customScrollbarStyles } from '../../styled/theme'
import AlgoliaCustomResults from '../AlgoliaCustomResults'
import AlgoliaCustomResultsFeedback from '../AlgoliaCustomResultsFeedback'
import AlgoliaRecommendedSearches from '../AlgoliaRecommendedSearches'
import AlgoliaSearchBox from '../AlgoliaSearchbox'
import ButtonIcon from '../ButtonIcon'
import DocumentHit from '../DocumentHit'
import Modal from '../Modal'
import { trackSearchSubmit } from '../../utils/analytics/trackSearchSubmit'

// TODO: DRY with search page
const customAlgoliaClient = {
  ...algoliaClientRead,
  // Wrap proxy around algolia search:
  // - Don't fire a request if no query has been provided
  search(requests: any) {
    if (requests.every(({ params }: { params: any }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      })
    }

    return algoliaClientRead.search(requests)
  },
}

const ModalSearch = () => {
  // State
  const [searchState, setSearchState] = useState<
    Record<string, any> | undefined
  >(undefined)
  const [ready, setReady] = useState(false)

  // Refs
  const refContent = useRef<HTMLDivElement>(null)

  // Redux
  const dispatch = useAppDispatch()
  const searchVisible = useTypedSelector(state => state.search.visible)
  const globalSearchState = useTypedSelector(state => state.search.searchState)

  const router = useRouter()

  // Callbacks
  const handleClose = () => {
    dispatch(searchActions.setVisible({ visible: false }))
  }

  const handleSearchStateChange = (searchState: any) => {
    setSearchState(searchState)

    if (searchState?.query) {
      // Analytics
      trackSearchSubmit(searchState.query)
    }
  }

  // Clear search state when modal has unmounted
  const handleUnmount = () => {
    setSearchState(undefined)
  }

  /*
  // Lock body scroll when modal visible, re-enable when hidden
  useEffect(() => {
    if (refContent?.current) {
      if (searchVisible) {
        disableBodyScroll(refContent.current)
      } else {
        enableBodyScroll(refContent.current)
      }
    }

    return () => {
      if (refContent?.current) {
        enableBodyScroll(refContent.current)
      }
    }
  }, [searchVisible])
  */

  // Effects
  // Close modal on route change
  useEffect(() => {
    router.events.on('routeChangeComplete', handleClose)

    return () => {
      router.events.off('routeChangeComplete', handleClose)
    }
  }, [])

  // Close menu when search state has changed (i.e. query has updated)
  useDeepCompareEffectNoCheck(() => {
    if (ready && searchVisible) {
      handleClose()
    }
  }, [globalSearchState])

  // HACK: only render <InstantSearch> after initial mount to prevent
  // odd rendering issues when encapsulated within a react portal
  useEffect(() => {
    setReady(true)
  }, [])

  const hasSearchQuery = !!(searchState?.query && searchState.query.length > 0)

  if (!ready) {
    return null
  }

  return (
    <Modal onUnmount={handleUnmount} visible={searchVisible}>
      <InstantSearch
        indexName={`${ALGOLIA_INDEX}_distinct`}
        onSearchStateChange={handleSearchStateChange}
        searchClient={customAlgoliaClient}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            height: '100%',
            overflowY: 'auto',
            width: '100%',
            ...customScrollbarStyles(),
          }}
        >
          {/* Header */}
          <Box bg="white" px={[4, null, null, 6]} pt={4}>
            <Flex sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
              <ButtonIcon
                background="stone"
                iconSize="12px"
                onClick={handleClose}
                size="34px"
                type="close"
              />
            </Flex>

            <Box mt={[6, null, null, 10]}>
              {/* Search box */}
              <AlgoliaSearchBox showViewAllButton />
            </Box>
          </Box>

          <Box bg="white" px={[4, null, null, 6]} py={8}>
            {/* Recommended searches */}
            {!hasSearchQuery && <AlgoliaRecommendedSearches />}

            <Box ref={refContent}>
              {/* No results */}
              {hasSearchQuery && <AlgoliaCustomResultsFeedback />}

              {/* Search results */}
              {hasSearchQuery && (
                <AlgoliaCustomResults
                  hitComponent={({ item }: { item: any }) => {
                    return <DocumentHit item={item} />
                  }}
                  rowGap={4}
                />
              )}
            </Box>
          </Box>
        </Flex>

        {/* Configuration */}
        <Configure
          distinct={true}
          hitsPerPage={6}
          optionalFilters={ALGOLIA_OPTIONAL_FILTERS}
        />
      </InstantSearch>
    </Modal>
  )
}

export default ModalSearch
