import React from 'react'

import { useAppDispatch } from '../../redux/store'
import useTypedSelector from '../../hooks/useTypedSelector'
import lineItemUpdate from '../../redux/modules/store/thunks/lineItemUpdate'
import ButtonIcon from '../ButtonIcon'

type Props = {
  disabled?: boolean
  productVariantId: string
  quantity: number
}

const ButtonAddSingleQuantity = (props: Props) => {
  const { disabled, productVariantId, quantity } = props

  // Store: redux
  const dispatch = useAppDispatch()
  const checkoutUpdating = useTypedSelector(
    state => state.store.checkoutUpdating
  )
  const lineItemRemoving = useTypedSelector(
    state => state.store.lineItemRemoving
  )
  const lineItemUpdating = useTypedSelector(
    state => state.store.lineItemUpdating
  )

  // Callbacks
  const handleUpdate = () => {
    dispatch(
      lineItemUpdate({
        productVariantId,
        quantity: quantity + 1,
      })
    )
  }

  return (
    <ButtonIcon
      disabled={
        checkoutUpdating || disabled || lineItemRemoving || lineItemUpdating
      }
      iconSize={12}
      onClick={handleUpdate}
      size={12}
      type="add"
    />
  )
}

export default ButtonAddSingleQuantity
