import { useEffect, useState } from 'react'

const useScrollAtTop = (
  { offset }: { offset: number } = { offset: 0 }
): boolean => {
  const [atTop, setAtTop] = useState(true)

  useEffect(() => {
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      setAtTop(scrollY <= offset)
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return atTop
}

export default useScrollAtTop
