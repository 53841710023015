import { MenuSectionType } from '@types'
import React from 'react'

import MenuSectionCourses from '../MenuSectionCourses'
import MenuSectionEvents from '../MenuSectionEvents'
import MenuSectionIdeas from '../MenuSectionIdeas'
import MenuSectionInfo from '../MenuSectionInfo'
import MenuSectionShop from '../MenuSectionShop'
import MenuSectionTalks from '../MenuSectionTalks'

type Props = {
  type?: MenuSectionType
}

const MenuSection = (props: Props) => {
  const { type } = props

  return (
    <>
      {type === 'sectionCourses' && <MenuSectionCourses />}
      {type === 'sectionEvents' && <MenuSectionEvents />}
      {type === 'sectionIdeas' && <MenuSectionIdeas />}
      {type === 'sectionInfo' && <MenuSectionInfo />}
      {type === 'sectionShop' && <MenuSectionShop />}
      {type === 'sectionTalks' && <MenuSectionTalks />}
    </>
  )
}

export default MenuSection
