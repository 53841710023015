import React from 'react'

import theme from '../../../styled/theme'

const Path07 = () => {
  return (
    <g fill={theme.colors.lemon}>
      <path d="M92.5996 60.8C87.7996 49.3 77.7996 42 64.2996 44.4C60.2996 45.1 56.4996 46.6 52.5996 47.8C51.4996 48.1 50.4996 48.5 49.3996 48.9C49.2996 48.6 49.0996 48.4 48.9996 48.1C49.6996 47.3 50.2996 46.5 50.9996 45.7C54.4996 41.6 57.6996 37.3 59.7996 32.4C61.7996 27.7 61.4996 23 59.1996 18.5C57.5996 15.3 55.4996 12.5 52.7996 10.2C44.6996 3.09995 35.7996 0.899955 25.4996 4.89995C16.4996 8.29995 8 12.5 0 17.5V85C0.7 84.9 1.49961 84.7 2.39961 84.6C1.59961 85.9 0.8 87.1 0 88.3V94H75.4996C80.6996 88.5 85.4996 82.7 90.2996 76.7C94.1996 71.8 94.9996 66.4 92.5996 60.8Z" />
    </g>
  )
}

export default Path07
