import React from 'react'

const Path13 = () => {
  return (
    <>
      <path d="M0 140.794C27.2911 95.9912 54.0842 52.0004 80.8868 8C84 10.0456 86.3182 12.8656 89.1919 18.3525C97.2864 33.8477 105.342 49.3621 113.379 64.886C124.52 86.4034 135.661 107.921 146.744 129.467C147.922 131.761 148.909 134.18 149.732 136.627C150.537 139.016 149.493 140.814 147.05 141.33C144.866 141.789 142.596 142.018 140.364 141.999C118.495 141.731 96.635 141.206 74.7752 141.119C55.1762 141.043 35.5676 141.483 15.9685 141.569C11.0065 141.588 6.0349 141.1 0 140.794Z" />
    </>
  )
}

export default Path13
