import { Box, BoxOwnProps, Flex } from '@theme-ui/components'
import {
  ColorTheme,
  SanityLinkEverything,
  SanityLinkExternal,
  SanityLinkInternal,
  SanityLinkStub,
} from '@types'
import React, { FunctionComponent } from 'react'
import Button from '../Button'
import LinkSanity from '../LinkSanity'

type Props = {
  align?: 'center' | 'left' | 'right'
  colorTheme?: ColorTheme
  links?: (
    | SanityLinkExternal
    | SanityLinkEverything
    | SanityLinkInternal
    | SanityLinkStub
  )[]
}

const Buttons: FunctionComponent<BoxOwnProps & Props> = (props: Props) => {
  const { align, colorTheme, links, ...rest } = props
  if (!links || links.length === 0) {
    return null
  }

  let justifyContent
  switch (align) {
    case 'left':
      justifyContent = 'flex-start'
      break
    case 'right':
      justifyContent = 'flex-end'
      break
    case 'center':
    default:
      justifyContent = 'center'
      break
  }

  return (
    <Flex sx={{ justifyContent, flexWrap: 'wrap' }} {...rest}>
      {links?.map((link, index) => {
        return (
          <Box key={index} mb={2} ml={index === 0 ? 0 : '6px'}>
            <LinkSanity link={link}>
              <Button
                background={colorTheme?.text}
                color={colorTheme?.background}
                variant="outline"
              >
                {link.title}
              </Button>
            </LinkSanity>
          </Box>
        )
      })}
    </Flex>
  )
}

export default Buttons
