import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  MailingList,
  SanityBlock,
  SanityImage,
  ShopifyProduct,
  SocialType,
} from '@types'
import { trackViewCartClick } from '../../../utils/analytics/trackViewCartClick'
import lineItemAdd from '../store/thunks/lineItemAdd'

type SystemState = {
  cartSinglePurchaseLineItemText?: SanityBlock[]
  cartText?: SanityBlock[]
  cartVisible: boolean
  courseLoginUrl?: string
  meta?: {
    description: string
    image: SanityImage
    title: string
  }
  newsletter?: {
    customTitle?: string
    hidden?: boolean
    mailingList: MailingList
    title?: string
  }
  newsletterModalVisible?: boolean
  productVariantPanel: {
    product?: ShopifyProduct
    singlePurchase?: boolean
    title?: string
    visible: boolean
  }
  recommendedSearches?: string[]
  social?: Record<SocialType, string>
}

export const initialState = {
  cartText: undefined,
  cartVisible: false,
  courseLoginUrl: undefined,
  meta: undefined,
  navigation: undefined,
  newsletter: undefined,
  newsletterVisible: false,
  productVariantPanel: {
    visible: false,
  },
} as SystemState

const systemSlice = createSlice({
  name: 'system',
  initialState,
  extraReducers: builder => {
    builder.addCase(
      // Line item added
      lineItemAdd.fulfilled,
      state => {
        state.cartVisible = true
      }
    )
  },
  reducers: {
    clearNewsletterCustomTitle(state) {
      delete state?.newsletter?.customTitle
    },
    hideProductVariantPanel(state) {
      state.productVariantPanel.visible = false
    },
    setCartVisible(state, action: PayloadAction<{ cartVisible: boolean }>) {
      if (action.payload.cartVisible) {
        // (Analytics)
        trackViewCartClick()
      }

      state.cartVisible = action.payload.cartVisible
    },
    setNewsletterHidden(state, action: PayloadAction<{ hidden: boolean }>) {
      if (state.newsletter) {
        state.newsletter.hidden = action.payload?.hidden
      }
    },
    setNewsletterModalVisible(
      state,
      action: PayloadAction<{ newsletterModalVisible: boolean }>
    ) {
      state.newsletterModalVisible = action.payload?.newsletterModalVisible
    },
    setNewsletterCustomTitle(
      state,
      action: PayloadAction<{ customTitle: string }>
    ) {
      if (state.newsletter) {
        state.newsletter.customTitle = action.payload?.customTitle
      }
    },
    showProductVariantPanel(
      state,
      action: PayloadAction<{
        product: ShopifyProduct
        singlePurchase?: boolean
        title: string
      }>
    ) {
      if (action.payload.singlePurchase) {
        state.productVariantPanel.singlePurchase = action.payload.singlePurchase
      }
      state.productVariantPanel.product = action.payload.product
      state.productVariantPanel.title = action.payload.title
      state.productVariantPanel.visible = true
    },
  },
})

export const systemActions = systemSlice.actions

export default systemSlice.reducer
