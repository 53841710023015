export const swrFetcher = async (url: string) => {
  const res = await fetch(url)

  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.')
    /*
    // Optional: Attach extra info to the error object.
    error.info = await res.json()
    error.status = res.status
    */
    throw error
  }

  return res.json()
}
