import { AnimatePresence } from 'framer-motion'
import React, { ReactNode, useState } from 'react'
import { useEffect } from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import BoxMotion from '../BoxMotion'
import Portal from '../Portal'

type Props = {
  children: ReactNode
  onUnmount?: () => void
  preventUnmount?: boolean
  visible?: boolean
}

const Modal = (props: Props) => {
  const { children, onUnmount, preventUnmount, visible } = props

  const [mounted, setMounted] = useState(false)

  const containerStyles: ThemeUIStyleObject = {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    height: '100%',
    left: 0,
    opacity: 0.5,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 'modal',
  }

  useEffect(() => {
    setMounted(true)
  }, [])

  // Don't render portals on initial mount
  if (!mounted) {
    return null
  }

  if (preventUnmount) {
    return (
      <Portal id="portal-modal">
        <BoxMotion
          // framer-motion
          animate={{
            display: 'block',
            opacity: visible ? 1 : 0,
            transitionEnd: {
              display: visible ? 'block' : 'none',
            },
          }}
          initial={{
            opacity: 0,
          }}
          transition={{
            damping: 20,
            stiffness: 125,
            type: 'spring',
          }}
          // theme-ui
          sx={containerStyles}
        >
          {children}
        </BoxMotion>
      </Portal>
    )
  }

  return (
    <Portal id="portal-modal">
      <AnimatePresence
        onExitComplete={() => {
          if (onUnmount) {
            onUnmount()
          }
        }}
      >
        {visible && (
          <BoxMotion
            // framer-motion
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            transition={{
              damping: 20,
              stiffness: 125,
              type: 'spring',
            }}
            // theme-ui
            sx={containerStyles}
          >
            {children}
          </BoxMotion>
        )}
      </AnimatePresence>
    </Portal>
  )
}

export default Modal
