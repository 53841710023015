import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import useSWR from 'swr'
import { Box, Flex } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import { systemActions } from '../../redux/modules/system'
import { useAppDispatch } from '../../redux/store'
import { customScrollbarStyles } from '../../styled/theme'
import { NewsletterPage } from '../../types'
import { trackNewsletterPanelSubmit } from '../../utils/analytics/trackNewsletterPanelSubmit'
import { swrFetcher } from '../../utils/swrFetcher'
import Artboard from '../Artboard'
import BlockSubscribe from '../BlockSubscribe'
import ButtonIcon from '../ButtonIcon'
import DividerHorizontalRandom from '../DividerHorizontalRandom'
import Eyebrow from '../Eyebrow'
import H3 from '../H3'
import HeroTitle from '../HeroTitle'
import Modal from '../Modal'

const ModalNewsletter = () => {
  // Refs
  const refContainer = useRef<HTMLDivElement>(null)

  // State
  const [descriptionVisible, setDescriptionVisible] = useState(true)

  const router = useRouter()

  const { data: newsletter, error } = useSWR<NewsletterPage>(
    '/api/pages/newsletter',
    swrFetcher
  )

  // Redux
  const dispatch = useAppDispatch()
  const modalVisible = useTypedSelector(
    state => state.system.newsletterModalVisible
  )

  // Callbacks
  const handleClose = () => {
    dispatch(
      systemActions.setNewsletterModalVisible({ newsletterModalVisible: false })
    )
  }

  const handleSuccess = (email: string) => {
    // Analytics
    trackNewsletterPanelSubmit(document?.title)

    setDescriptionVisible(false)
  }

  // Close modal on route change
  useEffect(() => {
    router.events.on('routeChangeComplete', handleClose)

    return () => {
      router.events.off('routeChangeComplete', handleClose)
    }
  }, [])

  // TODO: display loading / error state
  return (
    <Modal visible={modalVisible}>
      <Box
        bg="white"
        ref={refContainer}
        sx={{
          alignItems: 'space-between',
          bottom: [0, null, null, 6],
          color: 'white',
          display: 'flex',
          flexDirection: ['column', null, null, 'row'],
          height: 'auto',
          left: [0, null, null, 6],
          overflowY: ['auto', null, null, 'hidden'],
          position: 'fixed',
          right: [0, null, null, 6],
          top: [0, null, null, 6],
          width: 'auto',
          ...customScrollbarStyles(),
        }}
      >
        {newsletter && (
          <>
            <Flex
              color={newsletter?.artboard?.colorTheme.text}
              py={27}
              sx={{
                alignItems: 'center',
                flex: 1,
                flexShrink: 0,
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              {newsletter?.artboard && (
                <Artboard artboard={newsletter.artboard} />
              )}

              <Flex
                px={[4, null, null, 12]}
                sx={{
                  alignItems: 'center',
                  flexDirection: 'column',
                  maxWidth: '600px',
                  position: 'relative',
                  textAlign: 'center',
                }}
              >
                {/* Eyebrow */}
                {newsletter.eyebrow && <Eyebrow>{newsletter.eyebrow}</Eyebrow>}

                {/* Title */}
                {newsletter.heading && (
                  <Box mt={4} sx={{ h1: { lineHeight: 'single' } }}>
                    <HeroTitle title={newsletter.heading} />
                  </Box>
                )}

                {/* Description */}
                {newsletter.description && descriptionVisible && (
                  <Box
                    my={4}
                    sx={{
                      fontSize: ['xs', null, null, 's'],
                      lineHeight: 'body',
                      maxWidth: '420px',
                    }}
                  >
                    {newsletter.description}
                  </Box>
                )}

                {newsletter.mailingList && (
                  <BlockSubscribe
                    colorTheme={newsletter.artboard?.colorTheme}
                    mt={descriptionVisible ? 0 : 4}
                    onSuccess={handleSuccess}
                    {...newsletter.mailingList}
                  />
                )}
              </Flex>
            </Flex>
            <Box
              bg="white"
              color="midnight"
              sx={{
                flex: 1,
                overflowY: ['visible', null, null, 'auto'],
                ...customScrollbarStyles(),
              }}
            >
              <Box
                mb={[2, null, null, 0]}
                px={[4, null, null, 17]}
                pt={[8, null, null, 17]}
              >
                {newsletter?.items?.map((item, index) => {
                  const label = (index + 1).toString().padStart(2, '0') + '.'
                  return (
                    <Box key={index}>
                      <Eyebrow>{label}</Eyebrow>
                      <Box mb={8} mt={4}>
                        <H3>{item.title}</H3>
                        <Box
                          mt={3}
                          sx={{
                            fontSize: ['xs', null, null, 's'],
                            lineHeight: 'body',
                          }}
                        >
                          {item.body}
                        </Box>
                      </Box>

                      {newsletter.items && (
                        <DividerHorizontalRandom
                          pb={8}
                          sx={{
                            display: [
                              'block',
                              null,
                              null,
                              index === newsletter.items.length - 1
                                ? 'none'
                                : 'block',
                            ],

                            opacity: 0.2,
                            width: '100%',
                          }}
                        />
                      )}
                    </Box>
                  )
                })}
              </Box>
            </Box>
          </>
        )}

        {/* Header */}
        <Box
          p={6}
          sx={{
            color: 'black',
            display: 'flex',
            flexShrink: 0,
            justifyContent: 'flex-end',
            position: ['fixed', null, null, 'absolute'],
            right: 0,
            top: 0,
          }}
        >
          <ButtonIcon
            background="stone"
            iconSize="12px"
            onClick={handleClose}
            size="34px"
            type="close"
          />
        </Box>
      </Box>
    </Modal>
  )
}

export default ModalNewsletter
