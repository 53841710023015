import React from 'react'

const Print = () => {
  return (
    <>
      <path d="M32.3262 7.48163C32.267 6.90787 31.8439 6.58021 31.3931 6.49847C31.2936 6.46316 31.1872 6.43665 31.0658 6.43137C21.5461 6.01492 12.0164 5.81646 2.49237 6.22138C1.8555 6.24843 1.52215 6.73062 1.48548 7.22624C1.46291 7.3054 1.44691 7.39006 1.44176 7.48197C1.13146 13.2562 0.931981 19.0321 1.02152 24.8153C1.02342 24.9289 1.04423 25.0293 1.07263 25.1237C1.0079 25.6868 1.34056 26.3356 2.07199 26.391C12.1291 27.153 22.2799 27.523 32.2904 26.0379C32.879 25.9508 33.0972 25.5092 33.0441 25.0592C33.0579 24.9832 33.0641 24.902 33.0614 24.8149C32.8656 19.0328 32.9206 13.2429 32.3262 7.48163ZM3.11806 24.3667C3.04899 19.0022 3.22339 13.6435 3.50109 8.28701C12.4392 7.94661 21.3823 8.12217 30.3165 8.5016C30.786 13.6954 30.7794 18.9103 30.9397 24.1199C21.733 25.3901 12.3649 25.0438 3.11806 24.3667Z" />
      <path d="M29.5566 21.448C28.3409 19.2909 26.7707 17.3672 24.8753 15.7727C24.4244 15.3934 23.8847 15.4638 23.5075 15.7361C23.4307 15.7772 23.3554 15.8231 23.2845 15.8781C22.2822 16.6527 21.3036 17.4567 20.3313 18.2679C19.4808 16.7029 18.535 15.195 17.4171 13.7768C17.071 13.3376 16.2876 13.3929 15.9315 13.7768C13.6614 16.2221 11.8121 18.978 9.98955 21.7629C9.78512 22.0753 9.77841 22.3793 9.88523 22.6354C9.87561 23.1678 10.2125 23.7271 10.8963 23.764C14.3983 23.9515 17.8982 23.8693 21.401 23.764C21.4042 23.764 21.4069 23.7632 21.41 23.7629C23.8238 23.7609 26.2373 23.748 28.6497 23.659C29.4386 23.6297 29.7645 22.9021 29.6324 22.3107C29.7329 22.0561 29.7304 21.757 29.5566 21.448ZM12.5233 21.7299C13.7963 19.8068 15.1058 17.9183 16.6088 16.1684C17.8397 17.9007 18.8289 19.7858 19.7327 21.7099C17.3285 21.7729 14.9263 21.8052 12.5233 21.7299ZM22.0089 21.661C21.7807 21.1625 21.5501 20.6659 21.3112 20.1751C22.2319 19.4013 23.1616 18.6393 24.1047 17.8931C25.2928 18.9959 26.313 20.2354 27.1842 21.6007C25.4597 21.6441 23.7343 21.6579 22.0089 21.661Z" />
      <path d="M11.766 13.5798C11.9739 12.6098 11.6531 11.679 11.0518 11.003C10.3805 10.2483 9.35907 9.81106 8.33341 9.99102C6.40592 10.3294 5.26428 12.4343 6.13127 14.2709C6.73791 15.5552 9.0426 16.0944 10.2416 15.564C11.0226 15.219 11.5895 14.4023 11.766 13.5798ZM9.66612 13.2368C9.31644 13.8179 8.63063 13.7154 8.30345 13.2717C8.18904 13.1163 8.11848 12.9192 8.12187 12.6952C8.14267 11.2793 10.2604 12.2486 9.66612 13.2368Z" />
    </>
  )
}

export default Print
