import { SanityDocumentTypeSingleton } from '@types'
import NextLink from 'next/link'
import { ReactNode } from 'react'
import { Box } from 'theme-ui'
import getSingletonHref from '../../utils/getSingletonHref'
import Button from '../Button'
import DividerHorizontalRandom from '../DividerHorizontalRandom'

type Props = {
  children: ReactNode
  documentTypeIndex: SanityDocumentTypeSingleton
  documentTypeLabel: string
}

const MenuSectionWrapper = (props: Props) => {
  const { children, documentTypeIndex, documentTypeLabel } = props

  const OverviewLink = () => {
    return (
      <NextLink href={getSingletonHref(documentTypeIndex)}>
        <a>
          <Button sx={{ width: '100%' }}>{documentTypeLabel}</Button>
        </a>
      </NextLink>
    )
  }

  return (
    <Box>
      <Box sx={{ display: ['block', null, null, 'none'] }}>
        <OverviewLink />
        <DividerHorizontalRandom color="stone" my={8} />
      </Box>

      {children}

      <Box sx={{ display: ['none', null, null, 'block'] }}>
        <DividerHorizontalRandom color="stone" my={8} />
        <OverviewLink />
      </Box>
    </Box>
  )
}

export default MenuSectionWrapper
