import {
  LinkVariant,
  SanityAnnotationLinkExternal,
  SanityAnnotationLinkInternal,
  SanityLinkEverything,
  SanityLinkExternal,
  SanityLinkInternal,
  SanityLinkStub,
} from '@types'
import NextLink from 'next/link'
import React, { ReactNode } from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import { aliasDocumentType } from '../../utils/aliasDocumentType'
import Link from '../Link'
import LinkCourseLogin from '../LinkCourseLogin'
import LinkIubendaTrackingPreferences from '../LinkIubendaTrackingPreferences'
import LinkModalNewsletter from '../LinkModalNewsletter'
import RefinementLink from '../RefinementLink'

type Props = {
  children?: ReactNode
  link:
    | SanityAnnotationLinkExternal
    | SanityAnnotationLinkInternal
    | SanityLinkExternal
    | SanityLinkEverything
    | SanityLinkInternal
    | SanityLinkStub
  sx?: ThemeUIStyleObject
  variant?: LinkVariant
}

const LinkSanity = (props: Props) => {
  const { children, link, sx, variant } = props

  // External links
  if (
    link._type === 'annotationLinkExternal' ||
    link._type === 'linkExternal'
  ) {
    if (!link.url) {
      return null
    }

    return (
      <Link
        href={link.url}
        rel="noopener noreferrer"
        sx={sx}
        target={link.newWindow ? '_blank' : '_self'}
        variant={variant}
      >
        {children}
      </Link>
    )
  }

  // 'Everything' links
  if (link._type === 'linkEverything') {
    return (
      <RefinementLink
        refinements={[
          {
            name: 'type',
            slug: 'type',
            value: aliasDocumentType(link.documentType),
          },
        ]}
      >
        <a>{children}</a>
      </RefinementLink>
    )
  }

  // Internal links
  if (
    link._type === 'annotationLinkInternal' ||
    link._type === 'linkInternal'
  ) {
    if (!link.slug) {
      return null
    }

    return (
      <NextLink href={link.slug} passHref>
        <Link sx={sx} variant={variant}>
          {children}
        </Link>
      </NextLink>
    )
  }

  // Stub links
  if (link._type === 'linkStub') {
    if (link.type === 'cookiePreferences') {
      return (
        <LinkIubendaTrackingPreferences sx={sx} variant={variant}>
          {children}
        </LinkIubendaTrackingPreferences>
      )
    }

    if (link.type === 'courseLogin') {
      return (
        <LinkCourseLogin sx={sx} variant={variant}>
          {children}
        </LinkCourseLogin>
      )
    }

    if (link.type === 'modalNewsletter') {
      return (
        <LinkModalNewsletter sx={sx} variant={variant}>
          {children}
        </LinkModalNewsletter>
      )
    }
  }

  return null
}

export default LinkSanity
