import { ColorTheme, MailingList } from '@types'
import React, { FunctionComponent, useState } from 'react'
import { Box, BoxOwnProps, ThemeUIStyleObject } from 'theme-ui'
import FormSubscribe from '../FormSubscribe'
import PortableText from '../PortableText'

export type Props = MailingList & {
  colorTheme?: ColorTheme
  onSuccess?: (email: string) => void
  sx?: ThemeUIStyleObject
}

const BlockSubscribe: FunctionComponent<BoxOwnProps & Props> = (
  props: Props
) => {
  const { colorTheme, finePrint, label, listId, onSuccess, sx, ...rest } = props

  // State
  const [finePrintVisible, setFinePrintVisible] = useState(true)

  const handleSuccess = (email: string) => {
    if (onSuccess) {
      onSuccess(email)
    }

    setFinePrintVisible(false)
  }

  return (
    <Box
      sx={{
        textAlign: 'center',
        ...sx,
      }}
      {...rest}
    >
      {/* Signup form */}
      {listId && (
        <FormSubscribe
          colorTheme={colorTheme}
          label={label}
          listId={listId}
          onSuccess={handleSuccess}
        />
      )}

      {/* Fine print */}
      {finePrint && finePrintVisible && (
        <Box
          mt={2}
          sx={{
            opacity: 0.5,
            li: { fontSize: 'xxs' },
            p: { fontSize: 'xxs' },
          }}
        >
          <PortableText blocks={finePrint} />
        </Box>
      )}
    </Box>
  )
}

export default BlockSubscribe
