import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { ColorTheme } from '@types'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Flex } from 'theme-ui'
import * as yup from 'yup'
import Button from '../Button'
import FormFieldTextInput from '../FormFieldTextInput'

type FormData = yup.InferType<typeof formSchema>

const formSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter your email address'),
  phone: yup.string(), // honeypot
})

type Props = {
  colorTheme?: ColorTheme
  label?: string
  listId: string
  onSuccess?: (email: string) => void
}

const FormSubscribe = (props: Props) => {
  const { colorTheme, label, listId, onSuccess } = props

  // State
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [serverResponse, setFeedback] = useState<
    | {
        message: string
        status: 'error' | 'success'
      }
    | undefined
  >(undefined)

  const router = useRouter()

  // react-hook-form
  const {
    formState: { dirtyFields, errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    defaultValues: {
      email: '',
      phone: '', // honepot
    },
    resolver: yupResolver(formSchema),
  })

  // Callbacks
  // - reset form
  const handleReset = () => {
    // Clear feedback
    setFeedback(undefined)

    // Reset react-hook-form
    reset()
  }

  // - submit react-hook-form
  const onSubmit = async (formData: FormData) => {
    // Disable form input
    setIsSubmitting(true)
    // Clear existing feedback
    setFeedback(undefined)

    try {
      const response = await fetch('/api/subscribe', {
        body: JSON.stringify({
          ...formData,
          listId,
        }),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      })

      const data = await response.json()

      // Throw error if response is not 2xx
      if (!response.ok) {
        throw Error(
          data?.error?.message ||
            response?.statusText ||
            'An internal error has occurred'
        )
      }

      // Show successful feedback
      setFeedback({
        message: data?.result?.message,
        status: 'success',
      })

      if (onSuccess) {
        onSuccess(formData.email)
      }

      // Reset react-hook-form
      reset()
    } catch (err) {
      console.error(err)

      // Sentry: capture exception
      Sentry.captureException(err)

      setFeedback({
        message: err.message,
        status: 'error',
      })
    } finally {
      // Re-allow form input
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    // Reset form on route change
    router.events.on('routeChangeComplete', handleReset)

    return () => {
      router.events.off('routeChangeComplete', handleReset)
    }
  }, [])

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} sx={{ width: '100%' }}>
      {/* Feedback (success) */}
      {serverResponse?.status === 'success' ? (
        <Box sx={{ fontSize: 's', textAlign: 'center' }}>
          {serverResponse.message}
        </Box>
      ) : (
        <>
          {/* Form fields */}
          <Flex
            mb={1}
            sx={{
              flexDirection: ['column', null, null, 'row'],
            }}
          >
            <Box
              mb={[2, null, null, 0]}
              mr={[0, null, null, 2]}
              sx={{ flex: 1 }}
            >
              {/* Email */}
              <FormFieldTextInput
                disabled={isSubmitting}
                dirty={dirtyFields?.email}
                error={errors?.email}
                placeholder="Enter your email address"
                {...register('email')}
              />
              {/* Phone (honeypot) */}
              <FormFieldTextInput
                disabled={isSubmitting}
                dirty={dirtyFields?.phone}
                error={errors?.phone}
                honeypot
                placeholder="Enter your phone number"
                {...register('phone')}
              />
            </Box>
            {/* Submit */}
            <Button
              background={colorTheme?.text || 'white'}
              color={colorTheme?.background || 'midnight'}
              disabled={isSubmitting}
              sx={{ flexShrink: [1, null, null, 0] }}
              type="submit"
            >
              {isSubmitting ? 'Submitting' : label || 'Subscribe'}
            </Button>
          </Flex>

          {/* Feedback (error) */}
          {serverResponse?.status === 'error' && (
            <Box my={2} sx={{ fontSize: 'xxs', textAlign: 'center' }}>
              {serverResponse.message}
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default FormSubscribe
