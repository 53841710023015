import {
  Color,
  ColorTheme,
  SanityDocumentType,
  SanityDocumentTypeIndexable,
  SanityDocumentTypeLinkable,
  SanityDocumentTypeSingleton,
} from '@types'

export const ALGOLIA_FACET_MAP: {
  [K in SanityDocumentType | 'type']?: string
} = {
  category: 'categories.title',
  eventType: 'eventTypes.title',
  eventYear: 'eventYears.title',
  mood: 'moods.title',
  onlineCourseType: 'onlineCourseType.title',
  productType: 'productType.title',
  tag: 'tags.title',
  type: 'type',
}

export const ALGOLIA_INDEX = 'production'

// Custom weighting for algolia results, by type
export const ALGOLIA_OPTIONAL_FILTERS = [
  'type:page<score=8>',
  'type:talk<score=7>',
  'type:speaker<score=6>',
  'type:thoughts<score=5>',
  'type:event<score=4>',
  'type:onlineCourse<score=3>',
  'type:workshop<score=2>',
  'type:product<score=1>',
]

export const ARTBOARD_HERO_LAYOUTS = <const>[
  'bg-only', //
  'center',
  'left',
  'right',
  'zoomed',
]

export const ARTBOARD_LINKED_LAYOUTS = <const>[
  'bg-only', //
  'shape-only',
]

export const CHECKOUT_COOKIE_ID = 'checkout_id'

export const COLORS = {
  // grayscale
  black: '#000',
  lightGray: '#f8f8f8',
  white: '#ffffff',
  // custom
  bubblegum: '#ffd9d6',
  burgundy: '#781403',
  coral: '#ff5a30',
  corporate: '#b2c2d6',
  crust: '#9e5905',
  deepPurple: '#38052b',
  duck: '#7a6b00',
  lemon: '#fff7c2',
  lepea: '#c9b294',
  khaki: '#f2e5cf',
  metal: '#293d4f',
  notPurple: '#7a595c',
  midnight: '#171414',
  ocean: '#0a1c52',
  offWhite: '#f2f5eb',
  olive: '#a6bd78',
  racing: '#3d5c1f',
  slate: '#6c6c6c',
  sky: '#75a8fc',
  stone: '#e3dedb',
  sunshine: '#f79621',
  tagBlue: '#1E66DC',
  tagLightBlue: '#75A8FC',
  // temp
  debug: '#4257e8',
}

export const COLOR_THEMES: ColorTheme[] = [
  {
    background: 'bubblegum',
    shape: 'lepea',
    mark: 'crust',
    text: 'midnight',
    header: 'midnight',
    widgetBackground: 'lemon',
    widgetText: 'midnight',
  },
  {
    background: 'bubblegum',
    shape: 'offWhite',
    mark: 'burgundy',
    text: 'burgundy',
    header: 'midnight',
    widgetBackground: 'lemon',
    widgetText: 'midnight',
  },
  {
    background: 'bubblegum',
    shape: 'stone',
    mark: 'burgundy',
    text: 'burgundy',
    header: 'midnight',
    widgetBackground: 'olive',
    widgetText: 'midnight',
  },
  {
    background: 'burgundy',
    shape: 'deepPurple',
    mark: 'bubblegum',
    text: 'offWhite',
    header: 'white',
    widgetBackground: 'lemon',
    widgetText: 'midnight',
  },
  {
    background: 'coral',
    shape: 'burgundy',
    mark: 'lemon',
    text: 'offWhite',
    header: 'white',
    widgetBackground: 'bubblegum',
    widgetText: 'midnight',
  },
  {
    background: 'corporate',
    shape: 'offWhite',
    mark: 'ocean',
    text: 'ocean',
    header: 'midnight',
    widgetBackground: 'lemon',
    widgetText: 'midnight',
  },
  {
    background: 'coral',
    shape: 'notPurple',
    mark: 'bubblegum',
    text: 'offWhite',
    header: 'white',
    widgetBackground: 'olive',
    widgetText: 'midnight',
  },
  {
    background: 'deepPurple',
    shape: 'burgundy',
    mark: 'bubblegum',
    text: 'offWhite',
    header: 'white',
    widgetBackground: 'lemon',
    widgetText: 'midnight',
  },
  {
    background: 'duck',
    shape: 'olive',
    mark: 'lemon',
    text: 'offWhite',
    header: 'white',
    widgetBackground: 'bubblegum',
    widgetText: 'midnight',
  },
  {
    background: 'khaki',
    shape: 'olive',
    mark: 'corporate',
    text: 'midnight',
    header: 'midnight',
    widgetBackground: 'bubblegum',
    widgetText: 'midnight',
  },
  {
    background: 'lemon',
    shape: 'olive',
    mark: 'duck',
    text: 'midnight',
    header: 'midnight',
    widgetBackground: 'bubblegum',
    widgetText: 'midnight',
  },
  {
    background: 'lemon',
    shape: 'sunshine',
    mark: 'burgundy',
    text: 'midnight',
    header: 'midnight',
    widgetBackground: 'olive',
    widgetText: 'midnight',
  },
  {
    background: 'lepea',
    shape: 'bubblegum',
    mark: 'notPurple',
    text: 'midnight',
    header: 'midnight',
    widgetBackground: 'lemon',
    widgetText: 'midnight',
  },
  {
    background: 'metal',
    shape: 'coral',
    mark: 'lepea',
    text: 'offWhite',
    header: 'white',
    widgetBackground: 'lemon',
    widgetText: 'midnight',
  },
  {
    background: 'metal',
    shape: 'notPurple',
    mark: 'corporate',
    text: 'offWhite',
    header: 'white',
    widgetBackground: 'bubblegum',
    widgetText: 'midnight',
  },
  {
    background: 'metal',
    shape: 'notPurple',
    mark: 'corporate',
    text: 'offWhite',
    header: 'white',
    widgetBackground: 'olive',
    widgetText: 'midnight',
  },
  {
    background: 'metal',
    shape: 'olive',
    mark: 'lemon',
    text: 'offWhite',
    header: 'white',
    widgetBackground: 'bubblegum',
    widgetText: 'midnight',
  },
  {
    background: 'metal',
    shape: 'olive',
    mark: 'bubblegum',
    text: 'offWhite',
    header: 'white',
    widgetBackground: 'lemon',
    widgetText: 'midnight',
  },
  {
    background: 'metal',
    shape: 'sky',
    mark: 'lemon',
    text: 'offWhite',
    header: 'white',
    widgetBackground: 'olive',
    widgetText: 'midnight',
  },
  {
    background: 'notPurple',
    shape: 'deepPurple',
    mark: 'bubblegum',
    text: 'offWhite',
    header: 'white',
    widgetBackground: 'olive',
    widgetText: 'midnight',
  },
  {
    background: 'notPurple',
    shape: 'lepea',
    mark: 'bubblegum',
    text: 'offWhite',
    header: 'white',
    widgetBackground: 'lemon',
    widgetText: 'midnight',
  },
  {
    background: 'olive',
    shape: 'metal',
    mark: 'lemon',
    text: 'offWhite',
    header: 'white',
    widgetBackground: 'bubblegum',
    widgetText: 'midnight',
  },
  {
    background: 'olive',
    shape: 'offWhite',
    mark: 'racing',
    text: 'midnight',
    header: 'midnight',
    widgetBackground: 'bubblegum',
    widgetText: 'midnight',
  },
  {
    background: 'olive',
    shape: 'offWhite',
    mark: 'racing',
    text: 'midnight',
    header: 'midnight',
    widgetBackground: 'sky',
    widgetText: 'midnight',
  },
  {
    background: 'racing',
    shape: 'metal',
    mark: 'bubblegum',
    text: 'lemon',
    header: 'white',
    widgetBackground: 'offWhite',
    widgetText: 'midnight',
  },
  {
    background: 'racing',
    shape: 'metal',
    mark: 'lepea',
    text: 'offWhite',
    header: 'white',
    widgetBackground: 'bubblegum',
    widgetText: 'midnight',
  },
  {
    background: 'racing',
    shape: 'olive',
    mark: 'stone',
    text: 'offWhite',
    header: 'white',
    widgetBackground: 'bubblegum',
    widgetText: 'midnight',
  },
  {
    background: 'sky',
    shape: 'offWhite',
    mark: 'ocean',
    text: 'ocean',
    header: 'midnight',
    widgetBackground: 'bubblegum',
    widgetText: 'midnight',
  },
  {
    background: 'stone',
    shape: 'coral',
    mark: 'burgundy',
    text: 'midnight',
    header: 'midnight',
    widgetBackground: 'bubblegum',
    widgetText: 'midnight',
  },
  {
    background: 'sunshine',
    shape: 'lemon',
    mark: 'crust',
    text: 'midnight',
    header: 'midnight',
    widgetBackground: 'olive',
    widgetText: 'midnight',
  },
  {
    background: 'white',
    shape: 'sunshine',
    mark: 'olive',
    text: 'midnight',
    header: 'midnight',
    widgetBackground: 'bubblegum',
    widgetText: 'midnight',
  },
]

export const DOCUMENT_SLUG_MAP: Record<SanityDocumentTypeLinkable, string> = {
  article: `"/thoughts-and-opinions/" + slug.current`,
  articleIndex: `"/thoughts-and-opinions"`,
  chickenshed: `"/chicken-shed"`,
  collection: `"/collections/" + slug.current`,
  consentPolicy: `"/consent-policy"`,
  cookiePolicy: `"/cookie-policy"`,
  courses: `"/courses"`,
  eventIndex: `"/events"`,
  event: `"/events/" + slug.current`,
  ideas: `"/ideas"`,
  info: `"/info"`,
  manifesto: `"/manifestos/" + slug.current`,
  manifestoIndex: `"/manifestos"`,
  newsletter: `"/newsletter"`,
  newHere: `"/new-here"`,
  onlineCourse: `"/online-courses/" + slug.current`,
  onlineCourseIndex: `"/online-courses"`,
  page: `"/" + slug.current`,
  playlist: `"/talks/" + talks[0]->slug.current + "/" + id`,
  playlistIndex: `"/playlists"`,
  podcastIndex: `"/podcasts"`,
  privacyPolicy: `"/privacy-policy"`,
  product: `"/products/" + slug.current`,
  productIndex: `"/shop/all-products"`,
  readingList: `"/reading-lists/" + slug.current`,
  readingListIndex: `"/reading-lists"`,
  shippingAndReturns: `"/shipping-and-returns"`,
  shop: `"/shop"`,
  shopIndex: `"/shop/all-products"`,
  speaker: `"/speakers/" + slug.current`,
  speakerIndex: `"/speakers"`,
  talk: `select(
    defined(^.id) => "/talks/" + slug.current + "/" + ^.id,
    "/talks/" + slug.current
  )`,
  talks: `"/talks"`,
  teamMember: `"/team/" + slug.current`,
  teamMemberIndex: `"/team"`,
  termsAndConditions: `"/terms-and-conditions"`,
  workshop: `"/workshops/" + slug.current`,
  workshopIndex: `"/workshops"`,
}

export const DOCUMENT_TYPE_BOOST: {
  [K in SanityDocumentType]?: number
} = {
  category: 10,
  productType: 4,
  speaker: 6,
  tag: 4,
}

export const DOCUMENT_TYPE_MAP: Record<
  SanityDocumentTypeIndexable,
  SanityDocumentTypeSingleton
> = {
  article: 'articleIndex',
  course: 'courses',
  manifesto: 'manifestoIndex',
  onlineCourse: 'onlineCourseIndex',
  readingList: 'readingListIndex',
  playlist: 'playlistIndex',
  product: 'productIndex',
  talk: 'talks',
  workshop: 'workshopIndex',
}

export const FEATURED_MODULE_TYPES = [
  'moduleFeaturedCardsPair',
  'moduleFeaturedItems',
]

export const GRID_PAGE_SIZE = 6

export const ICON_TYPES = <const>[
  'add',
  'arrowLeft',
  'arrowRight',
  'cart',
  'chevronDown',
  'chevronLeft',
  'chevronRight',
  'clock',
  'close',
  'email',
  'filter',
  'globe',
  'learn',
  'link',
  'logoFacebook',
  'logoInstagram',
  'logoLinkedin',
  'logoMedium',
  'logoTwitter',
  'logoYoutube',
  'logoAnchor',
  'logoVimeo',
  'menu',
  'minus',
  'pause',
  'pin',
  'play',
  'playCircle',
  'screen',
  'search',
  'share',
  'shuffle',
  'suitcase',
  'volumeHigh',
  'volumeMute',
]

export const ILLUSTRATION_THICK_TYPES = <const>[
  'adventure',
  'apple',
  'bag',
  'beer',
  'book',
  'cart',
  'chat',
  'coffee',
  'create',
  'document',
  'education',
  'fire',
  'food',
  'globe',
  'graph',
  'info',
  'keyboard',
  'lightbulb',
  'like',
  'list',
  'lock',
  'magnifyingGlass',
  'music',
  'paperPlane',
  'present',
  'print',
  'question',
  'map',
  'rocket',
  'smile',
  'speaker',
  'star',
  'sun',
  'tick',
  'video',
]

export const ILLUSTRATION_THIN_TYPES = <const>['lightbulb', 'wineGlasses']

export const IS_PRODUCTION = process.env.NODE_ENV === 'production'

export const MODULE_SECTION_BACKGROUND_COLORS: Color[] = ['white', 'lightGray']

export const SANITY_API_VERSION = '2021-03-25'

export const SHOPIFY_PRODUCT_DOCUMENT_TYPE = 'shopify.product'

export const SINGLETON_TYPES = [
  'articleIndex',
  'chickenshed',
  'consentPolicy',
  'cookiePolicy',
  'courses',
  'eventIndex',
  'manifestoIndex',
  'newsletter',
  'onlineCourseIndex',
  'shippingAndReturns',
  'shop',
  'talks',
  'teamMemberIndex',
  'termsAndConditions',
  'workshopIndex',
]
