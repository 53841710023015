import { createAsyncThunk } from '@reduxjs/toolkit'
import { Checkout } from '@types'
import cookies from 'js-cookie'

import { CHECKOUT_COOKIE_ID } from '../../../../constants'
import createCheckout from '../../../../services/shopify/createCheckout'
import { CurrencyCode } from '../../../../types/codegen/shopify'
import getLineItemInputs from '../../../../utils/getLineItemInputs'
import { RootState } from '../../../store'

const presentmentCurrencySet = createAsyncThunk<
  // Return type of the payload creator
  { checkout: Checkout },
  // First argument to the payload creator
  CurrencyCode,
  {
    rejectValue: string
    state: RootState
  }
>(
  'store/presentmentCurrencySet',
  async (presentmentCurrency, { getState, rejectWithValue }) => {
    // Get existing checkout from store
    const checkout = getState().store.checkout

    // Get line items (inputs)
    const lineItemInputs = getLineItemInputs(
      checkout?.lineItems?.edges?.map(edge => edge.node)
    )

    // TODO: add custom attributes too
    // TODO: rather than creating a whole new checkout, should we just update the existing one?

    // Create new checkout with new currency code
    const { data } = await createCheckout({
      currencyCode: presentmentCurrency,
      lineItems: lineItemInputs,
    })
    const updatedCheckout = data?.checkoutCreate?.checkout

    if (!updatedCheckout) {
      // TODO: reject early / dispatch sentry error
      return rejectWithValue('No checkout found')
    } else {
      // TODO: DRY cookie setting?
      // Store checkout id cookie
      cookies.set(CHECKOUT_COOKIE_ID, updatedCheckout.id, {
        expires: 1000 * 60 * 60 * 24 * 7, // 7 days
      })

      return { checkout: updatedCheckout as Checkout }
    }
  }
)

export default presentmentCurrencySet
