import React from 'react'

const Document = () => {
  return (
    <>
      <path d="M7.10807 9.70991C7.16922 7.85454 7.20324 5.99365 7.18499 4.13735C7.18431 4.05471 7.19759 3.98176 7.21622 3.91303C7.17959 3.50854 7.41763 3.08133 7.93598 3.09493C14.4753 3.26641 21.013 3.01043 27.5526 3C28.0969 2.99907 28.335 3.47719 28.2679 3.89646C28.2898 3.96947 28.3045 4.04795 28.3037 4.13735C28.2048 11.9406 27.9368 23.1526 27.9282 30.9574C27.9282 31.1321 27.8786 31.2746 27.7991 31.3869C27.7511 31.7178 27.5133 32.0105 27.0834 31.9997C24.2569 31.9297 21.4292 31.884 18.6048 31.7456C16.0877 31.6224 12.9504 31.7876 10.5446 30.9659C9.69269 30.6749 8.9304 30.2449 8.27714 29.6222C7.08292 28.4837 6.67521 26.9557 6.57245 25.3452C6.47349 23.7986 6.65571 22.1765 6.69197 20.7515C6.78522 17.0702 6.987 13.3905 7.10807 9.70991ZM11.246 24.2744C11.7059 24.2769 12.1508 24.6364 11.9452 25.188C11.5316 26.2984 11.3424 27.6774 11.9783 28.7392C12.7476 30.0233 14.12 29.9883 15.4246 30.1529C16.2228 30.2535 17.0709 30.2002 17.8755 30.2233C18.5011 30.2409 26.4286 30.4342 26.4286 30.4651C26.4473 22.9528 26.6956 12.0324 26.796 4.52129C20.7583 4.54742 14.7224 4.75473 8.68504 4.62565C8.70553 10.1471 8.16576 19.0627 8.12888 24.5822C9.06557 24.2871 10.0334 24.162 11.0331 24.2288C11.1113 24.2334 11.1807 24.2514 11.246 24.2744ZM10.6561 29.4083C10.0498 28.3755 9.8982 27.1072 10.2138 25.7332C9.48359 25.7616 8.76352 25.9025 8.08157 26.1916C8.07871 26.2005 8.07685 26.2096 8.07368 26.2178C8.29471 27.8461 9.32502 28.8452 10.6561 29.4083Z" />
      <path d="M11.99 16.8091H23.0497C24.2025 16.8091 24.2025 18.6142 23.0497 18.6142H11.9899C10.8366 18.6142 10.8366 16.8091 11.99 16.8091Z" />
      <path d="M11.99 20.7803H23.0497C24.2025 20.7803 24.2025 22.5852 23.0497 22.5852H11.9899C10.8366 22.5852 10.8366 20.7803 11.99 20.7803Z" />
      <path d="M11.9901 13.1987H23.4966C24.6496 13.1987 24.6496 15.0038 23.4966 15.0038H11.9897C10.8367 15.0038 10.8367 13.1987 11.9901 13.1987Z" />
      <path d="M23.5861 10.852C21.5308 10.7686 14.1361 10.7618 12.0792 10.7615C10.9266 10.7615 10.9263 8.95623 12.0792 8.95654C14.1361 8.95685 21.5308 8.96331 23.5861 9.04688C24.7346 9.09338 24.7392 10.8986 23.5861 10.852Z" />
    </>
  )
}

export default Document
