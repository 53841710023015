import React from 'react'

import theme from '../../../styled/theme'

const Path02 = () => {
  return (
    <g fill={theme.colors.olive}>
      <path d="M56.6 94.0002C57.8 93.6002 59 93.1002 60.1 92.6002C67.9 89.0002 75.6 85.1002 83.5 81.5002C86.2 80.2002 87.5 78.3002 87.6 75.6002C85.3 74.1002 83 73.7002 80.4 73.9002C74.9 74.3002 69.4 74.6002 63.9 74.9002C62.8 75.0002 61.7 74.8002 59.9 74.8002C61.5 73.2002 62.5 72.3002 63.5 71.3002C71.9 63.0002 80.3 54.7002 88.8 46.5002C90.7 44.7002 91.7 42.6002 91.4 40.0002C89.1 38.9002 86.9 38.7002 84.5 39.7002C70.5 45.2002 56.5 50.7002 42.5 56.2002C41.7 56.5002 40.9 56.7002 39.4 57.2002C51.9 43.3002 60.4 28.1002 67.5 12.0002C68.7 9.20015 69.4 6.50015 68.4 3.50015C64.6 3.30015 64 3.40015 61.2 4.70015C55.9 7.10015 51.3 10.5002 47.2 14.5002C41.7 20.0002 36.3 25.6002 31 31.3002C24 38.6002 17.2 46.0002 10.2 53.4002C8.2 55.5002 6.3 57.6002 3.9 59.3002C4 57.9002 4.4 56.7002 4.9 55.6002C10 42.6002 12.1 29.2002 11.6 15.3002C11.5 13.0002 11.2 10.7002 9.2 8.80015C7 9.00015 5.3 9.90015 4.1 11.6002C2.8 13.5002 1.4 15.4002 0 17.3002V94.0002H56.6Z" />
    </g>
  )
}

export default Path02
