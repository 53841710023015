import {
  ArticlePreview,
  ManifestoPreview,
  OnlineCoursePreview,
  ProductPreview,
  TalkPreview,
  WorkshopPreview,
} from '@types'
import NextLink from 'next/link'
import React, { ReactNode, useState } from 'react'
import { AspectRatio, Box, Flex } from 'theme-ui'
import { CurrencyCode } from '../../types/codegen/shopify'
import isProductGuard from '../../utils/isProductGuard'
import Eyebrow from '../Eyebrow'
import NextImageZoomable from '../NextImageZoomable'
import Link from '../Link'
import ReferencesPrimary from '../ReferencesPrimary'
import ReferencesTags from '../ReferencesTags'
import TalkMark from '../TalkMark'
import VideoTimecode from '../VideoTimecode'
import ProductPresentmentPriceRange from '../ProductPresentmentPriceRange'

export type Props = {
  highlightedLabel?: string
  item:
    | ArticlePreview
    | ManifestoPreview
    | OnlineCoursePreview
    | ProductPreview
    | TalkPreview
    | WorkshopPreview
}

const NextLinkWrapper = (props: {
  active: boolean
  children?: ReactNode
  href: string
  passHref?: boolean
}) => {
  const { active, children, href, passHref } = props

  if (!active) {
    return (
      <Box
        sx={{
          a: {
            cursor: 'default',
            pointerEvents: 'none',
          },
        }}
      >
        {children}
      </Box>
    )
  }

  return (
    <NextLink href={href} passHref={passHref}>
      {children}
    </NextLink>
  )
}

const CardSlim = (props: Props) => {
  const { highlightedLabel, item } = props

  // State
  const [hover, setHover] = useState(false)

  const href = item.slug
  let anyVariantAvailable
  if (isProductGuard(item)) {
    const productVariants = item?.shopify?.variants || []
    anyVariantAvailable = productVariants.reduce((acc, val) => {
      if (val.inStock) {
        acc = true
      }
      return acc
    }, false as boolean)
  }

  const isProduct = item._type === 'product'

  return (
    <Flex
      sx={{
        flexDirection: 'row',
        minHeight: '88px',
        position: 'relative',
        width: '100%',
      }}
    >
      {/* Image */}
      <Box
        onMouseEnter={() => !highlightedLabel && setHover(true)}
        onMouseLeave={() => !highlightedLabel && setHover(false)}
        sx={{
          borderRadius: '2px', // TODO: use theme value?
          flexShrink: 0,
          overflow: 'hidden',
          position: 'relative',
          width: '154px',
        }}
      >
        <NextLinkWrapper active={!highlightedLabel} href={href}>
          <a>
            <AspectRatio bg="lightGray" ratio={154 / 88}>
              {item?.image && (
                <Flex
                  sx={{
                    alignItems: 'center',
                    flex: 1,
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      flexShrink: 0,
                      height: isProduct ? '60%' : '100%',
                      position: 'relative',
                      width: isProduct ? '80%' : '100%',
                    }}
                  >
                    <NextImageZoomable
                      image={item.image}
                      layout="fill"
                      objectFit={isProduct ? 'contain' : 'cover'}
                      sizes="154px"
                      zoomed={hover}
                    />
                  </Box>
                </Flex>
              )}
              {/* Mark + Timecode */}
              {item._type === 'talk' && item?.duration && (
                <>
                  <Box
                    sx={{
                      bottom: 0,
                      left: 0,
                      position: 'absolute',
                      width: '48px',
                    }}
                  >
                    <TalkMark stringToHash={item.title} />
                  </Box>

                  {/* Timecode */}
                  {item?.duration && (
                    <Box sx={{ bottom: 1, left: 1, position: 'absolute' }}>
                      <VideoTimecode duration={item.duration} />
                    </Box>
                  )}
                </>
              )}
              {/* Image overlay / highlight */}
              {highlightedLabel && (
                <Flex
                  sx={{
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'center',
                    left: 0,
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                  }}
                >
                  <Box
                    bg="midnight"
                    sx={{
                      height: '100%',
                      left: 0,
                      opacity: 0.7,
                      position: 'absolute',
                      top: 0,
                      width: '100%',
                    }}
                  />

                  <Eyebrow color="white" sx={{ position: 'relative' }}>
                    {highlightedLabel}
                  </Eyebrow>
                </Flex>
              )}
            </AspectRatio>
          </a>
        </NextLinkWrapper>
      </Box>
      <Box ml={3} pr={1}>
        <Box>
          {/* Title */}
          {item?.title && (
            <Box
              sx={{
                fontSize: 'xs',
                fontWeight: 'semibold',
              }}
            >
              <NextLinkWrapper active={!highlightedLabel} href={href} passHref>
                <Link
                  active={hover}
                  onMouseEnter={() => !highlightedLabel && setHover(true)}
                  onMouseLeave={() => !highlightedLabel && setHover(false)}
                  variant="simple"
                >
                  {item.title}
                </Link>
              </NextLinkWrapper>
            </Box>
          )}

          {/* Products only: Price / sold out */}
          {isProductGuard(item) && item.shopify && (
            <Box
              mt={1}
              sx={{
                fontSize: 'xs',
                fontWeight: 'semibold',
              }}
            >
              {anyVariantAvailable ? (
                <ProductPresentmentPriceRange
                  compareAtPriceRange={{
                    maxVariantPrice: {
                      amount:
                        item.shopify?.compareAtPriceRange?.maxVariantPrice,
                      currencyCode: 'GBP' as CurrencyCode,
                    },
                    minVariantPrice: {
                      amount:
                        item.shopify?.compareAtPriceRange?.minVariantPrice,
                      currencyCode: 'GBP' as CurrencyCode,
                    },
                  }}
                  displaySingleVariantComparePrice
                  priceRange={{
                    maxVariantPrice: {
                      amount: item.shopify?.priceRange?.maxVariantPrice,
                      currencyCode: 'GBP' as CurrencyCode,
                    },
                    minVariantPrice: {
                      amount: item.shopify?.priceRange?.minVariantPrice,
                      currencyCode: 'GBP' as CurrencyCode,
                    },
                  }}
                />
              ) : (
                <Eyebrow color="slate" mt={1}>
                  Sold out
                </Eyebrow>
              )}
            </Box>
          )}

          <Box
            color="slate"
            mt={isProductGuard(item) ? 1 : 2}
            sx={{ fontSize: 'xxs' }}
          >
            {/* Speakers / categories / product types */}
            <ReferencesPrimary item={item} />
          </Box>

          {/* Tag references */}
          <Box mt={2}>
            <ReferencesTags item={item} />
          </Box>
        </Box>
      </Box>
    </Flex>
  )
}

export default CardSlim
