import React from 'react'

const Info = () => {
  return (
    <>
      <path d="M20.3952 13.2174C20.3689 12.8888 20.1466 12.6002 19.7202 12.6435C18.0167 12.8175 16.3053 12.887 14.6024 13.0614C14.2661 12.9807 13.8849 13.1112 13.7763 13.5443C13.4968 14.66 13.2144 15.7737 13.0185 16.9079C12.9793 17.1349 13.0382 17.3281 13.15 17.4772C13.2243 17.7566 13.4393 18.0057 13.7983 18.0443C14.4544 18.1143 15.1114 18.158 15.7701 18.1766C15.675 21.3802 15.5504 24.5828 15.4587 27.7863C15.3618 28.196 15.5793 28.6797 16.12 28.6562C17.1151 28.6123 18.1094 28.6123 19.1047 28.6562C19.2813 28.6639 19.4229 28.6164 19.5311 28.5361C19.7729 28.4469 19.962 28.2349 19.9572 27.8977C19.8859 23.0747 20.3654 18.2688 20.4312 13.4489C20.4321 13.3626 20.4171 13.2874 20.3952 13.2174ZM18.5346 27.2189C17.989 27.2041 17.4439 27.1984 16.8983 27.2099C16.9937 24.0122 17.118 20.8157 17.209 17.6182C17.2173 17.3337 17.0857 17.1381 16.9002 17.0305C16.7869 16.8756 16.6073 16.7667 16.3564 16.7654C15.7368 16.7629 15.1196 16.7365 14.503 16.6879C14.6523 15.9355 14.8315 15.19 15.0163 14.4452C16.341 14.3218 17.6688 14.2449 18.9941 14.1295C18.902 18.4952 18.5151 22.8509 18.5346 27.2189Z" />
      <path d="M17.4988 5.85361C17.4351 5.84254 17.3753 5.84109 17.3165 5.84507C17.2289 5.80833 17.1342 5.78955 17.035 5.78955L16.9873 5.79082C15.9729 5.85013 14.9854 6.35845 14.3461 7.15032C13.7661 7.86833 13.5221 8.74868 13.6586 9.63004C13.8992 11.1843 15.3685 12.3567 17.0758 12.3567C17.3494 12.3567 17.6196 12.3248 17.8794 12.2613C19.1986 11.94 20.2432 10.6433 20.4209 9.10768C20.6257 7.33434 19.1058 6.12819 17.4988 5.85361ZM18.9163 8.82962C18.9504 9.52759 18.5117 10.2646 17.8248 10.6637C17.5952 10.7973 17.3372 10.8648 17.0586 10.8648C16.3566 10.8648 15.62 10.429 15.2672 9.80527C14.9641 9.26995 15.111 8.77075 15.288 8.44595C15.6269 7.82343 16.3498 7.34364 17.0071 7.30513C17.0199 7.3045 17.032 7.30292 17.0439 7.30095C17.0702 7.30911 17.0978 7.31607 17.128 7.32119C18.2754 7.51723 18.8769 8.02459 18.9163 8.82962Z" />
      <path d="M17.3545 2C14.4292 2 11.5884 2.74305 9.13988 4.14879C6.97598 5.39155 4.81385 8.06794 3.63097 10.1691C1.39194 14.147 1.4634 19.699 3.81354 24.3136C5.01021 26.6636 6.84571 28.5596 9.12154 29.7959C11.2191 30.9359 14.6075 32.2669 17.2532 32.2669C25.3993 32.2669 32.0899 25.7712 32.4846 17.4788C32.4916 17.3346 32.4961 17.1911 32.4987 17.0475C32.5447 14.3745 31.3369 11.308 30.9651 10.432C28.7615 5.23099 23.5458 2 17.3545 2ZM30.5139 17.3844C30.1693 24.6232 24.3444 30.2936 17.2533 30.2936C15.1231 30.2936 12.1077 29.1728 10.0633 28.0618C8.13963 27.0169 6.58662 25.4106 5.57189 23.4177C3.51335 19.3756 3.42646 14.5546 5.35049 11.1364C6.36667 9.33119 8.29362 6.9096 10.1229 5.85939C12.2729 4.62497 14.7736 3.97267 17.355 3.97267C22.7402 3.97267 27.2594 6.74265 29.1489 11.2014C29.8352 12.8205 30.5574 15.1976 30.5259 17.0134C30.5238 17.1371 30.52 17.2604 30.5139 17.3844Z" />
    </>
  )
}

export default Info
