import React from 'react'

const Path12 = () => {
  return (
    <>
      <path d="M142.382 29C144.926 60.0333 147.436 90.7533 150 122C99.865 106.007 50.1018 90.1333 0 74.1467C0.458248 72.6 0.84344 71.2733 1.24192 69.9534C4.44301 59.1933 7.684 48.4467 10.8253 37.6667C11.3433 35.8867 11.9544 35.02 13.9069 34.9333C52.5127 33.2333 91.1184 31.4333 129.724 29.6533C133.842 29.4533 137.973 29.2267 142.382 29Z" />
    </>
  )
}

export default Path12
