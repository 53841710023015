import { Theme } from '@theme-ui/css'
import { rgba } from 'polished'
import { COLORS } from '../constants'

// https://theme-ui.com/guides/typescript/#exact-theme-type
const makeTheme = <T extends Theme>(t: T) => t

// NOTE: defining a spaces array with length > 28 causes SVG issues with the `<select>` element in theme-ui
const spaces = new Array(55).fill(undefined).map((_, index) => `${index * 4}px`)

export const HEADER_HEIGHT_LARGE = 112 // px
export const HEADER_HEIGHT_SMALL = 68 // px
export const SUBHEADER_HEIGHT_LARGE = 73 // px

export const customScrollbarStyles = () => ({
  '::-webkit-scrollbar': {
    background: rgba(COLORS.slate, 0.3),
    width: '5px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '0',
    background: COLORS.slate,
    '&:hover': {
      background: COLORS.midnight,
    },
  },
})

const theme = makeTheme({
  // Remember that em units in media queries are always relative to 16px / the user setting
  // and NOT the html font size!
  // This means generally speaking:
  breakpoints: [
    '30em', // 1 - 480px
    '45em', // 2 - 720px
    '60em', // 3 - 960px
    '75em', // 4 - 1200px
    '90em', // 5 - 1440px
  ],
  colors: COLORS,
  fonts: {
    sans:
      'Spezia, -apple-system, BlinkMacSystemFont, helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif',
  },
  fontSizes: {
    xxxs: '10px', // cart item count
    xxs: '12px',
    xs: '14px',
    s: '16px',
    m: '18px',
    l: '24px',
    xl: '34px',
    xxl: '44px',
    xxxl: '74px',
    xxxxl: '100px',
  },
  fontWeights: {
    medium: 500,
    semibold: 600,
  },
  letterSpacings: {
    default: '-0.02em',
    loose: '0.05em',
  },
  lineHeights: {
    body: '1.4em',
    default: '1.2em',
    single: '1.0em', // and headings
  },
  radii: spaces,
  sizes: {
    menuHeight: ['100%', null, `calc(100vh - ${HEADER_HEIGHT_LARGE}px)`],
    moduleTextOneColMaxWidth: '675px',
    moduleTextTwoColMaxWidth: '1400px',
    headerHeight: [
      `${HEADER_HEIGHT_SMALL}px`,
      null,
      null,
      `${HEADER_HEIGHT_LARGE}px`,
    ],
  },
  space: spaces,
  zIndices: {
    debug: 9000,
    preview: 20,
    modal: 15,
    cart: 10,
    header: 9,
    subheader: 8,
    tooltip: 5,
    menu: 4,
    linkedPage: 2,
    content: 1,
  },
  // Variants
  buttons: {
    // default
    primary: {
      fontFamily: 'sans',
    },
  },
  forms: {
    input: {
      fontFamily: 'sans',
      letterSpacing: 'default',
    },
    label: {
      alignItems: 'center',
      display: 'flex',
    },
  },
  text: {
    // header default
    heading: {
      fontFamily: 'sans',
      fontWeight: 'semibold',
    },
    paragraph: {
      fontFamily: 'inherit',
    },
  },
})

export type ExactTheme = typeof theme

export default theme
