import React from 'react'
import { Text } from 'theme-ui'
import { MoneyV2 } from '../../types/codegen/shopify'
import formatIntlCurrency from '../../utils/formatIntlCurrency'

type Props = {
  compareAtPrice?: MoneyV2 | null
  price: MoneyV2
  quantity?: number
  significantFigures?: number
}

const FormattedMoney = (props: Props) => {
  const { compareAtPrice, price, quantity = 1, significantFigures = 2 } = props

  if (!price) {
    return null
  }

  const currencyCode = price?.currencyCode

  const formattedPrice = formatIntlCurrency({
    amount: price.amount * quantity,
    currencyCode,
    locale: 'en',
    significantFigures,
  })

  if (compareAtPrice && compareAtPrice.amount > 0) {
    const formattedCompareAtPrice = formatIntlCurrency({
      amount: compareAtPrice.amount * quantity,
      currencyCode,
      locale: 'en',
      significantFigures,
    })

    return (
      <Text>
        {compareAtPrice && (
          <Text sx={{ opacity: 0.5 }}>
            <s>{formattedCompareAtPrice}</s>
          </Text>
        )}
        <Text ml={'0.4em'}>{formattedPrice}</Text>
      </Text>
    )
  }

  // HACK: Return value wrapped in a fragment as TypeScript currently doesn't like returning
  // plain strings from functional components.
  // Source: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20544#issuecomment-434215660
  return <>{formattedPrice}</>
}

export default FormattedMoney
