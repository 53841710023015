import React from 'react'

const Food = () => {
  return (
    <>
      <path d="M16.1647 3.62883C15.9021 2.34704 13.7583 2.89129 14.0198 4.16662C14.2896 5.48431 13.9531 6.50121 13.1031 7.27534C13.0993 6.04826 13.0928 4.82152 13.0901 3.59409C13.0878 2.28945 10.8632 2.28911 10.8654 3.59409C10.8678 4.77086 10.8754 5.94756 10.8808 7.12405C10.2875 6.07002 10.1089 4.8743 10.3094 3.49299C10.4964 2.20248 8.26944 2.21889 8.08469 3.49299C7.69459 6.18101 8.6569 8.57978 10.8735 10.337C10.862 11.6608 10.8386 13.3893 10.7773 14.7128C10.6758 14.8256 10.6006 14.9712 10.5715 15.1618C9.8303 20.0003 8.46604 26.0712 9.23666 30.976C9.29265 31.3301 9.49725 31.541 9.75747 31.6404C9.8866 31.761 10.0461 31.8523 10.2361 31.8851C10.8527 31.9906 11.464 32.022 12.0894 32.0223C12.1086 32.0223 12.1262 32.0179 12.1449 32.0168C12.1637 32.0175 12.1813 32.0223 12.2009 32.0223C12.8221 32.022 13.4448 32.0116 14.0538 31.8851C14.1672 31.8613 14.2676 31.8157 14.3607 31.7606C14.6899 31.6125 14.9486 31.3302 14.9815 30.9095C15.3759 25.8705 14.2174 19.6554 13.0508 14.7572C13.0389 14.7084 13.0228 14.6642 13.0064 14.6206C13.0696 13.2328 13.0914 11.4404 13.1015 10.0522C13.1356 9.95519 13.1409 9.85497 13.1325 9.75421C15.6879 8.48416 16.7058 6.27072 16.1647 3.62883ZM12.8107 29.9924C12.6068 29.998 12.4033 29.999 12.2002 29.999C12.181 29.999 12.163 30.0033 12.1442 30.0042C12.1254 30.0032 12.1078 29.999 12.0886 29.999C11.8649 29.999 11.641 29.998 11.4173 29.9911C11.3858 29.99 11.3544 29.9872 11.3229 29.9854C10.9512 27.0569 11.4111 22.8889 11.9518 19.9635C12.5351 22.8767 12.9325 27.0279 12.8107 29.9924Z" />
      <path d="M24.4561 3.28264C24.4654 3.21483 24.465 3.14673 24.4549 3.07823C24.4446 2.59548 24.0824 2.32257 23.6701 2.26224C23.4597 2.21398 23.2249 2.22716 22.9822 2.34131C17.5476 4.89658 17.8489 12.4886 22.097 15.8779C22.1917 15.9536 22.2917 16.0039 22.3925 16.0418C22.3752 16.8422 22.3549 17.6425 22.3258 18.4421C22.1821 18.5466 22.0763 18.707 22.0411 18.936C21.3873 23.204 20.1843 27.487 20.8638 31.8132C20.9128 32.1255 21.0933 32.3113 21.3233 32.3991C21.4367 32.5055 21.5774 32.5864 21.7452 32.6149C22.2894 32.708 22.8283 32.7359 23.3797 32.7359C23.3966 32.7359 23.4123 32.7317 23.4287 32.7311C23.4452 32.7318 23.4609 32.7359 23.4778 32.7359C24.0258 32.7359 24.5749 32.7265 25.1122 32.6149C25.2122 32.594 25.3012 32.5539 25.3831 32.5051C25.6737 32.3744 25.9021 32.1255 25.9308 31.7547C26.2735 27.3803 25.2889 23.0384 24.2764 18.7836C24.4757 13.6248 24.3845 8.44439 24.4561 3.28264ZM22.4311 13.5495C20.4629 11.0504 20.1846 7.14592 22.476 4.91271C22.4522 7.78949 22.4622 10.6705 22.4311 13.5495ZM24.0158 30.9458C23.8365 30.951 23.6563 30.9517 23.4769 30.9517C23.4601 30.9517 23.4443 30.9556 23.428 30.9563C23.4114 30.9556 23.3957 30.9517 23.3789 30.9517C23.1815 30.9517 22.9842 30.9507 22.7868 30.9444C22.7588 30.9438 22.7317 30.9409 22.7036 30.9396C22.3759 28.3564 22.7814 25.751 23.2585 23.1714C23.7729 25.7401 24.1236 28.3313 24.0158 30.9458Z" />
    </>
  )
}

export default Food
