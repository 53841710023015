import { alpha } from '@theme-ui/color'
import React from 'react'
import { Box, Flex } from 'theme-ui'
import useTypedSelector from '../../hooks/useTypedSelector'
import LinkSanity from '../LinkSanity'
import PortableText from '../PortableText'
import SocialLinks from '../SocialLinks'

const Footer = () => {
  const footerLinks = useTypedSelector(state => state.navigation?.footer?.links)
  const footerText = useTypedSelector(state => state.navigation?.footer?.text)

  /*
  const handleSentryTestError = () => {
    console.log('send sentry test error')
    // @ts-ignore
    methodDoesNotExist()
  }
  */

  return (
    <Box bg="midnight">
      {/* Social links */}
      <Flex
        py={8}
        sx={{
          justifyContent: 'center',
        }}
      >
        <SocialLinks />
      </Flex>

      {/* Links */}
      <Flex
        pt={6}
        sx={{
          borderTop: '1px solid',
          borderColor: alpha('white', 0.2),
          flexWrap: 'wrap',
          justifyContent: 'center',
          flexDirection: ['column', 'column', 'row'],
        }}
      >
        {footerLinks?.map(link => (
          <Box color="white" key={link._key}>
            <LinkSanity
              link={link}
              sx={{
                fontSize: 'xs',
                lineHeight: 'body',
                whiteSpace: 'nowrap',
                mx: [4, null, '10px'], // TODO: move into theme
              }}
              variant="simple"
            >
              {link.title}
            </LinkSanity>
          </Box>
        ))}
      </Flex>

      {/* Footer text */}
      {footerText && (
        <Box
          pb={6}
          pt={[0, null, 5]}
          px={[4, null, 0]}
          sx={{
            color: 'slate',
            fontFamily: 'sans',
            fontSize: ['xs', null, 'xxs'],
            textAlign: ['left', null, 'center'],
          }}
        >
          <PortableText blocks={footerText} />
        </Box>
      )}

      {/* Sentry test error */}
      {/* <Link onClick={handleSentryTestError}>Sentry test error</Link> */}
    </Box>
  )
}

export default Footer
