import React from 'react'

const Path11 = () => {
  return (
    <>
      <path d="M72.3127 2C81.5404 2.45163 90.7046 2.66557 99.7025 4.93162C133.968 13.5759 155.536 47.5272 148.756 82.0489C146.672 92.6661 142.981 102.745 139.077 112.775C137.572 116.65 135.995 120.509 134.617 124.431C131.932 132.053 126.76 137.314 119.544 140.705C110.744 144.833 101.39 146.893 91.8135 148.089C74.6573 150.237 57.7148 148.327 40.8912 145.063C33.8655 143.7 26.8874 142.012 19.9647 140.158C12.8915 138.265 7.59254 133.431 3.00644 128.107C-0.573724 123.963 -0.161882 118.448 0.29752 113.243C0.432172 111.682 0.788674 110.121 1.16095 108.592C2.83222 101.722 6.62617 96.8892 13.5568 94.3855C21.4934 91.5251 29.6755 90.3208 37.9764 89.6949C45.1288 89.1561 52.313 89.0055 59.4733 88.6173C62.7683 88.443 66.0712 88.2845 69.3345 87.817C73.4216 87.2307 75.9483 84.7111 76.6612 80.9872C77.5483 76.3679 76.178 74.0385 71.6949 72.4142C61.8019 68.8249 51.6001 66.6777 41.1526 65.4575C33.5092 64.5622 26.3013 62.2724 20.0122 57.6135C11.4657 51.2907 8.56681 42.2344 11.846 32.1322C17.145 15.8024 28.8518 6.92829 45.3269 4.33738C54.2298 2.94288 63.3148 2.73687 72.3127 2Z" />
    </>
  )
}

export default Path11
